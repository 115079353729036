/* eslint-disable no-console */
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import React from "react";
import { notification } from "antd";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Alert, Form, Input } from "../../atoms";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { closeModal } from "../../../redux/data/modals/modalReducer";
import { ICreds } from "../../../common/types";
import { signInWithEmail } from "../../../firestore/firebaseService";

export const SignInForm: React.FC = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [, forceUpdate] = React.useState({});

  // To disable submit button at the beginning.
  React.useEffect(() => {
    forceUpdate({});
  }, []);

  const handleSubmit = async () => {
    try {
      const values: ICreds = await form.validateFields();
      setLoading(true);
      await signInWithEmail({
        email: values.email,
        password: values.password,
      });

      setLoading(false);
      dispatch(closeModal());
      history.push("/admin/");
      notification.success({
        message: "Notification Title",
        description:
          "This is the content of the notification. This is the content of the notification. This is the content of the notification.",
      });
    } catch (errorInfo: any) {
      console.log({ errorInfo });
      setLoading(false);
      setError("Problem with user name or password");
    }
  };
  return (
    <ModalWrapper {...props} onSave={handleSubmit} loading={loading}>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <Form form={form} name="horizontal_login" layout="vertical">
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          label="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
      </Form>
    </ModalWrapper>
  );
};
