import { Skeleton } from "antd";
import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import ChangePasswordPage from "../../Domain/Auth/ChangePasswordPage";
import ForgetPasswordPage from "../../Domain/Auth/ForgetPasswordPage";
import SignInPage from "../../Domain/Auth/SignInPage";
import SignOutPage from "../../Domain/Auth/SignOutPage";
import CompanyDetailsPage from "../../Domain/Company/CompanyDetailsPage";
import CompanyListPage from "../../Domain/Company/CompanyListPage";
import Dashboard from "../../Domain/Dashboard/DashboardPage";
import RegistrationPage from "../../Domain/Registration/RegistrationPage";
import BrandListPage from "../../Domain/Board/BrandListPage";
import BrandViewPage from "../../Domain/Board/BrandViewPage";
import ProfilePage from "../../Domain/Profile/ProfilePage";
import MyOKRsPage from "../../Domain/MyOKRs/MyOKRsPage";
import {
  BoardCycleList,
  BoardDetails,
  BoardInitiativeList,
  BoardKeyResultList,
  BoardObjectiveList,
  BoardReport,
  BoardSetting,
  BoardUserList,
  CompanyBilling,
  CompanyDepartmentList,
  CompanyDetails,
  CompanyNotifications,
  CompanyPermissions,
  CompanyUserList,
  CycleDetails,
  InitiativeDetails,
  ObjectiveInitiativeList,
  ProfileNotifications,
  ProfileDetails,
  ProfileSitting,
  RegCompanyDetails,
  RegCompanyInfo,
  RegEmailVerification,
  RegSignIn,
  RegTeamInvitation,
  RegUserDetails,
  UserDetails,
  UserInitiativeList,
  UserOkrList,
  CycleList,
  CyclesBoard,
  CompanyOkrList,
  CompanyOkrsInitiativeList,
  CompanyOkrsDetails,
  UserList,
  UsersChart,
  TeamOkrList,
  TeamInitiativeList,
  TeamDetails,
  CompanyOkrsChart,
  CompanyOkrs,
  CycleOkrList,
  KeyResultDetails,
  KeyResultCheckInList,
  MyOkrList,
} from "../components/organisms";
import { ObjectiveDetails } from "../components/organisms/Okr/ObjectiveDetails";
import CycleViewPage from "../../Domain/Cycle/CycleViewPage";
import UserViewPage from "../../Domain/User/UserViewPage";
import InitiativeViewPage from "../../Domain/Initiative/InitiativeViewPage";
import { MyOKRsInitiativeList } from "../components/organisms/MyOkrs/MyOKRsInitiativeList";
import CyclesPage from "../../Domain/Cycle/CyclesPage";
import UsersPage from "../../Domain/User/UsersPage";
import TeamPage from "../../Domain/TeamOKRs/TeamOKRsPage";
import TestPage from "../../Domain/Test/TestPage";
import OKRsViewPage from "../../Domain/OKRs/OKRsViewPage";
import CompanyOKRsPage from "../../Domain/CompanyOKRs/CompanyOKRsPage";
import ReportPage from "../../Domain/Report/ReportPage";
import PrivateRoute from "./PrivateRoute";

interface IClickableProps {
  childProps: string;
}

// function Redirect({ to }: { to: string }) {
//   const navigate = useNavigate();
//   React.useEffect(() => {
//     navigate(to);
//   });
//   return null;
// }
const AppRouter: React.FC<IClickableProps> = ({ childProps }): JSX.Element => (
  <Suspense fallback={<Skeleton paragraph active loading />}>
    <Routes>
      <Route path="/change-password/:key" element={<ChangePasswordPage />} />
      <Route path="/forget-password" element={<ForgetPasswordPage />} />
      <Route path="/sign-in" element={<SignInPage />} />
      <Route path="/sign-out" element={<SignOutPage />} />
      <Route path="/registration" element={<RegistrationPage />}>
        <Route path="/registration/sign-up" element={<RegSignIn />} />
        <Route
          path="/registration/email-verification/:key"
          element={<RegEmailVerification />}
        />
        <Route
          path="/registration/user-details/:key"
          element={<RegUserDetails />}
        />
        <Route
          path="/registration/company-info/:key"
          element={<RegCompanyInfo />}
        />
        <Route
          path="/registration/company-details/:key"
          element={<RegCompanyDetails />}
        />
        <Route
          path="/registration/user-invitations/:key"
          element={<RegTeamInvitation />}
        />
      </Route>

      <Route
        path="/company"
        element={
          <PrivateRoute>
            <CompanyDetailsPage />
          </PrivateRoute>
        }
      >
        <Route path="/company/details" element={<CompanyDetails />} />
        <Route
          path="/company/departments"
          element={<CompanyDepartmentList />}
        />
        <Route path="/company/users" element={<CompanyUserList />} />
        <Route
          path="/company/notifications"
          element={<CompanyNotifications />}
        />
        <Route path="/company/permissions" element={<CompanyPermissions />} />
        <Route path="/company/billing" element={<CompanyBilling />} />
      </Route>
      <Route
        path="/companies"
        element={
          <PrivateRoute>
            <CompanyListPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/company-okrs"
        element={
          <PrivateRoute>
            <CompanyOKRsPage />
          </PrivateRoute>
        }
      >
        <Route path="/company-okrs/details" element={<CompanyOkrsDetails />} />
        <Route path="/company-okrs/okrs" element={<CompanyOkrs />}>
          <Route
            path="/company-okrs/okrs/chart"
            element={<CompanyOkrsChart />}
          />
          <Route path="/company-okrs/okrs/list" element={<CompanyOkrList />} />
        </Route>
        <Route
          path="/company-okrs/initiatives"
          element={<CompanyOkrsInitiativeList />}
        />
      </Route>
      <Route
        path="/team/:teamId"
        element={
          <PrivateRoute>
            <TeamPage />
          </PrivateRoute>
        }
      >
        <Route path="/team/:teamId/details" element={<TeamDetails />} />
        <Route path="/team/:teamId/okrs" element={<TeamOkrList />} />
        <Route
          path="/team/:teamId/initiatives"
          element={<TeamInitiativeList />}
        />
      </Route>
      <Route
        path="/users"
        element={
          <PrivateRoute>
            <UsersPage />
          </PrivateRoute>
        }
      >
        <Route path="/users/list" element={<UserList />} />
        <Route path="/users/chart" element={<UsersChart />} />
      </Route>
      <Route
        path="/cycles"
        element={
          <PrivateRoute>
            <CyclesPage />
          </PrivateRoute>
        }
      >
        <Route path="/cycles/list" element={<CycleList />} />
        <Route path="/cycles/board" element={<CyclesBoard />} />
      </Route>
      <Route
        path="/board/:boardId"
        element={
          <PrivateRoute>
            <BrandViewPage />
          </PrivateRoute>
        }
      >
        <Route path="/board/:boardId/details" element={<BoardDetails />} />
        <Route
          path="/board/:boardId/objectives"
          element={<BoardObjectiveList />}
        />
        <Route
          path="/board/:boardId/key-results"
          element={<BoardKeyResultList />}
        />
        <Route
          path="/board/:boardId/initiatives"
          element={<BoardInitiativeList />}
        />
        <Route path="/board/:boardId/users" element={<BoardUserList />} />
        <Route path="/board/:boardId/cycles" element={<BoardCycleList />} />
        <Route path="/board/:boardId/report" element={<BoardReport />} />
        <Route path="/board/:boardId/setting" element={<BoardSetting />} />
      </Route>
      <Route
        path="/boards"
        element={
          <PrivateRoute>
            <BrandListPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/objective/:okrId"
        element={
          <PrivateRoute>
            <OKRsViewPage />
          </PrivateRoute>
        }
      >
        <Route
          path="/objective/:okrId/details"
          element={<ObjectiveDetails />}
        />
        <Route
          path="/objective/:okrId/initiatives"
          element={<ObjectiveInitiativeList />}
        />
      </Route>
      <Route
        path="/key-result/:okrId"
        element={
          <PrivateRoute>
            <OKRsViewPage />
          </PrivateRoute>
        }
      >
        <Route
          path="/key-result/:okrId/details"
          element={<KeyResultDetails />}
        />
        <Route
          path="/key-result/:okrId/initiatives"
          element={<ObjectiveInitiativeList />}
        />
        <Route
          path="/key-result/:okrId/check-ins"
          element={<KeyResultCheckInList />}
        />
      </Route>
      <Route
        path="/cycle/:cycleId"
        element={
          <PrivateRoute>
            <CycleViewPage />
          </PrivateRoute>
        }
      >
        <Route path="/cycle/:cycleId/details" element={<CycleDetails />} />
        <Route path="/cycle/:cycleId/okrs" element={<CycleOkrList />} />
      </Route>
      <Route
        path="/initiative/:initiativeId"
        element={
          <PrivateRoute>
            <InitiativeViewPage />
          </PrivateRoute>
        }
      >
        <Route
          path="/initiative/:initiativeId/details"
          element={<InitiativeDetails />}
        />
      </Route>
      <Route
        path="/user/:userId"
        element={
          <PrivateRoute>
            <UserViewPage />
          </PrivateRoute>
        }
      >
        <Route path="/user/:userId/details" element={<UserDetails />} />
        <Route path="/user/:userId/okrs" element={<UserOkrList />} />
        <Route
          path="/user/:userId/initiatives"
          element={<UserInitiativeList />}
        />
      </Route>
      <Route
        path="/profile"
        element={
          <PrivateRoute>
            <ProfilePage />
          </PrivateRoute>
        }
      >
        <Route path="/profile/details" element={<ProfileDetails />} />
        <Route
          path="/profile/notifications"
          element={<ProfileNotifications />}
        />
        <Route path="/profile/setting" element={<ProfileSitting />} />
      </Route>
      <Route
        path="/my-okr"
        element={
          <PrivateRoute>
            <MyOKRsPage />
          </PrivateRoute>
        }
      >
        <Route path="/my-okr/okrs" element={<MyOkrList />} />
        <Route path="/my-okr/initiatives" element={<MyOKRsInitiativeList />} />
      </Route>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/test"
        element={
          <PrivateRoute>
            <TestPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/reports"
        element={
          <PrivateRoute>
            <ReportPage />
          </PrivateRoute>
        }
      />
      <Route
        path="*"
        element={
          <main style={{ padding: "1rem" }}>
            <p>Theres nothing here!</p>
          </main>
        }
      />
    </Routes>
  </Suspense>
);

export default AppRouter;
