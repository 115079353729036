// import { Progress } from "antd";
import React from "react";
import { Tag } from "../../../atoms";
import { OkrProgressBarWrapper } from "./OkrProgressBar.style";

const progressTypes: any = {
  onTrack: "On Track",
  behind: "Behind",
  atRisk: "At Risk",
};

export const OkrProgressBar: React.FC<{ score: number; progress: string }> = ({
  score,
  progress = "onTrack",
}) => (
  <OkrProgressBarWrapper className={`okr-progress-${progress}`}>
    <div
      className="okr-progress-bar-progress"
      style={{ height: `${Math.round(+score || 0)}%` }}
    ></div>
    <div className="okr-progress-bar-details">
      {/* <Progress type="circle" percent={Math.round(+score || 0)} /> */}
      <div className="okr-progress-bar-score">{Math.round(+score || 0)}%</div>
      <Tag className="okr-progress-bar-tag">{progressTypes[progress]}</Tag>
    </div>
  </OkrProgressBarWrapper>
);
