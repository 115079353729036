/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import {
  fetchCompanyCyclesFromFirestore,
  fetchCompanyDepartmentsFromFirestore,
  fetchCompanyUsersFromFirestore,
  listenToCompanyFromFirestore,
} from "../../App/firestore/firestoreService";
import { userFirestoreDoc } from "../../App/hooks/useFirestoreDoc";
import { useFirestoreCollection } from "../../App/hooks/useFirestoreCollection";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";
import {
  listenToSelectedCompany,
  listenToSelectedCompanyDefaultCycles,
  listenToSelectedCompanyDepartments,
  listenToSelectedCompanyUsers,
} from "../../App/redux/data/company/companyActions";

const currentMenu = [
  {
    key: "details",
    label: "Details",
    link: `/company/details`,
  },
  {
    key: "departments",
    label: "Departments",
    link: `/company/departments`,
  },
  {
    key: "users",
    label: "Users",
    link: `/company/users`,
  },

  {
    key: "permissions",
    label: "Permissions",
    link: `/company/permissions`,
  },
  {
    key: "billing",
    label: "Billing",
    link: `/company/billing`,
  },
];

const CompanyDetailsPage: React.FC = () => {
  const dispatch = useDispatch();

  userFirestoreDoc({
    query: () => listenToCompanyFromFirestore(),
    data: listenToSelectedCompany,
    deps: [dispatch],
  });

  useFirestoreCollection({
    query: () => fetchCompanyUsersFromFirestore(),
    data: listenToSelectedCompanyUsers,
    deps: [dispatch],
  });
  useFirestoreCollection({
    query: () => fetchCompanyDepartmentsFromFirestore(),
    data: listenToSelectedCompanyDepartments,
    deps: [dispatch],
  });
  useFirestoreCollection({
    query: () =>
      fetchCompanyCyclesFromFirestore([
        {
          type: "filter",
          name: "isDefault",
          opr: "==",
          value: true,
        },
      ]),
    data: listenToSelectedCompanyDefaultCycles,
    deps: [dispatch],
  });
  return (
    <AdminPage title={"Company Setting"} headerMenu={currentMenu}>
      <Outlet />
    </AdminPage>
  );
};

export default CompanyDetailsPage;
