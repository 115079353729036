import { PageHeader as AntPageHeader, PageHeaderProps } from "antd";
import styled from "styled-components";

export const PageHeader: typeof AntPageHeader = styled(
  AntPageHeader
)<PageHeaderProps>`
  background-color: #fff;
  padding: 5px 30px;
  .ant-page-header-heading {
    margin-top: 0;
  }
  .ant-page-header-heading-title {
    color: #333;
  }
`;
