/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import {
  CheckOutlined,
  EllipsisOutlined,
  LikeOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { Button, Tag } from "../../atoms";
import {
  ActivityListCard,
  CycleCard,
  // ItemNotFound,
  ObjectiveListCard,
} from "../../molecules";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { DrawerWrapper } from "../../../common/Drawer/DrawerWrapper";
import { ICycle } from "../../../common/types";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { listenToCompanyCycleFromFirestore } from "../../../firestore/firestoreService";

interface ICycleDetailsDrawerProps {
  id: string;
}
export const CycleDetailsDrawer: React.FC<ICycleDetailsDrawerProps> = (
  props
) => {
  const cycleId = props.id;
  const dispatch = useDispatch();
  const [cycle, setCycle] = React.useState<ICycle>();

  userFirestoreDoc({
    query: () => listenToCompanyCycleFromFirestore(cycleId),
    data: setCycle,
    deps: [dispatch, cycleId],
    local: true,
  });

  const handelAddObjective = () => {
    dispatch(
      openModal({
        modalType: "AddObjectiveModal",
        modalProps: {
          title: "Create new objective",
          cycleId,
        },
      })
    );
  };
  // if (dataLoading) return <Loader color="success" title="Loading" />;
  if (!cycle) {
    return <div></div>;
  }
  return (
    <DrawerWrapper
      {...props}
      width={700}
      footer={null}
      extra={
        <>
          <LikeOutlined className="drawer-extra-btn-link" />
          <LinkOutlined className="drawer-extra-btn-link" />
          <EllipsisOutlined className="drawer-extra-btn-link" />
        </>
      }
      title={
        <div className="drawer-title">
          <Tag className="drawer-title-tag">Cycle</Tag>
          <Button
            onClick={handelAddObjective}
            icon={<CheckOutlined />}
            className="drawer-title-btn"
          >
            Add Objective
          </Button>
        </div>
      }
    >
      <CycleCard cycle={cycle} />
      <ObjectiveListCard
        cycleId={cycleId}
        action={handelAddObjective}
        greyHeader
      />
      <ActivityListCard activities={[]} />
    </DrawerWrapper>
  );
};
