import { values } from "lodash";
import { ISelectOption } from "../common/types";

/* eslint-disable prettier/prettier */
interface IIndustryMap { [key: string]: ISelectOption; }
export const industries: IIndustryMap = {
  academia: {
    value: 'academia',
    label: 'Academia',
  },
  accounting: {
    value: 'accounting',
    label: 'Accounting',
  },
  aerospace: {
    value: 'aerospace',
    label: 'Aerospace',
  },
  agriculture: {
    value: 'agriculture',
    label: 'Agriculture',
  },
  airlines: {
    value: 'airlines',
    label: 'Airlines',
  },
  alternativeMedicine: {
    value: 'alternativeMedicine',
    label: 'Alternative Medicine',
  },
  apparelFashion: {
    value: 'apparelFashion',
    label: 'Apparel & Fashion',
  },
  architecturePlanning: {
    value: 'architecturePlanning',
    label: 'Architecture & Planning',
  },
  artsCrafts: {
    value: 'artsCrafts',
    label: 'Arts & Crafts',
  },
  automotive: {
    value: 'automotive',
    label: 'Automotive',
  },
  banking: {
    value: 'banking',
    label: 'Banking',
  },
  biotechnology: {
    value: 'biotechnology',
    label: 'Biotechnology',
  },
  broadcastMedia: {
    value: 'broadcastMedia',
    label: 'Broadcast Media',
  },
  buildingMaterials: {
    value: 'buildingMaterials',
    label: 'Building Materials',
  },
  businessSuppliesEquipment: {
    value: 'businessSuppliesEquipment',
    label: 'Business Supplies &  Equipment',
  },
  chemicals: {
    value: 'chemicals',
    label: 'Chemicals',
  },
  civicSocialOrganizations: {
    value: 'civicSocialOrganizations',
    label: 'Civic &  Social Organizations',
  },
  civilEngineering: {
    value: 'civilEngineering',
    label: 'Civil Engineering',
  },
  civilService: {
    value: 'civilService',
    label: 'Civil Service',
  },
  composites: {
    value: 'composites',
    label: 'Composites',
  },
  computerNetworkSecurity: {
    value: 'computerNetworkSecurity',
    label: 'Computer &  Network Security',
  },
  computerGames: {
    value: 'computerGames',
    label: 'Computer Games',
  },
  computerHardware: {
    value: 'computerHardware',
    label: 'Computer Hardware',
  },
  computerNetworking: {
    value: 'computerNetworking',
    label: 'Computer Networking',
  },
  computerSoftware: {
    value: 'computerSoftware',
    label: 'Computer Software',
  },
  construction: {
    value: 'construction',
    label: 'Construction',
  },
  consulting: {
    value: 'consulting',
    label: 'Consulting',
  },
  consumerElectronics: {
    value: 'consumerElectronics',
    label: 'Consumer Electronics',
  },
  consumerGoods: {
    value: 'consumerGoods',
    label: 'Consumer Goods',
  },
  consumerServices: {
    value: 'consumerServices',
    label: 'Consumer Services',
  },
  cosmetics: {
    value: 'cosmetics',
    label: 'Cosmetics',
  },
  daycare: {
    value: 'daycare',
    label: 'Daycare',
  },
  defenseMilitary: {
    value: 'defenseMilitary',
    label: 'Defense/Military',
  },
  design: {
    value: 'design',
    label: 'Design',
  },
  education: {
    value: 'education',
    label: 'Education',
  },
  elearning: {
    value: 'elearning',
    label: 'E-learning',
  },
  electricalEngineering: {
    value: 'electricalEngineering',
    label: 'Electrical Engineering',
  },
  energy: {
    value: 'energy',
    label: 'Energy',
  },
  entertainment: {
    value: 'entertainment',
    label: 'Entertainment',
  },
  environmentalServices: {
    value: 'environmentalServices',
    label: 'Environmental Services',
  },
  eventsServices: {
    value: 'eventsServices',
    label: 'Events Services',
  },
  facilitiesServices: {
    value: 'facilitiesServices',
    label: 'Facilities Services',
  },
  facilityManagement: {
    value: 'facilityManagement',
    label: 'Facility Management',
  },
  financialServices: {
    value: 'financialServices',
    label: 'Financial Services',
  },
  fishery: {
    value: 'fishery',
    label: 'Fishery',
  },
  food: {
    value: 'food',
    label: 'Food',
  },
  fundraising: {
    value: 'fundraising',
    label: 'Fundraising',
  },
  furniture: {
    value: 'furniture',
    label: 'Furniture',
  },
  gardeningLandscaping: {
    value: 'gardeningLandscaping',
    label: 'Gardening/Landscaping',
  },
  geology: {
    value: 'geology',
    label: 'Geology',
  },
  glassCeramics: {
    value: 'glassCeramics',
    label: 'Glass & Ceramics',
  },
  graphicDesign: {
    value: 'graphicDesign',
    label: 'Graphic Design',
  },
  healthFitness: {
    value: 'healthFitness',
    label: 'Health & Fitness',
  },
  hospitality: {
    value: 'hospitality',
    label: 'Hospitality',
  },
  humanResources: {
    value: 'humanResources',
    label: 'Human Resources',
  },
  importExport: {
    value: 'importExport',
    label: 'Import & Export',
  },
  industrialAutomation: {
    value: 'industrialAutomation',
    label: 'Industrial Automation',
  },
  informationServices: {
    value: 'informationServices',
    label: 'Information Services',
  },
  informationTechnologyServices: {
    value: 'informationTechnologyServices',
    label: 'Information Technology & Services',
  },
  insurance: {
    value: 'insurance',
    label: 'Insurance',
  },
  internationalAffairs: {
    value: 'internationalAffairs',
    label: 'International Affairs',
  },
  internationalTradeDevelopment: {
    value: 'internationalTradeDevelopment',
    label: 'International Trade & Development',
  },
  internet: {
    value: 'internet',
    label: 'Internet',
  },
  investmentBanking: {
    value: 'investmentBanking',
    label: 'Investment Banking',
  },
  journalism: {
    value: 'journalism',
    label: 'Journalism',
  },
  legalServices: {
    value: 'legalServices',
    label: 'Legal Services',
  },
  leisureTravelTourism: {
    value: 'leisureTravelTourism',
    label: 'Leisure, Travel & Tourism',
  },
  logisticsSupplyChain: {
    value: 'logisticsSupplyChain',
    label: 'Logistics & Supply Chain',
  },
  luxuryGoodsJewelry: {
    value: 'luxuryGoodsJewelry',
    label: 'Luxury Goods & Jewelry',
  },
  machinery: {
    value: 'machinery',
    label: 'Machinery',
  },
  managementConsulting: {
    value: 'managementConsulting',
    label: 'Management Consulting',
  },
  maritime: {
    value: 'maritime',
    label: 'Maritime',
  },
  marketingAdvertising: {
    value: 'marketingAdvertising',
    label: 'Marketing & Advertising',
  },
  marketResearch: {
    value: 'marketResearch',
    label: 'Market Research',
  },
  mechanicalIndustrialEngineering: {
    value: 'mechanicalIndustrialEngineering',
    label: 'Mechanical / Industrial Engineering',
  },
  mediaProduction: {
    value: 'mediaProduction',
    label: 'Media Production',
  },
  medicalDevices: {
    value: 'medicalDevices',
    label: 'Medical Devices',
  },
  medicalServices: {
    value: 'medicalServices',
    label: 'Medical Services',
  },
  medicinalProducts: {
    value: 'medicinalProducts',
    label: 'Medicinal Products',
  },
  metalMetalworking: {
    value: 'metalMetalworking',
    label: 'Metal / Metalworking',
  },
  metrologyControlEngineering: {
    value: 'metrologyControlEngineering',
    label: 'Metrology / Control Engineering',
  },
  miningMetals: {
    value: 'miningMetals',
    label: 'Mining & Metals',
  },
  motionPictures: {
    value: 'motionPictures',
    label: 'Motion Pictures',
  },
  museumsCulturalInstitutions: {
    value: 'museumsCulturalInstitutions',
    label: 'Museums & Cultural Institutions',
  },
  music: {
    value: 'music',
    label: 'Music',
  },
  nanotechnology: {
    value: 'nanotechnology',
    label: 'Nanotechnology',
  },
  nonpProfitOrganization: {
    value: 'nonpProfitOrganization',
    label: 'Non-profit Organization',
  },
  nursingPersonalCare: {
    value: 'nursingPersonalCare',
    label: 'Nursing & Personal Care',
  },
  oilEnergy: {
    value: 'oilEnergy',
    label: 'Oil & Energy',
  },
  onlineMedia: {
    value: 'onlineMedia',
    label: 'Online Media',
  },
  outsourcingOffshoring: {
    value: 'outsourcingOffshoring',
    label: 'Outsourcing / Offshoring',
  },
  packagingContainers: {
    value: 'packagingContainers',
    label: 'Packaging & Containers',
  },
  paperForestProducts: {
    value: 'paperForestProducts',
    label: 'Paper & Forest Products',
  },
  pharmaceuticals: {
    value: 'pharmaceuticals',
    label: 'Pharmaceuticals',
  },
  photography: {
    value: 'photography',
    label: 'Photography',
  },
  plastics: {
    value: 'plastics',
    label: 'Plastics',
  },
  politics: {
    value: 'politics',
    label: 'Politics',
  },
  printing: {
    value: 'printing',
    label: 'Printing',
  },
  printMedia: {
    value: 'printMedia',
    label: 'Print Media',
  },
  processManagement: {
    value: 'processManagement',
    label: 'Process Management',
  },
  professionalTrainingCoaching: {
    value: 'professionalTrainingCoaching',
    label: 'Professional Training & Coaching',
  },
  psychologyPsychotherapy: {
    value: 'psychologyPsychotherapy',
    label: 'Psychology / Psychotherapy',
  },
  publicHealth: {
    value: 'publicHealth',
    label: 'Public Health',
  },
  publicRelationsCommunications: {
    value: 'publicRelationsCommunications',
    label: 'Public Relations & Communications',
  },
  publishing: {
    value: 'publishing',
    label: 'Publishing',
  },
  railroad: {
    value: 'railroad',
    label: 'Railroad',
  },
  realEstate: {
    value: 'realEstate',
    label: 'Real Estate',
  },
  recreationalFacilitiesServices: {
    value: 'recreationalFacilitiesServices',
    label: 'Recreational Facilities & Services',
  },
  recyclingWasteManagement: {
    value: 'recyclingWasteManagement',
    label: 'Recycling & Waste Management',
  },
  religion: {
    value: 'religion',
    label: 'Religion',
  },
  renewablesEnvironment: {
    value: 'renewablesEnvironment',
    label: 'Renewables & Environment',
  },
  research: {
    value: 'research',
    label: 'Research',
  },
  restaurantsFoodService: {
    value: 'restaurantsFoodService',
    label: 'Restaurants & Food Service',
  },
  retail: {
    value: 'retail',
    label: 'Retail',
  },
  securityInvestigations: {
    value: 'securityInvestigations',
    label: 'Security & Investigations',
  },
  semiconductors: {
    value: 'semiconductors',
    label: 'Semiconductors',
  },
  shipbuilding: {
    value: 'shipbuilding',
    label: 'Shipbuilding',
  },
  sports: {
    value: 'sports',
    label: 'Sports',
  },
  staffingRecruiting: {
    value: 'staffingRecruiting',
    label: 'Staffing & Recruiting',
  },
  taxAccountancyAuditing: {
    value: 'taxAccountancyAuditing',
    label: 'Tax accountancy / Auditing',
  },
  telecommunication: {
    value: 'telecommunication',
    label: 'Telecommunication',
  },
  textiles: {
    value: 'textiles',
    label: 'Textiles',
  },
  theaterStageCinema: {
    value: 'theaterStageCinema',
    label: 'Theater / Stage / Cinema',
  },
  timber: {
    value: 'timber',
    label: 'Timber',
  },
  trafficEngineering: {
    value: 'trafficEngineering',
    label: 'Traffic Engineering',
  },
  translationLocalization: {
    value: 'translationLocalization',
    label: 'Translation & Localization',
  },
  transport: {
    value: 'transport',
    label: 'Transport',
  },
  ventureCapitalPrivateEquity: {
    value: 'ventureCapitalPrivateEquity',
    label: 'Venture Capital & Private Equity',
  },
  veterinary: {
    value: 'veterinary',
    label: 'Veterinary',
  },
  welfareCommunityHealth: {
    value: 'welfareCommunityHealth',
    label: 'Welfare & Community Health',
  },
  wholesale: {
    value: 'wholesale',
    label: 'Wholesale',
  },
  wineSpirits: {
    value: 'wineSpirits',
    label: 'Wine & Spirits',
  },
  writingEditing: {
    value: 'writingEditing',
    label: 'Writing & Editing',
  },
  other: {
    value: 'other',
    label: 'Other',
  },
}

export const industryOptions = values(industries);

