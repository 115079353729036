import {
  AimOutlined,
  DashboardOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  GlobalOutlined,
  HistoryOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React from "react";
import classNames from "classnames";
import { IOkr } from "../../../../common/types";
import { okrsForTypes } from "../../../../enums";
import { UserCell } from "../../CommonCells/UserCell";
import { CycleCell } from "../../CommonCells/CycleCell";
import { AlignmentViewWrapper } from "./AlignmentView.style";

interface IAlignmentViewProps {
  objective: IOkr;
  keyResult?: IOkr;
  showAlignment?: boolean;
}
export const AlignmentView: React.FC<IAlignmentViewProps> = ({
  objective,
  keyResult,
  showAlignment,
}) => {
  const [hideView, setHideView] = React.useState(false);
  const toggleHideShow = () => {
    setHideView((v) => !v);
  };
  return (
    <AlignmentViewWrapper
      className={classNames({
        "alignment-view-hidden": hideView,
      })}
    >
      <div className="alignment-view-body">
        {objective?.okrFor === okrsForTypes.COMPANY_OBJECTIVE.value && (
          <div className="alignment-view-item">
            <GlobalOutlined className="alignment-view-icon" />
            <span>Company OKRs</span>
          </div>
        )}
        {objective?.okrFor === okrsForTypes.TEAM_OBJECTIVE.value && (
          <div className="alignment-view-item">
            <TeamOutlined className="alignment-view-icon" />
            <span>{objective?.teamId} OKRs</span>
          </div>
        )}
        {objective?.okrFor === okrsForTypes.INDIVIDUAL_OBJECTIVE.value && (
          <div className="alignment-view-item">
            <UserOutlined className="alignment-view-icon" />
            <span>
              <UserCell user={objective.owner} nameOnly small />
            </span>
          </div>
        )}
        <span className="alignment-view-line alignment-view-line-bottom"></span>
        <div className="alignment-view-item">
          {" "}
          <HistoryOutlined className="alignment-view-icon" />
          <span>
            <CycleCell cycle={null} nameOnly />
          </span>
        </div>
        <span className="alignment-view-line alignment-view-line-bottom"></span>
        <div className="alignment-view-item">
          {" "}
          <AimOutlined className="alignment-view-icon" />
          <span>{objective?.title}</span>
        </div>

        {keyResult?.okrParentId && (
          <span className="alignment-view-line alignment-view-line-bottom"></span>
        )}
        {keyResult?.okrParentId && (
          <div className="alignment-view-item">
            <DashboardOutlined className="alignment-view-icon" />
            <span>{keyResult?.okrParentId}</span>
          </div>
        )}
        {keyResult && (
          <span className="alignment-view-line alignment-view-line-bottom"></span>
        )}
        {keyResult && (
          <div className="alignment-view-item">
            <DashboardOutlined className="alignment-view-icon" />
            <span>{keyResult?.title}</span>
          </div>
        )}
        {showAlignment && (
          <div className="alignment-view-alignment">
            <div className="alignment-view-title">Alignment</div>
            <div className="alignment-view-item">
              {" "}
              <AimOutlined className="alignment-view-icon" />
              <span>{objective?.title}</span>
            </div>
            <span className="alignment-view-line alignment-view-line-top"></span>
            <div className="alignment-view-item">
              {" "}
              <AimOutlined className="alignment-view-icon" />
              <span>{objective?.title}</span>
            </div>
          </div>
        )}
      </div>
      <div className="alignment-view-border">
        {" "}
        {hideView ? (
          <DoubleRightOutlined onClick={toggleHideShow} />
        ) : (
          <DoubleLeftOutlined onClick={toggleHideShow} />
        )}
      </div>
    </AlignmentViewWrapper>
  );
};
