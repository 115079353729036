import styled from "styled-components";

export const AppWrapper = styled.div`
  background-color: #f6f8f9;
  padding: 0px 0px;
  margin: 0px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  .app-body {
    position: relative;
    flex: 1;
    overflow: hidden;
  }
`;
