/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { InputFormWrapper } from "../../atoms";
import {
  ActivityListCard,
  CycleCard,
  ItemNotFound,
  Loader,
  ObjectiveListCard,
} from "../../molecules";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { openModal } from "../../../redux/data/modals/modalReducer";

interface ICycleDetailsProps {
  cycleId?: string;
}
export const CycleDetails: React.FC<ICycleDetailsProps> = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const cycleId = params.cycleId || "";

  const { selectedCycle } = useSelector((state: any) => state.cycles);
  const { loading: dataLoading, error: dataError } = useSelector(
    (state: any) => state.async
  );

  const handelAddObjective = () => {
    dispatch(
      openModal({
        modalType: "AddObjectiveModal",
        modalProps: {
          title: "Create new objective",
          cycleId,
        },
      })
    );
  };
  if (dataLoading) return <Loader color="success" title="Loading" />;
  if (dataError) {
    return (
      <ItemNotFound
        redirectTo="/boards"
        redirectBtnText="Back to Boards"
        itemType="Board"
        itemId={cycleId}
      />
    );
  }
  return (
    <AdminPageBody>
      <InputFormWrapper>
        <div className="input-form-body">
          <CycleCard cycle={selectedCycle} />
          <ObjectiveListCard
            cycleId={cycleId}
            action={handelAddObjective}
            viewAll={`/cycle/${cycleId}/objectives`}
          />
          <ActivityListCard activities={[]} />
        </div>
      </InputFormWrapper>
    </AdminPageBody>
  );
};
