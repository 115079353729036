/* eslint-disable no-console */

import { ICycle, IOkr } from "../../../common/types";
import {
  CREATE_COMPANY_CYCLE,
  UPDATE_COMPANY_CYCLE,
  DELETE_COMPANY_CYCLE,
  FETCH_COMPANY_CYCLES,
  LISTEN_TO_SELECTED_COMPANY_CYCLE,
  CLEAR_COMPANY_CYCLES,
  CLEAR_SELECTED_COMPANY_CYCLE,
  LISTEN_TO_SELECTED_CYCLE_OKRS,
} from "./companyCycleConstants";

export function listenToCompanyCycles(cycles: ICycle[]) {
  return {
    type: FETCH_COMPANY_CYCLES,
    payload: cycles,
  };
}

export function listenToSelectedCompanyCycle(cycle: ICycle) {
  return {
    type: LISTEN_TO_SELECTED_COMPANY_CYCLE,
    payload: cycle,
  };
}

export function listenToSelectedCycleOkrs(okrs: IOkr[]) {
  return {
    type: LISTEN_TO_SELECTED_CYCLE_OKRS,
    payload: okrs,
  };
}

export function clearSelectedCompanyCycle() {
  return {
    type: CLEAR_SELECTED_COMPANY_CYCLE,
  };
}

export function createCompanyCycle(cycle: ICycle) {
  return {
    type: CREATE_COMPANY_CYCLE,
    payload: cycle,
  };
}

export function updateCompanyCycle(cycle: ICycle) {
  return {
    type: UPDATE_COMPANY_CYCLE,
    payload: cycle,
  };
}

export function deleteCompanyCycle(cycleId: string) {
  return {
    type: DELETE_COMPANY_CYCLE,
    payload: cycleId,
  };
}

export function clearCompanyCycles() {
  return {
    type: CLEAR_COMPANY_CYCLES,
  };
}
