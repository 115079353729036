import { ICompany } from "../../../common/types";
import {
  CREATE_COMPANY,
  UPDATE_COMPANY,
  DELETE_COMPANY,
  FETCH_COMPANIES,
  LISTEN_TO_SELECTED_COMPANY,
  CLEAR_COMPANIES,
  CLEAR_SELECTED_COMPANY,
  LISTEN_TO_SELECTED_COMPANY_CYCLES,
  LISTEN_TO_SELECTED_COMPANY_DEPARTMENTS,
  LISTEN_TO_SELECTED_COMPANY_USERS,
} from "./companyConstants";

const initialState = {
  companies: [],
  companiesDropDown: [],
  imagesGroups: [],
  moreCities: true,
  selectedCompany: null,
  selectedCompanyCycles: [],
  selectedCompanyDepartments: [],
  selectedCompanyUsers: [],
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
};

export default function companyReducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_COMPANY:
      return {
        ...state,
        companies: [...state.companies, payload],
      };
    case UPDATE_COMPANY:
      return {
        ...state,
        companies: [
          ...state.companies.filter((evt: ICompany) => evt.id !== payload.id),
          payload,
        ],
      };
    case DELETE_COMPANY:
      return {
        ...state,
        companies: [
          ...state.companies.filter((evt: ICompany) => evt.id !== payload),
        ],
      };
    case FETCH_COMPANIES:
      return {
        ...state,
        companies: payload,
        moreCities: payload.moreCities,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: payload,
      };
    case LISTEN_TO_SELECTED_COMPANY_CYCLES:
      return {
        ...state,
        selectedCompanyCycles: payload,
      };
    case LISTEN_TO_SELECTED_COMPANY_DEPARTMENTS:
      return {
        ...state,
        selectedCompanyDepartments: payload,
      };
    case LISTEN_TO_SELECTED_COMPANY_USERS:
      return {
        ...state,
        selectedCompanyUsers: payload,
      };
    case CLEAR_SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: null,
      };
    case CLEAR_COMPANIES:
      return {
        ...state,
        companies: [],
        moreCities: true,
        lastVisible: null,
      };
    default:
      return state;
  }
}
