/* eslint-disable no-console */
import { PlusOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SectionCard } from "../CommonComponents";
import { EmptyResult } from "../EmptyResult/EmptyResult";
import { IOkr } from "../../../common/types";
import { Button, Divider, Space } from "../../atoms";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchCompanyOkrsFromFirestore } from "../../../firestore/firestoreService";
import { OkrList } from "../OkrListView";
interface ITeamOkrsCardProps {
  action?: () => void;
  viewAll?: string;
  cycleId: string;
  teamId: string;
}
export const TeamOkrsCard: React.FC<ITeamOkrsCardProps> = ({
  action,
  viewAll,
  cycleId,
  teamId,
}) => {
  const [objectives, setObjectives] = React.useState<IOkr[]>([]);
  const dispatch = useDispatch();
  const handelObjectiveListView = (value: string) => {
    console.log(value);
  };

  useFirestoreCollection({
    query: () =>
      fetchCompanyOkrsFromFirestore([
        {
          type: "filter",
          name: "cycleId",
          opr: "==",
          value: cycleId,
        },
      ]),
    data: setObjectives,
    deps: [dispatch, cycleId, teamId],
    local: true,
  });
  return (
    <SectionCard
      title={"Objectives"}
      titleOnSelect={handelObjectiveListView}
      extra={
        <Space split={<Divider type="vertical" />}>
          <Button
            type="link"
            className="section-card-header-btn-link"
            onClick={action}
          >
            <PlusOutlined /> Create objective
          </Button>
          {viewAll && <Link to={viewAll}>See all objective</Link>}
        </Space>
      }
    >
      <div className="section-card-body">
        {objectives.length === 0 && (
          <EmptyResult
            subTitle="Create a new objective"
            title="No objective"
            btnName="Create objective"
            btnAction={action}
          />
        )}
        {objectives.length > 0 && (
          <OkrList okrs={objectives || []} loading={false} teamId={teamId} />
        )}
      </div>
    </SectionCard>
  );
};
