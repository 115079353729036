/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { InitiativeList } from "../../molecules";
import { UserWrapper } from "./User.style";
import { UserInitiativeToolbar } from "./UserInitiativeToolbar";

interface IUserInitiativeListProps {
  userId?: string;
}
export const UserInitiativeList: React.FC<IUserInitiativeListProps> = () => {
  const params = useParams();

  const userId = params.userId || "";
  const { selectedUserInitiatives } = useSelector((state: any) => state.users);
  const { loading } = useSelector((state: any) => state.async);

  return (
    <AdminPageBody pageToolbar={<UserInitiativeToolbar userId={userId} />}>
      <UserWrapper>
        <InitiativeList
          initiatives={selectedUserInitiatives}
          loading={loading}
        />
      </UserWrapper>
    </AdminPageBody>
  );
};
