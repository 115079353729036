/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { UserList } from "../../molecules";
import { CompanyWrapper } from "./Company.style";
import { CompanyUserListToolbar } from "./CompanyUserListToolbar";

export const CompanyUserList: React.FC = () => {
  const { selectedCompanyUsers } = useSelector((state: any) => state.companies);
  const { loading } = useSelector((state: any) => state.async);

  return (
    <AdminPageBody pageToolbar={<CompanyUserListToolbar />}>
      <CompanyWrapper>
        <UserList users={selectedCompanyUsers} loading={loading} />
      </CompanyWrapper>
    </AdminPageBody>
  );
};
