/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { KeyResultList } from "../../molecules";
import { BoardWrapper } from "./Board.style";
import { BoardKeyResultListToolbar } from "./BoardKeyResultListToolbar";

interface IBoardKeyResultListProps {
  boardId?: string;
}
export const BoardKeyResultList: React.FC<IBoardKeyResultListProps> = () => {
  const params = useParams();

  const boardId = params.boardId || "";
  const { selectedBoardKeyResults } = useSelector((state: any) => state.boards);
  const { loading } = useSelector((state: any) => state.async);

  return (
    <AdminPageBody
      pageToolbar={<BoardKeyResultListToolbar boardId={boardId} />}
    >
      <BoardWrapper>
        <KeyResultList keyResults={selectedBoardKeyResults} loading={loading} />
      </BoardWrapper>
    </AdminPageBody>
  );
};
