/* eslint-disable no-console */
import { ICycle, IInitiative, IOkr } from "../../../common/types";
import {
  CREATE_COMPANY_OKR,
  UPDATE_COMPANY_OKR,
  DELETE_COMPANY_OKR,
  FETCH_COMPANY_OKRS,
  LISTEN_TO_SELECTED_COMPANY_OKR,
  CLEAR_COMPANY_OKRS,
  CLEAR_SELECTED_COMPANY_OKR,
  LISTEN_TO_SELECTED_OKR_CYCLE,
  LISTEN_TO_SELECTED_OKR_INITIATIVES,
} from "./companyOKRConstants";

export function listenToCompanyOkrs(okrs: IOkr[]) {
  return {
    type: FETCH_COMPANY_OKRS,
    payload: okrs,
  };
}

export function listenToSelectedCompanyOkr(okr: IOkr) {
  return {
    type: LISTEN_TO_SELECTED_COMPANY_OKR,
    payload: okr,
  };
}

export function listenToSelectedCompanyOkrCycle(cycle: ICycle) {
  return {
    type: LISTEN_TO_SELECTED_OKR_CYCLE,
    payload: cycle,
  };
}
export function listenToSelectedOkrInitiatives(initiatives: IInitiative[]) {
  return {
    type: LISTEN_TO_SELECTED_OKR_INITIATIVES,
    payload: initiatives,
  };
}
export function clearSelectedCompanyOkr() {
  return {
    type: CLEAR_SELECTED_COMPANY_OKR,
  };
}

export function createCompanyOkr(okr: IOkr) {
  return {
    type: CREATE_COMPANY_OKR,
    payload: okr,
  };
}

export function updateCompanyOkr(okr: IOkr) {
  return {
    type: UPDATE_COMPANY_OKR,
    payload: okr,
  };
}

export function deleteCompanyOkr(okrId: string) {
  return {
    type: DELETE_COMPANY_OKR,
    payload: okrId,
  };
}

export function clearCompanyOkrs() {
  return {
    type: CLEAR_COMPANY_OKRS,
  };
}
