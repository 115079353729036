import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dashboard } from "../../App/components/organisms";
import {
  fetchCompanyBoardsFromFirestore,
  fetchCompanyInitiativesFromFirestore,
  fetchCompanyOkrsFromFirestore,
} from "../../App/firestore/firestoreService";
import { useFirestoreCollection } from "../../App/hooks/useFirestoreCollection";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";
import { listenToCompanyBoards } from "../../App/redux/data/companyBoard/companyBoardActions";
import {
  listenToMyProfileInitiatives,
  listenToMyProfileOkrs,
} from "../../App/redux/data/myProfile/myProfileActions";

function DashboardPage() {
  const dispatch = useDispatch();
  const { myProfile } = useSelector((state: any) => state.myProfile);
  useFirestoreCollection({
    query: () => fetchCompanyBoardsFromFirestore([]),
    data: listenToCompanyBoards,
    deps: [dispatch],
  });
  useFirestoreCollection({
    query: () =>
      fetchCompanyOkrsFromFirestore([
        {
          type: "filter",
          name: "ownerId",
          opr: "==",
          value: myProfile.id,
        },
      ]),
    data: listenToMyProfileOkrs,
    deps: [dispatch],
  });
  useFirestoreCollection({
    query: () =>
      fetchCompanyInitiativesFromFirestore([
        {
          type: "filter",
          name: "ownerId",
          opr: "==",
          value: myProfile.id,
        },
      ]),
    data: listenToMyProfileInitiatives,
    deps: [dispatch],
  });
  return (
    <AdminPage title="Dashboard">
      <Dashboard />
    </AdminPage>
  );
}

export default DashboardPage;
