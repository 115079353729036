/* eslint-disable no-console */
import React from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import {
  CheckCircleOutlined,
  CopyOutlined,
  ExportOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { arrayMoveImmutable } from "array-move";
import moment from "moment";
import cuid from "cuid";
import { findIndex } from "lodash";
import {
  EditableTextField,
  EditableFieldForm,
  //   FieldView,
  //   EditableDescriptionField,
  EditableDatePickerField,
  EditableNumberField,
  //   EditableUserSelectedField,
  //   EditableMultipleUsersSelectedField,
} from "../commonFields";
import { IOkr, IMilestone } from "../../../common/types";
import { Button, Table } from "../../atoms";
import { SectionCard } from "../CommonComponents";
import { ActionCell } from "../CommonCells";
import {
  deleteCompanyKeyResultMilestoneInFirestore,
  updateCompanyOkrInFirestore,
} from "../../../firestore/firestoreService";
interface IOkrMilestoneCardProps {
  milestones: IMilestone[];
  keyResultId: string;
  keyResult: IOkr;
  greyHeader?: boolean;
}
const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
));

const SortableItem = SortableElement((props: any) => <tr {...props} />);
const SortableBody = SortableContainer((props: any) => <tbody {...props} />);

export const KeyResultMilestoneCard: React.FC<IOkrMilestoneCardProps> = ({
  milestones,
  keyResultId,
  keyResult,
  greyHeader,
}) => {
  const [dataSource, setDataSource] = React.useState<IMilestone[]>([]);
  const onSortEnd = async ({ oldIndex, newIndex }: any) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(dataSource, oldIndex, newIndex).filter(
        (el) => !!el
      );
      console.log("Sorted items: ", newData);
      setDataSource(newData);
      await updateCompanyOkrInFirestore(keyResultId, {
        milestones: newData,
      });
    }
  };
  const handelAddMilestone = () => {
    const milestone: IMilestone = {
      id: cuid(),
      name: "",
      startDate: moment(),
      dueDate: keyResult.dueDate,
      weight: 0,
      isSaved: false,
    };
    const index = findIndex(dataSource, ["isSaved", false]);
    if (index === -1) {
      setDataSource([...dataSource, milestone]);
    }
  };
  const handelUpdateKeyResultMilestone =
    (id: string) =>
    async (name: string, value: string | number | moment.Moment) => {
      const index: number = findIndex(dataSource, ["id", id]);
      if (index !== -1) {
        if (name === `${id}-name`) {
          dataSource[index].name = String(value);
        }
        if (name === `${id}-startDate`) {
          dataSource[index].startDate = moment(value);
        }
        if (name === `${id}-dueDate`) {
          dataSource[index].dueDate = moment(value);
        }
        if (name === `${id}-weight`) {
          dataSource[index].weight = +value;
        }
        dataSource[index].isSaved = true;
        console.log("Sorted items: ", dataSource);
        await updateCompanyOkrInFirestore(keyResultId, {
          milestones: dataSource,
        });
      }
    };
  const handelDeleteKeyResultMilestone =
    (id: string, objectiveId: string) => async () => {
      await deleteCompanyKeyResultMilestoneInFirestore(id, objectiveId);
    };
  const columns = [
    {
      title: "",
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "Complete",
      dataIndex: "checkIn",
      width: 50,
      className: "drag-visible",
      style: { textAlign: "center" },
      render: (v: boolean) => (
        <CheckCircleOutlined
          style={{ color: v ? "#25c2a1" : "#999", fontSize: 21 }}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      className: "drag-visible",
      render: (v: string, record: any) => (
        <EditableTextField
          title={"Title"}
          editable
          dataIndex={`${record.id}-name`}
          value={v}
          handleSave={handelUpdateKeyResultMilestone(record.id)}
        />
      ),
    },
    {
      title: "Start date",
      dataIndex: "startDate",
      className: "drag-visible",
      render: (v: string, record: any) => (
        <EditableDatePickerField
          title={"startDate"}
          editable
          dataIndex={`${record.id}-startDate`}
          record={moment(v)}
          handleSave={handelUpdateKeyResultMilestone(record.id)}
        />
      ),
    },
    {
      title: "Due date",
      dataIndex: "dueDate",
      className: "drag-visible",
      render: (v: string, record: any) => (
        <EditableDatePickerField
          title={"dueDate"}
          editable
          dataIndex={`${record.id}-dueDate`}
          record={moment(v)}
          handleSave={handelUpdateKeyResultMilestone(record.id)}
        />
      ),
    },
    {
      title: "Weight",
      dataIndex: "weight",
      className: "drag-visible",
      width: 100,
      render: (v: string, record: any) => (
        <EditableNumberField
          title={"weight"}
          editable
          dataIndex={`${record.id}-weight`}
          record={v}
          handleSave={handelUpdateKeyResultMilestone(record.id)}
        />
      ),
    },
    {
      title: "",
      dataIndex: "action",
      className: "drag-visible",
      width: 50,
      render: (v: string, record: any) => (
        <ActionCell
          onDelete={handelDeleteKeyResultMilestone(record.id, keyResultId)}
          menu={[
            { title: "Duplicate", icon: <CopyOutlined /> },
            { title: "Check-in", icon: <ExportOutlined /> },
          ]}
        />
      ),
    },
  ];
  const DraggableContainer = (props: any) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      (x) => x.id === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };
  React.useEffect(() => {
    if (JSON.stringify(milestones) !== JSON.stringify(dataSource)) {
      setDataSource(milestones);
    }
  }, [milestones]);
  return (
    <SectionCard title="Milestone" greyHeader={greyHeader}>
      {" "}
      <EditableFieldForm>
        <Table
          pagination={false}
          dataSource={dataSource}
          columns={columns}
          rowKey="id"
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        />
        <div>
          <Button type="link" onClick={handelAddMilestone}>
            Add Milestone
          </Button>{" "}
        </div>
      </EditableFieldForm>
    </SectionCard>
  );
};
