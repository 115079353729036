import React from "react";
import { Form, Input } from "../../atoms";

interface IInputFieldProps {
  label: string | React.ReactElement;
  rules?: any[];
  name: string | any[];
  placeholder?: string;
  style?: any;
  initialValue?: any;
  className?: string;
  bordered?: boolean;
}

export const InputField: React.FC<IInputFieldProps> = ({
  label,
  rules,
  name,
  placeholder,
  style,
  className,
  initialValue,
  bordered = true,
}) => (
  <Form.Item
    label={label}
    name={name}
    rules={rules}
    className={className}
    initialValue={initialValue}
    style={style}
  >
    <Input placeholder={placeholder} bordered={bordered} />
  </Form.Item>
);
