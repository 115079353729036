export const CREATE_COMPANY_TEAM = "CREATE_COMPANY_TEAM";
export const UPDATE_COMPANY_TEAM = "UPDATE_COMPANY_TEAM";
export const DELETE_COMPANY_TEAM = "DELETE_COMPANY_TEAM";
export const FETCH_COMPANY_TEAMS = "FETCH_COMPANY_TEAMS";
export const LISTEN_TO_SELECTED_COMPANY_TEAM =
  "LISTEN_TO_SELECTED_COMPANY_TEAM";
export const CLEAR_SELECTED_COMPANY_TEAM = "CLEAR_SELECTED_COMPANY_TEAM";
export const CLEAR_COMPANY_TEAMS = "CLEAR_COMPANY_TEAMS";
export const SET_COMPANY_TEAMS_FILTER = "SET_COMPANY_TEAMS_FILTER";
export const LISTEN_TO_SELECTED_TEAM_USERS = "LISTEN_TO_SELECTED_TEAM_USERS";
export const LISTEN_TO_SELECTED_TEAM_OKRS = "LISTEN_TO_SELECTED_TEAM_OKRS";
export const LISTEN_TO_SELECTED_TEAM_INITIATIVES =
  "LISTEN_TO_SELECTED_TEAM_INITIATIVES";
