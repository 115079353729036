/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-shadow */
import { sortBy } from "lodash";
import React from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { IGridCell } from "../../../common/types";
import { DroppableSection } from "./DroppableSection";
import { GridWrapper } from "./Grid.style";
import { GridRow } from "./GridRow";

interface IGridProps {
  columns: IGridCell[];
  dataSource: any[];
  updateSortOrder?: (id: string, index: number) => void;
}

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const Grid: React.FC<IGridProps> = ({
  columns,
  dataSource,
  updateSortOrder,
}) => {
  const [data, setData] = React.useState<any[]>([]);

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    updateSortOrder &&
      updateSortOrder(result.draggableId, result.destination.index);
    const items = reorder(data, result.source.index, result.destination.index);
    setData(items);
  };
  React.useEffect(() => {
    setData(sortBy(dataSource, "index"));
  }, [dataSource]);
  return (
    <GridWrapper>
      <GridRow isHeader columns={columns} />
      <DragDropContext onDragEnd={onDragEnd}>
        <DroppableSection data={data} columns={columns} />
      </DragDropContext>
    </GridWrapper>
  );
};
