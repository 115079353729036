/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-console */
import React from "react";
import moment from "moment";
import { ColorResult, SliderPicker } from "react-color";
import { SelectField } from "../../molecules";
import { IBoard, ICycleInput } from "../../../common/types";
import { Alert, Button, DatePicker, Divider, Form, Input } from "../../atoms";
import { addCompanyCycleToFirestore } from "../../../firestore/firestoreService";
import { cycleStatusTypesOptions } from "../../../enums";
import { InputField } from "../../molecules/commonFields/InputField";
const { RangePicker } = DatePicker;
interface IAddCycleFormProps {
  boardId: string;
  board: IBoard;
  callback: (cycleId: string) => void;
}
export const AddCycleForm: React.FC<IAddCycleFormProps> = ({
  boardId,
  board,
  callback,
}) => {
  const [color, setColor] = React.useState<string>("#fff");
  const handleChangeComplete = (colors: ColorResult) => {
    setColor(colors.hex);
  };
  // const { boards, selectedBoardCycle } = useSelector(
  //   (state: any) => state.boards
  // );
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleSubmit = async () => {
    try {
      const values: ICycleInput = await form.validateFields();
      setLoading(true);
      const [startDate, endDate]: [moment.Moment, moment.Moment] =
        values.period || [moment(), moment()];
      const cycle = await addCompanyCycleToFirestore({
        name: values.name,
        description: values.description || "",
        startDate: moment(startDate),
        endDate: moment(endDate),
        color: color || "",
        status: values.status || "",
        boardId: boardId || null,
      });
      setLoading(false);
      callback(cycle.id);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  return (
    <>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <Form
        form={form}
        name="horizontal_login"
        layout="vertical"
        autoComplete="off"
      >
        <InputField
          label="Cycle name"
          name="name"
          rules={[{ required: true, message: "Missing name" }]}
          placeholder="Cycle name"
        />
        <Form.Item name="description" label="Description">
          <Input.TextArea placeholder="Description" />
        </Form.Item>
        <Form.Item
          name="period"
          label="Period"
          rules={[{ required: true, message: "Missing period" }]}
        >
          <RangePicker />
        </Form.Item>
        <Form.Item name="color" label="Color">
          <SliderPicker color={color} onChangeComplete={handleChangeComplete} />
        </Form.Item>
        <SelectField
          label="Status"
          name="status"
          initialValue="OPEN"
          options={cycleStatusTypesOptions}
        />
        <Divider></Divider>
        <Button
          type="primary"
          className="input-form-btn"
          onClick={handleSubmit}
          loading={loading}
        >
          Create Cycle
        </Button>
      </Form>
    </>
  );
};
