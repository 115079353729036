/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { DeploymentUnitOutlined, ReadOutlined } from "@ant-design/icons";
import {
  AddObjectiveForm,
  AddSuggestedObjectiveForm,
  FlowPicker,
} from "../../molecules";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { IBoard, IOkr, IOkrForTypes } from "../../../common/types";
import { InputFormWrapper } from "../../atoms";
import { closeModal } from "../../../redux/data/modals/modalReducer";

interface IAddObjectiveModalProps {
  boardId?: string;
  board?: IBoard;
  keyResultId?: string;
  keyResult?: IOkr;
  cycleId: string;
  teamId?: string;
  okrFor?: IOkrForTypes;
}
export const AddObjectiveModal: React.FC<IAddObjectiveModalProps> = (props) => {
  const dispatch = useDispatch();
  // const history = useNavigate();
  const [flow, setFlow] = React.useState("selectOption");
  const handleAddObjective = (objectiveId: string) => {
    dispatch(closeModal());
    // history(`/objective/${objectiveId}/details`);
  };
  const handleAddSuggestedObjective = () => {
    dispatch(closeModal());
  };
  return (
    <ModalWrapper {...props} className="full-page" footer={null}>
      <InputFormWrapper>
        <div className="input-form-body">
          <div className="input-form-title text-center">
            Create New Objective
          </div>
          <div className="input-form-description text-center">
            Board will allows you to create ... Get Started
          </div>
          {flow === "selectOption" && (
            <FlowPicker
              pickerOptions={[
                {
                  title: "Create New Objective",
                  subTitle: "Create New Objective",
                  icon: <DeploymentUnitOutlined style={{ color: "#c33323" }} />,
                  action: () => setFlow("customObjective"),
                },
                {
                  title: "Generate New Objective",
                  subTitle: "Select objective from our library",
                  icon: <ReadOutlined style={{ color: "#00bf9c" }} />,
                  action: () => setFlow("suggestedObjective"),
                },
              ]}
            />
          )}
          {flow === "customObjective" && (
            <AddObjectiveForm
              boardId={props.boardId}
              callback={handleAddObjective}
              cycleId={props.cycleId}
              teamId={props.teamId}
              okrFor={props.okrFor}
            />
          )}
          {flow === "suggestedObjective" && (
            <AddSuggestedObjectiveForm
              boardId={props.boardId}
              cycleId={props.cycleId}
              callback={handleAddSuggestedObjective}
              teamId={props.teamId}
            />
          )}
        </div>
      </InputFormWrapper>
    </ModalWrapper>
  );
};
