/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CommentListCard, InitiativeCard } from "../../molecules";
import { DrawerWrapper } from "../../../common/Drawer/DrawerWrapper";
import { InputFormWrapper } from "../../atoms";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import {
  addCompanyInitiativeCommentToFirestore,
  listenToCompanyInitiativeFromFirestore,
} from "../../../firestore/firestoreService";
import { listenToSelectedCompanyInitiative } from "../../../redux/data/companyInitiative/companyInitiativeActions";
interface IEditInitiativeFormProps {
  id: string;
}
export const EditInitiativeForm: React.FC<IEditInitiativeFormProps> = (
  props
) => {
  const dispatch = useDispatch();
  const { selectedInitiative } = useSelector((state: any) => state.initiatives);
  userFirestoreDoc({
    query: () => listenToCompanyInitiativeFromFirestore(props.id),
    data: listenToSelectedCompanyInitiative,
    deps: [dispatch, props.id],
  });
  return (
    <DrawerWrapper {...props} width={600} footer={null}>
      <InputFormWrapper>
        <div className="input-form-body" style={{ marginTop: 0 }}>
          <InitiativeCard
            initiative={selectedInitiative}
            keywords={["Sell", "Marketing"]}
          />
          <CommentListCard
            comments={selectedInitiative?.comments || []}
            addComment={addCompanyInitiativeCommentToFirestore}
            id={props.id}
          />
        </div>
      </InputFormWrapper>
    </DrawerWrapper>
  );
};
