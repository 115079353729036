/* eslint-disable no-console */
import { AppstoreAddOutlined, SortAscendingOutlined } from "@ant-design/icons";
import React from "react";
import { Button, Input } from "../../atoms";
import { PageToolbar } from "../../molecules";

export const CompanyUserListToolbar: React.FC = () => {
  const handelSorting = () => {
    console.log("filterCycles");
  };
  const handelCustomize = () => {
    console.log("filterCycles");
  };
  const handelOnSearch = () => {
    console.log("search");
  };
  return (
    <PageToolbar>
      <Input.Search
        placeholder="Search Users"
        onSearch={handelOnSearch}
        style={{ flex: 1 }}
      />

      <div className="page-toolbar-right-children-elements">
        <Button
          type="link"
          onClick={handelSorting}
          icon={<SortAscendingOutlined />}
          className="page-toolbar-btn-link"
        >
          Sorting
        </Button>
        <Button
          type="link"
          onClick={handelCustomize}
          icon={<AppstoreAddOutlined />}
          className="page-toolbar-btn-link"
        >
          Customize
        </Button>
      </div>
    </PageToolbar>
  );
};
