import { InfoCircleOutlined } from "@ant-design/icons";
import React from "react";
import { Button } from "../../atoms";
import { EmptyResultWrapper } from "./EmptyResult.style";
interface IEmptyResultProps {
  title: string;
  subTitle?: string;
  btnName?: string;
  btnAction?: () => void;
}
export const EmptyResult: React.FC<IEmptyResultProps> = ({
  title,
  subTitle,
  btnName,
  btnAction,
}) => (
  <EmptyResultWrapper>
    <InfoCircleOutlined className="empty-result-icon" />
    <div className="empty-result-title">{title}</div>
    {subTitle && <div className="empty-result-sub-title">{subTitle}</div>}
    {btnAction && (
      <Button type="link" className="empty-result-btn" onClick={btnAction}>
        {btnName || "Add"}
      </Button>
    )}
  </EmptyResultWrapper>
);
