/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HistoryOutlined, ThunderboltOutlined } from "@ant-design/icons";
import {
  FlowPicker,
  AddCycleForm,
  AddMultipleCycleForm,
} from "../../molecules";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { IBoard, IOkr } from "../../../common/types";
import { InputFormWrapper } from "../../atoms";
import { closeModal } from "../../../redux/data/modals/modalReducer";

interface IAddCycleModalProps {
  boardId: string;
  objective: IOkr;
  objectiveId: string;
  board: IBoard;
}
export const AddCycleModal: React.FC<IAddCycleModalProps> = (props) => {
  const [flow, setFlow] = React.useState("selectOption");
  const dispatch = useDispatch();
  const history = useNavigate();

  const handleAddCycle = (objectiveId: string) => {
    dispatch(closeModal());
    history(`/objective/${objectiveId}/details`);
  };
  const handleAddMultipleCycles = () => {
    dispatch(closeModal());
  };
  return (
    <ModalWrapper {...props} className="full-page" footer={null}>
      <InputFormWrapper>
        <div className="input-form-body">
          <div className="input-form-title text-center">Create New Cycle</div>
          <div className="input-form-description text-center">Cycle</div>
          {flow === "selectOption" && (
            <FlowPicker
              pickerOptions={[
                {
                  title: "Generate Cycles",
                  subTitle: "Generate Cycles",
                  icon: <ThunderboltOutlined style={{ color: "#00bf9c" }} />,
                  action: () => setFlow("multipleCycles"),
                },
                {
                  title: "Create Custom Cycle",
                  subTitle: "Create New Cycle",
                  icon: <HistoryOutlined style={{ color: "#c33323" }} />,
                  action: () => setFlow("customCycle"),
                },
              ]}
            />
          )}
          {flow === "customCycle" && (
            <AddCycleForm
              boardId={props.boardId}
              board={props.board}
              callback={handleAddCycle}
            />
          )}
          {flow === "multipleCycles" && (
            <AddMultipleCycleForm
              boardId={props.boardId}
              board={props.board}
              callback={handleAddMultipleCycles}
            />
          )}
        </div>
      </InputFormWrapper>
    </ModalWrapper>
  );
};
