/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CheckInList } from "../../molecules";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { KeyResultCheckInToolbar } from "./KeyResultCheckInToolbar";

export const KeyResultCheckInList: React.FC = () => {
  const params = useParams();

  const keyResultId = params.keyResultId || "";
  const { selectedKeyResultCheckIns, selectedKeyResult } = useSelector(
    (state: any) => state.keyResults
  );
  const { loading } = useSelector((state: any) => state.async);
  return (
    <AdminPageBody
      pageToolbar={
        <KeyResultCheckInToolbar
          boardId={selectedKeyResult?.boardId || ""}
          keyResultId={keyResultId}
          objectiveId={selectedKeyResult?.objectiveId || ""}
        />
      }
    >
      <CheckInList checkIns={selectedKeyResultCheckIns} loading={loading} />
    </AdminPageBody>
  );
};
