import React from "react";
import { useSelector } from "react-redux";
import {
  SignInForm,
  RegWelcomeForm,
  AddObjectiveModal,
  AddInitiativeForm,
  AddBoardModal,
  AddKeyResultModal,
  AddCycleModal,
  AddCheckInForm,
  InviteUsersModal,
  AddDepartmentModal,
  AddTeamModal,
  UpdateCompanyDetailsModal,
  CascadeKeyResultModal,
  AlignmentObjectiveModal,
} from "../../components/organisms";
export default function ModalManager() {
  const modalLookup: any = {
    SignInForm,
    RegWelcomeForm,
    AddBoardModal,
    AddObjectiveModal,
    AddKeyResultModal,
    AddCycleModal,
    AddInitiativeForm,
    InviteUsersModal,
    AddCheckInForm,
    AddDepartmentModal,
    AddTeamModal,
    UpdateCompanyDetailsModal,
    CascadeKeyResultModal,
    AlignmentObjectiveModal,
    // RegisterForm,
  };
  const currentModal = useSelector((state: any) => state.modals);
  let renderedModal;
  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];
    renderedModal = <ModalComponent {...modalProps} />;
  }

  return <span>{renderedModal}</span>;
}
