import { RightOutlined } from "@ant-design/icons";
import React from "react";
import classNames from "classnames";
import {
  InputValueViewGroupWrapper,
  InputValueViewWrapper,
} from "./CommonComponents.style";
interface IInputValueViewProps {
  title: string;
  subTitle?: string;
  value: string | React.ReactElement | number;
  onClick?: () => void;
}
interface IInputValueViewGroupProps {
  title: string;
}
export const InputValueView: React.FC<IInputValueViewProps> = ({
  title,
  value,
  subTitle,
  onClick,
}) => (
  <InputValueViewWrapper onClick={onClick}>
    <div
      className={classNames("input-value-view-body", {
        "input-value-view-body-hover": !!onClick,
      })}
    >
      <div className="input-value-view-title">{title}</div>
      <div className="input-value-view-subtitle">{subTitle}</div>
      <div className="input-value-view-value">{value}</div>
    </div>
    {onClick && (
      <div className="input-value-view-icon">
        <RightOutlined />
      </div>
    )}
  </InputValueViewWrapper>
);

export const InputValueViewGroup: React.FC<IInputValueViewGroupProps> = ({
  title,
  children,
}) => (
  <InputValueViewGroupWrapper>
    <div className="input-value-view-group-title">{title}</div>
    {children}
  </InputValueViewGroupWrapper>
);
