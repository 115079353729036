/* eslint-disable no-console */
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { CompanyOkrsWrapper } from "./CompanyOkrs.style";
import { CompanyOkrsToolbar } from "./CompanyOkrsToolbar";

export const CompanyOkrs: React.FC = () => {
  const history = useNavigate();
  const [okrView, setObjectiveView] = React.useState("chart");
  const handleObjectiveView = (e: any) => {
    console.log(e.target.value);
    setObjectiveView(e.target.value);
    history(`/company-okrs/okrs/${e.target.value}`);
  };

  return (
    <AdminPageBody
      pageToolbar={
        <CompanyOkrsToolbar
          okrView={okrView}
          onChangeObjectiveView={handleObjectiveView}
        />
      }
    >
      <CompanyOkrsWrapper>
        <Outlet />
      </CompanyOkrsWrapper>
    </AdminPageBody>
  );
};
