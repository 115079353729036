export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const FETCH_USERS = "FETCH_USERS";
export const LISTEN_TO_USER_CHAT = "LISTEN_TO_USER_CHAT";
export const CLEAR_COMMENTS = "CLEAR_COMMENTS";
export const LISTEN_TO_SELECTED_USER = "LISTEN_TO_SELECTED_USER";
export const CLEAR_SELECTED_USER = "CLEAR_SELECTED_USER";
export const CLEAR_USERS = "CLEAR_USERS";
export const SET_FILTER = "SET_FILTER";
export const SET_START_DATE = "SET_START_DATE";
export const RETAIN_STATE = "RETAIN_STATE";
export const LISTEN_TO_SELECTED_USER_OKRS = "LISTEN_TO_SELECTED_USER_OKRS";
export const LISTEN_TO_SELECTED_USER_INITIATIVES =
  "LISTEN_TO_SELECTED_USER_INITIATIVES";
