import styled from "styled-components";
export const GridWrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  .grid {
    &-row-header {
      height: 40px;
    }
    &-row {
      display: flex;
      height: 40px;
      width: 100%;
      border-bottom: 1px solid #edeae9;
      background: #fff;
      &-sortableHandle {
        font-size: 12;
        visibility: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px;
      }
      &:hover {
        background-color: #fafafa;
        .grid-row-sortableHandle {
          font-size: 12;
          visibility: visible;
        }
      }
    }
    &-cell {
      display: flex;
      align-items: center;

      color: #6d6e6f;
      font-weight: 400;
      padding: 0px 16px;
      font-size: 12px;
      flex: 1;
      overflow: hidden;
      &:not(:last-child) {
        border-right: 1px solid #edeae9;
      }
    }
  }
`;
