import styled from "styled-components";
export const CompanyWrapper = styled.div`
  background-color: #fff;
  width: 100%;
  .company {
    &-permissions {
      display: flex;
      justify-content: space-evenly;
      padding: 30px;
      &-title {
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
`;
