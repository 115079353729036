import React from "react";
import { Provider } from "react-redux";
import App from "./App/layout/App";
import { appStore } from "./App/redux/store";
import "antd/dist/antd.css";

const DashApp: React.FC = () => (
  <Provider store={appStore}>
    <App />
  </Provider>
);

export default DashApp;
