/* eslint-disable no-console */
import React, { useRef } from "react";
import { Form } from "antd";
import { Select } from "../../../atoms";
import { ISelectOption } from "../../../../common/types";
import { EditableContext } from "./EditableForm";
import { EditableFieldWrapper } from "./EditableFields.style";

interface IEditableSelectedFieldProps {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: string;
  record: string | number;
  options: ISelectOption[];
  placeholder?: string;
  style?: any;
  handleSave: (name: string, record: string | number) => void;
}

export const EditableSelectedField: React.FC<IEditableSelectedFieldProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  options,
  placeholder,
  style,
  handleSave,
  ...restProps
}) => {
  const valueRef = useRef<any>(null);
  const inputRef = useRef<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const form = React.useContext(EditableContext)!;

  const save = async () => {
    try {
      const values = await form.validateFields();

      // toggleEdit();
      handleSave(dataIndex, values[dataIndex]);
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  React.useEffect(() => {
    if (record !== valueRef.current) {
      form.setFieldsValue({ [dataIndex]: record });
      valueRef.current = record;
    }
  }, [dataIndex, record]);

  return (
    <EditableFieldWrapper {...restProps}>
      {" "}
      <EditableContext.Provider value={form}>
        <Form.Item
          className="editable-field-item"
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Select
            className="editable-field-selected-input"
            bordered={false}
            ref={inputRef}
            style={style}
            placeholder={placeholder}
            optionFilterProp="label"
            filterOption={(input, option: any) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={options}
            onSelect={save}
          ></Select>
        </Form.Item>
      </EditableContext.Provider>
    </EditableFieldWrapper>
  );
};
