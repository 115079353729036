import styled from "styled-components";
export const CycleWrapper = styled.div`
  background-color: #fff;
`;

export const CycleCardWrapper = styled.div`
  padding: 10px 24px;
  border-bottom: 1px solid #edeae9;
  .cycle-card {
    &-header {
      margin-bottom: 0px;
    }
    &-title {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
    }
    &-owner {
    }
    &-description {
      font-size: 14px;
      line-height: 32px;
      color: #7c7d7e;
    }
    &-keywords {
      display: flex;
      margin: 10px 0px;
      flex-wrap: wrap;
      &-name {
        display: block;
        background-color: #229cff13;
        color: #118fe9;
        border: none;
      }
    }
    &-footer {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #edeae9;
      padding: 10px;
      gap: 20px;
    }
    &-stat {
      background-color: #f1f7ff;
      color: #399ceb;
      border: #f1f7ff;
      border-radius: 7px;
      width: 100%;
      font-size: 13px;
      &-value {
        font-weight: 900;
        font-size: 14px;
        margin-right: 5px;
      }
      &-label {
      }
    }
  }
`;

export const CycleListCardWrapper = styled.div`
  margin-top: 50px;
  .cycle-list-card {
    &-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #edeae9;
      align-items: center;
      line-height: 52px;
    }
    &-title {
      font-size: 24px;
      font-weight: 500;
    }
    &-Actions {
    }
    &-body {
      padding: 20px;
    }
    &-tree {
      .ant-tree {
        &-treenode {
          width: 100%;
          padding: 0;
        }
        &-node-content-wrapper {
          width: 100%;
        }
        &-switcher {
          display: flex;
          align-items: center;
        }
      }
    }
  }
`;

export const CycleListPreviewWrapper = styled.div`
  margin-top: 10px;
  height: 300px;
  overflow: hidden;
  .cycle-list-Preview {
    &-title {
      font-size: 16px;
      font-weight: 500;
      border-bottom: 1px solid #edeae9;
      line-height: 40px;
      height: 40px;
    }
    &-body {
      height: calc(100% - 40px);
      overflow: auto;
    }
    &-item {
      display: flex;
      padding: 5px;
      margin-bottom: 8px;
      justify-content: space-between;
      border-bottom: 1px solid #edeae9;
    }
    &-name {
      font-weight: 500;
    }
  }
`;
