/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import {
  AppstoreAddOutlined,
  PlusOutlined,
  SortAscendingOutlined,
} from "@ant-design/icons";
import { Button } from "../../atoms";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchCompanyBoardsFromFirestore } from "../../../firestore/firestoreService";
import { listenToCompanyBoards } from "../../../redux/data/companyBoard/companyBoardActions";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { PageToolbar } from "../../molecules";

export const BoardListToolbar: React.FC = () => {
  const dispatch = useDispatch();

  useFirestoreCollection({
    query: () => fetchCompanyBoardsFromFirestore([]),
    data: listenToCompanyBoards,
    deps: [dispatch],
  });
  const handelSorting = () => {
    console.log("filterBoards");
  };
  const handelCustomize = () => {
    console.log("filterBoards");
  };
  const handelAddBoard = () => {
    dispatch(
      openModal({
        modalType: "AddBoardModal",
        modalProps: { title: "Add Board" },
      })
    );
  };

  return (
    <PageToolbar>
      <Button
        type="primary"
        onClick={handelAddBoard}
        block
        icon={<PlusOutlined />}
        className="page-toolbar-btn"
      >
        Add Board
      </Button>

      <div className="page-toolbar-right-children-elements">
        <Button
          type="link"
          onClick={handelSorting}
          icon={<SortAscendingOutlined />}
          className="page-toolbar-btn-link"
        >
          Sorting
        </Button>
        <Button
          type="link"
          onClick={handelCustomize}
          icon={<AppstoreAddOutlined />}
          className="page-toolbar-btn-link"
        >
          Customize
        </Button>
      </div>
    </PageToolbar>
  );
};
