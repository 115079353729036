/* eslint-disable no-console */
import { EllipsisOutlined, PlusOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SectionCard } from "../CommonComponents";
import { EmptyResult } from "../EmptyResult/EmptyResult";
import { IOkr, IQueryConfigArgs } from "../../../common/types";
import { Button, Space } from "../../atoms";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchCompanyOkrsFromFirestore } from "../../../firestore/firestoreService";
import { OkrList } from "../OkrListView";
interface IOkrListCardProps {
  action?: () => void;
  viewAll?: string;
  cycleId?: string;
  filters?: IQueryConfigArgs[];
  greyHeader?: boolean;
  noCascade?: boolean;
  title?: string;
}
export const ObjectiveListCard: React.FC<IOkrListCardProps> = ({
  action,
  viewAll,
  cycleId,
  filters = [],
  greyHeader,
  noCascade,
  title,
}) => {
  const [objectives, setObjectives] = React.useState<IOkr[]>([]);
  const dispatch = useDispatch();
  const handelObjectiveListView = (value: string) => {
    console.log(value);
  };

  useFirestoreCollection({
    query: () =>
      fetchCompanyOkrsFromFirestore([
        ...filters,
        {
          type: "filter",
          name: "cycleId",
          opr: "==",
          value: cycleId,
        },
      ]),
    data: setObjectives,
    deps: [dispatch, cycleId, filters],
    local: true,
  });
  return (
    <SectionCard
      title={title ? title : "Objectives"}
      titleOnSelect={handelObjectiveListView}
      greyHeader={greyHeader}
      extra={
        <Space>
          <Button
            type="link"
            className="section-card-header-btn-link"
            onClick={action}
          >
            <PlusOutlined /> Add objective
          </Button>
          <EllipsisOutlined className="section-card-header-btn-link" />
          {viewAll && <Link to={viewAll}>See all objective</Link>}
        </Space>
      }
    >
      <div className="section-card-body">
        {objectives.length === 0 && (
          <EmptyResult
            subTitle="Create a new objective"
            title="No objective"
            btnName="Create objective"
            btnAction={action}
          />
        )}
        {objectives.length > 0 && (
          <OkrList
            okrs={objectives || []}
            loading={false}
            noCascade={noCascade}
          />
        )}
      </div>
    </SectionCard>
  );
};
