/* eslint-disable no-param-reassign */
/* eslint-disable import/no-named-as-default */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-console */
import React from "react";
import { LineChart } from "..";
import { ICheckIn } from "../../../common/types";
import { SectionCard } from "../CommonComponents";
import { ICheckInRequestDate } from "../../../common/types/common";
import {
  checkInTotalDateValue,
  keyResultDateActualValue,
} from "../../../services/keyResultService";

interface IOkrChartCardProps {
  checkInRequestDates: ICheckInRequestDate[];
  action?: () => void;
  checkIns: ICheckIn[];
  greyHeader?: boolean;
  calculationMethod: string;
  initialNumber: number;
  targetNumber: number;
  startDate: moment.Moment;
}

export const KeyResultChartCard: React.FC<IOkrChartCardProps> = ({
  checkInRequestDates,
  checkIns,
  action,
  greyHeader,
  calculationMethod,
  initialNumber,
  targetNumber,
  startDate,
}) => {
  const [chartData, setChartData] = React.useState<any[]>([]);

  React.useEffect(() => {
    let checkInTotal = +initialNumber;
    let target = +initialNumber;
    if (
      calculationMethod === "SHOULD_STAY_ABOVE" ||
      calculationMethod === "SHOULD_STAY_BELOW"
    ) {
      target = +targetNumber;
    }
    const data = checkInRequestDates.map((date) => {
      checkInTotal = keyResultDateActualValue(
        +checkInTotal,
        checkInTotalDateValue(checkIns, date.id),
        calculationMethod,
        initialNumber
      );
      return {
        ...date,
        name: date.label,
        actual: checkInTotal,
      };
    });
    setChartData([
      {
        id: startDate.format("YYYY-MM-DD"),
        value: startDate.toDate(),
        label: startDate.format("DD/MM/YYYY"),
        name: startDate.format("DD/MM/YYYY"),
        actual: initialNumber,
        target,
      },
      ...data,
    ]);
  }, [checkInRequestDates, checkIns]);
  console.log(chartData);
  return (
    <SectionCard title={"Key Result Performance"} greyHeader={greyHeader}>
      <div className="section-card-body">
        <LineChart
          data={chartData}
          lines={[
            { dataKey: "target", stroke: "#8884d8", name: "Target" },
            { dataKey: "actual", stroke: "#82ca9d", name: "Actual" },
          ]}
        />
      </div>
    </SectionCard>
  );
};
