/* eslint-disable no-console */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { addSignUpDetailsToFirestore } from "../../../firestore/firestoreService";
import { Alert, Button, Form, Input } from "../../atoms";
import { SignUpWrapper } from "./Registration.style";
interface IRegSignInProps {
  name?: string;
}
export const RegSignIn: React.FC<IRegSignInProps> = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = React.useState("");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const handleOnFinish = async () => {
    setLoading(true);
    try {
      const values: { email: string } = await form.validateFields();
      const singUpDetails = await addSignUpDetailsToFirestore({
        email: values.email,
      });
      navigate(`/registration/email-verification/${singUpDetails.id}`);
      setLoading(false);
    } catch (errorInfo: any) {
      setLoading(false);
      setError("Problem with email please try again");
    }
  };

  return (
    <div className="registration-page">
      <div className="registration-page-body">
        <SignUpWrapper>
          <div className="sign-up-title">Welcome to weey </div>
          {error !== "" && (
            <Alert message={error} type="error" style={{ marginBottom: 20 }} />
          )}
          <Form
            form={form}
            layout="vertical"
            onFinish={handleOnFinish}
            autoComplete="off"
          >
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>

            <Button className="sign-up-btn" loading={loading} htmlType="submit">
              Sign Up for Free
            </Button>

            <div className="sign-up-up">
              {" "}
              Do you have an account? <Link to="/sign-in">Sign in</Link>
            </div>
          </Form>
        </SignUpWrapper>
      </div>
      <div className="registration-page-aside">
        <img src="/assets/sign-up.png" alt="" />
      </div>
    </div>
  );
};
