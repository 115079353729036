/* eslint-disable @typescript-eslint/no-shadow */
import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { IGridCell } from "../../../common/types";
import { GridRow } from "./GridRow";

interface IDroppableSectionProps {
  columns: IGridCell[];
  data: any[];
}
const getListStyle = (isDraggingOver: boolean): any => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: 0,
});

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: 0,
  margin: 0,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle,
});

export const DroppableSection: React.FC<IDroppableSectionProps> = ({
  data,
  columns,
}) => {
  // eslint-disable-next-line no-console
  console.log("tt");
  return (
    <Droppable droppableId="droppable">
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          style={getListStyle(snapshot.isDraggingOver)}
        >
          {data.map((record: any, index) => (
            <Draggable key={record.id} draggableId={record.id} index={index}>
              {(provided: any, snapshot: any) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                  )}
                >
                  <GridRow key={record.id} columns={columns} record={record} />
                </div>
              )}
            </Draggable>
          ))}

          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
