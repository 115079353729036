/* eslint-disable no-console */
import React from "react";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { InputFormWrapper } from "../../atoms";

export const CompanyBilling: React.FC = () => {
  console.log("tes");
  return (
    <AdminPageBody>
      <InputFormWrapper>
        <div className="input-form-body">
          <div className="input-form-title">CompanyBilling</div>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
