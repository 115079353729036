/* eslint-disable no-console */
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
// import { Tree } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { EmptyResult } from "..";
import { ICheckIn, IUser } from "../../../common/types";
import { deleteCompanyCheckInInFirestore } from "../../../firestore/firestoreService";
import { Button, Table } from "../../atoms";
import { UserCell } from "../CommonCells";
import { SectionCard } from "../CommonComponents";
// import { CheckInItem } from "./CheckInItem";
interface ICheckInListCardProps {
  checkIns: ICheckIn[];
  action?: () => void;
  viewAll?: string;
  greyHeader?: boolean;
}
export const CheckInListCard: React.FC<ICheckInListCardProps> = ({
  checkIns,
  action,
  viewAll,
  greyHeader,
}) => {
  const handelObjectiveListView = (value: string) => {
    console.log(value);
  };
  const handelDeleteObjective = async (id: string) => {
    await deleteCompanyCheckInInFirestore(id);
  };
  const columnsList = [
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      fixed: true,
      width: 200,
    },
    {
      title: "Owner",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (v: IUser) => <UserCell user={v} avatarOnly />,
    },
    {
      title: "Confidence Level",
      dataIndex: "confidenceLevel",
      key: "confidenceLevel",
      render: (v: number) => `${v}%`,
    },
    {
      title: "check In Date",
      dataIndex: "checkInDate",
      key: "checkInDate",
      render: (v: moment.Moment) => moment(v).format("DD/MM/YYYY"),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: ICheckIn) => (
        <DeleteOutlined onClick={() => handelDeleteObjective(record.id)} />
      ),
    },
  ];
  return (
    <SectionCard
      title="Check Ins"
      greyHeader={greyHeader}
      titleOnSelect={handelObjectiveListView}
      extra={
        <div className="section-card-Actions">
          {action && (
            <Button
              type="link"
              className="section-card-header-btn-link"
              onClick={action}
            >
              <PlusOutlined /> Add CheckIn
            </Button>
          )}
          {viewAll && <Link to={viewAll}>See all CheckIn</Link>}
        </div>
      }
    >
      <div className="section-card-body">
        {checkIns.length === 0 ? (
          <EmptyResult
            subTitle="No checkIn in the past five days"
            title="No checkIn"
          />
        ) : (
          <Table
            columns={columnsList}
            dataSource={checkIns}
            rowKey="id"
            pagination={false}
            // scroll={{ x: 800, y: 500 }}
          />
        )}
      </div>
    </SectionCard>
  );
};
