import styled from "styled-components";

// theme is now fully typed
export const AdminPageWrapper = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0px;
  display: flex;
  flex-direction: column;
  .admin-page {
    position: relative;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    &-container {
      display: grid;
      grid-template-columns: auto 1fr;
      flex: 1;
      /* overflow: hidden; */
    }
    &-body {
      flex: 1;
      overflow: hidden;
      position: relative;
      height: 200px;
      &-wrapper {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        top: 0px;
      }

      &-content {
        overflow: auto;
        padding: 0px 0px;
        height: 100%;
        background-color: #fff;
        display: flex;
        width: 100%;
      }
    }
    &-menu {
      width: 240px;
      transition: margin-left 250ms ease-out, transform 250ms ease-out;
      background-color: #252628;
      &-menu {
        background-color: #252628;
        color: #fff;
        border: none;
      }
      &-submenu {
        margin-top: 20px;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      }
      &-btn {
        padding-left: 0;
        color: #a2a0a2;
      }
      .ant-menu-sub.ant-menu-inline {
        background: #252628;
      }
      .ant-menu {
        &-item {
          height: 32px;
          line-height: 32px;
          margin: 0px;
          color: #e6e5e4;
          &:hover {
            background-color: rgba(255, 255, 255, 0.08);
          }
          &::after {
            border: none;
          }
          &-selected {
            background-color: rgba(255, 255, 255, 0.2);
          }

          a {
            color: #e6e5e4;
            font-size: 14px;
            font-weight: 400;
          }
        }
        &-submenu {
          &-arrow {
            color: #a2a0a2;
            &:hover {
              color: #e6e5e4;
            }
          }
          &-title {
            height: 32px;
            line-height: 32px;
            margin: 5px 0px;
            color: #a2a0a2;
            &:active {
              background-color: rgba(255, 255, 255, 0.08);
            }
            &:hover {
              color: #e6e5e4;
            }
          }
        }
      }
      &-hidden {
        margin-left: -240px;
      }
    }
    &-logo {
      padding: 15px 30px;
      img {
        height: 26px;
      }
    }
    &-header {
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-side-burger-icon {
      color: #a2a0a2;
      font-size: 16px;
      margin: 0 16px;
    }
    &-breadcrumb {
      height: 30px;
      display: flex;
      align-items: center;
      background-color: rgb(0 0 0 / 5%);
      .ant-breadcrumb {
        font-size: 12px;
        display: flex;
        span {
          display: flex;
        }
        &-link {
          a {
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
          }
        }
      }
      &-back {
        width: 90px;
        text-align: center;
        font-size: 12px;
        &-btn {
          font-size: 12px;
          font-weight: 500;
          color: #6d6e6f;
          height: 21px;
          line-height: 21px;
          padding: 0 8px;
          border-radius: 6px;
          &:hover {
            background: rgb(55 23 23 / 3%);
            color: #1e1f21;
          }
          .ant-btn > .anticon + span {
            margin-left: 5px;
          }
        }
      }
    }
    &-title {
      max-width: 500px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .ant-tag {
        background-color: rgb(213 95 142 / 5%);
        color: #d55f8e;
        border: rgb(213 95 142 / 6%);
        border-radius: 7px;
        font-size: 12px;
      }
    }
  }
`;
