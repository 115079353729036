import styled from "styled-components";
// import {palette} from 'styled-theme'

export const LeftMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  .left-menu {
    &-btn {
      font-size: 14px;
      height: 36px;
      line-height: 36px;
      padding: 0 12px;
      background-color: #f1bd6c;
      border-color: #f1bd6c;
      margin: 0 10px;
      border-radius: 4px;
      color: #1e1f21;
    }
    &-user {
      width: 250px;
      padding: 10px 20px;
    }
  }
`;
