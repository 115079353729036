/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { BarsOutlined, PercentageOutlined } from "@ant-design/icons";
import {
  FlowPicker,
  KeyResultAddAmountTrackingForm,
  KeyResultAddMilestoneTrackingForm,
} from "../../molecules";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { InputFormWrapper } from "../../atoms";
import { closeModal } from "../../../redux/data/modals/modalReducer";
import { ICycle, IOkrForTypes } from "../../../common/types";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { listenToCompanyCycleFromFirestore } from "../../../firestore/firestoreService";

interface IAddKeyResultModalProps {
  boardId: string;
  okrParentId: string;
  okrParentIds: string[];
  cycleId: string;
  okrFor: IOkrForTypes;
}
export const AddKeyResultModal: React.FC<IAddKeyResultModalProps> = (props) => {
  const dispatch = useDispatch();
  // const history = useNavigate();
  const [flow, setFlow] = React.useState("selectOption");
  const [cycle, setCycle] = React.useState<ICycle>();

  userFirestoreDoc({
    query: () => listenToCompanyCycleFromFirestore(props.cycleId),
    data: setCycle,
    deps: [props.cycleId],
    shouldExecute: !!props.cycleId,
    local: true,
  });
  const handelAddKeyResult = (keyResultId: string) => {
    dispatch(closeModal());
    // history(`/key-result/${keyResultId}/details`);
  };
  return (
    <ModalWrapper {...props} className="full-page" footer={null}>
      {!cycle && <div></div>}
      <InputFormWrapper>
        <div className="input-form-body">
          <div className="input-form-title text-center">Create Key Result</div>
          <div className="input-form-description text-center">
            {flow === "selectOption" && "What would you like to do ?"}
            {flow === "PercentageTracked" && "Percentage / amount Tracked"}
            {flow === "MilestoneTracked" && "Milestone Tracked"}
            {flow === "TaskTracked" && "Task Tracked"}
          </div>

          {flow === "selectOption" && (
            <FlowPicker
              pickerOptions={[
                {
                  title: "Percentage / amount Tracked",
                  subTitle:
                    "Track the completion of a qualitative outcome that can’t be measured using a metric.",
                  icon: <PercentageOutlined style={{ color: "#c33323" }} />,
                  action: () => setFlow("PercentageTracked"),
                },
                {
                  title: "Milestone Tracked",
                  subTitle:
                    "something done that is not measurable by a KPI. It’s more a series of milestones that you want to track against.",
                  icon: <BarsOutlined style={{ color: "#00bf9c" }} />,
                  action: () => setFlow("MilestoneTracked"),
                },
              ]}
            />
          )}
          {flow === "PercentageTracked" && cycle && (
            <KeyResultAddAmountTrackingForm
              boardId={props.boardId}
              okrParentId={props.okrParentId}
              callback={handelAddKeyResult}
              cycleId={props.cycleId}
              cycleStartDate={cycle.startDate}
              cycleEndDate={cycle.endDate}
              okrFor={props.okrFor}
              okrParentIds={props.okrParentIds}
            />
          )}
          {flow === "MilestoneTracked" && cycle && (
            <KeyResultAddMilestoneTrackingForm
              boardId={props.boardId}
              okrParentId={props.okrParentId}
              callback={handelAddKeyResult}
              cycleId={props.cycleId}
              cycle={cycle}
              okrFor={props.okrFor}
              okrParentIds={props.okrParentIds}
            />
          )}
        </div>
      </InputFormWrapper>
    </ModalWrapper>
  );
};
