/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { CycleList as CycleListMole } from "../../molecules/Cycle";
import { CycleWrapper } from "./Cycle.style";
import { CycleListToolbar } from "./CycleListToolbar";

export const CycleList: React.FC = () => {
  const { selectedCompany } = useSelector((state: any) => state.companies);
  const { cycles } = useSelector((state: any) => state.cycles);
  const { loading } = useSelector((state: any) => state.async);
  return (
    <AdminPageBody pageToolbar={<CycleListToolbar />}>
      <CycleWrapper>
        <CycleListMole
          cycles={cycles}
          loading={loading}
          defaultCycleId={selectedCompany?.defaultCycleId || ""}
        />
      </CycleWrapper>
    </AdminPageBody>
  );
};
