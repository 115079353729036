/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { InputFormWrapper } from "../../atoms";
import { MyProfileCard } from "../../molecules";

interface IProfileDetailsProps {
  name?: string;
}
export const ProfileDetails: React.FC<IProfileDetailsProps> = ({ name }) => {
  const { myProfile } = useSelector((state: any) => state.myProfile);
  return (
    <AdminPageBody>
      <InputFormWrapper>
        <div className="input-form-body">
          <MyProfileCard user={myProfile} keywords={["Sell", "Marketing"]} />
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
