/* eslint-disable @typescript-eslint/await-thenable */
/* eslint-disable no-console */
import { Comment, List } from "antd";
import moment from "moment";
import React from "react";
import { UserCell } from "..";
import { IComment } from "../../../common/types";
import { Tooltip } from "../../atoms";
import { CommentWrapper } from "./Comment.style";

interface ICommentListCardProps {
  addComment: (id: string, comment: IComment) => void;
  comments: IComment[];
  id: string;
}

export const CommentListCard: React.FC<ICommentListCardProps> = ({
  addComment,
  comments,
  id,
}) => {
  console.log("Comment");
  return (
    <CommentWrapper>
      {" "}
      <List
        className="comment-list"
        // header={`${comments.length} replies`}
        itemLayout="horizontal"
        dataSource={comments}
        renderItem={(item: IComment) => (
          <li>
            <Comment
              author={item.owner.displayName}
              avatar={<UserCell user={item.owner} avatarOnly xSmall />}
              content={item.comment}
              datetime={
                <Tooltip
                  title={moment(item.createDate).format("YYYY-MM-DD HH:mm:ss")}
                >
                  <span>{moment(item.createDate).fromNow()}</span>
                </Tooltip>
              }
            />
          </li>
        )}
      />
    </CommentWrapper>
  );
};
