/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { IOkr } from "../../../common/types";
import {
  InputFormWrapper,
  Form,
  Alert,
  Space,
  Divider,
  Button,
} from "../../atoms";
import { closeModal } from "../../../redux/data/modals/modalReducer";
import { TeamSelectField } from "../../molecules/commonFields/TeamSelectField";
import { SelectField } from "../../molecules";
import {
  objectivePriorityTypesOptions,
  okrsForTypesOptions,
} from "../../../enums";
import { updateCompanyOkrInFirestore } from "../../../firestore/firestoreService";

interface ICascadeKeyResultModalProps {
  keyResultId: string;
  keyResult: IOkr;
}
export const CascadeKeyResultModal: React.FC<ICascadeKeyResultModalProps> = ({
  keyResultId,
  keyResult,
  ...rest
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    try {
      const values: IOkr = await form.validateFields();
      setLoading(true);
      await updateCompanyOkrInFirestore(keyResultId, {
        okrType: "OBJECTIVE_CASCADED",
        status: true,
        priority: values.priority || "",
        okrFor: values.okrFor || "INDIVIDUAL_OBJECTIVE",
        teamId: values.teamId || null,
        progressRange: [30, 70],
      });
      setLoading(false);
      dispatch(closeModal());
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  return (
    <ModalWrapper {...rest} footer={null}>
      <InputFormWrapper>
        <div className="input-form-body">
          {error !== "" && (
            <Alert message={error} type="error" style={{ marginBottom: 20 }} />
          )}
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
          >
            <Space className="input-form-space">
              <SelectField
                label="Type"
                name="okrFor"
                initialValue={keyResult.okrFor}
                options={okrsForTypesOptions}
                rules={[{ required: true, message: "Missing Type" }]}
              />
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.okrFor !== currentValues.okrFor
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("type") === "TEAM_OBJECTIVE" ? (
                    <TeamSelectField
                      label="Team"
                      name="teamId"
                      initialValue={keyResult.teamId}
                      rules={[{ required: true, message: "Missing Team" }]}
                    />
                  ) : null
                }
              </Form.Item>
            </Space>
            <Space className="input-form-space">
              <SelectField
                label="Priority"
                name="priority"
                initialValue="NORMAL"
                options={objectivePriorityTypesOptions}
                rules={[{ required: true, message: "Missing priority" }]}
              />
            </Space>
            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              Cascade Key Result
            </Button>
          </Form>
        </div>
      </InputFormWrapper>
    </ModalWrapper>
  );
};
