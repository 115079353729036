import React from "react";
import { Link } from "react-router-dom";
import { LogoWrapper } from "./Logo.style";

export const Logo: React.FC<{ className?: string }> = ({ className }) => (
  <LogoWrapper className={className || ""}>
    <Link to="/">
      <img src="/assets/weeyLogo.svg" alt="" />
    </Link>
  </LogoWrapper>
);
