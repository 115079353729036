/* eslint-disable no-console */
import React, { useRef } from "react";
import { Input, Form } from "antd";
import { EditableContext } from "./EditableForm";
import { EditableFieldWrapper } from "./EditableFields.style";

interface IEditableDescriptionFieldProps {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: string;
  record: string | number;
  handleSave: (name: string, record: string | number) => void;
  placeholder?: string;
}

export const EditableDescriptionField: React.FC<
  IEditableDescriptionFieldProps
> = ({
  title,
  editable,
  dataIndex,
  record,
  handleSave,
  placeholder,
  ...restProps
}) => {
  const valueRef = useRef<any>(null);
  const inputRef = useRef<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const form = React.useContext(EditableContext)!;

  const save = async () => {
    try {
      const values = await form.validateFields();

      // toggleEdit();
      handleSave(dataIndex, values[dataIndex]);
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  React.useEffect(() => {
    if (record !== valueRef.current) {
      form.setFieldsValue({ [dataIndex]: record });
      valueRef.current = record;
    }
  }, [dataIndex, record]);

  return (
    <EditableFieldWrapper {...restProps}>
      {" "}
      <EditableContext.Provider value={form}>
        <Form.Item
          className="editable-field-item"
          style={{ margin: 0 }}
          name={dataIndex}
        >
          <Input.TextArea
            className="editable-field-simple-textarea"
            ref={inputRef}
            data-replicated-value={record}
            onBlur={save}
            autoSize
            placeholder={placeholder}
          />
        </Form.Item>
      </EditableContext.Provider>
    </EditableFieldWrapper>
  );
};
