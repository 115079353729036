/* eslint-disable import/no-named-as-default */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-console */
import React from "react";
import moment from "moment";
import faker from "@faker-js/faker";
import { InputNumberField, SelectField } from "..";
import { IBoard, ICadence } from "../../../common/types";
import { Alert, Button, DatePicker, Divider, Form, Space } from "../../atoms";
import { addCompanyCyclesToFirestore } from "../../../firestore/firestoreService";
import { cadenceTypes, cadenceTypesOptions } from "../../../enums";
import { InputField } from "../commonFields/InputField";
import { CycleListPreview } from "./CycleListPreview";
interface IAddMultipleCycleFormProps {
  boardId?: string;
  board?: IBoard;
  callback: () => void;
}
export const AddMultipleCycleForm: React.FC<IAddMultipleCycleFormProps> = ({
  boardId,
  board,
  callback,
}) => {
  const [cadence, setCadence] = React.useState<ICadence | undefined>();
  const [cycles, setCycles] = React.useState<any[]>([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleSelectCadence = (value: string) => {
    setCadence(cadenceTypes[value]);
    setCycles([]);
  };

  const handleGenerateCycles = async () => {
    try {
      const values: any = await form.validateFields();
      setLoading(true);
      let increment = 0;
      const numberOfRepeats = values.numberOfRepeats;
      const startDated = values.startDate;
      const timePeriod = cadenceTypes[values.cadence].timePeriod;
      const alias = cadenceTypes[values.cadence].alias;
      const cycleList = [];
      while (increment < numberOfRepeats) {
        const startDuration: number = +increment * +timePeriod.value;
        const endDuration: number = (+increment + 1) * +timePeriod.value;
        const type: any = timePeriod.name;
        const startDate = moment(startDated).clone().add(startDuration, type);
        const endDate = moment(startDated).clone().add(endDuration, type);
        cycleList.push({
          name: `${values.name || ""} ${startDate.format(alias)} `,
          startDate,
          endDate,
          color: faker.internet.color(),
        });
        increment++;
        if (increment === 200) break;
      }
      setCycles(cycleList);
      setLoading(false);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await addCompanyCyclesToFirestore(
        cycles.map((cycle) => ({
          name: cycle.name,
          description: "",
          startDate: cycle.startDate,
          endDate: cycle.endDate,
          updateKRs: "",
          weekDay: "",
          color: cycle.color,
          status: "OPEN",
          board: board || null,
          boardId: boardId || null,
          defaultCycle: false,
        }))
      );
      setLoading(false);
      callback();
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  return (
    <>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <Form
        form={form}
        name="horizontal_login"
        layout="vertical"
        autoComplete="off"
      >
        <SelectField
          label="Cadence"
          name="cadence"
          options={cadenceTypesOptions}
          onChange={handleSelectCadence}
          rules={[{ required: true, message: "Missing Cadence" }]}
        />
        <InputField label="Custom name" name="name" placeholder="Custom name" />
        <Space className="input-form-space">
          <Form.Item
            name="startDate"
            label="Start Date"
            rules={[{ required: true, message: "Missing Start Date" }]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
          <InputNumberField
            label="Repeats"
            name="numberOfRepeats"
            rules={[
              {
                type: "number",
                min: 1,
                max: cadence?.numberOfRepeats,
                message: `Repeats must be between 1 and ${cadence?.numberOfRepeats}`,
              },
            ]}
            placeholder="Repeats"
            style={{ width: "100%" }}
          />
        </Space>
        <Button
          type="primary"
          className="input-form-btn"
          onClick={handleGenerateCycles}
          loading={loading}
        >
          Generate Cycles
        </Button>
        <CycleListPreview cycles={cycles} />

        <Divider></Divider>
        <Button
          type="primary"
          className="input-form-btn"
          onClick={handleSubmit}
          loading={loading}
          disabled={cycles.length === 0}
        >
          Create Cycle
        </Button>
      </Form>
    </>
  );
};
