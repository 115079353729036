import { find } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { SelectField, UserSelectedField } from "..";
import { IBoardInput } from "../../../common/types";
import { addCompanyBoardToFirestore } from "../../../firestore/firestoreService";
import { Alert, Button, Divider, Form, Input } from "../../atoms";

interface IAddBoardFormProps {
  callback: (boardId: string) => void;
  btnName?: string;
}
export const AddBoardForm: React.FC<IAddBoardFormProps> = ({
  callback,
  btnName = "Create Board",
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const { users } = useSelector((state: any) => state.users);
  const [error, setError] = React.useState("");
  const handleSubmit = async () => {
    try {
      const values: IBoardInput = await form.validateFields();
      setLoading(true);
      const owner = values.ownerId ? find(users, ["id", values.ownerId]) : null;
      const board = await addCompanyBoardToFirestore({
        status: true,
        title: values.title,
        owner,
        ownerId: values.ownerId || "",
        description: values.description || "",
        segments: values.segments || [],
      });
      setLoading(false);
      callback(board.id);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  return (
    <>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <Form
        form={form}
        name="horizontal_login"
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          name="title"
          label="Board Name"
          rules={[{ required: true, message: "Missing Name" }]}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input.TextArea placeholder="Description" />
        </Form.Item>
        <UserSelectedField
          label="Who owns this board?"
          name="ownerId"
          rules={[{ required: true, message: "Missing Owner" }]}
        />
        <SelectField label="Segment" name="segment" options={[]} />
        <Divider></Divider>
        <Button
          type="primary"
          onClick={handleSubmit}
          loading={loading}
          style={{ width: "100%" }}
        >
          {btnName}
        </Button>
      </Form>
    </>
  );
};
