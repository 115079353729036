/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserCell } from "../CommonCells";
import { IUser } from "../../../common/types";
import { fetchCompanyUsersFromFirestore } from "../../../firestore/firestoreService";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { listenToCompanyUsers } from "../../../redux/data/companyUser/companyUserActions";
import { Form, Select } from "../../atoms";
import { UserSelectedFieldWrapper } from "./Fields.style";

interface IUserSelectedFieldProps {
  boardId?: string;
  bordered?: boolean;
  name: string | Array<string | number>;
  label?: string;
  rules?: any[];
  placeholder?: string;
  style?: any;
  mode?: "multiple" | "tags";
  maxTagCount?: number;
  initialValue?: any;
  getUser?: () => IUser;
  onChange?: (value: any) => void;
}
export const UserSelectedField: React.FC<IUserSelectedFieldProps> = ({
  name,
  label,
  boardId,
  mode,
  rules,
  getUser,
  onChange,
  bordered = true,
  style,
  placeholder,
  maxTagCount,
  initialValue,
}) => {
  const dispatch = useDispatch();
  const { users } = useSelector((state: any) => state.users);
  useFirestoreCollection({
    query: () =>
      fetchCompanyUsersFromFirestore([
        {
          type: "filter",
          name: "boardIds",
          opr: "array-contains",
          value: boardId,
        },
      ]),
    data: listenToCompanyUsers,
    deps: [dispatch],
  });
  const handelOnSelect = (value: string | string[]) => {
    // onChange && onChange(value);
  };
  return (
    <UserSelectedFieldWrapper>
      <Form.Item
        label={label}
        name={name}
        rules={rules}
        initialValue={initialValue}
      >
        <Select
          showSearch
          bordered={bordered}
          style={style}
          placeholder={placeholder || label}
          optionFilterProp="label"
          filterOption={(input, option: any) =>
            option.search.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          options={users.map((user: IUser) => ({
            value: user.id,
            label: <UserCell user={user} nameOnly xSmall />,
            search: `${user.firstName} ${user.lastName}`,
          }))}
          mode={mode}
          maxTagCount={maxTagCount}
          onSelect={handelOnSelect}
          onChange={onChange}
        ></Select>
      </Form.Item>
    </UserSelectedFieldWrapper>
  );
};

export default UserSelectedField;
