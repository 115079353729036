/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-console */
import {
  ApartmentOutlined,
  DeploymentUnitOutlined,
  DotChartOutlined,
  DoubleLeftOutlined,
  HistoryOutlined,
  HomeOutlined,
  MenuFoldOutlined,
  PlusOutlined,
  SnippetsOutlined,
  StarOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { IMenuOption, ITeam } from "../../common/types";
import { Breadcrumb, Button, Logo, Menu } from "../../components/atoms";
import { Header } from "../../components/organisms";
import { openModal } from "../../redux/data/modals/modalReducer";
import { listenToCompanyTeams } from "../../redux/data/companyTeam/companyTeamActions";
import { fetchCompanyTeamsFromFirestore } from "../../firestore/firestoreService";
import { useFirestoreCollection } from "../../hooks/useFirestoreCollection";
import { AdminPageWrapper } from "./AdminPage.style";

const { SubMenu } = Menu;
interface IAdminPageProps {
  children: React.ReactNode;
  title: string | React.ReactElement;
  route?: any[];
  pageToolbar?: React.ReactNode;
  headerMenu?: IMenuOption[];
  breadcrumb?: IMenuOption[];
}

export const AdminPage: React.FC<IAdminPageProps> = ({
  children,
  route,
  title,
  pageToolbar,
  breadcrumb,
  headerMenu,
}) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { currentLocation } = useSelector((state: any) => state.auth);
  const { teams } = useSelector((state: any) => state.teams);
  const [selectedKey, setSelectedKey] = useState("");
  const [hideLeftMenu, setHideRightMenu] = useState(false);
  useFirestoreCollection({
    query: () => fetchCompanyTeamsFromFirestore([]),
    data: listenToCompanyTeams,
    deps: [dispatch],
  });
  const handelOnChange = ({ key }: any) => {
    setSelectedKey(key);
  };
  const handleHideMenu = () => {
    setHideRightMenu((v) => !v);
  };
  const handleGoBack = () => {
    history(-1);
  };
  const handelAddTeam = () => {
    dispatch(
      openModal({
        modalType: "AddTeamModal",
        modalProps: { title: "Add Team" },
      })
    );
  };
  React.useEffect(() => {
    if (currentLocation) {
      setSelectedKey(currentLocation?.pathname || "");
    }
  }, [currentLocation]);
  return (
    <AdminPageWrapper>
      <div className="admin-page-container">
        <div
          className={classNames("admin-page-menu", {
            "admin-page-menu-hidden": hideLeftMenu,
          })}
        >
          <div className="admin-page-header">
            <Logo className="admin-page-logo" />
            <MenuFoldOutlined
              className="admin-page-side-burger-icon"
              onClick={handleHideMenu}
            />
          </div>

          <Menu
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub2", "sub6"]}
            mode="inline"
            className="admin-page-menu-menu"
            onSelect={handelOnChange}
            selectedKeys={[selectedKey]}
          >
            <Menu.Item icon={<HomeOutlined />} key="/">
              <Link to="/">Home</Link>
            </Menu.Item>
            <Menu.Item icon={<HistoryOutlined />} key="/cycles/list">
              <Link to="/cycles/list">Cycles</Link>
            </Menu.Item>
            <Menu.Item icon={<DeploymentUnitOutlined />} key="/okr/details">
              <Link to="/company-okrs/details">Company OKRs</Link>
            </Menu.Item>
            <Menu.Item icon={<SnippetsOutlined />} key="/my-okr/objectives">
              <Link to="/my-okr/okrs">My OKRs</Link>
            </Menu.Item>
            <Menu.Item icon={<ApartmentOutlined />} key="/user/list">
              <Link to="/users/list">Members</Link>
            </Menu.Item>
            {/* <Menu.Item icon={<AppstoreOutlined />} key="/boards">
              <Link to="/boards">Boards</Link>
            </Menu.Item> */}
            <Menu.Item icon={<DotChartOutlined />} key="/reports">
              <Link to="/reports">Reports</Link>
            </Menu.Item>
            <SubMenu
              key="sub4"
              icon={<HistoryOutlined />}
              title="Recent Activities"
              className="admin-page-menu-submenu"
            ></SubMenu>
            <SubMenu
              key="sub5"
              icon={<StarOutlined />}
              title="Favorites"
              className="admin-page-menu-submenu"
            ></SubMenu>
            <SubMenu
              key="sub6"
              icon={<TeamOutlined />}
              title="Teams"
              className="admin-page-menu-submenu"
            >
              {teams.map((team: ITeam) => (
                <Menu.Item key={`/team/${team.id}`}>
                  <Link to={`/team/${team.id}/details`}>{team.name}</Link>
                </Menu.Item>
              ))}
              <Menu.Item key="Add-team">
                <Button
                  type="link"
                  className="admin-page-menu-btn"
                  onClick={handelAddTeam}
                >
                  <PlusOutlined /> Add team
                </Button>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </div>
        <div className="admin-page">
          <Header
            className="site-page-header"
            title={title}
            headerMenu={headerMenu}
            hideLeftMenu={hideLeftMenu}
            hideMenuOnClick={handleHideMenu}
            // breadcrumb={{ routes: [...defaultRoutes] }}
          />
          {breadcrumb && (
            <div className="admin-page-breadcrumb">
              <div className="admin-page-breadcrumb-back">
                <Button
                  type="link"
                  className="admin-page-breadcrumb-back-btn"
                  onClick={handleGoBack}
                >
                  <DoubleLeftOutlined /> Back
                </Button>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                {breadcrumb?.map((item) => (
                  <Breadcrumb.Item key={item.key}>
                    <Link to={item.link}>{item.label}</Link>
                  </Breadcrumb.Item>
                ))}
              </Breadcrumb>
            </div>
          )}
          {children}
        </div>
      </div>
    </AdminPageWrapper>
  );
};
