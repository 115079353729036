import moment from "moment";
import { IMilestone } from "../../common/types/common";

export const milestoneDataFormat = (doc: IMilestone, mode: string) => {
  if (!doc) return undefined;
  if (doc.dueDate) {
    doc.dueDate = moment(doc.dueDate).toDate();
  }
  if (doc.startDate) {
    doc.startDate = moment(doc.startDate).toDate();
  }
  return {
    ...doc,
  };
};
