export const CREATE_COMPANY_DEPARTMENT = "CREATE_COMPANY_DEPARTMENT";
export const UPDATE_COMPANY_DEPARTMENT = "UPDATE_COMPANY_DEPARTMENT";
export const DELETE_COMPANY_DEPARTMENT = "DELETE_COMPANY_DEPARTMENT";
export const FETCH_COMPANY_DEPARTMENTS = "FETCH_COMPANY_DEPARTMENTS";
export const LISTEN_TO_SELECTED_COMPANY_DEPARTMENT =
  "LISTEN_TO_SELECTED_COMPANY_DEPARTMENT";
export const CLEAR_SELECTED_COMPANY_DEPARTMENT =
  "CLEAR_SELECTED_COMPANY_DEPARTMENT";
export const CLEAR_COMPANY_DEPARTMENTS = "CLEAR_COMPANY_DEPARTMENTS";
export const SET_COMPANY_DEPARTMENTS_FILTER = "SET_COMPANY_DEPARTMENTS_FILTER";
export const LISTEN_TO_SELECTED_DEPARTMENT_USERS =
  "LISTEN_TO_SELECTED_DEPARTMENT_USERS";
