/* eslint-disable no-console */
import React from "react";
import classNames from "classnames";
import { Progress } from "antd";
import {
  AimOutlined,
  DashboardOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { UserCell } from "../CommonCells/UserCell";
import { IOkr } from "../../../common/types";
import { IOkrTypes } from "../../../common/types/okrTypes";
import { Tag, Tooltip } from "../../atoms";
import { openDrawer } from "../../../redux/data/drawer/drawerReducer";
import { TooltipField } from "../commonFields";
import { KeyResultActionMenu } from "../KeyResult";
import { ObjectiveActionMenu } from "../Objective";
import { OkrItemWrapper } from "./OkrListView.style";

interface IOkrItemProps {
  okr: IOkr;
}
type IOrkTypesOptionMap = {
  [key in IOkrTypes]: {
    icon: React.ReactElement;
    link: (id: string) => string;
    isBold?: boolean;
    drawerType: string;
    title: string;
  };
};
const progressTypes: any = {
  onTrack: "On Track",
  behind: "Behind",
  atRisk: "At Risk",
};
const orkTypes: IOrkTypesOptionMap = {
  OBJECTIVE: {
    icon: <AimOutlined className="okr-item-icon" />,
    link: (id) => `/objective/${id}/details`,
    isBold: true,
    drawerType: "ObjectiveDetailsDrawer",
    title: "Objective",
  },
  KEY_RESULT: {
    icon: <DashboardOutlined className="okr-item-icon" />,
    link: (id) => `/key-result/${id}/details`,
    drawerType: "KeyResultDetailsDrawer",
    title: "Key Result",
  },
  OBJECTIVE_CASCADED: {
    icon: (
      <div className="okr-item-icon-group">
        <AimOutlined className="okr-item-icon" />{" "}
        <VerticalAlignBottomOutlined className="okr-item-icon" />
      </div>
    ),
    link: (id) => `/objective/${id}/details`,
    drawerType: "ObjectiveDetailsDrawer",
    title: "Cascaded Objective",
  },
  OBJECTIVE_ALIGNMENT: {
    icon: (
      <div className="okr-item-icon-group">
        <AimOutlined className="okr-item-icon" />{" "}
        <VerticalAlignTopOutlined className="okr-item-icon" />
      </div>
    ),
    title: "Alignment Objective",
    link: (id) => `/objective/${id}/details`,
    drawerType: "ObjectiveDetailsDrawer",
  },
};
export const OkrItem: React.FC<IOkrItemProps> = ({ okr }) => {
  const okrType = orkTypes[okr.okrType];
  const dispatch = useDispatch();
  const handelOpenOkr = (id: string) => () => {
    dispatch(
      openDrawer({
        drawerType: okrType.drawerType,
        drawerProps: {
          title: okr.title,
          id,
        },
      })
    );
  };
  return (
    <OkrItemWrapper className={`okr-item-${okr.progress || "onTrack"}`}>
      <div className="okr-item-type">
        <Tooltip
          title={<TooltipField title={okrType.title} description={okr.title} />}
        >
          {okrType?.icon || ""}
        </Tooltip>
      </div>
      <div
        className={classNames("okr-item-title", { bold: okrType.isBold })}
        onClick={handelOpenOkr(okr.id)}
      >
        {okr.title}
      </div>
      <UserCell
        className="okr-item-owner"
        user={okr.owner || null}
        nameOnly
        xSmall
      />
      <Progress
        percent={Math.round(okr.score || 0)}
        className="okr-item-completion"
      />
      <div className="okr-item-progress">
        <Tag className="okr-item-tag">
          {progressTypes[okr.progress || "onTrack"]}
        </Tag>
      </div>
      <div className="okr-item-actions">
        {okr.okrType === "KEY_RESULT" && (
          <KeyResultActionMenu keyResult={okr} keyResultId={okr.id} />
        )}
        {(okr.okrType === "OBJECTIVE" ||
          okr.okrType === "OBJECTIVE_CASCADED" ||
          okr.okrType === "OBJECTIVE_ALIGNMENT") && (
          <ObjectiveActionMenu objective={okr} objectiveId={okr.id} />
        )}
      </div>
    </OkrItemWrapper>
  );
};
