/* eslint-disable no-console */
import React from "react";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";

const TestPage: React.FC = () => {
  console.log("date");
  return <AdminPage title="Test Page">Test</AdminPage>;
};

export default TestPage;
