/* eslint-disable no-console */
import React from "react";
import { Outlet } from "react-router-dom";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";

const currentMenu = [
  {
    key: "details",
    label: "Details",
    link: `/profile/details`,
  },
  {
    key: "notifications",
    label: "Notifications",
    link: `/profile/notifications`,
  },
  {
    key: "setting",
    label: "Setting",
    link: `/profile/setting`,
  },
];
const ProfilePage: React.FC = () => {
  console.log("ddd");
  return (
    <AdminPage title={"My Profile"} headerMenu={currentMenu}>
      <Outlet />
    </AdminPage>
  );
};

export default ProfilePage;
