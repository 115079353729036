/* eslint-disable no-console */
import { DeleteOutlined, EllipsisOutlined } from "@ant-design/icons";
import React from "react";
// import { Link } from "react-router-dom";
import { Dropdown, Menu } from "../../atoms";
import { Divider } from "../../atoms/Divider/Divider";
import { MenuActionCellWrapper } from "./Cells.style";
interface IMenuActionCell {
  title: string | React.ReactNode;
  action?: () => void;
  icon?: React.ReactNode;
  divider?: boolean;
  hidden?: boolean;
}
interface IActionCellProps {
  editLink?: string;
  onDelete: () => void;
  menu?: IMenuActionCell[];
  className?: string;
}
export const ActionCell: React.FC<IActionCellProps> = ({
  menu,
  onDelete,
  className,
}) => (
  <Dropdown
    trigger={["click"]}
    overlay={
      <MenuActionCellWrapper>
        {menu &&
          menu.map((item, index) => {
            if (item.hidden) return;
            if (item.divider) {
              return (
                <Divider
                  className="menu-action-cell-item-divider"
                  key={index}
                />
              );
            }
            return (
              <Menu.Item
                key={index}
                className="menu-action-cell-item"
                icon={item?.icon}
                onClick={item?.action}
              >
                {item.title}
              </Menu.Item>
            );
          })}
        <Divider className="menu-action-cell-item-divider" />
        {onDelete && (
          <Menu.Item
            icon={<DeleteOutlined />}
            className="menu-action-cell-item menu-action-cell-delete"
            onClick={onDelete}
          >
            Delete
          </Menu.Item>
        )}
      </MenuActionCellWrapper>
    }
    placement="bottomRight"
  >
    <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
      <EllipsisOutlined className={className || ""} />
    </a>
  </Dropdown>
);
