/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { UserList as UserListMole } from "../../molecules";
import { UserWrapper } from "./User.style";
import { UserListToolbar } from "./UserListToolbar";

export const UserList: React.FC = () => {
  const { users } = useSelector((state: any) => state.users);
  const { loading } = useSelector((state: any) => state.async);
  return (
    <AdminPageBody pageToolbar={<UserListToolbar />}>
      <UserWrapper>
        <UserListMole users={users} loading={loading} />
      </UserWrapper>
    </AdminPageBody>
  );
};
