/* eslint-disable no-console */
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { InputFormWrapper } from "../../atoms";
import {
  InitiativeListCard,
  KeyResultListCard,
  ObjectiveListCard,
} from "../../molecules";
import { DashboardCard } from "./DashboardCard";
interface IDashboardProps {
  name?: string;
}

export const Dashboard: React.FC<IDashboardProps> = ({ name }) => {
  // const { cycles } = useSelector((state: any) => state.cycles);
  const { myProfile } = useSelector((state: any) => state.myProfile);
  // const { loading } = useSelector((state: any) => state.async);
  const dispatch = useDispatch();
  const handelAddObjective = () => {
    dispatch(
      openModal({
        modalType: "AddObjectiveModal",
        modalProps: {
          title: "Create new objective",
        },
      })
    );
  };
  // React.useEffect(() => {
  //   if (!loading && cycles.length === 0) {
  //     dispatch(
  //       openModal({
  //         modalType: "RegWelcomeForm",
  //         modalProps: { title: "Add Category" },
  //       })
  //     );
  //   }
  // }, [cycles]);
  const generateGreetings = () => {
    const currentHour = moment().format("HH");

    if (currentHour >= "3" && currentHour < "12") {
      return "Good Morning";
    } else if (currentHour >= "12" && currentHour < "15") {
      return "Good Afternoon";
    } else if (currentHour >= "15" && currentHour < "20") {
      return "Good Evening";
    } else if (currentHour >= "20" && currentHour < "3") {
      return "Good Night";
    }
    return "Hello";
  };
  return (
    <AdminPageBody>
      <InputFormWrapper>
        <div className="input-form-body">
          <div
            className="input-form-description text-center "
            style={{ marginBottom: 0, fontWeight: 500 }}
          >
            {moment().format("dddd, MMMM DD")}
          </div>
          <div className="input-form-title text-center">
            {generateGreetings()}, {myProfile?.displayName || ""}
          </div>
          <DashboardCard selectedInitiative={[]} selectedCheckIns={[]} />
          <ObjectiveListCard
            filters={[
              {
                type: "filter",
                name: "ownerId",
                opr: "==",
                value: myProfile?.id,
              },
              {
                type: "filter",
                name: "okrType",
                opr: "in",
                value: [
                  "OBJECTIVE",
                  "OBJECTIVE_CASCADED",
                  "OBJECTIVE_ALIGNMENT",
                ],
              },
            ]}
            noCascade
            cycleId={myProfile?.defaultCycleId || ""}
            action={handelAddObjective}
          />
          <KeyResultListCard
            filters={[
              {
                type: "filter",
                name: "ownerId",
                opr: "==",
                value: myProfile?.id,
              },
              {
                type: "filter",
                name: "cycleId",
                opr: "==",
                value: myProfile?.defaultCycleId || "",
              },
            ]}
          />
          <InitiativeListCard
            filters={[
              {
                type: "filter",
                name: "ownerId",
                opr: "==",
                value: myProfile?.id,
              },
              {
                type: "filter",
                name: "cycleId",
                opr: "==",
                value: myProfile?.defaultCycleId || "",
              },
            ]}
          />
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
