import styled from "styled-components";

// theme is now fully typed
export const TasksFieldWrapper = styled.div`
  position: relative;
  padding: 20px 0;
  .ant-form-item {
    margin-bottom: 10px;
  }
  .tasks-field {
    &-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 40px;
      color: #1e1f21;
      border-bottom: 1px solid #edeae9;
      margin-bottom: 16px;
    }
    &-leg {
      display: flex;
      overflow: auto;
      align-items: center;
      border-bottom: 1px solid #edeae9;
      margin-bottom: 10px;
    }
    &-space {
      display: flex;
      gap: 10px;
      flex: 1;
    }
    &-action {
      /* position: absolute;
      right: 30px;
      background-color: #fff;
      width: 30px; */
      padding: 0 30px;
    }
    &-name {
      flex: 1;
    }
  }
`;
