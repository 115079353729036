/* eslint-disable no-console */
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { IInitiative, IUser } from "../../../common/types";
import { deleteCompanyInitiativeInFirestore } from "../../../firestore/firestoreService";
import { openDrawer } from "../../../redux/data/drawer/drawerReducer";
import { Table } from "../../atoms";
import { ActionCell, UserCell } from "../CommonCells";
import { InitiativeWrapper } from "./Initiative.style";

interface IInitiativeListProps {
  initiatives: IInitiative[];
  loading: boolean;
}
export const InitiativeList: React.FC<IInitiativeListProps> = ({
  initiatives,
  loading,
}) => {
  const dispatch = useDispatch();
  const handelDeleteObjective = async (id: string) => {
    await deleteCompanyInitiativeInFirestore(id);
  };
  const handelEditInitiative = (id: string) => () => {
    dispatch(
      openDrawer({
        drawerType: "EditInitiativeForm",
        drawerProps: {
          title: "Add Initiative",
          id,
        },
      })
    );
  };
  const columnsList = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: true,
      render: (v: string, record: any) => (
        <Link to={``} onClick={handelEditInitiative(record.id)}>
          {v}
        </Link>
      ),
      width: 400,
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      render: (v: IUser) => <UserCell user={v} avatarOnly />,
    },
    {
      title: "Cycle",
      dataIndex: "cycle",
      key: "cycle",
      render: (v: string) => v,
    },
    {
      title: "Completion",
      dataIndex: "completion",
      key: "completion",
      render: (v: string) => v,
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (v: moment.Moment) => moment(v).format("DD/MM/YYYY"),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: IInitiative) => (
        <ActionCell onDelete={() => handelDeleteObjective(record.id)} />
      ),
    },
  ];

  return (
    <InitiativeWrapper>
      <Table
        columns={columnsList}
        dataSource={initiatives}
        loading={loading}
        rowKey="id"
        // scroll={{ x: 800, y: 500 }}
      />
    </InitiativeWrapper>
  );
};
