/* eslint-disable no-console */
import { ICycleInput, IQueryConfigArgs } from "../../common/types";
import { cycleDataFormat } from "../firestoreDataFormat";
import { setUserAddLog, setUserUpdateLog } from "../helper";
import { db, collectionWithArgs } from "./firestoreService";
import { COLLECTION_COMPANIES, COLLECTION_CYCLES } from "./firebaseConstants";

export function fetchCompanyCyclesFromFirestore(args?: IQueryConfigArgs[]) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  const ref = db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_CYCLES);
  return collectionWithArgs(ref, args || []);
}
export function listenToCompanyCycleFromFirestore(id: string) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_CYCLES)
    .doc(id);
}

export function addCompanyCycleToFirestore(doc: ICycleInput) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_CYCLES)
    .add({
      ...cycleDataFormat(doc),
      ...setUserAddLog(),
    });
}

export async function addCompanyCyclesToFirestore(docs: ICycleInput[]) {
  const batch = db.batch();
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  docs.forEach((doc) => {
    const docRef = db
      .collection(COLLECTION_COMPANIES)
      .doc(companyId)
      .collection(COLLECTION_CYCLES)
      .doc(); // automatically generate unique id
    batch.set(docRef, {
      ...cycleDataFormat(doc),
      ...setUserAddLog(),
    });
  });
  return await batch.commit();
}
export function updateCompanyCycleInFirestore(
  docId: string,
  doc: ICycleInput | any
) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_CYCLES)
    .doc(docId)
    .update({
      ...cycleDataFormat(doc),
      ...setUserUpdateLog(),
    });
}

export function deleteCompanyCycleInFirestore(docId: string) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_CYCLES)
    .doc(docId)
    .delete();
}

export function toggleCompanyCycleDefaultInFirestore(docId: string) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .update({
      defaultCycleId: docId,
      ...setUserUpdateLog(),
    });
}
