/* eslint-disable no-console */
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import React from "react";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { InputFormWrapper } from "../../atoms";
import { CheckboxGroup } from "../../molecules";

const notificationOptions = [
  {
    label:
      "I am assigned as the owner of an Objective, Key Result or an Initiative",
    value: "ASSIGNED-OWNER",
  },
  {
    label: "Updates of Objectives I am assigned as the owner",
    value: "UPDATE_ASSIGNED_OWNER_OBJECTIVES",
  },
  {
    label: "Updates of Key results I am assigned as the owner",
    value: "UPDATE_ASSIGNED_OWNER_KEY_RESULTS",
  },
  {
    label: "Updates of Objectives I participate on",
    value: "UPDATE_OBJECTIVES_PARTICIPATE",
  },
  {
    label: "Updates of Key results I participate on",
    value: "UPDATE_KEY_RESULTS_PARTICIPATE",
  },
  {
    label: "Initiatives I am assigned with",
    value: "UPDATE_INITIATIVES _PARTICIPATE",
  },
  { label: "Comments and tags of my name", value: "UPDATE_Comments _TAG" },
];

export const ProfileNotifications: React.FC = () => {
  const [notification, setNotification] = React.useState<CheckboxValueType[]>(
    []
  );
  const handelNotificationOnChange = (value: CheckboxValueType[]) => {
    setNotification(value);
  };
  return (
    <AdminPageBody>
      <InputFormWrapper>
        <div className="input-form-body">
          <CheckboxGroup
            plainOptions={notificationOptions}
            defaultCheckedList={notification}
            onChange={handelNotificationOnChange}
            checkAllName={"Notification"}
          />
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
