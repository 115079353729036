import { notification } from "antd";
interface INotification {
  message: string;
  description: string;
}
interface INotificationOptionsMap {
  [key: string]: INotification;
}
const notificationOptions: INotificationOptionsMap = {
  COPY_OKR_URL: {
    message: `Copy Url`,
    description: "The link was copied to your clipboard.",
  },
  ADD_OKR_TO_FAVORED: {
    message: `Add To Favor`,
    description: "The OKR has add to your Favored.",
  },
  REMOVE_OKR_FROM_FAVORED: {
    message: `Remove from Favor`,
    description: "The OKR has removed from your Favored.",
  },
};

export const notificationServices = (name: string) => {
  notification.info({
    message: notificationOptions[name].message,
    description: notificationOptions[name].description,
    placement: "bottomLeft",
  });
};
