/* eslint-disable no-console */
import React from "react";
import { find } from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";
import cuid from "cuid";
import {
  InputField,
  UserSelectedField,
  SelectField,
  MilestoneField,
} from "../commonFields";
import { Alert, Button, Divider, Form, Input, Space } from "../../atoms";
import { checkInRequestTypesOptions } from "../../../enums";
import {
  ICycle,
  IMilestone,
  IOkrKeyResultMilestonesInput,
} from "../../../common/types";
import { addCompanyOkrToFirestore } from "../../../firestore/firestoreService";
import { IOkrForTypes } from "../../../common/types/okrTypes";
interface IOkrAddMilestoneTrackingFormProps {
  boardId: string;
  okrParentId: string;
  okrParentIds: string[];
  cycleId: string;
  okrFor: IOkrForTypes;
  callback: (keyResultId: string) => void;
  cycle: ICycle | null;
}
export const KeyResultAddMilestoneTrackingForm: React.FC<
  IOkrAddMilestoneTrackingFormProps
> = ({
  boardId,
  callback,
  okrParentId,
  cycleId,
  cycle,
  okrFor,
  okrParentIds,
}) => {
  const { users } = useSelector((state: any) => state.users);
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleSubmit = async () => {
    try {
      const values: IOkrKeyResultMilestonesInput = await form.validateFields();
      setLoading(true);
      const owner = find(users, ["id", values.ownerId]);
      const keyResult = await addCompanyOkrToFirestore({
        okrType: "KEY_RESULT",
        keyResultType: "MILESTONE",
        status: true,
        title: values.title,
        owner,
        ownerId: values.ownerId,
        description: values.description || "",
        okrParentId,
        boardId,
        cycleId,
        okrFor,
        milestones: values.milestones.map((milestone: IMilestone) => ({
          ...milestone,
          id: cuid(),
          startDate: moment(milestone.startDate).toDate(),
          dueDate: moment(milestone.dueDate).toDate(),
        })),
        okrParentIds: [...(okrParentIds || []), okrParentId],
      });
      setLoading(false);
      callback(keyResult.id);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  return (
    <>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <Form
        form={form}
        name="horizontal_login"
        layout="vertical"
        autoComplete="off"
      >
        <InputField
          label="Key result name"
          name="title"
          rules={[{ required: true, message: "Missing title" }]}
          placeholder="Key result title"
        />
        <Form.Item name="description" label="Description">
          <Input.TextArea placeholder="Description" />
        </Form.Item>
        <UserSelectedField
          label="Who owns this Key Result?"
          name="ownerId"
          boardId={boardId}
          rules={[{ required: true, message: "Missing owner" }]}
        />
        <Space className="input-form-space">
          <SelectField
            label="CheckIn Request"
            name="checkInRequest"
            options={checkInRequestTypesOptions}
            initialValue="Daily"
          />
        </Space>

        {cycle && <MilestoneField name="milestones" cycle={cycle} />}
        <Divider></Divider>
        <Button
          type="primary"
          className="input-form-btn"
          onClick={handleSubmit}
          loading={loading}
        >
          Create Key Result
        </Button>
      </Form>
    </>
  );
};
