/* eslint-disable no-console */
import React, { useRef } from "react";
import { Form, DatePicker } from "antd";
import { EditableContext } from "./EditableForm";
import { EditableFieldWrapper } from "./EditableFields.style";
interface IEditableDatePickerFieldProps {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: string;
  record: moment.Moment;
  handleSave: (name: string, record: moment.Moment) => void;
}

export const EditableDatePickerField: React.FC<
  IEditableDatePickerFieldProps
> = ({ title, editable, dataIndex, record, handleSave, ...restProps }) => {
  const valueRef = useRef<any>(null);
  const inputRef = useRef<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const form = React.useContext(EditableContext)!;

  const save = async () => {
    try {
      const values = await form.validateFields();
      console.log(values[dataIndex]);
      // toggleEdit();
      handleSave(dataIndex, values[dataIndex]);
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  React.useEffect(() => {
    if (record !== valueRef.current) {
      form.setFieldsValue({ [dataIndex]: record });
      valueRef.current = record;
    }
  }, [dataIndex, record]);

  return (
    <EditableFieldWrapper {...restProps}>
      {" "}
      <EditableContext.Provider value={form}>
        <Form.Item
          className="editable-field-item"
          style={{ margin: 0 }}
          name={dataIndex}
        >
          <DatePicker
            className="editable-field-dateRangePicker"
            ref={inputRef}
            onBlur={save}
          />
        </Form.Item>
      </EditableContext.Provider>
    </EditableFieldWrapper>
  );
};
