/* eslint-disable no-console */
import moment from "moment";
import React from "react";
import { ICycle, ICycleInput } from "../../../common/types";
import { listenToCompanyCycleFromFirestore } from "../../../firestore/firestoreService";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { CycleCellWrapper } from "./Cells.style";

interface ICycleCellProps {
  cycle?: ICycle | ICycleInput | null;
  cycleId?: string;
  avatarOnly?: boolean;
  nameOnly?: boolean;
  className?: string;
}

export const CycleCell: React.FC<ICycleCellProps> = ({
  cycle,
  avatarOnly,
  nameOnly,
  className,
  cycleId,
}) => {
  const [localCycle, setCycle] = React.useState<ICycle>();
  userFirestoreDoc({
    query: () => listenToCompanyCycleFromFirestore(cycleId || ""),
    data: setCycle,
    deps: [cycleId],
    shouldExecute: !!cycleId,
    local: true,
  });
  if (!cycle && !localCycle) return <span></span>;
  return (
    <CycleCellWrapper className={className}>
      <div
        className="cycle-cell-color"
        style={{ backgroundColor: cycle?.color || localCycle?.color }}
      ></div>
      <div className="cycle-cell-body">
        <div className="cycle-cell-name">{cycle?.name || localCycle?.name}</div>
        {!nameOnly && (
          <div className="cycle-cell-period">
            {moment(cycle?.startDate || localCycle?.startDate).format(
              "DD/MM/YYYY"
            )}
            -
            {moment(cycle?.endDate || localCycle?.startDate).format(
              "DD/MM/YYYY"
            )}
          </div>
        )}
      </div>
    </CycleCellWrapper>
  );
};

// name: string;
//   description: string;
//   startDate: string;
//   endDate: string;
//   updateKRs: string;
//   weekDay: string;
//   color: string;
//   status: string;
//   boardId?: string;
