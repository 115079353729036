/* eslint-disable no-console */
import { TeamOutlined } from "@ant-design/icons";
import React from "react";

interface IOrgChartCardTeamProps {
  data: any;
}
export const OrgChartCardTeam: React.FC<IOrgChartCardTeamProps> = ({
  data,
}) => {
  console.log("data");
  return (
    <div className="org-chart-card">
      <div className="org-chart-card-icon">
        <TeamOutlined className="alignment-view-icon" />
      </div>
      <div className="org-chart-card-info">
        <div className="org-chart-card-title">Team OKRs</div>
      </div>
    </div>
  );
};
