/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { useCycleListObjectives } from "../../../hooks/useCycleListObjectives";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { CycleBoard } from "../../molecules/Cycle";
import { CycleListToolbar } from "./CycleListToolbar";

export const CyclesBoard: React.FC = () => {
  const { cycles } = useSelector((state: any) => state.cycles);
  const { okrs } = useSelector((state: any) => state.okrs);
  const { loading } = useSelector((state: any) => state.async);
  const [data, setData] = React.useState<any[]>([]);
  useCycleListObjectives({
    objectives: okrs,
    cycles,
    deps: [okrs, cycles],
    data: setData,
  });
  return (
    <AdminPageBody pageToolbar={<CycleListToolbar />}>
      <CycleBoard cycles={data} loading={loading} />
    </AdminPageBody>
  );
};
