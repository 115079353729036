/* eslint-disable no-console */
import { UserOutlined } from "@ant-design/icons";
import React from "react";

interface IOrgChartCardUserProps {
  data: any;
}
export const OrgChartCardUser: React.FC<IOrgChartCardUserProps> = ({
  data,
}) => {
  console.log("data");
  return (
    <div className="org-chart-card">
      <div className="org-chart-card-icon">
        <UserOutlined className="alignment-view-icon" />
      </div>
      <div className="org-chart-card-info">
        <div className="org-chart-card-title">User OKRs</div>
      </div>
    </div>
  );
};
