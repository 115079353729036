/* eslint-disable no-console */

import { IInitiative, IOkr, IUser } from "../../../common/types";
import {
  LISTEN_TO_MY_PROFILE,
  LISTEN_TO_MY_PROFILE_INITIATIVES,
  LISTEN_TO_MY_PROFILE_OKRS,
} from "./myProfileConstants";

export function listenToMyProfile(profile: IUser) {
  return {
    type: LISTEN_TO_MY_PROFILE,
    payload: profile,
  };
}

export function listenToMyProfileInitiatives(initiatives: IInitiative[]) {
  return {
    type: LISTEN_TO_MY_PROFILE_INITIATIVES,
    payload: initiatives,
  };
}

export function listenToMyProfileOkrs(okrs: IOkr[]) {
  return {
    type: LISTEN_TO_MY_PROFILE_OKRS,
    payload: okrs,
  };
}
