/* eslint-disable no-console */
import faker from "@faker-js/faker";
import cuid from "cuid";
import firebase from "../../config/firebase";
import { IUserInput, IQueryConfigArgs, IUser } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import { COLLECTION_COMPANIES, COLLECTION_USERS } from "./firebaseConstants";

export function fetchCompanyUsersFromFirestore(args?: IQueryConfigArgs[]) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  const ref = db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_USERS);
  return collectionWithArgs(ref, args || []);
}
export function listenToCompanyUserFromFirestore(id: string) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_USERS)
    .doc(id);
}

export function addCompanyUserToFirestore(doc: IUserInput) {
  // const user = firebase.auth().currentUser;
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_USERS)
    .add({
      ...doc,
      createDate: new Date(),
      // createBy: user,
    });
}

export function updateCompanyUserInFirestore(docId: string, doc: IUser | any) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_USERS)
    .doc(docId)
    .update(doc);
}
export function deleteCompanyUserInFirestore(docId: string) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_USERS)
    .doc(docId)
    .delete();
}
export function activeToggleCompanyUserInFirestore(
  docId: string,
  status: boolean
) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_USERS)
    .doc(docId)
    .update({
      status,
    });
}

export function inviteCompanyUserInFirestore(
  emails: string[],
  boardId?: string
) {
  return emails.map(
    async (email) =>
      await addCompanyUserToFirestore({
        firstName: faker.name.firstName(),
        lastName: faker.name.lastName(),
        displayName: `${faker.name.firstName()} ${faker.name.lastName()}`,
        phoneNumber: faker.phone.phoneNumber(),
        email: faker.internet.email(),
        status: true,
        position: faker.name.jobTitle(),
        okrChampion: "--",
        image: { url: faker.image.avatar(), name: "d", uid: cuid() } || "",
        boardIds: boardId ? [boardId] : [],
        manager: null,
        managerId: null,
      })
  );
}

export function toggleCompanyUserDefaultCycleInFirestore(
  defaultCycleId: string
) {
  const user = firebase.auth().currentUser;
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  if (!user) return;
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_USERS)
    .doc(user.uid)
    .update({
      defaultCycleId,
    });
}

export function likeOkrCompanyUserFirestore(okrId: string, remove: boolean) {
  const user = firebase.auth().currentUser;
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  if (!user) return;
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_USERS)
    .doc(user.uid)
    .update({
      okrsLiked: !remove
        ? firebase.firestore.FieldValue.arrayUnion(okrId)
        : firebase.firestore.FieldValue.arrayRemove(okrId),
    });
}
