/* eslint-disable no-console */
import React from "react";
import { IActivity } from "../../../common/types";
import { ActivityItemWrapper } from "./Activity.style";

interface IActivityItemProps {
  activity: IActivity;
}
export const ActivityItem: React.FC<IActivityItemProps> = ({ activity }) => {
  console.log(activity);
  return <ActivityItemWrapper>ActivityItem</ActivityItemWrapper>;
};
