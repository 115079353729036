/* eslint-disable no-console */
import React from "react";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Form, Input } from "../../atoms";
import { changeUserPassword } from "../../../firestore/firebaseService";
import { AuthWrapper } from "./Auth.style";

interface IChangePasswordProps {
  changePasswordKey: string;
}
export const ChangePassword: React.FC<IChangePasswordProps> = ({
  changePasswordKey,
}) => {
  const history = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const handleSubmit = async () => {
    try {
      const values: { key: string; password: string } =
        await form.validateFields();
      setLoading(true);
      changeUserPassword({
        key: changePasswordKey,
        password: values.password,
      });

      setLoading(false);
      history("/sing-in");
      notification.success({
        message: "Notification Title",
        description: "This is the content",
      });
    } catch (errorInfo: any) {
      console.log({ errorInfo });
      setLoading(false);
      setError("Problem with user name or password");
    }
  };
  return (
    <AuthWrapper>
      <div className="sign-in-title">Change Password</div>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <Form form={form} name="horizontal_login" layout="vertical">
        <Form.Item
          name="password"
          rules={[
            { required: true, message: "Please input your New Password" },
          ]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: "Please input your New Password" },
          ]}
        >
          <Input.Password placeholder="Confiem Password" />
        </Form.Item>
        <Button
          className="sign-in-btn"
          onClick={handleSubmit}
          loading={loading}
        >
          Send Link
        </Button>
      </Form>
    </AuthWrapper>
  );
};
