import { IDepartment } from "../../../common/types";
import {
  CREATE_COMPANY_DEPARTMENT,
  UPDATE_COMPANY_DEPARTMENT,
  DELETE_COMPANY_DEPARTMENT,
  FETCH_COMPANY_DEPARTMENTS,
  LISTEN_TO_SELECTED_COMPANY_DEPARTMENT,
  CLEAR_COMPANY_DEPARTMENTS,
  CLEAR_SELECTED_COMPANY_DEPARTMENT,
  LISTEN_TO_SELECTED_DEPARTMENT_USERS,
} from "./companyDepartmentConstants";

const initialState = {
  departments: [],
  departmentsDropDown: [],
  imagesGroups: [],
  moreDepartments: true,
  selectedDepartment: null,
  selectedDepartmentUsers: [],
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
};

export default function departmentReducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_COMPANY_DEPARTMENT:
      return {
        ...state,
        departments: [...state.departments, payload],
      };
    case UPDATE_COMPANY_DEPARTMENT:
      return {
        ...state,
        departments: [
          ...state.departments.filter(
            (evt: IDepartment) => evt.id !== payload.id
          ),
          payload,
        ],
      };
    case DELETE_COMPANY_DEPARTMENT:
      return {
        ...state,
        departments: [
          ...state.departments.filter((evt: IDepartment) => evt.id !== payload),
        ],
      };
    case FETCH_COMPANY_DEPARTMENTS:
      return {
        ...state,
        departments: payload,
        moreDepartments: payload.moreDepartments,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_COMPANY_DEPARTMENT:
      return {
        ...state,
        selectedDepartment: payload,
      };
    case LISTEN_TO_SELECTED_DEPARTMENT_USERS:
      return {
        ...state,
        selectedDepartmentUsers: payload,
      };
    case CLEAR_SELECTED_COMPANY_DEPARTMENT:
      return {
        ...state,
        selectedDepartment: null,
      };
    case CLEAR_COMPANY_DEPARTMENTS:
      return {
        ...state,
        departments: [],
        moreDepartments: true,
        lastVisible: null,
      };
    default:
      return state;
  }
}
