/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AppstoreAddOutlined,
  PlusOutlined,
  SortAscendingOutlined,
} from "@ant-design/icons";
import { Button } from "../../atoms";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { PageToolbar } from "../../molecules";

interface IBoardObjectiveListToolbarProps {
  boardId: string;
}
export const BoardObjectiveListToolbar: React.FC<
  IBoardObjectiveListToolbarProps
> = ({ boardId }) => {
  const dispatch = useDispatch();
  const { selectedBoard } = useSelector((state: any) => state.boards);
  const handelSorting = () => {
    console.log("filterCycles");
  };
  const handelCustomize = () => {
    console.log("filterCycles");
  };
  const handelAddBoard = () => {
    dispatch(
      openModal({
        modalType: "AddObjectiveModal",
        modalProps: {
          title: "Add Objective Form",

          boardId,
          board: selectedBoard,
        },
      })
    );
  };

  return (
    <PageToolbar>
      <Button
        type="primary"
        onClick={handelAddBoard}
        icon={<PlusOutlined />}
        className="page-toolbar-btn"
      >
        Add objective
      </Button>

      <div className="page-toolbar-right-children-elements">
        <Button
          type="link"
          onClick={handelSorting}
          icon={<SortAscendingOutlined />}
          className="page-toolbar-btn-link"
        >
          Sorting
        </Button>
        <Button
          type="link"
          onClick={handelCustomize}
          icon={<AppstoreAddOutlined />}
          className="page-toolbar-btn-link"
        >
          Customize
        </Button>
      </div>
    </PageToolbar>
  );
};
