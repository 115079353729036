import {
  IInitiativeInput,
  IQueryConfigArgs,
  IInitiative,
  IComment,
} from "../../common/types";
import firebase from "../../config/firebase";
import { db, collectionWithArgs } from "./firestoreService";
import {
  COLLECTION_COMPANIES,
  COLLECTION_INITIATIVES,
} from "./firebaseConstants";

export function fetchCompanyInitiativesFromFirestore(
  args?: IQueryConfigArgs[]
) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  const ref = db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_INITIATIVES);
  return collectionWithArgs(ref, args || []);
}
export function listenToCompanyInitiativeFromFirestore(id: string) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_INITIATIVES)
    .doc(id);
}

export function addCompanyInitiativeToFirestore(doc: IInitiativeInput) {
  // const user = firebase.auth().currentUser;
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_INITIATIVES)
    .add({
      ...doc,
      createDate: new Date(),
      // createBy: user,
    });
}

export function updateCompanyInitiativeInFirestore(
  docId: string,
  doc: IInitiative | { [x: string]: any }
) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_INITIATIVES)
    .doc(docId)
    .update(doc);
}
export function deleteCompanyInitiativeInFirestore(docId: string) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_INITIATIVES)
    .doc(docId)
    .delete();
}

export function activeToggleCompanyInitiativeInFirestore(
  docId: string,
  status: string
) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_INITIATIVES)
    .doc(docId)
    .update({
      status,
    });
}

export function addCompanyInitiativeCommentToFirestore(
  docId: string,
  doc: IComment
) {
  // const user = firebase.auth().currentUser;
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_INITIATIVES)
    .doc(docId)
    .update({
      comments: firebase.firestore.FieldValue.arrayUnion(doc),
    });
}
