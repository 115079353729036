/* eslint-disable no-console */
import { filter, findIndex, reduce } from "lodash";
import moment from "moment";
import { ICheckIn, ICheckInRequestDate, IDateOption } from "../common/types";
import { checkInRequestTypes } from "../enums";

interface IGetKeyResultScoreNumber {
  targetNumber: number;
  initialNumber: number;
  checkIns: ICheckIn[];
}
const getTimeUnite = (type: string) => {
  if (type === "months") {
    return "month";
  } else if (type === "weeks") {
    return "weeks";
  } else if (type === "quarters") {
    return "quarter";
  }
  return "day";
};
export const getKeyResultCheckInDatesRange = (
  startDate: moment.Moment,
  endDate: moment.Moment,
  type: any
) => {
  const dates: IDateOption[] = [];
  let date = startDate;

  let i = 0;
  if (!["months", "weeks", "days", "quarters"].includes(type)) {
    return [];
  }
  while (date <= endDate) {
    date.endOf(getTimeUnite(type));
    const nextDate = date.clone().add(1, type);
    nextDate.endOf(getTimeUnite(type));
    if (nextDate > endDate) {
      dates.push({
        id: endDate.format("YYYY-MM-DD"),
        value: endDate.toDate(),
        label: endDate.format("DD/MM/YYYY"),
        unitOfTime: type,
      });
    } else {
      dates.push({
        id: date.format("YYYY-MM-DD"),
        value: date.toDate(),
        label: date.format("DD/MM/YYYY"),
        unitOfTime: type,
      });
    }
    date = date.clone().add(1, type);
    i++;
    if (i === 200) break;
  }
  return dates;
};

export const getKeyResultScoreNumber = ({
  targetNumber,
  initialNumber,
  checkIns,
}: IGetKeyResultScoreNumber): number => {
  const totalActualValue = reduce(
    checkIns,
    (acc: number, val: ICheckIn) => acc + +val.value,
    0
  );
  return (+totalActualValue * 100) / Math.abs(+targetNumber - +initialNumber);
};

export const getKeyResultProgress = (
  currentTarget: number,
  targetNumber: number,
  initialNumber: number,
  score: number,
  progressRange: [number, number]
): string => {
  const [low, high] = progressRange;
  const totalTarget = Math.abs(targetNumber - initialNumber);
  const scorePresent = (+score * totalTarget) / (targetNumber - +currentTarget);
  if (scorePresent < low) {
    return "atRisk";
  }
  if (scorePresent > low && scorePresent < high) {
    return "behind";
  }
  return "onTrack";
};

export const checkInTotalDateValue = (
  checkIns: ICheckIn[],
  checkInDate: string
) => {
  const values = reduce(
    filter(
      checkIns,
      (checkIn) =>
        moment(checkIn.checkInDate).format("YYYY-MM-DD") === checkInDate
    ) || [],
    (acc: number, val: ICheckIn) => +acc + +val.value,
    0
  );
  return +values;
};

export const keyResultDateActualValue = (
  total: number,
  value: number,
  type: string,
  initialNumber: number
) => {
  if (type === "SHOULD_INCREASE_TO") {
    return total + value;
  } else if (type === "SHOULD_DECREASE_TO") {
    return total - value;
  } else if (type === "SHOULD_STAY_ABOVE") {
    return value === 0 ? initialNumber : value;
  } else if (type === "SHOULD_STAY_BELOW") {
    return total + value;
  }

  return total + value;
};

const getValueIncrement = (
  targetNumber: number,
  initialNumber: number,
  numberDays: number
): number => {
  const currentValue: number = Math.abs(targetNumber - initialNumber);
  if (currentValue === 0) {
    return numberDays;
  }
  return Math.round(currentValue / numberDays);
};
interface IGenerateKeyResultCheckInDates {
  startDate: moment.Moment | Date;
  endDate: moment.Moment | Date;
  checkInRequest: string;
  targetNumber: number;
  initialNumber: number;
  calculationMethod: string;
}
export const generateKeyResultCheckInDates = ({
  startDate,
  endDate,
  checkInRequest,
  targetNumber,
  initialNumber,
  calculationMethod,
}: IGenerateKeyResultCheckInDates) => {
  const datesRange = getKeyResultCheckInDatesRange(
    moment(startDate),
    moment(endDate),
    checkInRequestTypes[checkInRequest]?.unitOfTime || ""
  );
  console.log(datesRange);
  const numberDays = datesRange.length || 0;
  const valueIncrement: number = getValueIncrement(
    +targetNumber,
    +initialNumber,
    +numberDays
  );
  let target = +initialNumber;
  if (
    calculationMethod === "SHOULD_STAY_ABOVE" ||
    calculationMethod === "SHOULD_STAY_BELOW"
  ) {
    target = +targetNumber;
  }
  const record = datesRange.map((date) => {
    if (calculationMethod === "SHOULD_DECREASE_TO") {
      target = target - +valueIncrement;
    }
    if (calculationMethod === "SHOULD_INCREASE_TO") {
      target = target + +valueIncrement;
    }
    return {
      ...date,
      target,
      calculationMethod,
    };
  });
  return record;
};
export const keyResultCheckInNearestDate = (
  dates: ICheckInRequestDate[]
): ICheckInRequestDate => {
  let nearestDate: moment.Moment;
  let nearestDateValue = "";
  dates.forEach((date: ICheckInRequestDate) => {
    const diff = moment(date.value).diff(moment(), "days");
    if (diff > 0) {
      if (nearestDate) {
        if (moment(date.value).diff(nearestDate, "days") < 0) {
          nearestDate = moment(date.value);
          nearestDateValue = date.id;
        }
      } else {
        nearestDate = moment(date.value);
        nearestDateValue = date.id;
      }
    }
  });
  const nearestDateIndex = findIndex(dates, ["value", nearestDateValue]);
  if (nearestDateIndex !== -1) {
    return dates[nearestDateIndex];
  }
  return dates[0];
};
