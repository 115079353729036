import { IBoard } from "../../../common/types";
import {
  CREATE_COMPANY_BOARD,
  UPDATE_COMPANY_BOARD,
  DELETE_COMPANY_BOARD,
  FETCH_COMPANY_BOARDS,
  LISTEN_TO_SELECTED_COMPANY_BOARD,
  CLEAR_COMPANY_BOARDS,
  CLEAR_SELECTED_COMPANY_BOARD,
  LISTEN_TO_SELECTED_BOARD_OKRS,
  LISTEN_TO_SELECTED_BOARD_INITIATIVES,
  LISTEN_TO_SELECTED_BOARD_CYCLES,
  LISTEN_TO_SELECTED_BOARD_USERS,
} from "./companyBoardConstants";

const initialState = {
  boards: [],
  boardsDropDown: [],
  imagesGroups: [],
  moreBoards: true,
  selectedBoard: {},
  selectedBoardUsers: [],
  selectedBoardOkrs: [],
  selectedBoardInitiatives: [],
  selectedBoardCycles: [],
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
};

export default function boardReducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_COMPANY_BOARD:
      return {
        ...state,
        boards: [...state.boards, payload],
      };
    case UPDATE_COMPANY_BOARD:
      return {
        ...state,
        boards: [
          ...state.boards.filter((evt: IBoard) => evt.id !== payload.id),
          payload,
        ],
      };
    case DELETE_COMPANY_BOARD:
      return {
        ...state,
        boards: [...state.boards.filter((evt: IBoard) => evt.id !== payload)],
      };
    case FETCH_COMPANY_BOARDS:
      return {
        ...state,
        boards: payload,
        moreBoards: payload.moreBoards,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_COMPANY_BOARD:
      return {
        ...state,
        selectedBoard: payload,
      };
    case LISTEN_TO_SELECTED_BOARD_USERS:
      return {
        ...state,
        selectedBoardUsers: payload,
      };
    case LISTEN_TO_SELECTED_BOARD_OKRS:
      return {
        ...state,
        selectedBoardOkrs: payload,
      };
    case LISTEN_TO_SELECTED_BOARD_INITIATIVES:
      return {
        ...state,
        selectedBoardInitiatives: payload,
      };
    case LISTEN_TO_SELECTED_BOARD_CYCLES:
      return {
        ...state,
        selectedBoardCycles: payload,
      };
    case CLEAR_SELECTED_COMPANY_BOARD:
      return {
        ...state,
        selectedBoard: null,
      };
    case CLEAR_COMPANY_BOARDS:
      return {
        ...state,
        boards: [],
        moreBoards: true,
        lastVisible: null,
      };
    default:
      return state;
  }
}
