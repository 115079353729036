/* eslint-disable no-console */
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { ActionCell } from "..";
import { IUser } from "../../../common/types";
import { deleteCompanyUserInFirestore } from "../../../firestore/firestoreService";
import { Table } from "../../atoms";
import { UserCell } from "../CommonCells/UserCell";
import { UserWrapper } from "./User.style";

interface IUserListProps {
  users: IUser[];
  loading: boolean;
}
export const UserList: React.FC<IUserListProps> = ({ users, loading }) => {
  const handelDeleteUser = async (id: string) => {
    await deleteCompanyUserInFirestore(id);
  };
  const columnsList = [
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
      fixed: true,
      render: (v: string, record: IUser) => (
        <Link to={`/user/${record.id}/details`}>
          <UserCell user={record} />
        </Link>
      ),
      width: 400,
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      render: (v: string) => v || "",
    },
    {
      title: "Manager",
      dataIndex: "manager",
      key: "manager",
      render: (v: IUser) => v?.firstName || "-",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (v: string) => (v ? "Active" : "Waiting"),
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (v: moment.Moment) => moment(v).format("DD/MM/YYYY"),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: IUser) => (
        <ActionCell onDelete={() => handelDeleteUser(record.id)} />
      ),
    },
  ];
  return (
    <UserWrapper>
      <Table
        columns={columnsList}
        dataSource={users}
        loading={loading}
        rowKey="id"
        // scroll={{ x: 800, y: 500 }}
      />
    </UserWrapper>
  );
};
