export const CREATE_COMPANY_CYCLE = "CREATE_COMPANY_CYCLE";
export const UPDATE_COMPANY_CYCLE = "UPDATE_COMPANY_CYCLE";
export const DELETE_COMPANY_CYCLE = "DELETE_COMPANY_CYCLE";
export const FETCH_COMPANY_CYCLES = "FETCH_COMPANY_CYCLES";
export const LISTEN_TO_SELECTED_COMPANY_CYCLE =
  "LISTEN_TO_SELECTED_COMPANY_CYCLE";
export const CLEAR_SELECTED_COMPANY_CYCLE = "CLEAR_SELECTED_COMPANY_CYCLE";
export const CLEAR_COMPANY_CYCLES = "CLEAR_COMPANY_CYCLES";
export const SET_COMPANY_CYCLES_FILTER = "SET_COMPANY_CYCLES_FILTER";
export const LISTEN_TO_SELECTED_CYCLE_OKRS = "LISTEN_TO_SELECTED_CYCLE_OKRS";
