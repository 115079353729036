/* eslint-disable no-console */
import React from "react";
import { CompanyWrapper } from "./Company.style";

interface ICompanyListProps {
  name?: string;
}
export const CompanyList: React.FC<ICompanyListProps> = ({ name }) => {
  console.log(name);
  return <CompanyWrapper>CompanyList</CompanyWrapper>;
};
