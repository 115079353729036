/* eslint-disable no-console */
import React from "react";
// import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ActionCell, UserCell } from "../../molecules";
import { IBoard, IUser } from "../../../common/types";
// import { TableHeader } from "../../molecules/TableHeader/TableHeader";
import { Table } from "../../atoms";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import {
  deleteCompanyBoardInFirestore,
  fetchCompanyBoardsFromFirestore,
} from "../../../firestore/firestoreService";
// import { openModal } from "../../../redux/data/modals/modalReducer";
import { listenToCompanyBoards } from "../../../redux/data/companyBoard/companyBoardActions";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { BoardListToolbar } from "./BoardListToolbar";

export const BoardList: React.FC = () => {
  // const { users } = useSelector((state: any) => state.users);
  const { boards } = useSelector((state: any) => state.boards);
  const { loading } = useSelector((state: any) => state.async);
  const dispatch = useDispatch();

  useFirestoreCollection({
    query: () => fetchCompanyBoardsFromFirestore([]),
    data: listenToCompanyBoards,
    deps: [dispatch],
  });
  const handelDeleteBoard = async (id: string) => {
    await deleteCompanyBoardInFirestore(id);
  };
  const columnsBoardList = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      fixed: true,
      render: (v: string, record: any) => (
        <Link to={`/board/${record.id}/details`}>{v}</Link>
      ),
      width: 400,
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      render: (v: IUser) => <UserCell user={v} avatarOnly />,
    },
    {
      title: "Members",
      dataIndex: "users",
      key: "users",
      render: (v: number) => v,
    },
    {
      title: "Visibility",
      dataIndex: "visibility",
      key: "visibility",
      render: (v: string) => v,
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (v: moment.Moment) => moment(v).format("DD/MM/YYYY"),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: IBoard) => (
        <ActionCell onDelete={() => handelDeleteBoard(record.id)} />
      ),
    },
  ];
  return (
    <AdminPageBody pageToolbar={<BoardListToolbar />}>
      <Table
        columns={columnsBoardList}
        dataSource={boards}
        loading={loading}
        rowKey="id"
        // scroll={{ x: 800, y: 500 }}
      />
    </AdminPageBody>
  );
};
