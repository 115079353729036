import React from "react";
import moment from "moment";
import { CycleCell } from "../CommonCells";
import { IInitiative, IInitiativeInput, IUser } from "../../../common/types";
import {
  EditableTitleField,
  EditableFieldForm,
  FieldView,
  EditableDescriptionField,
  EditableSelectedField,
  EditableUserSelectedField,
  EditableDatePickerField,
} from "../commonFields";
import { updateCompanyInitiativeInFirestore } from "../../../firestore/firestoreService";
import {
  initiativePriorityTypesOptions,
  initiativeStatusTypesOptions,
} from "../../../enums";
import { InitiativeCardWrapper } from "./Initiative.style";

interface IInitiativeCardProps {
  initiative: IInitiative;
  keywords: string[];
  parent?: IInitiativeInput;
}
export const InitiativeCard: React.FC<IInitiativeCardProps> = ({
  initiative,
}) => {
  const handleEdit = async (name: string, value: string | number) => {
    await updateCompanyInitiativeInFirestore(initiative.id, {
      [name]: value,
    });
  };
  const handleEditUser = async (
    name: string,
    value: { userId: string; user: IUser }
  ) => {
    await updateCompanyInitiativeInFirestore(initiative.id, {
      ownerId: value.userId,
      owner: value.user,
    });
  };
  const handleEditDatePicker = async (name: string, value: moment.Moment) => {
    await updateCompanyInitiativeInFirestore(initiative.id, {
      dueDate: value.format("YYYY-MM-DD"),
    });
  };
  return (
    <InitiativeCardWrapper>
      <EditableFieldForm>
        <div className="initiative-card-header">
          <div className="initiative-card-title">
            <EditableTitleField
              title={"Title"}
              editable
              dataIndex={"name"}
              record={initiative?.name}
              handleSave={handleEdit}
            />
          </div>
          <FieldView label="Owner" width={100}>
            <EditableUserSelectedField
              title={"ownerId"}
              editable
              dataIndex={"ownerId"}
              record={initiative?.ownerId || ""}
              handleSave={handleEditUser}
            />
          </FieldView>
          <FieldView label="Key result" width={100}>
            {initiative?.keyResultId || "--"}
          </FieldView>
          <FieldView label="Objective" width={100}>
            {initiative?.objectiveId || "--"}
          </FieldView>
          <FieldView label="Cycle" width={100}>
            <CycleCell cycle={null} />
          </FieldView>
          <FieldView label="Status" width={100}>
            <EditableSelectedField
              title={"status"}
              editable
              dataIndex={"status"}
              record={initiative?.status}
              handleSave={handleEdit}
              options={initiativeStatusTypesOptions}
            />
          </FieldView>
          <FieldView label="Priority" width={100}>
            <EditableSelectedField
              title={"Priority"}
              editable
              dataIndex={"priority"}
              record={initiative?.priority}
              handleSave={handleEdit}
              options={initiativePriorityTypesOptions}
            />
          </FieldView>
          <FieldView label="Due Date" width={100}>
            <EditableDatePickerField
              title={"dueDate"}
              editable
              dataIndex={"dueDate"}
              record={moment(initiative?.dueDate)}
              handleSave={handleEditDatePicker}
            />
          </FieldView>
          <FieldView label="Description" width={100} block>
            <EditableDescriptionField
              title={"description"}
              editable
              dataIndex={"description"}
              record={initiative?.description}
              handleSave={handleEdit}
            />
          </FieldView>
        </div>
      </EditableFieldForm>
      <div className="initiative-card-footer"></div>
    </InitiativeCardWrapper>
  );
};
