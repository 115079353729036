/* eslint-disable @typescript-eslint/await-thenable */
/* eslint-disable no-console */
import cuid from "cuid";
import moment from "moment";
import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { IComment } from "../../../common/types";
import { Button, Form, Input } from "../../atoms";
import { UserCell } from "../CommonCells";
import { CommentFormWrapper } from "./Comment.style";

interface ICommentFormProps {
  addComment: (id: string, comment: IComment) => void;
  id: string;
}
export const CommentForm: React.FC<ICommentFormProps> = ({
  addComment,
  id,
}) => {
  const [loading, setLoading] = React.useState(false);
  const { myProfile } = useSelector((state: any) => state.myProfile);
  const [value, setValue] = React.useState("");
  const [isFocused, setIsFocused] = React.useState(false);
  const handleSubmit = async (comment: string) => {
    setLoading(true);
    await addComment(id, {
      id: cuid(),
      comment,
      owner: myProfile,
      ownerId: myProfile.id,
      createDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
    setLoading(false);
  };

  const handleOnChange = (e: any) => {
    setValue(e.target.value);
  };
  const handleOnSubmit = async () => {
    if (value !== "") {
      await handleSubmit(value);
      setValue("");
    }
  };
  // const handleOnFocus = (e: any) => {
  //   console.log(e);
  //   setTextAreaRows(5);
  // };
  return (
    <CommentFormWrapper>
      <div className="comment-form-user">
        <UserCell user={myProfile} avatarOnly xSmall />
      </div>
      <div className="comment-form-area">
        <Form.Item>
          <Input.TextArea
            className="comment-form-textarea"
            rows={1}
            onChange={handleOnChange}
            value={value}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
          <div
            className={classNames("comment-form-action", { show: isFocused })}
          >
            <Button
              htmlType="submit"
              loading={loading}
              onClick={handleOnSubmit}
              type="primary"
              className="comment-form-action-btn"
            >
              Add Comment
            </Button>
          </div>
        </Form.Item>
      </div>
    </CommentFormWrapper>
  );
};
