import React from "react";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";
import { BoardList, BoardListToolbar } from "../../App/components/organisms";

const BoardListPage: React.FC = () => (
  <AdminPage title="Boards" pageToolbar={<BoardListToolbar />}>
    <BoardList />
  </AdminPage>
);

export default BoardListPage;
