import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/auth";
import "firebase/compat/storage";

const firebaseConfig = {
  // apiKey: process.env.REACT_APP_API_KEY,
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "weey-app.firebaseapp.com",
  projectId: "weey-app",
  storageBucket: "weey-app.appspot.com",
  messagingSenderId: "475880520721",
  appId: "1:475880520721:web:e3e4167ee027603bcb85b0",
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();
export default firebase;
