/* eslint-disable no-console */
import { find } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import {
  fetchCompanyCyclesFromFirestore,
  fetchCompanyOkrsFromFirestore,
  listenToCompanyFromFirestore,
} from "../../App/firestore/firestoreService";
import { useFirestoreCollection } from "../../App/hooks/useFirestoreCollection";
import { userFirestoreDoc } from "../../App/hooks/useFirestoreDoc";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";
import { listenToSelectedCompany } from "../../App/redux/data/company/companyActions";
import { listenToCompanyCycles } from "../../App/redux/data/companyCycle/companyCycleActions";
import { listenToCompanyOkrs } from "../../App/redux/data/companyOKR/companyOKRActions";

const currentMenu = [
  {
    key: "details",
    label: "List",
    link: `/cycles/list`,
  },
  {
    key: "board",
    label: "Board",
    link: `/cycles/board`,
  },
  // {
  //   key: "keyResults",
  //   label: "Key Results",
  //   link: `/cycle/${cycleId}/key-results`,
  // },
  // {
  //   key: "initiatives",
  //   label: "Initiatives",
  //   link: `/cycle/${cycleId}/initiatives`,
  // },
  // {
  //   key: "report",
  //   label: "Report",
  //   link: `/cycle/${cycleId}/report`,
  // },
  // {
  //   key: "setting",
  //   label: "Setting",
  //   link: `/cycle/${cycleId}/setting`,
  // },
];
const currentBreadcrumb = (name: string) => [
  {
    key: "cycles",
    label: "Cycles",
    link: "/cycles/list",
  },
  {
    key: "page",
    label: name,
    link: "#",
  },
];
const CyclesPage: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [breadcrumb, setBreadcrumb] = React.useState<any[]>([]);
  const { selectedCycle } = useSelector((state: any) => state.cycles);
  userFirestoreDoc({
    query: () => listenToCompanyFromFirestore(),
    data: listenToSelectedCompany,
    deps: [dispatch],
  });
  useFirestoreCollection({
    query: () =>
      fetchCompanyCyclesFromFirestore([
        {
          type: "sorting",
          name: "startDate",
          opr: "asc",
          order: "asc",
        },
      ]),
    data: listenToCompanyCycles,
    deps: [dispatch],
  });
  useFirestoreCollection({
    query: () => fetchCompanyOkrsFromFirestore(),
    data: listenToCompanyOkrs,
    deps: [dispatch],
  });
  React.useEffect(() => {
    const name = find(currentMenu, ["link", location.pathname]);
    setBreadcrumb(currentBreadcrumb(name?.label || "") || []);
  }, [selectedCycle, location.pathname]);
  return (
    <AdminPage title="Cycles" headerMenu={currentMenu} breadcrumb={breadcrumb}>
      <Outlet />
    </AdminPage>
  );
};

export default CyclesPage;
