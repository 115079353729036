/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { find } from "lodash";
import { InputField, SelectField } from "../../molecules";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { ICompanyInput, ISelectOption } from "../../../common/types";
import {
  Alert,
  Button,
  Divider,
  Form,
  Input,
  InputFormWrapper,
} from "../../atoms";
import { updateCompanyInFirestore } from "../../../firestore/firestoreService";
import { closeModal } from "../../../redux/data/modals/modalReducer";
import { industryOptions } from "../../../enums/industies";

export const UpdateCompanyDetailsModal: React.FC = (props: any) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleSubmit = (editField: string) => async () => {
    try {
      const values: ICompanyInput = await form.validateFields();
      setLoading(true);
      switch (editField) {
        case "name":
          await updateCompanyInFirestore({
            name: values.name,
          });
          break;
        case "industries":
          const selectedIndustries: ISelectOption[] =
            values.industryCodes.map((code) => {
              const ind = find(industryOptions, ["value", code]);
              if (ind) {
                return ind;
              }
              return { value: code, label: "" };
            }) || [];
          await updateCompanyInFirestore({
            industries: selectedIndustries || [],
            industryCodes: values.industryCodes || [],
          });
          break;
        case "mission":
          await updateCompanyInFirestore({
            mission: values.mission,
            missionKeywords: values.missionKeywords,
          });
          break;
        case "strengthKeywords":
          await updateCompanyInFirestore({
            strengthKeywords: values.strengthKeywords,
          });
          break;
        case "weaknessKeywords":
          await updateCompanyInFirestore({
            weaknessKeywords: values.weaknessKeywords,
          });
          break;
        case "opportunityKeywords":
          await updateCompanyInFirestore({
            opportunityKeywords: values.opportunityKeywords,
          });
          break;
        case "threadsKeywords":
          await updateCompanyInFirestore({
            threadsKeywords: values.threadsKeywords,
          });
          break;
        default:
        // code block
      }

      setLoading(false);
      dispatch(closeModal());
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  return (
    <ModalWrapper {...props} footer={null}>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <InputFormWrapper>
        <div className="input-form-body">
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={props.initialValues}
          >
            {props.editField === "name" && (
              <InputField
                label="Company name"
                name="name"
                rules={[{ required: true, message: "Missing Company name" }]}
                placeholder="Company name"
                style={{ width: "100%" }}
              />
            )}
            {props.editField === "industries" && (
              <SelectField
                label="Industries"
                name="industryCodes"
                rules={[{ required: true, message: "Missing Industries" }]}
                options={industryOptions}
                mode="multiple"
              />
            )}
            {props.editField === "mission" && (
              <Form.Item name="mission" label="Mission">
                <Input.TextArea placeholder="Mission" />
              </Form.Item>
            )}
            {props.editField === "mission" && (
              <SelectField
                label="What keywords describe your mission"
                name="missionKeywords"
                options={[]}
                mode="tags"
              />
            )}
            {props.editField === "strengthKeywords" && (
              <SelectField
                label="What keywords describe your strengths?"
                name="strengthKeywords"
                options={[]}
                mode="tags"
              />
            )}
            {props.editField === "weaknessKeywords" && (
              <SelectField
                label="What keywords describe your weaknesses?"
                name="weaknessKeywords"
                options={[]}
                mode="tags"
              />
            )}
            {props.editField === "opportunityKeywords" && (
              <SelectField
                label="What keywords describe your opportunities?"
                name="opportunityKeywords"
                options={[]}
                mode="tags"
              />
            )}
            {props.editField === "threadsKeywords" && (
              <SelectField
                label="What keywords describe your threads?"
                name="threadsKeywords"
                options={[]}
                mode="tags"
              />
            )}

            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit(props.editField)}
              loading={loading}
            >
              Update
            </Button>
          </Form>
        </div>
      </InputFormWrapper>
    </ModalWrapper>
  );
};
