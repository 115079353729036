/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { AppstoreAddOutlined, SortAscendingOutlined } from "@ant-design/icons";
import { Button, Input } from "../../atoms";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchCompanyBoardsFromFirestore } from "../../../firestore/firestoreService";
import { listenToCompanyBoards } from "../../../redux/data/companyBoard/companyBoardActions";
import { PageToolbar } from "../../molecules";

interface IBoardKeyResultListToolbarProps {
  boardId: string;
}
export const BoardKeyResultListToolbar: React.FC<
  IBoardKeyResultListToolbarProps
> = ({ boardId }) => {
  const dispatch = useDispatch();
  const handelSorting = () => {
    console.log("filterCycles");
  };
  const handelCustomize = () => {
    console.log("filterCycles");
  };
  useFirestoreCollection({
    query: () => fetchCompanyBoardsFromFirestore([]),
    data: listenToCompanyBoards,
    deps: [dispatch],
  });
  const handelOnSearch = () => {
    // dispatch(
    //   openModal({
    //     modalType: "AddKeyResultModal",
    //     modalProps: { title: "Add KeyResult",  boardId },
    //   })
    // );
    console.log("search");
  };

  return (
    <PageToolbar>
      <Input.Search
        placeholder="Search Key Result"
        onSearch={handelOnSearch}
        style={{ flex: 1 }}
      />

      <div className="page-toolbar-right-children-elements">
        <Button
          type="link"
          onClick={handelSorting}
          icon={<SortAscendingOutlined />}
          className="page-toolbar-btn-link"
        >
          Sorting
        </Button>
        <Button
          type="link"
          onClick={handelCustomize}
          icon={<AppstoreAddOutlined />}
          className="page-toolbar-btn-link"
        >
          Customize
        </Button>
      </div>
    </PageToolbar>
  );
};
