import React from "react";
import { CycleListPreviewWrapper } from "./Cycle.style";

export const CycleListPreview: React.FC<{ cycles: any[] }> = ({ cycles }) => (
  <CycleListPreviewWrapper>
    <div className="cycle-list-Preview-title">Cycles</div>
    <div className="cycle-list-Preview-body">
      {cycles.map((cycle, i) => (
        <div className="cycle-list-Preview-item" key={i}>
          <div className="cycle-list-Preview-name">{cycle.name}</div>
          <div className="cycle-list-Preview-date">
            {cycle.startDate.format("DD/MM/YYYY")}
          </div>
          <div className="cycle-list-Preview-date">
            {cycle.endDate.format("DD/MM/YYYY")}
          </div>
        </div>
      ))}
    </div>
  </CycleListPreviewWrapper>
);
