/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ICheckIn, ICycle, IOkr } from "../../../common/types";
import {
  addCompanyOkrCommentToFirestore,
  fetchCompanyCheckInsFromFirestore,
  listenToCompanyCycleFromFirestore,
  listenToCompanyOkrFromFirestore,
} from "../../../firestore/firestoreService";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { getKeyResultScoreNumber } from "../../../services/keyResultService";
import { InputFormWrapper } from "../../atoms";
import {
  AlignmentView,
  CommentForm,
  CommentListCard,
  InitiativeListCard,
  KeyResultCard,
  // KeyResultCard,
  // KeyResultChartCard,
  KeyResultMilestoneCard,
} from "../../molecules";
import { CheckInListCard } from "../../molecules/CheckIn/CheckInListCard";

interface IOkrDetailsProps {
  okrId?: string;
}
export const KeyResultDetails: React.FC<IOkrDetailsProps> = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [cycle, setCycle] = React.useState<ICycle>();
  const [objective, setObjective] = React.useState<IOkr>();
  const { myProfile } = useSelector((state: any) => state.myProfile);
  const [checkIns, setCheckIns] = React.useState<ICheckIn[]>([]);
  const [score, setScore] = React.useState<number>(0);
  const okrId = params.okrId || "";
  const { selectedOkr, selectedKeyResultCheckIns } = useSelector(
    (state: any) => state.okrs
  );

  userFirestoreDoc({
    query: () =>
      listenToCompanyOkrFromFirestore(selectedOkr?.okrParentId || ""),
    data: setObjective,
    deps: [selectedOkr],
    shouldExecute: !!selectedOkr,
    local: true,
  });
  userFirestoreDoc({
    query: () => listenToCompanyCycleFromFirestore(selectedOkr?.cycleId || ""),
    data: setCycle,
    deps: [selectedOkr],
    shouldExecute: !!selectedOkr,
    local: true,
  });
  useFirestoreCollection({
    query: () =>
      fetchCompanyCheckInsFromFirestore([
        {
          type: "filter",
          name: "keyResultId",
          opr: "==",
          value: okrId,
        },
      ]),
    data: setCheckIns,
    deps: [dispatch, okrId],
    local: true,
  });
  const handelAddCheckIn = () => {
    if (!selectedOkr) return;
    dispatch(
      openModal({
        modalType: "AddCheckInForm",
        modalProps: {
          title: "Create Check In",
          boardId: selectedOkr.boardId,
          objectiveId: selectedOkr.okrParentId,
          keyResultId: okrId,
          keyResult: selectedOkr,
          cycleId: selectedOkr.cycleId,
        },
      })
    );
  };

  React.useEffect(() => {
    const currentScore = getKeyResultScoreNumber({
      targetNumber: +(selectedOkr?.targetNumber || 0),
      initialNumber: +(selectedOkr?.initialNumber || 0),
      checkIns,
    });
    setScore(currentScore);
  }, [selectedOkr, checkIns]);

  if (!selectedOkr || !cycle || !objective) {
    return <div></div>;
  }
  const handelAddInitiative = () => {
    dispatch(
      openModal({
        modalType: "AddInitiativeForm",
        modalProps: {
          title: "Add Initiative",
          boardId: selectedOkr.boardId,
          objectiveId: selectedOkr.okrParentId,
          keyResultId: okrId,
          cycleId: selectedOkr.cycleId,
        },
      })
    );
  };
  return (
    <AdminPageBody>
      <AlignmentView objective={selectedOkr} keyResult={selectedOkr} />
      <InputFormWrapper>
        <div className="input-form-body">
          <KeyResultCard
            keyResult={selectedOkr}
            cycle={cycle}
            score={score}
            objective={objective}
          />

          {/* {selectedOkr?.keyResultType === "NUMBER" && (
            // <KeyResultChartCard
            //   keyResult={selectedOkr}
            //   checkIns={selectedKeyResultCheckIns || []}
            // />
          )} */}
          {selectedOkr?.keyResultType === "MILESTONE" && (
            <KeyResultMilestoneCard
              milestones={selectedOkr?.milestones || []}
              keyResult={selectedOkr}
              keyResultId={okrId}
            />
          )}

          <CheckInListCard
            checkIns={selectedKeyResultCheckIns || []}
            action={handelAddCheckIn}
          />
          <InitiativeListCard
            keyResult={selectedOkr}
            keyResultId={okrId}
            me={myProfile}
            action={handelAddInitiative}
            filters={[
              {
                type: "filter",
                name: "keyResultId",
                opr: "==",
                value: okrId,
              },
            ]}
          />
          <CommentListCard
            comments={selectedOkr?.comments || []}
            addComment={addCompanyOkrCommentToFirestore}
            id={okrId}
          />
          <CommentForm
            addComment={addCompanyOkrCommentToFirestore}
            id={okrId}
          />
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
