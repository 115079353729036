import { ITeamInput, IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import { COLLECTION_COMPANIES, COLLECTION_TEAMS } from "./firebaseConstants";

export function fetchCompanyTeamsFromFirestore(args?: IQueryConfigArgs[]) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  const ref = db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_TEAMS);
  return collectionWithArgs(ref, args || []);
}
export function listenToCompanyTeamFromFirestore(id: string) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_TEAMS)
    .doc(id);
}

export function addCompanyTeamToFirestore(doc: ITeamInput) {
  // const user = firebase.auth().currentUser;
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_TEAMS)
    .add({
      ...doc,
      createDate: new Date(),
      // createBy: user,
    });
}

export function updateCompanyTeamInFirestore(
  docId: string,
  doc: ITeamInput | any
) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_TEAMS)
    .doc(docId)
    .update(doc);
}
export function deleteCompanyTeamInFirestore(docId: string) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_TEAMS)
    .doc(docId)
    .delete();
}

export function activeToggleCompanyTeamInFirestore(
  docId: string,
  status: boolean
) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_TEAMS)
    .doc(docId)
    .update({
      status,
    });
}
