/* eslint-disable no-console */
import React from "react";
import { PlusOutlined, SortAscendingOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { Button } from "../../atoms";
import { PageToolbar } from "../../molecules";
import { openModal } from "../../../redux/data/modals/modalReducer";

export const CompanyDepartmentListToolbar: React.FC = () => {
  const dispatch = useDispatch();
  const handelSorting = () => {
    console.log("filterCycles");
  };
  const handelAddDepartment = () => {
    dispatch(
      openModal({
        modalType: "AddDepartmentModal",
        modalProps: { title: "Add Department" },
      })
    );
  };
  return (
    <PageToolbar>
      <Button
        type="primary"
        onClick={handelAddDepartment}
        block
        icon={<PlusOutlined />}
        className="page-toolbar-btn"
      >
        Add Department
      </Button>
      <div className="page-toolbar-right-children-elements">
        <Button
          type="link"
          onClick={handelSorting}
          icon={<SortAscendingOutlined />}
          className="page-toolbar-btn-link"
        >
          Sorting
        </Button>
      </div>
    </PageToolbar>
  );
};
