import React from "react";
import { CheckInCellWrapper } from "./Cells.style";

interface ICheckInCellProp {
  initialNumber: number;
  targetNumber: number;
  actualNumber: number;
}
export const CheckInCell: React.FC<ICheckInCellProp> = ({
  initialNumber,
  targetNumber,
  actualNumber,
}) => (
  <CheckInCellWrapper>
    <div className="checkIn-cell-info">
      <span className="checkIn-cell-info-initial">{initialNumber}</span>{" "}
      <span className="checkIn-cell-info-target">{targetNumber}</span>
    </div>
    <div className="checkIn-cell-actual"> {actualNumber || "-"}</div>
  </CheckInCellWrapper>
);
