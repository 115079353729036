import styled from "styled-components";

// theme is now fully typed
export const DashboardWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;
  height: 100%;
  .dashboard {
    &-info {
      display: grid;
      grid-template-columns: auto 1fr 1fr;
      grid-gap: 20px;
    }
  }
`;

export const DashboardCardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .dashboard-card {
    &-selector {
      align-items: center;
      background: #f9f8f8;
      border-radius: 60px;
      display: inline-flex;
      height: 48px;
      padding-right: 8px;
      transition: all 200ms ease-in-out;
      margin: 16px 0;
      width: 480px;
      justify-content: center;
      text-align: center;
      gap: 20px;
      &-text {
        color: #757677;
        span {
          font-weight: 500;
          color: #262626;
          font-size: 16px;
        }
      }
    }
  }
`;
