export const CREATE_COMPANY_BOARD = "CREATE_COMPANY_BOARD";
export const UPDATE_COMPANY_BOARD = "UPDATE_COMPANY_BOARD";
export const DELETE_COMPANY_BOARD = "DELETE_COMPANY_BOARD";
export const FETCH_COMPANY_BOARDS = "FETCH_COMPANY_BOARDS";
export const LISTEN_TO_SELECTED_COMPANY_BOARD =
  "LISTEN_TO_SELECTED_COMPANY_BOARD";
export const CLEAR_SELECTED_COMPANY_BOARD = "CLEAR_SELECTED_COMPANY_BOARD";
export const CLEAR_COMPANY_BOARDS = "CLEAR_COMPANY_BOARDS";
export const SET_COMPANY_BOARDS_FILTER = "SET_COMPANY_BOARDS_FILTER";
export const LISTEN_TO_SELECTED_BOARD_OKRS = "LISTEN_TO_SELECTED_BOARD_OKRS";

export const LISTEN_TO_SELECTED_BOARD_INITIATIVES =
  "LISTEN_TO_SELECTED_BOARD_INITIATIVES";
export const LISTEN_TO_SELECTED_BOARD_CYCLES =
  "LISTEN_TO_SELECTED_BOARD_CYCLES";
export const LISTEN_TO_SELECTED_BOARD_USERS = "LISTEN_TO_SELECTED_BOARD_USERS";
