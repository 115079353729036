import React from "react";
import { IActivity } from "../../../common/types";
import { Button } from "../../atoms";
import { EmptyResult } from "../EmptyResult/EmptyResult";
import { ActivityListCardWrapper } from "./Activity.style";
import { ActivityItem } from "./ActivityItem";

interface IActivityListCardProps {
  activities: IActivity[];
  action?: () => void;
}
export const ActivityListCard: React.FC<IActivityListCardProps> = ({
  activities,
  action,
}) => (
  <ActivityListCardWrapper>
    <div className="activity-list-card-header">
      <div className="activity-list-card-title">Activities</div>
      <div className="activity-list-card-Actions">
        <Button type="link" className="" onClick={action}>
          See all activities
        </Button>
      </div>
    </div>
    <div className="activity-list-card-body">
      {activities.map((activity) => (
        <ActivityItem key={activity.id} activity={activity} />
      ))}
      {activities.length === 0 && (
        <EmptyResult
          subTitle="No activity in the past five days"
          title="No activity"
        />
      )}
    </div>
  </ActivityListCardWrapper>
);
