/* eslint-disable no-console */
import React from "react";
import {
  VerticalAlignBottomOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";
import {
  ICycle,
  IOkr,
  IOkrInput,
  IUser,
  IUserRelationObject,
} from "../../../common/types";
import {
  EditableTitleField,
  EditableFieldForm,
  FieldView,
  EditableDescriptionField,
  EditableSelectedField,
  EditableUserSelectedField,
  EditableCycleSelectedField,
} from "../commonFields";
import { updateCompanyOkrInFirestore } from "../../../firestore/firestoreService";
import {
  objectivePriorityTypesOptions,
  okrsForTypesOptions,
  objectiveVisibilityOptions,
} from "../../../enums";
import { ObjectiveProgressIndicator } from "../ObjectiveProgressIndicator/ObjectiveProgressIndicator";
import { OkrProgressBar } from "../CommonComponents";
import { CycleCell } from "../CommonCells/CycleCell";
import { ObjectiveCardWrapper } from "./Objective.style";

interface IOkrCardProps {
  objective: IOkr;
  title: string;
  owner: IUser | IUserRelationObject;
  description: string;
  parent?: IOkrInput;
  score: number;
  parentObjective?: IOkr;
}
export const ObjectiveCard: React.FC<IOkrCardProps> = ({
  title,
  score,
  objective,
  parentObjective,
}) => {
  const handleEditUser = async (
    name: string,
    value: { userId: string; user: IUser }
  ) => {
    await updateCompanyOkrInFirestore(objective.id, {
      ownerId: value.userId,
      owner: value.user,
    });
  };
  const handleEditCycle = async (
    name: string,
    value: { cycleId: string; cycle: ICycle }
  ) => {
    await updateCompanyOkrInFirestore(objective.id, {
      cycleId: value.cycleId,
    });
  };
  const handleEdit = async (name: string, value: string | number) => {
    await updateCompanyOkrInFirestore(objective.id, {
      [name]: value,
    });
  };
  const handleOnChangeProgressRange = async (value: [number, number]) => {
    console.log(objective);
    await updateCompanyOkrInFirestore(objective.id, {
      progressRange: value,
    });
  };
  return (
    <ObjectiveCardWrapper>
      <EditableFieldForm>
        <div className="objective-card-header">
          <div className="objective-card-header-info">
            <div className="objective-card-title">
              <EditableTitleField
                title={"Title"}
                editable
                dataIndex={"title"}
                record={objective?.title}
                handleSave={handleEdit}
              />
            </div>
            <div className="objective-card-details">
              <div>
                {objective.okrType === "OBJECTIVE_ALIGNMENT" &&
                  parentObjective && (
                    <FieldView label="Alignment" width={100}>
                      <VerticalAlignTopOutlined className="objective-card-objective-icon" />{" "}
                      {parentObjective.title}
                    </FieldView>
                  )}
                {objective.okrType === "OBJECTIVE_CASCADED" && parentObjective && (
                  <FieldView label="Cascaded" width={100}>
                    <VerticalAlignBottomOutlined className="objective-card-objective-icon" />{" "}
                    {parentObjective.title}
                  </FieldView>
                )}
                <FieldView label="Owner" width={100}>
                  <EditableUserSelectedField
                    title={"ownerId"}
                    editable
                    dataIndex={"ownerId"}
                    record={objective?.ownerId}
                    handleSave={handleEditUser}
                    style={{ width: 200 }}
                  />
                </FieldView>
                {objective.okrType === "OBJECTIVE" && (
                  <FieldView label="Cycle" width={100}>
                    <EditableCycleSelectedField
                      title={"cycleId"}
                      editable
                      dataIndex={"cycleId"}
                      record={objective?.cycleId}
                      handleSave={handleEditCycle}
                      style={{ width: 200 }}
                    />
                  </FieldView>
                )}
                {(objective.okrType === "OBJECTIVE_CASCADED" ||
                  objective.okrType === "OBJECTIVE_ALIGNMENT") && (
                  <FieldView label="Cycle" width={100}>
                    <CycleCell cycleId={objective?.cycleId} />
                  </FieldView>
                )}
              </div>
              <div>
                {" "}
                <FieldView label="Priority" width={100}>
                  <EditableSelectedField
                    title={"priority"}
                    editable
                    dataIndex={"priority"}
                    record={objective?.priority}
                    handleSave={handleEdit}
                    options={objectivePriorityTypesOptions}
                    style={{ width: 200 }}
                  />
                </FieldView>
                <FieldView label="Type" width={100}>
                  <EditableSelectedField
                    title={"Type"}
                    editable
                    dataIndex={"okrFor"}
                    record={objective?.okrFor}
                    handleSave={handleEdit}
                    options={okrsForTypesOptions}
                    style={{ width: 200 }}
                  />
                </FieldView>
                <FieldView label="Visibility" width={100}>
                  <EditableSelectedField
                    title={"visibility"}
                    editable
                    dataIndex={"visibility"}
                    record={objective?.visibility}
                    handleSave={handleEdit}
                    options={objectiveVisibilityOptions}
                    style={{ width: 200 }}
                  />
                </FieldView>
              </div>
            </div>
            <FieldView label="Description" width={100} block>
              <EditableDescriptionField
                title={"description"}
                editable
                dataIndex={"description"}
                record={objective?.description}
                handleSave={handleEdit}
                placeholder="Add description to objective"
              />
            </FieldView>
          </div>
          <OkrProgressBar score={score} progress={"onTrack"} />
        </div>
      </EditableFieldForm>
      <FieldView label="Objective progress range %" width={300} block>
        {objective && (
          <ObjectiveProgressIndicator
            onUpdate={handleOnChangeProgressRange}
            value={objective.progressRange}
          />
        )}
      </FieldView>
    </ObjectiveCardWrapper>
  );
};
