export const CREATE_COMPANY_INITIATIVE = "CREATE_COMPANY_INITIATIVE";
export const UPDATE_COMPANY_INITIATIVE = "UPDATE_COMPANY_INITIATIVE";
export const DELETE_COMPANY_INITIATIVE = "DELETE_COMPANY_INITIATIVE";
export const FETCH_COMPANY_INITIATIVES = "FETCH_COMPANY_INITIATIVES";
export const LISTEN_TO_SELECTED_COMPANY_INITIATIVE =
  "LISTEN_TO_SELECTED_COMPANY_INITIATIVE";
export const CLEAR_SELECTED_COMPANY_INITIATIVE =
  "CLEAR_SELECTED_COMPANY_INITIATIVE";
export const CLEAR_COMPANY_INITIATIVES = "CLEAR_COMPANY_INITIATIVES";
export const SET_COMPANY_INITIATIVES_FILTER = "SET_COMPANY_INITIATIVES_FILTER";
