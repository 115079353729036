import styled from "styled-components";
export const OkrProgressBarWrapper = styled.div`
  width: 120px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #f8f8f8;
  margin: 24px 0;
  border-radius: 8px;
  position: relative;
  &.okr-progress-onTrack {
    background-color: rgb(113 183 0 / 20%);
    .okr-progress-bar {
      &-progress {
        background-color: #71b700;
      }
      &-tag {
        background-color: #e3f1d4;
        border-color: #71b700;
        color: #497107;
      }
    }
  }
  &.okr-progress-behind {
    background-color: rgb(255 160 49 / 20%);
    .okr-progress-bar {
      &-progress {
        background-color: #ffa031;
      }
      &-tag {
        background-color: #ffecd8;
        border-color: #ffa031;
        color: #ac6d22;
      }
    }
  }
  &.okr-progress-atRisk {
    background-color: rgb(255 51 0 / 20%);
    .okr-progress-bar {
      &-progress {
        background-color: #ff3300;
      }
      &-tag {
        background-color: #ffd8d2;
        border-color: #ff3300;
        color: #871d03;
      }
    }
  }
  .okr-progress-bar {
    &-progress {
      background-color: #ffa031;
      max-height: 100%;
      border-radius: 8px;
      width: 100%;
      position: absolute;
      bottom: 0;
    }

    &-details {
      position: relative;
      z-index: 100;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    &-tag {
      text-align: center;
      font-size: 14px;
      padding: 3px 12px;
      text-align: center;
      background-color: #ffecd8;
      border-color: #ffecd7;
      color: #ac6d22;
      border-radius: 6px;
      margin: 0;
    }
    &-score {
      font-size: 21px;
      text-align: center;
      font-weight: 500;
      color: #252628;
    }
  }
`;
