import styled from "styled-components";
export const OrgChartCardWrapper = styled.div`
  display: flex;
  background-color: #f9f8f8;
  box-shadow: 0 1px 4px rgb(64 87 109 / 7%);
  border-radius: 8px;
  min-height: 170px;
  overflow: hidden;
  .org-chart-card {
    display: flex;
    flex: 1;
    &-icon {
      font-size: 32px;
      display: flex;
      justify-content: center;
      padding: 20px;
      color: #d55f8e;
    }
    &-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
    }
    &-title {
      font-size: 32px;
      font-weight: 300;
      line-height: 40px;
      color: #1e1f21;
    }
    &-progress {
      background-color: #fff;
      padding: 10px 20px;
    }
    &-objective {
      display: flex;
      flex-direction: column;
      flex: 1;
      &-title {
        color: #1e1f21;
        font-size: 14px;
        font-weight: 500;
        padding: 20px 0px 10px;
        a {
          color: #1e1f21;
        }
      }
    }
    &-keyResult {
      display: flex;
      flex-direction: column;
      flex: 1;
      &-info {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      &-title {
        color: #1e1f21;
        font-size: 14px;
        font-weight: 500;
        padding: 20px 0px 10px;
        a {
          color: #1e1f21;
        }
      }
      &-owner {
        flex: 1;
      }
      &-checkIn {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        align-items: center;
        width: 100%;
        &-btn {
          font-size: 12px;
          height: 28px;
          line-height: 26px;
          padding: 0 8px;
          border-radius: 6px;
          user-select: none;
          font-weight: 500;
          color: #6d6e6f;
        }
      }
    }
  }
`;
export const OrgChartObjectCountWrapper = styled.div`
  background-color: #d55f8e;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  margin-top: 10px;
  color: #fff;
  font-size: 12px;
  width: 42px;
  .anticon {
    margin-right: 5px;
  }
`;
