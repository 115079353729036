import { pick, values } from "lodash";
import { ICountry } from "../common/types";

/* eslint-disable prettier/prettier */
interface ICountryMap { [key: string]: ICountry; }
export const countries: ICountryMap = {
  AF: {value: 'AF', label: 'Afghanistan', callingCode: '93', alpha3Code: 'AFG'},
  AX: {value: 'AX', label: 'Aland Islands', callingCode: '358', alpha3Code: 'ALA'},
  AL: {value: 'AL', label: 'Albania', callingCode: '355', alpha3Code: 'ALB'},
  DZ: {value: 'DZ', label: 'Algeria', callingCode: '213', alpha3Code: 'DZA'},
  AS: {value: 'AS', label: 'American Samoa', callingCode: '1684', alpha3Code: 'ASM'},
  AD: {value: 'AD', label: 'Andorra', callingCode: '376', alpha3Code: 'AND'},
  AO: {value: 'AO', label: 'Angola', callingCode: '244', alpha3Code: 'AGO'},
  AI: {value: 'AI', label: 'Anguilla', callingCode: '1264', alpha3Code: 'AIA'},
  AQ: {value: 'AQ', label: 'Antarctica', callingCode: '672', alpha3Code: 'ATA'},
  AG: {value: 'AG', label: 'Antigua And Barbuda', callingCode: '1268', alpha3Code: 'ATG'},
  AR: {value: 'AR', label: 'Argentina', callingCode: '54', alpha3Code: 'ARG'},
  AM: {value: 'AM', label: 'Armenia', callingCode: '374', alpha3Code: 'ARM'},
  AW: {value: 'AW', label: 'Aruba', callingCode: '297', alpha3Code: 'ABW'},
  AU: {value: 'AU', label: 'Australia', callingCode: '61', alpha3Code: 'AUS'},
  AT: {value: 'AT', label: 'Austria', callingCode: '43', alpha3Code: 'AUT'},
  AZ: {value: 'AZ', label: 'Azerbaijan', callingCode: '994', alpha3Code: 'AZE'},
  BS: {value: 'BS', label: 'Bahamas', callingCode: '1242', alpha3Code: 'BHS'},
  BH: {value: 'BH', label: 'Bahrain', callingCode: '973', alpha3Code: 'BHR'},
  BD: {value: 'BD', label: 'Bangladesh', callingCode: '880', alpha3Code: 'BGD'},
  BB: {value: 'BB', label: 'Barbados', callingCode: '1246', alpha3Code: 'BRB'},
  BY: {value: 'BY', label: 'Belarus', callingCode: '375', alpha3Code: 'BLR'},
  BE: {value: 'BE', label: 'Belgium', callingCode: '32', alpha3Code: 'BEL'},
  BZ: {value: 'BZ', label: 'Belize', callingCode: '501', alpha3Code: 'BLZ'},
  BJ: {value: 'BJ', label: 'Benin', callingCode: '229', alpha3Code: 'BEN'},
  BM: {value: 'BM', label: 'Bermuda', callingCode: '1441', alpha3Code: 'BMU'},
  BT: {value: 'BT', label: 'Bhutan', callingCode: '975', alpha3Code: 'BTN'},
  BO: {value: 'BO', label: 'Bolivia', callingCode: '591', alpha3Code: 'BOL'},
  BA: {value: 'BA', label: 'Bosnia And Herzegovina', callingCode: '387', alpha3Code: 'BIH'},
  BW: {value: 'BW', label: 'Botswana', callingCode: '267', alpha3Code: 'BWA'},
  BV: {value: 'BV', label: 'Bouvet Island', alpha3Code: 'BVT', callingCode: '47'},
  BR: {value: 'BR', label: 'Brazil', callingCode: '55', alpha3Code: 'BRA'},
  IO: {value: 'IO', label: 'British Indian Ocean Territory', callingCode: '246', alpha3Code: 'IOT'},
  BN: {value: 'BN', label: 'Brunei Darussalam', callingCode: '673', alpha3Code: 'BRN'},
  BG: {value: 'BG', label: 'Bulgaria', callingCode: '359', alpha3Code: 'BGR'},
  BF: {value: 'BF', label: 'Burkina Faso', callingCode: '226', alpha3Code: 'BFA'},
  BI: {value: 'BI', label: 'Burundi', callingCode: '257', alpha3Code: 'BDI'},
  KH: {value: 'KH', label: 'Cambodia', callingCode: '855', alpha3Code: 'KHM'},
  CM: {value: 'CM', label: 'Cameroon', callingCode: '237', alpha3Code: 'CMR'},
  CA: {value: 'CA', label: 'Canada', callingCode: '1', alpha3Code: 'CAN'},
  CV: {value: 'CV', label: 'Cape Verde', callingCode: '238', alpha3Code: 'CPV'},
  KY: {value: 'KY', label: 'Cayman Islands', callingCode: ' 345', alpha3Code: 'CYM'},
  CF: {value: 'CF', label: 'Central African Republic', callingCode: '236', alpha3Code: 'CAF'},
  TD: {value: 'TD', label: 'Chad', callingCode: '235', alpha3Code: 'TCD'},
  CL: {value: 'CL', label: 'Chile', callingCode: '56', alpha3Code: 'CHL'},
  CN: {value: 'CN', label: 'China', callingCode: '86', alpha3Code: 'CHN'},
  CX: {value: 'CX', label: 'Christmas Island', callingCode: '61', alpha3Code: 'CXR'},
  CC: {value: 'CC', label: 'Cocos (Keeling) Islands', callingCode: '61', alpha3Code: 'CCK'},
  CO: {value: 'CO', label: 'Colombia', callingCode: '57', alpha3Code: 'COL'},
  KM: {value: 'KM', label: 'Comoros', callingCode: '269', alpha3Code: 'COM'},
  CG: {value: 'CG', label: 'Congo', callingCode: '242', alpha3Code: 'COG'},
  CD: {value: 'CD', label: 'Congo, Democratic Republic', callingCode: '243', alpha3Code: 'COD'},
  CK: {value: 'CK', label: 'Cook Islands', callingCode: '682', alpha3Code: 'COK'},
  CR: {value: 'CR', label: 'Costa Rica', callingCode: '506', alpha3Code: 'CRI'},
  CI: {value: 'CI', label: "Cote D'Ivoire", callingCode: '225', alpha3Code: 'CIV'},
  HR: {value: 'HR', label: 'Croatia', callingCode: '385', alpha3Code: 'HRV'},
  CU: {value: 'CU', label: 'Cuba', callingCode: '53', alpha3Code: 'CUB'},
  CY: {value: 'CY', label: 'Cyprus', callingCode: '357', alpha3Code: 'CYP'},
  CZ: {value: 'CZ', label: 'Czech Republic', callingCode: '420', alpha3Code: 'CZE'},
  DK: {value: 'DK', label: 'Denmark', callingCode: '45', alpha3Code: 'DNK'},
  DJ: {value: 'DJ', label: 'Djibouti', callingCode: '253', alpha3Code: 'DJI'},
  DM: {value: 'DM', label: 'Dominica', callingCode: '1767', alpha3Code: 'DMA'},
  DO: {value: 'DO', label: 'Dominican Republic', callingCode: '1849', alpha3Code: 'DOM'},
  EC: {value: 'EC', label: 'Ecuador', callingCode: '593', alpha3Code: 'ECU'},
  EG: {value: 'EG', label: 'Egypt', callingCode: '20', alpha3Code: 'EGY'},
  SV: {value: 'SV', label: 'El Salvador', callingCode: '503', alpha3Code: 'SLV'},
  GQ: {value: 'GQ', label: 'Equatorial Guinea', callingCode: '240', alpha3Code: 'GNQ'},
  ER: {value: 'ER', label: 'Eritrea', callingCode: '291', alpha3Code: 'ERI'},
  EE: {value: 'EE', label: 'Estonia', callingCode: '372', alpha3Code: 'EST'},
  ET: {value: 'ET', label: 'Ethiopia', callingCode: '251', alpha3Code: 'ETH'},
  FK: {value: 'FK', label: 'Falkland Islands (Malvinas)', callingCode: '500', alpha3Code: 'FLK'},
  FO: {value: 'FO', label: 'Faroe Islands', callingCode: '298', alpha3Code: 'FRO'},
  FJ: {value: 'FJ', label: 'Fiji', callingCode: '679', alpha3Code: 'FJI'},
  FI: {value: 'FI', label: 'Finland', callingCode: '358', alpha3Code: 'FIN'},
  FR: {value: 'FR', label: 'France', callingCode: '33', alpha3Code: 'FRA'},
  GF: {value: 'GF', label: 'French Guiana', callingCode: '594', alpha3Code: 'GUF'},
  PF: {value: 'PF', label: 'French Polynesia', callingCode: '689', alpha3Code: 'PYF'},
  TF: {value: 'TF', label: 'French Southern Territories', alpha3Code: 'ATF', callingCode: '262'},
  GA: {value: 'GA', label: 'Gabon', callingCode: '241', alpha3Code: 'GAB'},
  GM: {value: 'GM', label: 'Gambia', callingCode: '220', alpha3Code: 'GMB'},
  GE: {value: 'GE', label: 'Georgia', callingCode: '995', alpha3Code: 'GEO'},
  DE: {value: 'DE', label: 'Germany', callingCode: '49', alpha3Code: 'DEU'},
  GH: {value: 'GH', label: 'Ghana', callingCode: '233', alpha3Code: 'GHA'},
  GI: {value: 'GI', label: 'Gibraltar', callingCode: '350', alpha3Code: 'GIB'},
  GR: {value: 'GR', label: 'Greece', callingCode: '30', alpha3Code: 'GRC'},
  GL: {value: 'GL', label: 'Greenland', callingCode: '299', alpha3Code: 'GRL'},
  GD: {value: 'GD', label: 'Grenada', callingCode: '1473', alpha3Code: 'GRD'},
  GP: {value: 'GP', label: 'Guadeloupe', callingCode: '590', alpha3Code: 'GLP'},
  GU: {value: 'GU', label: 'Guam', callingCode: '1671', alpha3Code: 'GUM'},
  GT: {value: 'GT', label: 'Guatemala', callingCode: '502', alpha3Code: 'GTM'},
  GG: {value: 'GG', label: 'Guernsey', callingCode: '44', alpha3Code: 'GGY'},
  GN: {value: 'GN', label: 'Guinea', callingCode: '224', alpha3Code: 'GIN'},
  GW: {value: 'GW', label: 'Guinea-Bissau', callingCode: '245', alpha3Code: 'GNB'},
  GY: {value: 'GY', label: 'Guyana', callingCode: '592', alpha3Code: 'GUY'},
  HT: {value: 'HT', label: 'Haiti', callingCode: '509', alpha3Code: 'HTI'},
  HM: {value: 'HM', label: 'Heard Island & Mcdonald Islands', alpha3Code: 'HDM', callingCode: '672'},
  VA: {value: 'VA', label: 'Holy See (Vatican City State)', callingCode: '379', alpha3Code: 'VAT'},
  HN: {value: 'HN', label: 'Honduras', callingCode: '504', alpha3Code: 'HND'},
  HK: {value: 'HK', label: 'Hong Kong', callingCode: '852', alpha3Code: 'HKG'},
  HU: {value: 'HU', label: 'Hungary', callingCode: '36', alpha3Code: 'HUN'},
  IS: {value: 'IS', label: 'Iceland', callingCode: '354', alpha3Code: 'ISL'},
  IN: {value: 'IN', label: 'India', callingCode: '91', alpha3Code: 'IND'},
  ID: {value: 'ID', label: 'Indonesia', callingCode: '62', alpha3Code: 'IDN'},
  IR: {value: 'IR', label: 'Iran, Islamic Republic Of', callingCode: '98', alpha3Code: 'IRN'},
  IQ: {value: 'IQ', label: 'Iraq', callingCode: '964', alpha3Code: 'IRQ'},
  IE: {value: 'IE', label: 'Ireland', callingCode: '353', alpha3Code: 'IRL'},
  IM: {value: 'IM', label: 'Isle Of Man', callingCode: '44', alpha3Code: 'IMN'},
  IL: {value: 'IL', label: 'Israel', callingCode: '972', alpha3Code: 'ISR'},
  IT: {value: 'IT', label: 'Italy', callingCode: '39', alpha3Code: 'ITA'},
  JM: {value: 'JM', label: 'Jamaica', callingCode: '1876', alpha3Code: 'JAM'},
  JP: {value: 'JP', label: 'Japan', callingCode: '81', alpha3Code: 'JPN'},
  JE: {value: 'JE', label: 'Jersey', callingCode: '44', alpha3Code: 'JEY'},
  JO: {value: 'JO', label: 'Jordan', callingCode: '962', alpha3Code: 'JOR'},
  KZ: {value: 'KZ', label: 'Kazakhstan', callingCode: '7', alpha3Code: 'KAZ'},
  KE: {value: 'KE', label: 'Kenya', callingCode: '254', alpha3Code: 'KEN'},
  KI: {value: 'KI', label: 'Kiribati', callingCode: '686', alpha3Code: 'KIR'},
  KR: {value: 'KR', label: 'South Korea', callingCode: '82', alpha3Code: 'PRK'},
  XK: {value: 'XK', label: 'Kosovo', alpha3Code: 'XKX', callingCode: '383'},
  KW: {value: 'KW', label: 'Kuwait', callingCode: '965', alpha3Code: 'KWT'},
  KG: {value: 'KG', label: 'Kyrgyzstan', callingCode: '996', alpha3Code: 'KGZ'},
  LA: {value: 'LA', label: "Lao People's Democratic Republic", callingCode: '856', alpha3Code: 'LAO'},
  LV: {value: 'LV', label: 'Latvia', callingCode: '371', alpha3Code: 'LVA'},
  LB: {value: 'LB', label: 'Lebanon', callingCode: '961', alpha3Code: 'LBN'},
  LS: {value: 'LS', label: 'Lesotho', callingCode: '266', alpha3Code: 'LSO'},
  LR: {value: 'LR', label: 'Liberia', callingCode: '231', alpha3Code: 'LBR'},
  LY: {value: 'LY', label: 'Libyan Arab Jamahiriya', callingCode: '218', alpha3Code: 'LBY'},
  LI: {value: 'LI', label: 'Liechtenstein', callingCode: '423', alpha3Code: 'LIE'},
  LT: {value: 'LT', label: 'Lithuania', callingCode: '370', alpha3Code: 'LTU'},
  LU: {value: 'LU', label: 'Luxembourg', callingCode: '352', alpha3Code: 'LUX'},
  MO: {value: 'MO', label: 'Macao', callingCode: '853', alpha3Code: 'MAC'},
  MK: {value: 'MK', label: 'Macedonia', callingCode: '389', alpha3Code: 'MKD'},
  MG: {value: 'MG', label: 'Madagascar', callingCode: '261', alpha3Code: 'MDG'},
  MW: {value: 'MW', label: 'Malawi', callingCode: '265', alpha3Code: 'MWI'},
  MY: {value: 'MY', label: 'Malaysia', callingCode: '60', alpha3Code: 'MYS'},
  MV: {value: 'MV', label: 'Maldives', callingCode: '960', alpha3Code: 'MDV'},
  ML: {value: 'ML', label: 'Mali', callingCode: '223', alpha3Code: 'MLI'},
  MT: {value: 'MT', label: 'Malta', callingCode: '356', alpha3Code: 'MLT'},
  MH: {value: 'MH', label: 'Marshall Islands', callingCode: '692', alpha3Code: 'MHL'},
  MQ: {value: 'MQ', label: 'Martinique', callingCode: '596', alpha3Code: 'MTQ'},
  MR: {value: 'MR', label: 'Mauritania', callingCode: '222', alpha3Code: 'MRT'},
  MU: {value: 'MU', label: 'Mauritius', callingCode: '230', alpha3Code: 'MUS'},
  YT: {value: 'YT', label: 'Mayotte', callingCode: '262', alpha3Code: 'MYT'},
  MX: {value: 'MX', label: 'Mexico', callingCode: '52', alpha3Code: 'MEX'},
  FM: {value: 'FM', label: 'Micronesia, Federated States Of', callingCode: '691', alpha3Code: 'FSM'},
  MD: {value: 'MD', label: 'Moldova', callingCode: '373', alpha3Code: 'MDA'},
  MC: {value: 'MC', label: 'Monaco', callingCode: '377', alpha3Code: 'MCO'},
  MN: {value: 'MN', label: 'Mongolia', callingCode: '976', alpha3Code: 'MNG'},
  ME: {value: 'ME', label: 'Montenegro', callingCode: '382', alpha3Code: 'MNE'},
  MS: {value: 'MS', label: 'Montserrat', callingCode: '1664', alpha3Code: 'MSR'},
  MA: {value: 'MA', label: 'Morocco', callingCode: '212', alpha3Code: 'MAR'},
  MZ: {value: 'MZ', label: 'Mozambique', callingCode: '258', alpha3Code: 'MOZ'},
  MM: {value: 'MM', label: 'Myanmar', callingCode: '95', alpha3Code: 'MMR'},
  NA: {value: 'NA', label: 'Namibia', callingCode: '264', alpha3Code: 'NAM'},
  NR: {value: 'NR', label: 'Nauru', callingCode: '674', alpha3Code: 'NRU'},
  NP: {value: 'NP', label: 'Nepal', callingCode: '977', alpha3Code: 'NPL'},
  NL: {value: 'NL', label: 'Netherlands', callingCode: '31', alpha3Code: 'NLD'},
  AN: {value: 'AN', label: 'Netherlands Antilles', callingCode: '599', alpha3Code: 'ANT'},
  NC: {value: 'NC', label: 'New Caledonia', callingCode: '687', alpha3Code: 'NCL'},
  NZ: {value: 'NZ', label: 'New Zealand', callingCode: '64', alpha3Code: 'NZL'},
  NI: {value: 'NI', label: 'Nicaragua', callingCode: '505', alpha3Code: 'NIC'},
  NE: {value: 'NE', label: 'Niger', callingCode: '227', alpha3Code: 'NER'},
  NG: {value: 'NG', label: 'Nigeria', callingCode: '234', alpha3Code: 'NGA'},
  NU: {value: 'NU', label: 'Niue', callingCode: '683', alpha3Code: 'NIU'},
  NF: {value: 'NF', label: 'Norfolk Island', callingCode: '672', alpha3Code: 'NFK'},
  MP: {value: 'MP', label: 'Northern Mariana Islands', callingCode: '1670', alpha3Code: 'MNP'},
  NO: {value: 'NO', label: 'Norway', callingCode: '47', alpha3Code: 'NOR'},
  OM: {value: 'OM', label: 'Oman', callingCode: '968', alpha3Code: 'OMN'},
  PK: {value: 'PK', label: 'Pakistan', callingCode: '92', alpha3Code: 'PAK'},
  PW: {value: 'PW', label: 'Palau', callingCode: '680', alpha3Code: 'PLW'},
  PS: {value: 'PS', label: 'Palestinian Territory, Occupied', callingCode: '970', alpha3Code: 'PSE'},
  PA: {value: 'PA', label: 'Panama', callingCode: '507', alpha3Code: 'PAN'},
  PG: {value: 'PG', label: 'Papua New Guinea', callingCode: '675', alpha3Code: 'PNG'},
  PY: {value: 'PY', label: 'Paraguay', callingCode: '595', alpha3Code: 'PRY'},
  PE: {value: 'PE', label: 'Peru', callingCode: '51', alpha3Code: 'PER'},
  PH: {value: 'PH', label: 'Philippines', callingCode: '63', alpha3Code: 'PHL'},
  PN: {value: 'PN', label: 'Pitcairn', callingCode: '64', alpha3Code: 'PCN'},
  PL: {value: 'PL', label: 'Poland', callingCode: '48', alpha3Code: 'POL'},
  PT: {value: 'PT', label: 'Portugal', callingCode: '351', alpha3Code: 'PRT'},
  PR: {value: 'PR', label: 'Puerto Rico', callingCode: '1939', alpha3Code: 'PRI'},
  QA: {value: 'QA', label: 'Qatar', callingCode: '974', alpha3Code: 'QAT'},
  RE: {value: 'RE', label: 'Reunion', callingCode: '262', alpha3Code: 'REU'},
  RO: {value: 'RO', label: 'Romania', callingCode: '40', alpha3Code: 'ROU'},
  RU: {value: 'RU', label: 'Russian Federation', callingCode: '7', alpha3Code: 'RUS'},
  RW: {value: 'RW', label: 'Rwanda', callingCode: '250', alpha3Code: 'RWA'},
  BL: {value: 'BL', label: 'Saint Barthelemy', callingCode: '590', alpha3Code: 'BLM'},
  SH: {value: 'SH', label: 'Saint Helena', callingCode: '290', alpha3Code: 'SHN'},
  KN: {value: 'KN', label: 'Saint Kitts And Nevis', callingCode: '1869', alpha3Code: 'KNA'},
  LC: {value: 'LC', label: 'Saint Lucia', callingCode: '1758', alpha3Code: 'LCA'},
  MF: {value: 'MF', label: 'Saint Martin', callingCode: '590', alpha3Code: 'MAF'},
  PM: {value: 'PM', label: 'Saint Pierre And Miquelon', callingCode: '508', alpha3Code: 'SPM'},
  VC: {value: 'VC', label: 'Saint Vincent And Grenadines', callingCode: '1784', alpha3Code: 'VCT'},
  WS: {value: 'WS', label: 'Samoa', callingCode: '685', alpha3Code: 'WSM'},
  SM: {value: 'SM', label: 'San Marino', callingCode: '378', alpha3Code: 'SMR'},
  ST: {value: 'ST', label: 'Sao Tome And Principe', callingCode: '239', alpha3Code: 'STP'},
  SA: {value: 'SA', label: 'Saudi Arabia', callingCode: '966', alpha3Code: 'SAU'},
  SN: {value: 'SN', label: 'Senegal', callingCode: '221', alpha3Code: 'SEN'},
  RS: {value: 'RS', label: 'Serbia', callingCode: '381', alpha3Code: 'SRB'},
  SC: {value: 'SC', label: 'Seychelles', callingCode: '248', alpha3Code: 'SYC'},
  SL: {value: 'SL', label: 'Sierra Leone', callingCode: '232', alpha3Code: 'SLE'},
  SG: {value: 'SG', label: 'Singapore', callingCode: '65', alpha3Code: 'SGP'},
  SK: {value: 'SK', label: 'Slovakia', callingCode: '421', alpha3Code: 'SVK'},
  SI: {value: 'SI', label: 'Slovenia', callingCode: '386', alpha3Code: 'SVN'},
  SB: {value: 'SB', label: 'Solomon Islands', callingCode: '677', alpha3Code: 'SLB'},
  SO: {value: 'SO', label: 'Somalia', callingCode: '252', alpha3Code: 'SOM'},
  ZA: {value: 'ZA', label: 'South Africa', callingCode: '27', alpha3Code: 'ZAF'},
  GS: {value: 'GS', label: 'South Georgia And Sandwich Isl.', callingCode: '500', alpha3Code: 'SGS'},
  ES: {value: 'ES', label: 'Spain', callingCode: '34', alpha3Code: 'ESP'},
  LK: {value: 'LK', label: 'Sri Lanka', callingCode: '94', alpha3Code: 'LKA'},
  SD: {value: 'SD', label: 'Sudan', callingCode: '249', alpha3Code: 'SDN'},
  SR: {value: 'SR', label: 'Suriname', callingCode: '597', alpha3Code: 'SUR'},
  SJ: {value: 'SJ', label: 'Svalbard And Jan Mayen', callingCode: '47', alpha3Code: 'SJM'},
  SZ: {value: 'SZ', label: 'Swaziland', callingCode: '268', alpha3Code: 'SWZ'},
  SE: {value: 'SE', label: 'Sweden', callingCode: '46', alpha3Code: 'SWE'},
  CH: {value: 'CH', label: 'Switzerland', callingCode: '41', alpha3Code: 'CHE'},
  SY: {value: 'SY', label: 'Syrian Arab Republic', callingCode: '963', alpha3Code: 'SYR'},
  TW: {value: 'TW', label: 'Taiwan', callingCode: '886', alpha3Code: 'TWN'},
  TJ: {value: 'TJ', label: 'Tajikistan', callingCode: '992', alpha3Code: 'TJK'},
  TZ: {value: 'TZ', label: 'Tanzania', callingCode: '255', alpha3Code: 'TZA'},
  TH: {value: 'TH', label: 'Thailand', callingCode: '66', alpha3Code: 'THA'},
  TL: {value: 'TL', label: 'Timor-Leste', callingCode: '670', alpha3Code: 'TLS'},
  TG: {value: 'TG', label: 'Togo', callingCode: '228', alpha3Code: 'TGO'},
  TK: {value: 'TK', label: 'Tokelau', callingCode: '690', alpha3Code: 'TKL'},
  TO: {value: 'TO', label: 'Tonga', callingCode: '676', alpha3Code: 'TON'},
  TT: {value: 'TT', label: 'Trinidad And Tobago', callingCode: '1868', alpha3Code: 'TTO'},
  TN: {value: 'TN', label: 'Tunisia', callingCode: '216', alpha3Code: 'TUN'},
  TR: {value: 'TR', label: 'Turkey', callingCode: '90', alpha3Code: 'TUR'},
  TM: {value: 'TM', label: 'Turkmenistan', callingCode: '993', alpha3Code: 'TKM'},
  TC: {value: 'TC', label: 'Turks And Caicos Islands', callingCode: '1649', alpha3Code: 'TCA'},
  TV: {value: 'TV', label: 'Tuvalu', callingCode: '688', alpha3Code: 'TUV'},
  UG: {value: 'UG', label: 'Uganda', callingCode: '256', alpha3Code: 'UGA'},
  UA: {value: 'UA', label: 'Ukraine', callingCode: '380', alpha3Code: 'UKR'},
  AE: {value: 'AE', label: 'United Arab Emirates', callingCode: '971', alpha3Code: 'ARE'},
  GB: {value: 'GB', label: 'United Kingdom', callingCode: '44', alpha3Code: 'GBR'},
  US: {value: 'US', label: 'United States', callingCode: '1', alpha3Code: 'USA'},
  UM: {value: 'UM', label: 'United States Outlying Islands', callingCode: '1', alpha3Code: 'UMI'},
  UY: {value: 'UY', label: 'Uruguay', callingCode: '598', alpha3Code: 'URY'},
  UZ: {value: 'UZ', label: 'Uzbekistan', callingCode: '998', alpha3Code: 'UZB'},
  VU: {value: 'VU', label: 'Vanuatu', callingCode: '678', alpha3Code: 'VUT'},
  VE: {value: 'VE', label: 'Venezuela', callingCode: '58', alpha3Code: 'VEN'},
  VN: {value: 'VN', label: 'Viet Nam', callingCode: '84', alpha3Code: 'VNM'},
  VG: {value: 'VG', label: 'Virgin Islands, British', callingCode: '1284', alpha3Code: 'VGB'},
  VI: {value: 'VI', label: 'Virgin Islands, U.S.', callingCode: '1340', alpha3Code: 'VIR'},
  WF: {value: 'WF', label: 'Wallis And Futuna', callingCode: '681', alpha3Code: 'WLF'},
  EH: {value: 'EH', label: 'Western Sahara', alpha3Code: 'ESH', callingCode: '212'},
  YE: {value: 'YE', label: 'Yemen', callingCode: '967', alpha3Code: 'YEM'},
  ZM: {value: 'ZM', label: 'Zambia', callingCode: '260', alpha3Code: 'ZMB'},
  ZW: {value: 'ZW', label: 'Zimbabwe', callingCode: '263', alpha3Code: 'ZWE'},
}

export const countriesOptions = values(countries).map(country => pick(country, ['value', 'label']));