import React from "react";
import classNames from "classnames";
import { ISelectOption } from "../../../../common/types";
import { Collapse } from "../../../atoms";
import { StaticDropdown } from "../StaticDropdown";
import { SectionCardWrapper } from "./SectionCard.style";

interface ISectionCardProps {
  title: string | ISelectOption[];
  extra?: React.ReactNode;
  titleOnSelect?: (value: string) => void;
  greyHeader?: boolean;
}

export const SectionCard: React.FC<ISectionCardProps> = ({
  children,
  title,
  extra,
  titleOnSelect,
  greyHeader,
}) => (
  <SectionCardWrapper>
    <Collapse
      defaultActiveKey={["1"]}
      className={classNames("section-card-collapse ", { grey: greyHeader })}
      collapsible="header"
    >
      <Collapse.Panel
        className="section-card-panel"
        header={
          <div className="section-card-header">
            <div className="section-card-title">
              {typeof title !== "string" && titleOnSelect ? (
                <StaticDropdown
                  defaultTitle={title[0]}
                  items={title}
                  onSelect={titleOnSelect}
                />
              ) : (
                title
              )}
            </div>
          </div>
        }
        extra={<div className="section-card-Actions">{extra}</div>}
        key="1"
      >
        {children}
      </Collapse.Panel>
    </Collapse>
  </SectionCardWrapper>
);
