/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IUser } from "../../../common/types";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { InputFormWrapper } from "../../atoms";
import { ActivityListCard, ObjectiveListCard, UserCard } from "../../molecules";
interface IUserDetailsProps {
  user?: IUser;
}
export const UserDetails: React.FC<IUserDetailsProps> = () => {
  const params = useParams();

  const userId = params.userId || "";
  const { selectedUser } = useSelector((state: any) => state.users);
  console.log(userId);
  return (
    <AdminPageBody>
      <InputFormWrapper>
        <div className="input-form-body">
          <UserCard user={selectedUser} keywords={["Sell", "Marketing"]} />
          <ObjectiveListCard
            filters={[
              {
                type: "filter",
                name: "type",
                opr: "==",
                value: "INDIVIDUAL_OBJECTIVE",
              },
              {
                type: "filter",
                name: "ownerId",
                opr: "==",
                value: userId,
              },
            ]}
            cycleId={""}
          />
          <ActivityListCard activities={[]} />
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
