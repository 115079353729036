import styled from "styled-components";

export const DndBoardColumnWrapper = styled.div`
  width: 100%;
  height: 100%;

  .dndColumn {
    font-size: 14px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-right: 1px solid #f9f7f7;

    &__column {
      margin: 0;
      width: 100%;
      min-width: 250px;
    }

    &__box {
      text-align: center;
      padding: 10px 0;
      width: 100%;
      background-color: white;
    }
  }
`;

export const DndBoardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: auto;
  overflow-y: auto;
  flex: 1;
`;
