/* eslint-disable no-console */
import {
  MenuOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Popconfirm } from "antd";
import moment from "moment";
import React from "react";
import { ICycle } from "../../../../common/types";
import { Button, DatePicker, Form } from "../../../atoms";
import { InputField } from "../InputField";
import { MilestoneFieldWrapper } from "./MilestoneField.style";

interface IMilestoneFieldProps {
  name: string;
  cycle: ICycle;
}
export const MilestoneField: React.FC<IMilestoneFieldProps> = ({
  name: itemName,
  cycle,
}) => {
  console.log("df");
  return (
    <MilestoneFieldWrapper>
      <div className="milestone-field-title">Milestones</div>
      <Form.List name={itemName} initialValue={[{}, {}]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <div key={key} className="milestone-field-leg">
                <MenuOutlined
                  style={{
                    marginTop: index === 0 ? "20px " : "",
                  }}
                />
                <div className="milestone-field-space">
                  <InputField
                    name={[name, "name"]}
                    className="milestone-field-name"
                    label={index === 0 ? "Name" : ""}
                    rules={[
                      {
                        required: true,
                        message: "Name",
                      },
                    ]}
                    bordered={false}
                    placeholder="Name"
                  />
                  <Form.Item
                    label={index === 0 ? "Start Date" : ""}
                    name={[name, "startDate"]}
                    rules={[
                      {
                        required: true,
                        message: "Missing Start Date",
                      },
                    ]}
                    initialValue={moment(cycle.startDate)}
                  >
                    <DatePicker
                      bordered={false}
                      placeholder="Start Date"
                      format="DD/MM/yyyy"
                      minuteStep={5}
                      style={{ width: "100%" }}
                      disabledDate={(current) =>
                        current <= moment() ||
                        current <= moment(cycle.startDate) ||
                        current >= moment(cycle.endDate)
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label={index === 0 ? "Due Date" : ""}
                    name={[name, "dueDate"]}
                    rules={[
                      {
                        required: true,
                        message: "Missing Due Date",
                      },
                    ]}
                  >
                    <DatePicker
                      bordered={false}
                      placeholder="Due Date"
                      format="DD/MM/yyyy"
                      minuteStep={5}
                      style={{ width: "100%" }}
                      disabledDate={(current) => current <= moment()}
                    />
                  </Form.Item>
                  <InputField
                    name={[name, "weight"]}
                    label={index === 0 ? "Weight(%)" : ""}
                    rules={[
                      {
                        required: true,
                        message: "Weight",
                      },
                    ]}
                    placeholder="Weight"
                    bordered={false}
                    style={{ width: 100 }}
                  />
                  <div
                    className="milestone-field-total"
                    style={{ marginTop: index === 0 ? "30px " : "" }}
                  >
                    10%
                  </div>
                </div>
                <div className="milestone-field-action">
                  <Popconfirm
                    placement="top"
                    title="Are you sure？"
                    onConfirm={() => remove(name)}
                    disabled={fields.length <= 1}
                  >
                    <MinusCircleOutlined
                      style={{
                        color: index < 1 ? "#ccc" : "red",
                        marginTop: index === 0 ? "20px " : "",
                      }}
                    />
                  </Popconfirm>
                </div>
              </div>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add Milestone
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </MilestoneFieldWrapper>
  );
};
