import { Space as AntSpace, SpaceProps } from "antd";
import styled from "styled-components";

export const Space: typeof AntSpace = styled(AntSpace)<SpaceProps>`
  &.full-width {
    display: flex;
    .ant-space {
      &-item {
        flex: 1;
      }
    }
  }
`;
