import React from "react";
import { Button, Spin } from "../../atoms";
import { LoaderWrapper } from "./Loader.style";
interface ILoaderProps {
  title?: string;
  btn?: {
    action: () => void;
    title: string;
  };
  color: string;
  subTitle?: string;
}
export const Loader: React.FC<ILoaderProps> = ({
  title,
  btn,
  color,
  subTitle,
}) => (
  <LoaderWrapper className="loader-wrapper" color={color}>
    <div className="loader-wrapper__body">
      <Spin />
      {title && <div className="loader-wrapper__title">{title}</div>}
      {subTitle && <div className="loader-wrapper__subTitle">{subTitle}</div>}
      {btn && (
        <Button type="primary" onClick={btn.action}>
          {btn.title}
        </Button>
      )}
    </div>
  </LoaderWrapper>
);
