import { CheckOutlined, DownOutlined } from "@ant-design/icons";
import React from "react";
import { ISelectOption } from "../../../common/types";
import { Dropdown, Menu } from "../../atoms";
import { StaticDropdownWrapper } from "./CommonComponents.style";
interface IStaticDropdownProps {
  defaultTitle: ISelectOption;
  items: ISelectOption[];
  onSelect: (value: string) => void;
}
export const StaticDropdown: React.FC<IStaticDropdownProps> = ({
  defaultTitle,
  items,
  onSelect,
}) => {
  const [title, setTitle] = React.useState<ISelectOption>(defaultTitle);
  const [menuItems, setMenuItems] = React.useState<ISelectOption[]>([]);
  const handelItemClick = (item: ISelectOption) => (e: any) => {
    setTitle(item);
    onSelect(item.value);
    e.stopPropagation();
    e.preventDefault();
  };

  const menu = (
    <StaticDropdownWrapper className="static-dropdown-menu">
      {menuItems.map((item, index) => (
        <Menu.Item
          className="static-dropdown-menu-item"
          key={index}
          onClick={handelItemClick(item)}
        >
          {title.value === item.value && (
            <CheckOutlined className="static-dropdown-selected-icon" />
          )}{" "}
          {item.label}
        </Menu.Item>
      ))}
    </StaticDropdownWrapper>
  );
  React.useEffect(() => {
    setTitle(defaultTitle);
    setMenuItems(items);
  }, [defaultTitle, items]);
  return (
    <Dropdown overlay={menu}>
      <div className="static-dropdown-title">
        {title.label} <DownOutlined />
      </div>
    </Dropdown>
  );
};
