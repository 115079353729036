/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ObjectiveList } from "../../molecules";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { BoardWrapper } from "./Board.style";
import { BoardObjectiveListToolbar } from "./BoardObjectiveListToolbar";

interface IBoardObjectiveListProps {
  boardId?: string;
}
export const BoardObjectiveList: React.FC<IBoardObjectiveListProps> = () => {
  const params = useParams();

  const boardId = params.boardId || "";
  const { selectedBoardObjectives } = useSelector((state: any) => state.boards);
  const { loading } = useSelector((state: any) => state.async);

  return (
    <AdminPageBody
      pageToolbar={<BoardObjectiveListToolbar boardId={boardId} />}
    >
      <BoardWrapper>
        <ObjectiveList objectives={selectedBoardObjectives} loading={loading} />
      </BoardWrapper>
    </AdminPageBody>
  );
};
