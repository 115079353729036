/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { OkrList } from "../../molecules";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { CycleWrapper } from "./Cycle.style";
import { CycleOkrListToolbar } from "./CycleOkrListToolbar";

interface ICycleOkrListProps {
  cycleId?: string;
}
export const CycleOkrList: React.FC<ICycleOkrListProps> = () => {
  const params = useParams();
  const cycleId = params.cycleId || "";
  const { loading } = useSelector((state: any) => state.async);
  const { selectedCycleOkrs } = useSelector((state: any) => state.cycles);

  return (
    <AdminPageBody pageToolbar={<CycleOkrListToolbar cycleId={cycleId} />}>
      <CycleWrapper>
        <OkrList okrs={selectedCycleOkrs} loading={loading} />
      </CycleWrapper>
    </AdminPageBody>
  );
};
