import React from "react";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";
import { CompanyList } from "../../App/components/organisms";

const CompanyListPage: React.FC = () => (
  <AdminPage title="Provider List">
    <CompanyList />
  </AdminPage>
);

export default CompanyListPage;
