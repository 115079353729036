import styled from "styled-components";
export const ObjectiveProgressIndicatorWrapper = styled.div`
  width: 100%;
  font-size: 12px;
  margin: 20px 10px;
  .indicator-tag {
    color: #fff;
    padding: 5px 20px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 6px;
  }
  .labels {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  .c-1-color {
    background: #ff3300;
    border-color: #ff3300;
  }
  .c-2-color {
    background: #ffa031;
    border-color: #ffa031;
  }
  .c-3-color {
    background: #71b700;
    border-color: #71b700;
  }
  .noUi-marker-large {
    height: 10px;
  }
  .noUi-horizontal {
    height: 10px;
  }
  .noUi-target {
    border-radius: 6px;
    border: none;
  }
  .noUi-handle {
    height: 18px;
    width: 18px;
    top: -5px;
    right: -9px; /* half the width */
    border-radius: 9px;
    &:before {
      content: none;
    }
    &:after {
      content: none;
    }
  }
`;
