import { Modal as AntModal, ModalProps } from "antd";
import styled from "styled-components";

export const Modal: typeof AntModal = styled(AntModal)<ModalProps>`
  &.full-page {
    top: 0;
    max-width: 100%;
    width: 100% !important;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    .ant-modal {
      &-wrap {
        z-index: 10000;
      }
      &-content {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
      }
      &-body {
        flex: 1;
        overflow: hidden;
        padding: 0;
      }
      &-title {
        font-size: 14px;
      }
    }
  }
`;
