/* eslint-disable import/no-named-as-default */
/* eslint-disable no-console */
import faker from "@faker-js/faker";
import {
  ISignUpDetails,
  ISignUpDetailsInput,
  IQueryConfigArgs,
} from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import { COLLECTION_SIGNUP_DETAILS } from "./firebaseConstants";

export function fetchSignUpDetailsListFromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_SIGNUP_DETAILS);
  return collectionWithArgs(ref, args || []);
}
export function listenToSignUpDetailsFromFirestore(id: string) {
  return db.collection(COLLECTION_SIGNUP_DETAILS).doc(id);
}

export function addSignUpDetailsToFirestore(doc: ISignUpDetailsInput) {
  // const user = firebase.auth().currentUser;
  console.log(doc);
  return db.collection(COLLECTION_SIGNUP_DETAILS).add({
    ...doc,
    createDate: new Date(),
    code: faker.phone.phoneNumber("###-###"),
    // createBy: user,
  });
}

export async function verificationEmailFromFirestore(
  id: string,
  doc: { code: string }
) {
  try {
    const singUpEmail = await db
      .collection(COLLECTION_SIGNUP_DETAILS)
      .doc(id)
      .get();
    const selectedSingUpEmail = singUpEmail.data();
    if (!selectedSingUpEmail) {
      throw new Error("Email Failed");
    }
    if (
      selectedSingUpEmail.code.replace(/\D/g, "") !==
      doc.code.replace(/\D/g, "")
    ) {
      throw new Error("Wrong Code");
    }
    return db.collection(COLLECTION_SIGNUP_DETAILS).doc(id).update({
      confirm: true,
      confirmDate: new Date(),
    });
  } catch (err) {
    throw err;
  }
}

export function updateSignUpDetailsInFirestore(
  docId: string,
  doc: ISignUpDetails
) {
  return db.collection(COLLECTION_SIGNUP_DETAILS).doc(docId).update(doc);
}

export async function deleteSignUpDetailsInFirestore(docId: string) {
  return db.collection(COLLECTION_SIGNUP_DETAILS).doc(docId).delete();
}
