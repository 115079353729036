import {
  IQueryConfigArgs,
  IOkr,
  IOkrObjectiveInput,
  IOkrKeyResultAmountInput,
  IOkrKeyResultMilestonesInput,
  IMilestone,
  IComment,
} from "../../common/types";
import firebase from "../../config/firebase";
import { setUserAddLog, setUserUpdateLog } from "../helper";
import { objectiveDataFormat } from "../firestoreDataFormat/objectiveDataFormat";
import { db, collectionWithArgs } from "./firestoreService";
import { COLLECTION_COMPANIES, COLLECTION_OKRS } from "./firebaseConstants";

export function fetchCompanyOkrsFromFirestore(args?: IQueryConfigArgs[]) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  const ref = db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_OKRS);
  return collectionWithArgs(ref, args || []);
}
export function listenToCompanyOkrFromFirestore(id: string) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_OKRS)
    .doc(id);
}

export function addCompanyOkrToFirestore(
  doc:
    | IOkrObjectiveInput
    | IOkrKeyResultAmountInput
    | IOkrKeyResultMilestonesInput
) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  // const user = firebase.auth().currentUser;
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_OKRS)
    .add({
      ...objectiveDataFormat(doc),
      ...setUserAddLog(),
      // createBy: user,
    });
}

export function updateCompanyOkrInFirestore(docId: string, doc: IOkr | any) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_OKRS)
    .doc(docId)
    .update({
      ...objectiveDataFormat(doc),
      ...setUserUpdateLog(),
      // createBy: user,
    });
}
export function deleteCompanyOkrInFirestore(docId: string) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_OKRS)
    .doc(docId)
    .delete();
}
export function activeToggleCompanyOkrInFirestore(
  docId: string,
  status: boolean
) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_OKRS)
    .doc(docId)
    .update({
      status,
      ...setUserUpdateLog(),
    });
}

export function addCompanyOkrCommentToFirestore(docId: string, doc: IComment) {
  // const user = firebase.auth().currentUser;
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_OKRS)
    .doc(docId)
    .update({
      comments: firebase.firestore.FieldValue.arrayUnion(doc),
    });
}

export async function deleteCompanyKeyResultMilestoneInFirestore(
  milestoneId: string,
  keyResultId: string
) {
  try {
    const companyId = localStorage.getItem("defaultCompanyKey") || "";
    const currentKeyResult = await db
      .collection(COLLECTION_COMPANIES)
      .doc(companyId)
      .collection(COLLECTION_OKRS)
      .doc(keyResultId)
      .get();
    const doc = currentKeyResult.data();
    if (!doc) throw Error;

    return db
      .collection(COLLECTION_COMPANIES)
      .doc(companyId)
      .collection(COLLECTION_OKRS)
      .doc(keyResultId)
      .update({
        milestones: [
          ...doc.milestones.filter((evt: IMilestone) => evt.id !== milestoneId),
        ],
      });
  } catch (err) {
    throw err;
  }
}
