/* eslint-disable no-console */
import { HistoryOutlined } from "@ant-design/icons";
import React from "react";
import { ICycle } from "../../../common/types";
import { CycleCell } from "../CommonCells";
import { OrgChartCardWrapper } from "./OrgChart.style";

interface IOrgChartCardCycleProps {
  data: ICycle;
}
export const OrgChartCardCycle: React.FC<IOrgChartCardCycleProps> = ({
  data,
}) => {
  console.log("data");
  return (
    <OrgChartCardWrapper>
      <div className="org-chart-card">
        <div className="org-chart-card-icon">
          <HistoryOutlined className="alignment-view-icon" />
        </div>
        <div className="org-chart-card-info">
          <CycleCell cycle={data} />
        </div>
      </div>
    </OrgChartCardWrapper>
  );
};
