/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";
import { useFirestoreCollection } from "../../App/hooks/useFirestoreCollection";
import {
  fetchCompanyInitiativesFromFirestore,
  fetchCompanyOkrsFromFirestore,
  listenToCompanyTeamFromFirestore,
} from "../../App/firestore/firestoreService";
import { userFirestoreDoc } from "../../App/hooks/useFirestoreDoc";
import {
  listenToSelectedCompanyTeam,
  listenToSelectedTeamInitiatives,
  listenToSelectedTeamOkrs,
} from "../../App/redux/data/companyTeam/companyTeamActions";

const currentMenu = (teamId: string) => [
  {
    key: "details",
    label: "Details",
    link: `/team/${teamId}/details`,
  },
  {
    key: "okrs",
    label: "OKRs",
    link: `/team/${teamId}/okrs`,
  },
  {
    key: "initiatives",
    label: "Initiatives",
    link: `/team/${teamId}/initiatives`,
  },
  {
    key: "members",
    label: "Members",
    link: `/team/${teamId}/members`,
  },
  // {
  //   key: "report",
  //   label: "Report",
  //   link: `/user/${userId}/report`,
  // },
  // {
  //   key: "setting",
  //   label: "Setting",
  //   link: `/user/${userId}/setting`,
  // },
];

const TeamOKRsPage: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { selectedTeam } = useSelector((state: any) => state.teams);
  const teamId = params.teamId || "";
  const headerMenu = currentMenu(teamId);
  userFirestoreDoc({
    query: () => listenToCompanyTeamFromFirestore(teamId),
    data: listenToSelectedCompanyTeam,
    deps: [dispatch, teamId],
  });
  useFirestoreCollection({
    query: () =>
      fetchCompanyOkrsFromFirestore([
        {
          type: "filter",
          name: "teamId",
          opr: "==", // TODO
          value: teamId,
        },
      ]),
    data: listenToSelectedTeamOkrs,
    deps: [dispatch, teamId],
  });
  useFirestoreCollection({
    query: () =>
      fetchCompanyInitiativesFromFirestore([
        {
          type: "filter",
          name: "teamId",
          opr: "==", // TODO
          value: teamId,
        },
      ]),
    data: listenToSelectedTeamInitiatives,
    deps: [dispatch, teamId],
  });
  return (
    <AdminPage
      title={`${selectedTeam?.name} Team OKRs`}
      headerMenu={headerMenu}
    >
      <Outlet />
    </AdminPage>
  );
};

export default TeamOKRsPage;
