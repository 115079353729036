/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import React, { useLayoutEffect, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import { OrgChart } from "d3-org-chart";
// import * as d3 from "d3";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { openDrawer } from "../../../redux/data/drawer/drawerReducer";
import { OrgChartCardCycle } from "./OrgChartCardCycle";
import { OrgChartCardCompany } from "./OrgChartCardCompany";
import { OrgChartCardTeam } from "./OrgChartCardTeam";
import { OrgChartCardUser } from "./OrgChartCardUser";
import { OrgChartCardKeyResult } from "./OrgChartCardKeyResult";
import { OrgChartCardObjective } from "./OrgChartCardObjective";
import { OrgChartObjectCountWrapper } from "./OrgChart.style";
interface IOrgChartComponentProps {
  data: any;
  onNodeClick: (v: any) => void;
  setClick?: (c: any) => void;
}
export const OrgChartComponent: React.FC<IOrgChartComponentProps> = (
  { data, onNodeClick, setClick },
  ref
) => {
  const d3Container = useRef(null);
  let chart: any = null;
  const dispatch = useDispatch();
  function addNode(node: any) {
    chart.addNode(node);
  }

  setClick && setClick(addNode);

  const handelOpenOkr = (id: string) => () => {
    console.log("ffff");
    dispatch(
      openDrawer({
        drawerType: "ObjectiveDetailsDrawer",
        drawerProps: {
          title: "dd",
          id,
        },
      })
    );
  };
  // We need to manipulate DOM
  useLayoutEffect(() => {
    if (data && d3Container.current) {
      console.log(data);
      if (!chart) {
        chart = new OrgChart();
      }
      chart
        .container(d3Container.current)
        .data(data)
        .nodeHeight((d: any) => 170)
        .nodeWidth((d: any) => {
          if (d.depth === 0) return 500;
          return 330;
        })
        .childrenMargin(() => 90)
        .compactMarginBetween(() => 65)
        .compactMarginPair(() => 100)
        .neightbourMargin((a: any, b: any) => 50)
        .siblingsMargin(() => 100)
        .buttonContent(({ node, state }: any) =>
          ReactDOMServer.renderToStaticMarkup(
            <OrgChartObjectCountWrapper>
              {node.children ? <UpOutlined /> : <DownOutlined />}
              {node?.data?._directSubordinates}
            </OrgChartObjectCountWrapper>
          )
        )
        // .linkUpdate(function (d: any) {
        //   d3.select(this)
        //     .attr("stroke", (d) =>
        //       d.data._upToTheRootHighlighted ? "#14760D" : "#2CAAE5"
        //     )
        //     .attr("stroke-width", (d: any) =>
        //       d.data._upToTheRootHighlighted ? 15 : 1
        //     );

        //   if (d.data._upToTheRootHighlighted) {
        //     d3.select(this).raise();
        //   }
        // })
        .nodeContent((d: any) => {
          if (d.data.treeType === "CYCLE") {
            return ReactDOMServer.renderToStaticMarkup(
              <OrgChartCardCycle data={d.data} />
            );
          }
          if (d.data.treeType === "COMPANY") {
            return ReactDOMServer.renderToStaticMarkup(
              <OrgChartCardCompany data={d.data} />
            );
          }
          if (d.data.treeType === "TEAM") {
            return ReactDOMServer.renderToStaticMarkup(
              <OrgChartCardTeam data={d.data} />
            );
          }
          if (d.data.treeType === "USER") {
            return ReactDOMServer.renderToStaticMarkup(
              <OrgChartCardUser data={d.data} />
            );
          }
          if (d.data.treeType === "KEY_RESULT") {
            return ReactDOMServer.renderToStaticMarkup(
              <OrgChartCardKeyResult data={d.data} score={0} />
            );
          }
          if (
            d.data.treeType === "OBJECTIVE" ||
            d.data.treeType === "OBJECTIVE_CASCADED" ||
            d.data.treeType === "OBJECTIVE_ALIGNMENT"
          ) {
            return ReactDOMServer.renderToStaticMarkup(
              <OrgChartCardObjective
                data={d.data}
                score={0}
                openOkr={handelOpenOkr}
              />
            );
          }
        })
        .render();
    }
  }, [data, d3Container.current]);

  return (
    <div>
      <div ref={d3Container} />
    </div>
  );
};
