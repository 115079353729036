/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddBoardForm, AddBoardSettingForm } from "../../molecules";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { closeModal } from "../../../redux/data/modals/modalReducer";
import { InputFormWrapper } from "../../atoms";

export const AddBoardModal: React.FC = (props) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [flow, setFlow] = React.useState("addBoard");
  const [boardId, setBoardId] = React.useState("");
  const handleAddBoard = (id: string) => {
    setFlow("AddBoardSetting");
    setBoardId(id);
  };
  const handelAddBardSetting = () => {
    dispatch(closeModal());
    history(`/board/${boardId}/details`);
  };
  return (
    <ModalWrapper {...props} className="full-page" footer={null}>
      <InputFormWrapper>
        <div className="input-form-body">
          <div className="input-form-title text-center">
            {flow === "addBoard" && "Create New Board"}
            {flow === "AddBoardSetting" && "Advance Setting"}
          </div>
          {flow === "addBoard" && (
            <div className="input-form-description text-center">
              Board will allows you to create ... Get Started
            </div>
          )}
          {flow === "AddBoardSetting" && (
            <div className="input-form-description text-center">
              This will help us to recommend you better Objectives and key
              Results create by experts and it will save you a lot of time
            </div>
          )}
          {flow === "addBoard" && (
            <AddBoardForm callback={handleAddBoard} btnName="Create board" />
          )}
          {flow === "AddBoardSetting" && (
            <AddBoardSettingForm
              callback={handelAddBardSetting}
              boardId={boardId}
              btnName="Done"
            />
          )}
        </div>
      </InputFormWrapper>
    </ModalWrapper>
  );
};
