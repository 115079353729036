/* eslint-disable no-console */
import React from "react";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { InputFormWrapper } from "../../atoms";

interface IProfileSittingProps {
  name?: string;
}
export const ProfileSitting: React.FC<IProfileSittingProps> = ({ name }) => {
  console.log(name);
  return (
    <AdminPageBody>
      <InputFormWrapper>
        <div className="input-form-body">
          <div className="input-form-title">My Sitting</div>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
