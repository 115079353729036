/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { InitiativeList } from "../../molecules";
import { ObjectiveInitiativeListToolbar } from "./ObjectiveInitiativeListToolbar";

interface IOkrInitiativeListProps {
  objectiveId?: string;
}
export const ObjectiveInitiativeList: React.FC<
  IOkrInitiativeListProps
> = () => {
  const params = useParams();

  const objectiveId = params.objectiveId || "";
  const { selectedObjectiveInitiatives, selectedOkr } = useSelector(
    (state: any) => state.okrs
  );
  const { loading } = useSelector((state: any) => state.async);

  return (
    <AdminPageBody
      pageToolbar={
        <ObjectiveInitiativeListToolbar
          boardId={selectedOkr?.boardId || ""}
          objectiveId={objectiveId}
        />
      }
    >
      <InitiativeList
        initiatives={selectedObjectiveInitiatives}
        loading={loading}
      />
    </AdminPageBody>
  );
};
