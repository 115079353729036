import React from "react";
import { Logo } from "../../atoms/Logo/Logo";
import { RegistrationWrapper } from "./Registration.style";

export const Registration: React.FC = ({ children }) => (
  <RegistrationWrapper>
    <header className="registration-header">
      <Logo className="registration-header-logo" />
    </header>
    {children}
    <div className="registration-footer"> </div>
  </RegistrationWrapper>
);
