/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  ActivityListCard,
  AlignmentView,
  ObjectiveCard,
} from "../../molecules";
import { InputFormWrapper } from "../../atoms";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { KeyResultListCard } from "../../molecules/KeyResult/KeyResultListCard";
import { CheckInListCard } from "../../molecules/CheckIn/CheckInListCard";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { listenToCompanyOkrFromFirestore } from "../../../firestore/firestoreService";
import { IOkr } from "../../../common/types";

interface IOkrDetailsProps {
  objectiveId?: string;
}
export const ObjectiveDetails: React.FC<IOkrDetailsProps> = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const okrId = params.okrId || "";
  const { selectedOkr } = useSelector((state: any) => state.okrs);
  const [parentObjective, setParentObjective] = React.useState<IOkr>();
  userFirestoreDoc({
    query: () =>
      listenToCompanyOkrFromFirestore(selectedOkr?.okrParentId || ""),
    data: setParentObjective,
    deps: [dispatch, selectedOkr],
    shouldExecute: !!selectedOkr?.okrParentId,
    local: true,
  });
  const handelAddKeyResult = () => {
    dispatch(
      openModal({
        modalType: "AddKeyResultModal",
        modalProps: {
          title: "Create key result",
          boardId: selectedOkr.boardId || null,
          okrParentId: okrId,
          cycleId: selectedOkr.cycleId,
        },
      })
    );
  };
  if (!selectedOkr) {
    return <div></div>;
  }
  return (
    <AdminPageBody>
      {" "}
      <AlignmentView
        objective={selectedOkr}
        showAlignment={!!selectedOkr?.alignmentId}
      />
      <InputFormWrapper>
        <div className="input-form-body">
          <ObjectiveCard
            title={selectedOkr.title || ""}
            owner={selectedOkr.owner}
            description={selectedOkr.description}
            objective={selectedOkr}
            score={selectedOkr.score || 0}
            parentObjective={parentObjective}
          />
          <KeyResultListCard
            filters={[
              {
                type: "filter",
                name: "okrParentId",
                opr: "==",
                value: okrId,
              },
            ]}
            action={handelAddKeyResult}
          />

          <CheckInListCard checkIns={[]} />
          <ActivityListCard activities={[]} />
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
