/* eslint-disable no-console */
import React from "react";
import { Link } from "react-router-dom";
import { ActionCell } from "..";
import { IOkr, IUser } from "../../../common/types";
import { Table } from "../../atoms";
import { deleteCompanyOkrInFirestore } from "../../../firestore/firestoreService";
import { UserCell } from "../CommonCells";
import { ObjectiveWrapper } from "./Objective.style";

interface IOkrListProps {
  objectives: IOkr[];
  loading: boolean;
}
export const ObjectiveList: React.FC<IOkrListProps> = ({
  objectives,
  loading,
}) => {
  const handelDeleteObjective = async (id: string) => {
    await deleteCompanyOkrInFirestore(id);
  };
  const columnsList = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      fixed: true,
      render: (v: string, record: any) => (
        <Link to={`/objective/${record.id}/details`}>{v}</Link>
      ),
      width: 400,
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      render: (v: IUser) => <UserCell user={v} avatarOnly />,
    },
    {
      title: "Completion",
      dataIndex: "completion",
      key: "completion",
      render: (v: string) => v,
    },
    {
      title: "Progress",
      dataIndex: "progress",
      key: "progress",
      render: (v: string) => v,
    },
    // {
    //   title: "Created Date",
    //   dataIndex: "createdDate",
    //   key: "createdDate",
    //   render: (v: moment.Moment) => moment(v).format("DD/MM/YYYY"),
    // },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: IOkr) => (
        <ActionCell onDelete={() => handelDeleteObjective(record.id)} />
      ),
    },
  ];
  return (
    <ObjectiveWrapper>
      <Table
        columns={columnsList}
        dataSource={objectives}
        loading={loading}
        rowKey="id"
        // scroll={{ x: 800, y: 500 }}
      />
    </ObjectiveWrapper>
  );
};
