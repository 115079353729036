/* eslint-disable no-console */
import {
  ArrowsAltOutlined,
  CopyOutlined,
  DashboardOutlined,
  NodeExpandOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IOkr } from "../../../common/types";
import { closeDrawer } from "../../../redux/data/drawer/drawerReducer";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { Modal } from "../../atoms";
import { ActionCell } from "../CommonCells";

interface IObjectiveActionMenuProps {
  objective: IOkr;
  objectiveId: string;
}
export const ObjectiveActionMenu: React.FC<IObjectiveActionMenuProps> = ({
  objective,
  objectiveId,
}) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const handelAddKeyResult = () => {
    if (!objective || objectiveId) return;
    dispatch(
      openModal({
        modalType: "AddKeyResultModal",
        modalProps: {
          title: "Create key result",
          boardId: objective?.boardId || null,
          okrParentId: objectiveId,
          okrParentIds: objective.okrParentIds || [],
          cycleId: objective.cycleId,
          okrFor: objective.okrFor,
        },
      })
    );
  };
  const makeObjectiveAlignment = () => {
    if (!objective) return;
    dispatch(
      openModal({
        modalType: "CascadeObjectiveModal",
        modalProps: {
          title: "Cascade objective",
          objectiveId,
          objective,
        },
      })
    );
  };
  const handelDeleteObjective = (id: string) => () => {
    Modal.warning({
      title: `Delete Objective "${objective.title}"`,
      content:
        "Are Sure you want to delete this objective, the delete will be parament",
      cancelText: "Cancel",
      closable: true,
      okText: "Delete",
      onOk: () => console.log("delete"),
    });
  };
  const handelFullPageObjective = () => {
    dispatch(closeDrawer());
    history(`/objective/${objectiveId}/details`);
  };
  const handelDuplicatedObjective = () => {
    Modal.warning({
      title: `Duplicated Objective "${objective.title}"`,
      content: "Coming Soon",
      cancelText: "Cancel",
      closable: true,
      okText: "Duplicate",
      onOk: () => console.log("Duplicate"),
    });
  };
  const handelMoveObjective = () => {
    Modal.warning({
      title: `Move objective Objective "${objective.title}"`,
      content: "Coming Soon",
      cancelText: "Cancel",
      closable: true,
      okText: "Duplicate",
      onOk: () => console.log("Duplicate"),
    });
  };
  const handelCloseObjective = () => {
    Modal.warning({
      title: `Close Objective "${objective.title}"`,
      content: "Coming Soon",
      cancelText: "Cancel",
      closable: true,
      okText: "Close",
      onOk: () => console.log("Close"),
    });
  };
  const handelMakeTemplateObjective = () => {
    Modal.warning({
      title: `Create objective template "${objective.title}"`,
      content: "Coming Soon",
      cancelText: "Create objective template ",
      closable: true,
      okText: "Close",
      onOk: () => console.log("Create objective template "),
    });
  };
  return (
    <ActionCell
      onDelete={handelDeleteObjective(objectiveId)}
      className="drawer-extra-btn-link"
      menu={[
        {
          title: "Full Page objective",
          icon: <ArrowsAltOutlined />,
          action: handelFullPageObjective,
        },
        {
          title: "Duplicate objective",
          icon: <CopyOutlined />,
          action: handelDuplicatedObjective,
        },
        {
          title: "Add key result",
          icon: <DashboardOutlined />,
          action: handelAddKeyResult,
        },
        {
          title: "Alignment Objective",
          action: makeObjectiveAlignment,
          icon: <VerticalAlignTopOutlined />,
          hidden: objective.okrType !== "OBJECTIVE",
        },
        {
          title: "Move objective",
          icon: <NodeExpandOutlined />,
          action: handelMoveObjective,
        },
        { title: "", divider: true },
        { title: "Close objective", action: handelCloseObjective },
        {
          title: "Make objective as template",
          action: handelMakeTemplateObjective,
        },
      ]}
    />
  );
};
