import { values } from "lodash";
import { ICadence } from "../common/types";

interface ISelectOptionMap {
  [key: string]: ICadence;
}
export const cadenceTypes: ISelectOptionMap = {
  WEEKLY: {
    label: "Weekly",
    value: "WEEKLY",
    timePeriod: { value: 1, name: "week" },
    numberOfRepeats: 52,
    alias: "[Week] W, YYYY",
  },
  BIWEEKLY: {
    label: "Biweekly",
    value: "BIWEEKLY",
    timePeriod: { value: 2, name: "week" },
    numberOfRepeats: 26,
    alias: "[Week] W, YYYY",
  },
  TRIWEEKLY: {
    label: "Triweekly",
    value: "TRIWEEKLY",
    timePeriod: { value: 3, name: "week" },
    numberOfRepeats: 18,
    alias: "[Week] W, YYYY",
  },
  MONTHLY: {
    label: "Monthly",
    value: "MONTHLY",
    timePeriod: { value: 1, name: "month" },
    numberOfRepeats: 36,
    alias: "MMM, YYYY",
  },
  BIMONTHLY: {
    label: "Bimonthly",
    value: "BIMONTHLY",
    timePeriod: { value: 2, name: "month" },
    numberOfRepeats: 18,
    alias: "MMM, YYYY",
  },
  TRIMESTER: {
    label: "Trimester",
    value: "TRIMESTER",
    timePeriod: { value: 3, name: "month" },
    numberOfRepeats: 12,
    alias: "MMM, YYYY",
  },
  QUARTER: {
    label: "Quarter",
    value: "QUARTER",
    timePeriod: { value: 4, name: "month" },
    numberOfRepeats: 9,
    alias: "[Q]Q, YYYY",
  },
  SEMIANNUAL: {
    label: "Semiannual",
    value: "SEMIANNUAL",
    timePeriod: { value: 6, name: "month" },
    numberOfRepeats: 10,
    alias: "MMM, YYYY",
  },
  ANNUAL: {
    label: "Annual",
    value: "ANNUAL",
    timePeriod: { value: 1, name: "year" },
    numberOfRepeats: 5,
    alias: "YYYY",
  },
  TWO_YEARS: {
    label: "Two Years",
    value: "TWO_YEARS",
    timePeriod: { value: 2, name: "year" },
    numberOfRepeats: 5,
    alias: "YYYY",
  },
  THREE_YEARS: {
    label: "Three Years",
    value: "THREE_YEARS",
    timePeriod: { value: 5, name: "year" },
    numberOfRepeats: 5,
    alias: "YYYY",
  },
  FIVE_YEARS: {
    label: "Five Years",
    value: "FIVE_YEARS",
    timePeriod: { value: 5, name: "year" },
    numberOfRepeats: 3,
    alias: "YYYY",
  },
};

export const cadenceTypesOptions = values(cadenceTypes);
