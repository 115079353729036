/* eslint-disable no-console */
import { find } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams, useLocation } from "react-router-dom";
import { ICycle } from "../../App/common/types";
import {
  fetchCompanyOkrsFromFirestore,
  listenToCompanyCycleFromFirestore,
} from "../../App/firestore/firestoreService";
import { userFirestoreDoc } from "../../App/hooks/useFirestoreDoc";
import { useFirestoreCollection } from "../../App/hooks/useFirestoreCollection";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";
import { Tag } from "../../App/components/atoms/Tag/Tag";
import {
  listenToSelectedCompanyCycle,
  listenToSelectedCycleOkrs,
} from "../../App/redux/data/companyCycle/companyCycleActions";

const currentMenu = (cycleId: string) => [
  {
    key: "details",
    label: "Details",
    link: `/cycle/${cycleId}/details`,
  },
  {
    key: "OKRs",
    label: "OKRs",
    link: `/cycle/${cycleId}/okrs`,
  },
];
const currentBreadcrumb = (cycle: ICycle, name: string) =>
  [
    {
      key: "cycles",
      label: "Cycles",
      link: "/cycles/list",
    },
    {
      key: "cycle",
      label: cycle?.name,
      link: `/cycle/${cycle?.id}/details`,
    },
    {
      key: "page",
      label: name,
      link: "#",
    },
  ].filter((v) => !!v);
const CycleViewPage: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();

  const cycleId = params.cycleId || "";
  const [breadcrumb, setBreadcrumb] = React.useState<any[]>([]);
  const headerMenu = currentMenu(cycleId);
  const { selectedCycle } = useSelector((state: any) => state.cycles);
  userFirestoreDoc({
    query: () => listenToCompanyCycleFromFirestore(cycleId),
    data: listenToSelectedCompanyCycle,
    deps: [dispatch, cycleId],
  });
  useFirestoreCollection({
    query: () =>
      fetchCompanyOkrsFromFirestore([
        // {
        //   type: "filter",
        //   name: "cycleId",
        //   opr: "==",
        //   value: cycleId,
        // },
      ]),
    data: listenToSelectedCycleOkrs,
    deps: [dispatch],
  });
  React.useEffect(() => {
    const name = find(headerMenu, ["link", location.pathname]);
    setBreadcrumb(currentBreadcrumb(selectedCycle, name?.label || "") || []);
  }, [selectedCycle, location.pathname]);
  return (
    <AdminPage
      title={
        <div className="admin-page-title">
          <Tag>Cycle</Tag> {selectedCycle?.name || ""}
        </div>
      }
      headerMenu={headerMenu}
      breadcrumb={breadcrumb}
    >
      <Outlet />
    </AdminPage>
  );
};

export default CycleViewPage;
