import styled from "styled-components";

// theme is now fully typed
export const EditableFieldWrapper = styled.div`
  position: relative;
  width: 100%;
  .editable-field {
    &-item {
      margin-bottom: 10px;
      position: relative;
    }
    &-simple-textarea,
    &-simple-text,
    &-simple-number {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      background-color: #fff;
      border-width: 1px;
      border-style: solid;
      border-color: #cfcbcb;
      border-radius: 6px;
      padding: 0 12px;
      box-sizing: border-box;
      background-color: inherit;
      line-height: 22px;
      min-height: 70px;
      overflow-wrap: break-word;
      padding-bottom: 6px;
      padding-top: 6px;
      resize: none;
      white-space: pre-wrap;
      border-color: transparent;
      width: calc(100% + 14px);
      margin-left: -14px;
      &:hover {
        border-color: #afabac;
        transition: border-color 100ms;
      }
      &:focus {
        border-color: #6d6e6f;
        outline: none;
        box-shadow: none;
      }
    }
    &-simple-text {
      min-height: auto;
    }
    &-simple-number {
      min-height: 32px;
      padding: 0;
      .ant-input-number-handler-wrap {
        border-radius: 0 6px 6px 0;
      }
    }
    &-title-input {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      max-width: none;
      overflow: hidden;
      min-height: 0;
    }
    &-selected-input {
      width: auto;
      margin-left: -14px;
      border-radius: 6px;
      &:hover {
        background-color: rgb(55 23 23 / 3%);
        transition: background-color 100ms;
      }
      .ant-select-selection-item {
        display: flex;
        align-items: center;
      }
    }
    &-dateRangePicker {
      width: auto;
      margin-left: -14px;
      border-radius: 6px;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      background-color: #fff;
      border-width: 1px;
      border-style: solid;
      border-color: transparent;
      border-radius: 6px;
      padding: 5px 12px;
      box-sizing: border-box;
      background-color: inherit;
      overflow-wrap: break-word;
      resize: none;
      white-space: pre-wrap;
      &:hover {
        background-color: rgb(55 23 23 / 3%);
        transition: background-color 100ms;
      }
      &:focus {
        border-color: #6d6e6f;
        outline: none;
        box-shadow: none;
      }
    }
    &-mutable-users-selected {
      &-view {
        width: auto;
        border-radius: 6px;
        padding: 5px 14px;
        margin-left: -14px;
        cursor: pointer;
        &:hover {
          background-color: rgb(55 23 23 / 3%);
          transition: background-color 100ms;
        }
      }
      &-input {
        border-width: 1px;
        border-style: solid;
        border-color: transparent;
        border-radius: 6px;
        margin-left: -14px;
        padding: 5px 12px;
        &:hover,
        &:focus {
          .ant-select-selector {
            border-color: #6d6e6f;
            border-radius: 6px;
            box-shadow: none;
          }
        }
        .ant-select-selection-item {
          height: 32px;
          margin-top: 2px;
          padding: 0 12px;
          margin-bottom: 4px;
          line-height: 32px;
          background: #edeae9;
          border: 1px solid #edeae9;
          border-radius: 6px;
          display: flex;
          align-items: center;
        }
        .ant-select-selector {
          border: 1px solid #edeae9;
          box-shadow: none;
          &:focus {
            border-color: #6d6e6f;
            outline: none;
            box-shadow: none;
          }
        }
      }
    }
  }
`;
