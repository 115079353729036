import styled from "styled-components";
export const AlignmentViewWrapper = styled.div`
  margin-right: 20px;
  position: relative;
  width: 250px;
  left: 0;
  padding: 0;
  display: flex;
  transition: margin-left 250ms ease-out, transform 250ms ease-out;
  /* &:after {
    position: absolute;
    content: "";
    bottom: 50%;
    right: -8px;
    width: 15px;
    height: 40px;
    border-radius: 6px;
    background-color: #eceae9;
    cursor: pointer;
    &:hover {
      background-color: dark(#eceae9, 30);
    }
  } */
  &.alignment-view-hidden {
    margin-left: -250px;
  }
  .alignment-view {
    &-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      background-color: #fff;
      padding: 16px 20px;
    }
    &-border {
      background-color: #eceae9;
      width: 1px;
      height: 100%;
      display: flex;
      align-items: center;
      .anticon {
        width: 20px;
        height: 60px;
        border-radius: 0 6px 6px 0;
        background-color: #eceae9;
        font-size: 12px;
        padding: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        &:hover {
          background-color: #bababa;
          color: #f5f4f3;
        }
      }
    }
    &-header {
      display: flex;
      justify-content: space-between;
      /* border-bottom: 1px solid #edeae9; */
      align-items: center;
      line-height: 24px;
    }
    &-item {
      border: 1px solid #edeae9;
      border-radius: 8px;
      width: 100%;
      padding: 5px 10px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      font-size: 12px;
      span {
        text-align: center;
      }
    }
    &-alignment {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &-line {
      width: 1px;
      height: 30px;
      background: #edeae9;
      position: relative;
      &-top {
        /* margin-right: 100px; */
        &:after {
          position: absolute;
          content: "";
          top: 0px;
          right: -4px;
          width: 0;
          height: 0;
          border-right: 5px solid transparent;
          border-bottom: 8px solid #eceae9;
          border-left: 5px solid transparent;
        }
      }
      &-bottom {
        &:after {
          position: absolute;
          content: "";
          bottom: 0px;
          right: -4px;
          width: 0;
          height: 0;
          border-right: 5px solid transparent;
          border-top: 8px solid #eceae9;
          border-left: 5px solid transparent;
        }
      }
    }
    &-icon {
      font-size: 32px;
      color: #d55f8e;
      margin-bottom: 6px;
    }
    &-title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 16px;
      padding-bottom: 8px;
      border-bottom: 1px solid #edeae9;
      width: 100%;
    }
    &-Actions {
    }
    &-body {
      padding: 20px;
    }
    &-collapse {
      border: none;
      .ant-collapse {
        &-header {
          background-color: #fff;
          border-bottom: 1px solid #edeae9;
        }
        &-content {
          border: none;
          &-box {
            padding: 0;
          }
        }
      }
    }
    &-panel {
      border: none;
    }
  }
`;
