import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import companyReducer from "../data/company/companyReducer";
import companyBoardReducer from "../data/companyBoard/companyBoardReducer";
import companyCheckInReducer from "../data/companyCheckIn/companyCheckInReducer";
import companyDepartmentReducer from "../data/companyDepartment/companyDepartmentReducer";
import companyInitiativeReducer from "../data/companyInitiative/companyInitiativeReducer";
import companyOkrReducer from "../data/companyOKR/companyOKRReducer";
import companyCycleReducer from "../data/companyCycle/companyCycleReducer";
import companyUserReducer from "../data/companyUser/companyUserReducer";
import companyTeamReducer from "../data/companyTeam/companyTeamReducer";
import userReducer from "../data/user/userReducer";
import asyncReducer from "../data/async/asyncReducer";
import modalReducer from "../data/modals/modalReducer";
import drawerReducer from "../data/drawer/drawerReducer";
import authReducer from "../data/auth/authReducer";
import myProfileReducer from "../data/myProfile/myProfileReducer";
const rootReducer = (history: any) =>
  combineReducers({
    companies: companyReducer,
    boards: companyBoardReducer,
    checkIns: companyCheckInReducer,
    departments: companyDepartmentReducer,
    initiatives: companyInitiativeReducer,
    okrs: companyOkrReducer,
    cycles: companyCycleReducer,
    users: companyUserReducer,
    teams: companyTeamReducer,
    adminUsers: userReducer,
    async: asyncReducer,
    modals: modalReducer,
    drawer: drawerReducer,
    auth: authReducer,
    myProfile: myProfileReducer,
    router: connectRouter(history),
  });

export default rootReducer;
