/* eslint-disable no-console */
import React from "react";
import { AuthWrapper } from "./Auth.style";

interface ISignOutProps {
  name?: string;
}
export const SignOut: React.FC<ISignOutProps> = ({ name }) => {
  console.log(name);
  return <AuthWrapper>SignOut</AuthWrapper>;
};
