/* eslint-disable no-console */
import React from "react";
import { SignOut } from "../../App/components/organisms";

const SignInPage: React.FC = () => (
  <div>
    <SignOut />
  </div>
);

export default SignInPage;
