/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { find } from "lodash";
import {
  CycleSelectField,
  SelectField,
  UserSelectedField,
  TeamSelectField,
} from "../../molecules";
import { IOkrObjectiveInput, IOkrForTypes } from "../../../common/types";
import { Alert, Button, Divider, Form, Input, Space } from "../../atoms";
import { addCompanyOkrToFirestore } from "../../../firestore/firestoreService";
import {
  objectiveVisibilityOptions,
  okrsForTypesOptions,
  objectivePriorityTypesOptions,
} from "../../../enums/commonEnums";
import { ObjectiveSelectField } from "../commonFields";
import { ObjectiveProgressIndicator } from "../ObjectiveProgressIndicator/ObjectiveProgressIndicator";

interface IAddObjectiveFormProps {
  boardId?: string;
  callback: (objectiveId: string) => void;
  cycleId?: string;
  teamId?: string;
  okrFor?: IOkrForTypes;
}
export const AddObjectiveForm: React.FC<IAddObjectiveFormProps> = ({
  boardId,
  callback,
  cycleId,
  teamId,
  okrFor,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const { users } = useSelector((state: any) => state.users);
  const handleOnChangeProgressRange = (value: [number, number]) => {
    form.setFieldsValue({ progressRange: value });
  };
  const handleSubmit = async () => {
    try {
      const values: IOkrObjectiveInput = await form.validateFields();
      setLoading(true);
      const owner = find(users, ["id", values.ownerId]);
      const objective = await addCompanyOkrToFirestore({
        okrType: "OBJECTIVE",
        status: true,
        title: values.title,
        owner,
        ownerId: values.ownerId,
        description: values.description || "",
        priority: values.priority || "",
        progressRange: values.progressRange || [30, 70],
        cycleId: values.cycleId,
        visibility: values.visibility,
        okrFor: values.okrFor || "INDIVIDUAL_OBJECTIVE",
        boardId: boardId || values.boardId || null,
        teamId: values.teamId || null,
        okrParentId: null,
        okrParentIds: [],
      });
      setLoading(false);
      callback(objective.id);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  return (
    <>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <Form
        form={form}
        name="horizontal_login"
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          name="title"
          label="Objective Name"
          rules={[{ required: true, message: "Missing Name" }]}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input.TextArea placeholder="Description" />
        </Form.Item>
        <UserSelectedField
          label="Who owns this objective?"
          name="ownerId"
          rules={[{ required: true, message: "Missing Owner" }]}
          boardId={boardId}
        />
        <Space className="input-form-space">
          <SelectField
            label="Type"
            name="okrFor"
            initialValue={okrFor || ""}
            options={okrsForTypesOptions}
            rules={[{ required: true, message: "Missing Type" }]}
          />
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.type !== currentValues.type
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("okrFor") === "TEAM_OBJECTIVE" ? (
                <TeamSelectField
                  label="Team"
                  name="teamId"
                  initialValue={teamId}
                  rules={[{ required: true, message: "Missing Team" }]}
                />
              ) : null
            }
          </Form.Item>
        </Space>
        <Space className="input-form-space">
          <CycleSelectField
            label="Cycle"
            name="cycleId"
            boardId={boardId}
            initialValue={cycleId}
            rules={[{ required: true, message: "Missing Cycle" }]}
          />
          <SelectField
            label="Visibility"
            name="visibility"
            initialValue="PRIVATE_OBJECTIVE"
            options={objectiveVisibilityOptions}
            rules={[{ required: true, message: "Missing Visibility" }]}
          />
        </Space>
        <Space className="input-form-space">
          <SelectField
            label="Priority"
            name="priority"
            initialValue="NORMAL"
            options={objectivePriorityTypesOptions}
            rules={[{ required: true, message: "Missing priority" }]}
          />
          <ObjectiveSelectField label="Alignment" name="alignment" />
        </Space>
        <Form.Item
          name="progressRange"
          label="Objective progress range %"
          tooltip={
            <div>
              <div>Difficultly</div>
              <div>What is the difficultly of this objective</div>
            </div>
          }
        >
          <ObjectiveProgressIndicator onUpdate={handleOnChangeProgressRange} />
        </Form.Item>
        <Divider></Divider>
        <Button
          type="primary"
          className="input-form-btn"
          onClick={handleSubmit}
          loading={loading}
        >
          Create Objective
        </Button>
      </Form>
    </>
  );
};
