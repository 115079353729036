/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { notification } from "antd";
import { Link } from "react-router-dom";
import { MenuUnfoldOutlined, RocketOutlined } from "@ant-design/icons";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { LeftMenu } from "../../molecules";
import { signOutFirebase } from "../../../firestore/firebaseService";
import { Avatar, Menu } from "../../atoms";
import { IMenuOption } from "../../../common/types";
import { HeaderWrapper } from "./Header.style";

interface IHeaderProps {
  isMobile?: boolean;
  setShowMenu?: (show: boolean) => void;
  showMenu?: boolean;
  className?: string;
  title: string | React.ReactElement;
  headerMenu?: IMenuOption[];
  hideMenuOnClick: () => void;
  hideLeftMenu: boolean;
}
export const Header: React.FC<IHeaderProps> = ({
  isMobile,
  setShowMenu,
  showMenu,
  className,
  title,
  headerMenu,
  hideLeftMenu,
  hideMenuOnClick,
}) => {
  const { authenticated } = useSelector((state: any) => state.auth);
  const { selectedCompany } = useSelector((state: any) => state.companies);
  const { myProfile } = useSelector((state: any) => state.myProfile);
  const [current, setCurrent] = React.useState("mail");
  const dispatch = useDispatch();
  // const history = useNavigate();
  const handleLogin = () => {
    dispatch(
      openModal({ modalType: "LoginForm", modalProps: { title: "Login" } })
    );
    // setAuthenticated(true);
    // history.push("/admin/");
  };
  const handleLogout = async () => {
    try {
      await signOutFirebase();
      // history("/");
    } catch (e) {
      notification.error({
        message: "Log out failed",
        description: "Log out failed",
      });
    }
  };

  const handleClick = (e: any) => {
    console.log("click ", e);
    setCurrent(e?.key || "");
  };
  return (
    <HeaderWrapper>
      {hideLeftMenu && (
        <MenuUnfoldOutlined
          onClick={hideMenuOnClick}
          className="header-burger-icon"
        />
      )}
      <Avatar
        size={48}
        shape="square"
        style={{
          background: "#d55f8e",
          marginRight: "16px",
          borderRadius: "10px",
        }}
      >
        <RocketOutlined />
      </Avatar>
      <div className="header-page-header">
        <div
          className="header-page-header-title"
          style={{ marginTop: headerMenu ? 10 : 0 }}
        >
          {title}
        </div>
        {headerMenu && (
          <Menu
            onClick={handleClick}
            selectedKeys={[current]}
            mode="horizontal"
          >
            {headerMenu.map((item: IMenuOption) => (
              <Menu.Item key={item.key}>
                <Link to={item.link}>{item.label}</Link>
              </Menu.Item>
            ))}
          </Menu>
        )}
      </div>
      <LeftMenu
        authenticated={authenticated}
        login={handleLogin}
        logout={handleLogout}
        currentUser={myProfile}
        defaultCycleId={myProfile?.defaultCycleId}
        defaultCompanyCycleId={selectedCompany?.defaultCycleId || ""}
      />
    </HeaderWrapper>
  );
};
