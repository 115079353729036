/* eslint-disable no-console */
import { ICheckInInput, IQueryConfigArgs, ICheckIn } from "../../common/types";
import { setUserAddLog, setUserUpdateLog } from "../helper";
import { checkInDataFormat } from "./../firestoreDataFormat";
import { db, collectionWithArgs } from "./firestoreService";
import {
  COLLECTION_COMPANIES,
  COLLECTION_CHECK_INS,
} from "./firebaseConstants";

export function fetchCompanyCheckInsFromFirestore(args?: IQueryConfigArgs[]) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  const ref = db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_CHECK_INS);
  return collectionWithArgs(ref, args || []);
}
export function listenToCompanyCheckInFromFirestore(id: string) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_CHECK_INS)
    .doc(id);
}

export async function addCompanyCheckInToFirestore(doc: ICheckInInput) {
  // const user = firebase.auth().currentUser;
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_CHECK_INS)
    .add({
      ...checkInDataFormat(doc),
      ...setUserAddLog(),
    });
}

export function updateCompanyCheckInInFirestore(docId: string, doc: ICheckIn) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_CHECK_INS)
    .doc(docId)
    .update({
      ...checkInDataFormat(doc),
      ...setUserUpdateLog(),
    });
}

export function deleteCompanyCheckInInFirestore(docId: string) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_CHECK_INS)
    .doc(docId)
    .delete();
}
export function activeToggleCompanyCheckInInFirestore(
  docId: string,
  status: boolean
) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_CHECK_INS)
    .doc(docId)
    .update({
      status,
    });
}

// export async function addCompanyCheckInToFirestore(doc: ICheckInInput) {
//   // const user = firebase.auth().currentUser;
//   const companyId = localStorage.getItem("defaultCompanyKey") || "";
//   try {
//     let total = 0;
//     const milestones = doc.keyResult.milestones || [];
//     const batch = db.batch();
//     if (doc.keyResult.type === "NUMBER") {
//       const score = (+doc.value * 100) / +doc.keyResult.targetNumber;
//       total = +(doc.keyResult?.score || 0) + score;
//     }
//     if (doc.keyResult.type === "MILESTONE") {
//       const index = findIndex(milestones, ["id", doc.value]);
//       if (index !== -1) {
//         total = +(doc.keyResult?.score || 0) + +milestones[index].weight;
//         milestones[index].checkIn = true;
//       }
//     }
//     const docRef = await db
//       .collection(COLLECTION_COMPANIES)
//       .doc(companyId)
//       .collection(COLLECTION_CHECK_INS)
//       .add({});
//     const keyResultRef = db
//       .collection(COLLECTION_COMPANIES)
//       .doc(companyId)
//       .collection(COLLECTION_KEY_RESULTS)
//       .doc(doc.keyResultId);

//     batch.set(docRef, doc);
//     if (doc.keyResult.type === "MILESTONE") {
//       batch.update(keyResultRef, { score: total, milestones });
//     } else {
//       batch.update(keyResultRef, { score: total });
//     }

//     return await batch.commit();
//   } catch (err) {
//     throw err;
//   }
// }
