/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { Tag } from "../../App/components/atoms";
import { listenToCompanyInitiativeFromFirestore } from "../../App/firestore/firestoreService";
import { userFirestoreDoc } from "../../App/hooks/useFirestoreDoc";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";
import { listenToSelectedCompanyInitiative } from "../../App/redux/data/companyInitiative/companyInitiativeActions";

const currentMenu = (initiativeId: string) => [
  {
    key: "details",
    label: "Details",
    link: `/initiative/${initiativeId}/details`,
  },
];
const InitiativeViewPage: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const initiativeId = params.initiativeId || "";
  const headerMenu = currentMenu(initiativeId);
  const { selectedInitiative } = useSelector((state: any) => state.initiatives);
  userFirestoreDoc({
    query: () => listenToCompanyInitiativeFromFirestore(initiativeId),
    data: listenToSelectedCompanyInitiative,
    deps: [dispatch, initiativeId],
  });
  return (
    <AdminPage
      title={
        <div className="admin-page-title">
          <Tag>Initiative</Tag> {selectedInitiative?.name || ""}
        </div>
      }
      headerMenu={headerMenu}
    >
      <Outlet />
    </AdminPage>
  );
};

export default InitiativeViewPage;
