import styled from "styled-components";
export const SectionCardWrapper = styled.div`
  /* margin-top: 50px; */
  .section-card {
    &-header {
      display: flex;
      justify-content: space-between;
      /* border-bottom: 1px solid #edeae9; */
      align-items: center;
      line-height: 24px;
      &-btn,
      &-btn-link,
      &-btn-group {
        font-size: 12px;
        height: 28px;
        line-height: 26px;
        padding: 0 8px;
        border-radius: 6px;
        user-select: none;
      }
      &-btn-link {
        font-weight: 500;
        color: #6d6e6f;
        align-items: center;
        display: flex;
        &:hover,
        &:active {
          background: rgb(55 23 23 / 3%);
          color: #1e1f21;
        }
      }
    }
    &-title {
      font-size: 16px;
      font-weight: 500;
    }
    &-Actions {
    }
    &-body {
      padding: 0px 24px 24px;
    }
    &-collapse {
      border: none;
      &.grey {
        .ant-collapse-header {
          background-color: #f9f8f8;
          padding: 8px 24px;
        }
      }
      .ant-collapse {
        &-header {
          background-color: #fff;
          border-bottom: 1px solid #edeae9;
          display: flex;
          align-items: center;
        }
        &-content {
          border: none;
          &-box {
            padding: 0;
          }
        }
      }
    }
    &-tree {
      .ant-tree {
        &-treenode {
          width: 100%;
          padding: 0;
        }
        &-node-content-wrapper {
          width: 100%;
        }
        &-switcher {
          display: flex;
          align-items: center;
        }
      }
    }
    &-panel {
      border: none;
    }
  }
`;
