/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { Slider } from "antd";
import { InputNumberField, SelectField, TooltipField } from "../../molecules";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import {
  IBoard,
  ICheckInInput,
  ICycle,
  IDateOption,
  IOkr,
} from "../../../common/types";
import { Alert, Button, Divider, Form, InputFormWrapper } from "../../atoms";
import {
  addCompanyCheckInToFirestore,
  listenToCompanyCycleFromFirestore,
} from "../../../firestore/firestoreService";
import { closeModal } from "../../../redux/data/modals/modalReducer";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
interface IAddCheckInFormProps {
  boardId: string;
  objective: IOkr;
  objectiveId: string;
  board: IBoard;
  keyResult: IOkr;
  keyResultId: string;
  cycleId: string;
}
export const AddCheckInForm: React.FC<IAddCheckInFormProps> = (props) => {
  const dispatch = useDispatch();
  const [checkInDates, setCheckInDates] = React.useState<IDateOption[]>([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [cycle, setCycle] = React.useState<ICycle>();

  userFirestoreDoc({
    query: () => listenToCompanyCycleFromFirestore(props.cycleId),
    data: setCycle,
    deps: [props.cycleId],
    shouldExecute: !!props.cycleId,
    local: true,
  });

  const handleSubmit = async () => {
    try {
      const values: ICheckInInput = await form.validateFields();
      setLoading(true);
      await addCompanyCheckInToFirestore({
        confidenceLevel: values.confidenceLevel || 0,
        keyResultId: props.keyResultId,
        objectiveId: props.objectiveId,
        value: values.value,
        checkInDate: values.checkInDate || "",
        cycleId: props.cycleId,
      });
      setLoading(false);
      dispatch(closeModal());
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  React.useEffect(() => {
    const { checkInRequestDates } = props.keyResult;
    if (checkInRequestDates) {
      setCheckInDates(checkInRequestDates);
    }
  }, [props.keyResult, cycle]);

  return (
    <ModalWrapper {...props} footer={null}>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <InputFormWrapper>
        <div className="input-form-body">
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
          >
            {props.keyResult.keyResultType === "NUMBER" && (
              <>
                <InputNumberField
                  label="CheckIn value"
                  name="value"
                  rules={[
                    { required: true, message: "Missing value" },
                    { type: "number", message: "Value should be Number" },
                  ]}
                  placeholder="CheckIn value"
                  style={{ width: "100%" }}
                />
                <SelectField
                  label="CheckIn Date"
                  name="checkInDate"
                  rules={[{ required: true, message: "Missing CheckIn Date" }]}
                  placeholder="CheckIn Date"
                  options={checkInDates.map((checkInDate) => ({
                    value: checkInDate.id,
                    label: `${checkInDate.label} ${
                      checkInDate.isPast ? "(Past)" : ""
                    } `,
                  }))}
                  style={{ width: "100%" }}
                />
              </>
            )}
            {props.keyResult.keyResultType === "MILESTONE" && (
              <SelectField
                label="CheckIn value"
                name="value"
                rules={[{ required: true, message: "Missing value" }]}
                placeholder="CheckIn value"
                options={props.keyResult.milestones.map((milestone) => ({
                  value: milestone.id,
                  label: milestone.name,
                }))}
                style={{ width: "100%" }}
              />
            )}
            <Form.Item
              name="confidenceLevel"
              label="Confidence level"
              tooltip={
                <TooltipField
                  title="Confidence Level"
                  description="What is your confidence Level "
                />
              }
              initialValue={100}
            >
              <Slider
                className="input-form-slider"
                tipFormatter={(v) => `${v}%`}
                marks={{
                  0: "0%",
                  25: "25%",
                  50: "50%",
                  75: "75%",
                  100: "100%",
                }}
              />
            </Form.Item>
            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              CheckIn
            </Button>
          </Form>
        </div>
      </InputFormWrapper>
    </ModalWrapper>
  );
};
