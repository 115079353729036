/* eslint-disable no-console */
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import React from "react";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { InputFormWrapper } from "../../atoms";
import { CheckboxGroup } from "../../molecules";
import { CompanyWrapper } from "./Company.style";

const permissionsOptions = [
  {
    label: "Administrators",
    value: "ADMINISTRATORS",
  },
  {
    label: "Managers",
    value: "MANAGERS",
  },
  {
    label: "Everyone",
    value: "EVERYONE",
  },
  {
    label: "No need for approval",
    value: "NO_NEED_APPROVAL",
  },
  {
    label: "Allow changes to OKRs after approval",
    value: "ALLOW_CHANGES_AFTER_APPROVAL",
  },
];
export const CompanyPermissions: React.FC = () => {
  const [createBoards, setCreateBoards] = React.useState<CheckboxValueType[]>(
    []
  );
  const [approveBoards, setApproveBoards] = React.useState<CheckboxValueType[]>(
    []
  );
  const [createOrganizationOKRs, setCreateOrganizationOKRs] = React.useState<
    CheckboxValueType[]
  >([]);
  const [approveOrganizationOKRs, setApproveOrganizationOKRs] = React.useState<
    CheckboxValueType[]
  >([]);
  const [createOKRs, setCreateOKRs] = React.useState<CheckboxValueType[]>([]);
  const [approveOKRs, setApproveOKRs] = React.useState<CheckboxValueType[]>([]);
  const [invite, setInvite] = React.useState<CheckboxValueType[]>([]);
  const [cycles, setCycles] = React.useState<CheckboxValueType[]>([]);
  const handelCreateBoardsOnChange = (value: CheckboxValueType[]) => {
    setCreateBoards(value);
  };
  const handelApproveBoardsOnChange = (value: CheckboxValueType[]) => {
    setApproveBoards(value);
  };
  const handelCreateOrganizationOKRsOnChange = (value: CheckboxValueType[]) => {
    setCreateOrganizationOKRs(value);
  };
  const handelApproveOrganizationOKRsOnChange = (
    value: CheckboxValueType[]
  ) => {
    setApproveOrganizationOKRs(value);
  };
  const handelCreateOKRsOnChange = (value: CheckboxValueType[]) => {
    setCreateOKRs(value);
  };
  const handelApproveOKRsOnChange = (value: CheckboxValueType[]) => {
    setApproveOKRs(value);
  };
  const handelInviteOnChange = (value: CheckboxValueType[]) => {
    setInvite(value);
  };
  const handelCyclesOnChange = (value: CheckboxValueType[]) => {
    setCycles(value);
  };
  return (
    <AdminPageBody>
      <InputFormWrapper>
        <div className="input-form-body">
          <CompanyWrapper>
            <div className="company-permissions-title">OKRs management</div>
            <div className="company-permissions">
              <CheckboxGroup
                plainOptions={permissionsOptions}
                defaultCheckedList={createBoards}
                onChange={handelCreateBoardsOnChange}
                checkAllName={"Who can create Boards?"}
              />
              <CheckboxGroup
                plainOptions={permissionsOptions}
                defaultCheckedList={approveBoards}
                onChange={handelApproveBoardsOnChange}
                checkAllName={"Who can approve Boards?"}
              />
            </div>
            <div className="company-permissions-title">
              Organization wide OKRs
            </div>
            <div className="company-permissions">
              <CheckboxGroup
                plainOptions={permissionsOptions}
                defaultCheckedList={createOrganizationOKRs}
                onChange={handelCreateOrganizationOKRsOnChange}
                checkAllName={"Who can create Organization OKRs?"}
              />
              <CheckboxGroup
                plainOptions={permissionsOptions}
                defaultCheckedList={approveOrganizationOKRs}
                onChange={handelApproveOrganizationOKRsOnChange}
                checkAllName={"Who can approve Organization OKRs?"}
              />
            </div>
            <div className="company-permissions-title">Other OKRs</div>
            <div className="company-permissions">
              <CheckboxGroup
                plainOptions={permissionsOptions}
                defaultCheckedList={createOKRs}
                onChange={handelCreateOKRsOnChange}
                checkAllName={"Who can create OKRs?"}
              />
              <CheckboxGroup
                plainOptions={permissionsOptions}
                defaultCheckedList={approveOKRs}
                onChange={handelApproveOKRsOnChange}
                checkAllName={"Who can approve OKRs?"}
              />
            </div>
            <div className="company-permissions-title">Other settings</div>
            <div className="company-permissions">
              <CheckboxGroup
                plainOptions={permissionsOptions}
                defaultCheckedList={invite}
                onChange={handelInviteOnChange}
                checkAllName={
                  "Who can invite people to join your organization?"
                }
              />
              <CheckboxGroup
                plainOptions={permissionsOptions}
                defaultCheckedList={cycles}
                onChange={handelCyclesOnChange}
                checkAllName={"Who can create Cycles?"}
              />
            </div>
          </CompanyWrapper>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
