/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { DepartmentList } from "../../molecules";
import { CompanyWrapper } from "./Company.style";
import { CompanyDepartmentListToolbar } from "./CompanyDepartmentListToolbar";

export const CompanyDepartmentList: React.FC = () => {
  const { selectedCompanyDepartments } = useSelector(
    (state: any) => state.companies
  );
  const { loading } = useSelector((state: any) => state.async);

  return (
    <AdminPageBody pageToolbar={<CompanyDepartmentListToolbar />}>
      <CompanyWrapper>
        <DepartmentList
          departments={selectedCompanyDepartments}
          loading={loading}
        />
      </CompanyWrapper>
    </AdminPageBody>
  );
};
