/* eslint-disable no-console */
import { useEffect } from "react";
import { groupBy } from "lodash";
import { ICycle, IOkr } from "../common/types";
interface IUseCycleListObjectivesProps {
  objectives: IOkr[];
  cycles: ICycle[];
  deps: any[];
  data: (data: ICycle[]) => void;
}
export const useCycleListObjectives = ({
  objectives,
  cycles,
  deps,
  data,
}: IUseCycleListObjectivesProps) => {
  useEffect(() => {
    const objectivesByCycleList = groupBy(objectives, "cycleId");
    const cycleList = cycles.map((cycle) => ({
      ...cycle,
      objectives: objectivesByCycleList[cycle.id] || [],
    }));
    data(cycleList);
    return () => {
      data([]);
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  }, deps);
};
