/* eslint-disable no-console */
import { DeleteOutlined } from "@ant-design/icons";
import { find } from "lodash";
import moment from "moment";
import React from "react";
import { ICheckIn, IUser } from "../../../common/types";
import { deleteCompanyCheckInInFirestore } from "../../../firestore/firestoreService";
import { Table } from "../../atoms";
import { UserCell, CheckInCell } from "../CommonCells";
import { CheckInWrapper } from "./CheckIn.style";

interface ICheckInListProps {
  checkIns: ICheckIn[];
  loading: boolean;
}
export const CheckInList: React.FC<ICheckInListProps> = ({
  checkIns,
  loading,
}) => {
  const handelDeleteObjective = async (id: string) => {
    await deleteCompanyCheckInInFirestore(id);
  };
  const columnsList = [
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      fixed: true,
      render: (v: string, { keyResult }: any) => {
        if (keyResult.type === "NUMBER") {
          return (
            <CheckInCell
              initialNumber={+keyResult.initialNumber}
              targetNumber={+keyResult.targetNumber}
              actualNumber={+v}
            />
          );
        }
        if (keyResult.type === "MILESTONE") {
          return find(keyResult.milestones, ["id", v])?.name || "-";
        }
      },
      width: 400,
    },
    {
      title: "Owner",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (v: IUser) => <UserCell user={v} avatarOnly />,
    },
    {
      title: "Confidence Level",
      dataIndex: "confidenceLevel",
      key: "confidenceLevel",
      render: (v: number) => `${v}%`,
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (v: moment.Moment) => moment(v).format("DD/MM/YYYY"),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: ICheckIn) => (
        <DeleteOutlined onClick={() => handelDeleteObjective(record.id)} />
      ),
    },
  ];
  return (
    <CheckInWrapper>
      <Table
        columns={columnsList}
        dataSource={checkIns}
        loading={loading}
        rowKey="id"
        // scroll={{ x: 800, y: 500 }}
      />
    </CheckInWrapper>
  );
};
