/* eslint-disable no-console */
import React from "react";
import { IOkrInput, IOkr, IUser, ICycle } from "../../../common/types";
import {
  EditableTitleField,
  EditableFieldForm,
  FieldView,
  EditableDescriptionField,
  EditableSelectedField,
  EditableNumberField,
  EditableUserSelectedField,
} from "../commonFields";
import {
  checkInRequestTypesOptions,
  keyResultTypesOptions,
  unitTypesOptions,
} from "../../../enums";
import { updateCompanyOkrInFirestore } from "../../../firestore/firestoreService";
import { OkrProgressBar } from "../CommonComponents";
import {
  generateKeyResultCheckInDates,
  getKeyResultProgress,
  keyResultCheckInNearestDate,
} from "../../../services/keyResultService";
import { CycleCell } from "../CommonCells/CycleCell";
import { KeyResultCardWrapper } from "./KeyResult.style";

interface IOkrCardProps {
  keyResult: IOkr;
  parent?: IOkrInput;
  cycle: ICycle;
  objective: IOkr;
  score: number;
}
export const KeyResultCard: React.FC<IOkrCardProps> = ({
  keyResult,
  cycle,
  score,
  objective,
}) => {
  const [progress, setProgress] = React.useState("ON_TRACK");
  const handleEdit = async (name: string, value: string | number) => {
    await updateCompanyOkrInFirestore(keyResult.id, {
      [name]: value,
      score: Math.round(score || 0),
    });
  };
  const handleEditUser = async (
    name: string,
    value: { userId: string; user: IUser }
  ) => {
    await updateCompanyOkrInFirestore(keyResult.id, {
      ownerId: value.userId,
      owner: value.user,
    });
  };
  React.useEffect(() => {
    if (keyResult.checkInRequestDates) {
      const nearestDate = keyResultCheckInNearestDate(
        keyResult.checkInRequestDates
      );
      const currentProgress = getKeyResultProgress(
        nearestDate?.target || 0,
        keyResult.targetNumber,
        keyResult.initialNumber,
        score,
        objective.progressRange
      );
      setProgress(currentProgress);
    }
  }, [keyResult.checkInRequestDates, score]);
  React.useEffect(() => {
    const checkInRequest = generateKeyResultCheckInDates({
      startDate: cycle.startDate,
      endDate: cycle.endDate,
      targetNumber: keyResult.targetNumber,
      initialNumber: keyResult.initialNumber,
      calculationMethod: keyResult.calculationMethod,
      checkInRequest: keyResult.checkInRequest,
    });
    const updateCheckInRequestDates = async () => {
      await updateCompanyOkrInFirestore(keyResult.id, {
        checkInRequestDates: checkInRequest,
      });
    };
    if (
      keyResult &&
      JSON.stringify(checkInRequest) !==
        JSON.stringify(keyResult.checkInRequestDates)
    ) {
      updateCheckInRequestDates().catch(console.error);
    }
  }, [
    keyResult.targetNumber,
    keyResult.initialNumber,
    keyResult.calculationMethod,
    keyResult.checkInRequest,
  ]);
  return (
    <KeyResultCardWrapper>
      <EditableFieldForm>
        <div className="key-result-card-header">
          <div className="key-result-card-header-info">
            <div className="key-result-card-title">
              <EditableTitleField
                title={"Title"}
                editable
                dataIndex={"title"}
                record={keyResult?.title}
                handleSave={handleEdit}
              />
            </div>
            <div className="key-result-card-details">
              <div>
                <FieldView label="Owner" width={150}>
                  <EditableUserSelectedField
                    title={"ownerId"}
                    editable
                    dataIndex={"ownerId"}
                    record={keyResult?.ownerId}
                    handleSave={handleEditUser}
                  />
                </FieldView>
                <FieldView label="Objective" width={150}>
                  {objective.title || "--"}
                </FieldView>
                <FieldView label="Cycle" width={150}>
                  <CycleCell cycle={cycle} />
                </FieldView>
              </div>
              {keyResult?.keyResultType === "NUMBER" && (
                <div>
                  {" "}
                  <FieldView label="Unit type" width={150}>
                    <EditableSelectedField
                      title={"unitType"}
                      editable
                      dataIndex={"unitType"}
                      record={keyResult?.unitType}
                      handleSave={handleEdit}
                      options={unitTypesOptions}
                      style={{ width: 200 }}
                    />
                  </FieldView>
                  <FieldView label="Key result type" width={150}>
                    <EditableSelectedField
                      title={"Calculation Method"}
                      editable
                      dataIndex={"calculationMethod"}
                      record={keyResult?.calculationMethod}
                      handleSave={handleEdit}
                      options={keyResultTypesOptions}
                      style={{ width: 200 }}
                    />
                  </FieldView>
                  <FieldView label="Initial number" width={150}>
                    <EditableNumberField
                      title={"initialNumber"}
                      editable
                      dataIndex={"initialNumber"}
                      record={keyResult?.initialNumber}
                      handleSave={handleEdit}
                    />
                  </FieldView>
                  <FieldView label="Target number" width={150}>
                    <EditableNumberField
                      title={"targetNumber"}
                      editable
                      dataIndex={"targetNumber"}
                      record={keyResult?.targetNumber || 0}
                      handleSave={handleEdit}
                    />
                  </FieldView>
                  {/* <FieldView label="Cascading" width={100}>
                    <EditableSelectedField
                      title={"cascading"}
                      editable
                      dataIndex={"cascading"}
                      record={keyResult?.cascading}
                      handleSave={handleEdit}
                      options={[
                        { value: "yes", label: "Yes" },
                        { value: "no", label: "No" },
                      ]}
                      style={{ width: 200 }}
                    />
                  </FieldView> */}
                  <FieldView label="CheckIn Request" width={150}>
                    <EditableSelectedField
                      title={"checkInRequest"}
                      editable
                      dataIndex={"checkInRequest"}
                      record={keyResult?.checkInRequest}
                      handleSave={handleEdit}
                      options={checkInRequestTypesOptions}
                      style={{ width: 200 }}
                    />
                  </FieldView>
                </div>
              )}
            </div>

            <FieldView label="Description" width={150} block>
              <EditableDescriptionField
                title={"description"}
                editable
                dataIndex={"description"}
                record={keyResult?.description}
                handleSave={handleEdit}
                placeholder="Add description to key result"
              />
            </FieldView>
          </div>
          <OkrProgressBar score={score} progress={progress} />
        </div>
      </EditableFieldForm>
    </KeyResultCardWrapper>
  );
};
