import React from "react";
import { UserCell } from "../CommonCells";
import { IBoardInput, IUser } from "../../../common/types";
import { Button, Tag } from "../../atoms";
import { FieldView } from "../commonFields";
import { BoardCardWrapper } from "./Board.style";

interface IBoardCardProps {
  title: string;
  owner: IUser;
  description: string;
  keywords: string[];
  parent?: IBoardInput;
  objectivesTotal: number;
  keyResultsTotal: number;
  initiativesTotal: number;
  cyclesTotal: number;
}
export const BoardCard: React.FC<IBoardCardProps> = ({
  title,
  owner,
  description,
  keywords,
  parent,
  objectivesTotal,
  keyResultsTotal,
  initiativesTotal,
  cyclesTotal,
}) => (
  <BoardCardWrapper>
    <div className="board-card-header">
      <div className="board-card-title">{title || ""}</div>
      <FieldView label="Owner" width={100}>
        <UserCell user={owner} nameOnly />
      </FieldView>
      {parent && <div className="board-card-parent">{parent.title || ""}</div>}
    </div>
    <FieldView label="Segmentation" width={100}>
      <div className="board-card-keywords">
        {keywords.map(
          (keyword: string, index: number) =>
            (
              <Tag className="board-card-keywords-name" key={index}>
                {" "}
                {keyword}{" "}
              </Tag>
            ) || ""
        )}
      </div>
    </FieldView>
    <FieldView label="Description" width={100}>
      {description}
    </FieldView>
    <div className="board-card-keywords"></div>
    <div className="board-card-footer">
      <Button className="board-card-stat">
        <span className="board-card-stat-value">{cyclesTotal || 0}</span>
        <span className="board-card-stat-label">Cycles</span>
      </Button>
      <Button className="board-card-stat">
        <span className="board-card-stat-value">{objectivesTotal || 0}</span>
        <span className="board-card-stat-label">Objectives</span>
      </Button>
      <Button className="board-card-stat">
        <span className="board-card-stat-value">{keyResultsTotal || 0}</span>
        <span className="board-card-stat-label">Key Results</span>
      </Button>
      <Button className="board-card-stat">
        <span className="board-card-stat-value">{initiativesTotal || 0}</span>
        <span className="board-card-stat-label">Initiatives</span>
      </Button>
    </div>
  </BoardCardWrapper>
);
