/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { InputFormWrapper, Tag, Typography } from "../../atoms";
import {
  InputValueView,
  InputValueViewGroup,
  ItemNotFound,
  Loader,
  UserCell,
} from "../../molecules";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { openModal } from "../../../redux/data/modals/modalReducer";
const { Paragraph } = Typography;

export const CompanyDetails: React.FC = () => {
  const dispatch = useDispatch();
  const { selectedCompany } = useSelector((state: any) => state.companies);
  const { loading: dataLoading, error: dataError } = useSelector(
    (state: any) => state.async
  );
  const handelUpdate = (field: string) => () => {
    dispatch(
      openModal({
        modalType: "UpdateCompanyDetailsModal",
        modalProps: {
          title: "Update Company Details",
          editField: field,
          initialValues: selectedCompany,
        },
      })
    );
  };
  if (dataLoading) return <Loader color="success" title="Loading" />;
  if (dataError) {
    return (
      <ItemNotFound
        redirectTo="/boards"
        redirectBtnText="Back to Boards"
        itemType="Board"
        itemId={"11"}
      />
    );
  }
  return (
    <AdminPageBody>
      <InputFormWrapper>
        <div className="input-form-body">
          <InputValueViewGroup title="Company Details">
            <InputValueView
              title="Logo"
              subTitle=""
              value={selectedCompany?.logo}
            />

            <InputValueView
              title="Company Name"
              subTitle="Your Company Name"
              value={selectedCompany?.name}
              onClick={handelUpdate("name")}
            />
            <InputValueView
              title="Industries"
              subTitle="The industries your company current"
              value={selectedCompany?.industries.map((v: any) => (
                <Tag key={v.value}>{v.label}</Tag>
              ))}
              onClick={handelUpdate("industries")}
            />
            {/* <InputValueView
              title="Address"
              subTitle="Company Location"
              value={selectedCompany?.address}
            /> */}
            {/* <InputValueView
              title="Time Zone"
              subTitle="Time Zone"
              value={selectedCompany?.timeZone}
              onClick={handelUpdate("timeZone")}
            /> */}
          </InputValueViewGroup>
          <InputValueViewGroup title="Company Setting">
            <InputValueView
              title="Mission"
              subTitle="Company Mission"
              value={selectedCompany?.mission}
              onClick={handelUpdate("mission")}
            />

            <InputValueView
              title="Mission Keyword"
              subTitle="What keywords describe your mission"
              value={
                selectedCompany?.missionKeywords?.map((v: any) => (
                  <Tag key={v}>{v}</Tag>
                )) || ""
              }
              onClick={handelUpdate("mission")}
            />
            <InputValueView
              title="Strengths keywords"
              subTitle="What keywords describe your strengths?"
              value={
                selectedCompany?.strengthKeywords?.map((v: any) => (
                  <Tag key={v}>{v}</Tag>
                )) || ""
              }
              onClick={handelUpdate("strengthKeywords")}
            />
            <InputValueView
              title="Weaknesses keywords"
              subTitle="What keywords describe your weaknesses?"
              value={
                selectedCompany?.weaknessKeywords?.map((v: any) => (
                  <Tag key={v}>{v}</Tag>
                )) || ""
              }
              onClick={handelUpdate("weaknessKeywords")}
            />
            <InputValueView
              title="opportunities keywords"
              subTitle="What keywords describe your opportunities?"
              value={
                selectedCompany?.opportunityKeywords?.map((v: any) => (
                  <Tag key={v}>{v}</Tag>
                )) || ""
              }
              onClick={handelUpdate("opportunityKeywords")}
            />
            <InputValueView
              title="Threads keywords"
              subTitle="What keywords describe your threads?"
              value={
                selectedCompany?.threadsKeywords?.map((v: any) => (
                  <Tag key={v}>{v}</Tag>
                )) || ""
              }
              onClick={handelUpdate("threadsKeywords")}
            />
          </InputValueViewGroup>
          <InputValueViewGroup title="Company info">
            <InputValueView
              title="Company ID"
              subTitle=""
              value={<Paragraph copyable>{selectedCompany?.id}</Paragraph>}
            />
            <InputValueView
              title="Create by"
              subTitle="The user who created this Company"
              value={<UserCell user={selectedCompany?.owner} />}
            />
            <InputValueView
              title="Create Date"
              subTitle="When company created"
              value={moment(selectedCompany?.createDate).format("DD MMM, YYYY")}
            />
          </InputValueViewGroup>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
