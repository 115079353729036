/* eslint-disable no-console */
import React from "react";
import { Outlet } from "react-router-dom";
import { Registration } from "../../App/components/organisms";

const RegistrationPage: React.FC = () => (
  <Registration>
    <Outlet />
  </Registration>
);

export default RegistrationPage;
