import React from "react";
import { Link } from "react-router-dom";
import { ITeam, ITeamInput, IUser } from "../../../common/types";
import {
  EditableTitleField,
  EditableFieldForm,
  FieldView,
  EditableDescriptionField,
  EditableUserSelectedField,
} from "../commonFields";
import { updateCompanyTeamInFirestore } from "../../../firestore/firestoreService";
import { TeamCardWrapper } from "./Team.style";

interface ITeamCardProps {
  team: ITeam;
  parent?: ITeamInput;
  keyResultsTotal: number;
  initiativesTotal: number;
  objectivesTotal: number;
  usersTotal: number;
}
export const TeamCard: React.FC<ITeamCardProps> = ({
  team,
  keyResultsTotal,
  initiativesTotal,
  objectivesTotal,
  usersTotal,
}) => {
  const handleEditUser = async (
    name: string,
    value: { userId: string; user: IUser }
  ) => {
    await updateCompanyTeamInFirestore(team.id, {
      ownerId: value.userId,
      owner: value.user,
    });
  };
  const handleEdit = async (name: string, value: string | number) => {
    await updateCompanyTeamInFirestore(team.id, {
      [name]: value,
    });
  };
  return (
    <TeamCardWrapper>
      <EditableFieldForm>
        <div className="team-card-header">
          <div className="team-card-header-info">
            <div className="team-card-title">
              <EditableTitleField
                title={"Name"}
                editable
                dataIndex={"name"}
                record={team?.name}
                handleSave={handleEdit}
              />
            </div>
            <div className="team-card-details">
              <FieldView label="Owner" width={100}>
                <EditableUserSelectedField
                  title={"ownerId"}
                  editable
                  dataIndex={"ownerId"}
                  record={team?.ownerId}
                  handleSave={handleEditUser}
                />
              </FieldView>
            </div>
            <FieldView label="Description" width={100} block>
              <EditableDescriptionField
                title={"description"}
                editable
                dataIndex={"description"}
                record={team?.description}
                handleSave={handleEdit}
                placeholder="Add description to team"
              />
            </FieldView>
          </div>
        </div>
      </EditableFieldForm>
      <div className="team-card-footer">
        <Link to={`/team/${team?.id}/objectives`} className="team-card-stat">
          <span className="team-card-stat-value">{objectivesTotal || 0}</span>
          <span className="team-card-stat-label">Objectives</span>
        </Link>
        <Link to={`/team/${team?.id}/key-results`} className="team-card-stat">
          <span className="team-card-stat-value">{keyResultsTotal || 0}</span>
          <span className="team-card-stat-label">Key Results</span>
        </Link>
        <Link to={`/team/${team?.id}/initiatives`} className="team-card-stat">
          <span className="team-card-stat-value">{initiativesTotal || 0}</span>
          <span className="team-card-stat-label">Initiatives</span>
        </Link>
        <Link to={`/team/${team?.id}/members`} className="team-card-stat">
          <span className="team-card-stat-value">{usersTotal || 0}</span>
          <span className="team-card-stat-label">Members</span>
        </Link>
      </div>
    </TeamCardWrapper>
  );
};
