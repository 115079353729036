/* eslint-disable no-console */
import moment from "moment";
import React from "react";
import { ICycle } from "../../../common/types";
import { cycleStatusTypesOptions } from "../../../enums";
import { updateCompanyCycleInFirestore } from "../../../firestore/firestoreService";
import {
  EditableTitleField,
  EditableFieldForm,
  FieldView,
  EditableDescriptionField,
  EditableSelectedField,
  EditableDateRangePickerField,
} from "../commonFields";
import { CycleCardWrapper } from "./Cycle.style";

interface ICycleCardProps {
  cycle: ICycle;
  parent?: ICycle;
}
export const CycleCard: React.FC<ICycleCardProps> = ({ parent, cycle }) => {
  const handleEditDateRangePicker = async (
    name: string,
    value: [moment.Moment, moment.Moment]
  ) => {
    const [startDate, endDate] = value;
    await updateCompanyCycleInFirestore(cycle.id, {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    });
  };

  const handleEdit = async (name: string, value: string | number) => {
    await updateCompanyCycleInFirestore(cycle.id, {
      [name]: value,
    });
  };
  return (
    <CycleCardWrapper>
      <EditableFieldForm>
        <div className="cycle-card-header">
          <div className="cycle-card-title">
            <EditableTitleField
              title={"name"}
              editable
              dataIndex={"name"}
              record={cycle?.name}
              handleSave={handleEdit}
            >
              {cycle?.name || "--"}
            </EditableTitleField>
          </div>
          <FieldView label="Period" width={100}>
            <EditableDateRangePickerField
              title={"Period"}
              editable
              dataIndex={"period"}
              record={[moment(cycle?.startDate), moment(cycle?.endDate)]}
              handleSave={handleEditDateRangePicker}
            />
          </FieldView>
          <FieldView label="Status" width={100}>
            <EditableSelectedField
              title={"status"}
              editable
              dataIndex={"status"}
              record={cycle?.status}
              handleSave={handleEdit}
              options={cycleStatusTypesOptions}
            />
          </FieldView>
          <FieldView label="Color" width={100}>
            <div
              style={{
                backgroundColor: cycle?.color,
                height: 24,
                width: 24,
                borderRadius: 60,
              }}
            ></div>
          </FieldView>

          {parent && (
            <div className="cycle-card-parent">{parent.name || ""}</div>
          )}
          <FieldView label="Description" width={100} block>
            <EditableDescriptionField
              title={"description"}
              editable
              dataIndex={"description"}
              record={cycle?.description}
              handleSave={handleEdit}
              placeholder="Add description to Cycle"
            />
          </FieldView>
        </div>
      </EditableFieldForm>
    </CycleCardWrapper>
  );
};
