/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CycleCell } from "../CommonCells";
import { ICycle } from "../../../common/types";
import { fetchCompanyCyclesFromFirestore } from "../../../firestore/firestoreService";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { listenToCompanyCycles } from "../../../redux/data/companyCycle/companyCycleActions";
import { Form, Select } from "../../atoms";
import { CycleSelectFieldWrapper } from "./Fields.style";

interface ICycleSelectFieldProps {
  boardId?: string;
  bordered?: boolean;
  name: string;
  label?: string;
  rules?: any[];
  placeholder?: string;
  style?: any;
  selectStyle?: any;
  mode?: "multiple" | "tags";
  maxTagCount?: number;
  initialValue?: any;
  getCycle?: () => ICycle;
  onChange?: (value: any) => void;
}
export const CycleSelectField: React.FC<ICycleSelectFieldProps> = ({
  name,
  label,
  boardId,
  mode,
  rules,
  getCycle,
  initialValue,
  bordered,
  onChange,
  style,
  placeholder,
  maxTagCount,
  selectStyle,
}) => {
  const dispatch = useDispatch();
  const { cycles } = useSelector((state: any) => state.cycles);
  useFirestoreCollection({
    query: () =>
      fetchCompanyCyclesFromFirestore([
        {
          type: "filter",
          name: "boardId",
          opr: "==",
          value: boardId,
        },
      ]),
    data: listenToCompanyCycles,
    deps: [dispatch],
  });
  const handelOnSelect = (value: string) => {
    console.log(value);
  };
  return (
    <CycleSelectFieldWrapper>
      <Form.Item
        label={label}
        name={name}
        rules={rules}
        initialValue={initialValue}
        style={style}
      >
        <Select
          showSearch
          bordered={bordered}
          placeholder={placeholder || label}
          optionFilterProp="label"
          style={selectStyle}
          filterOption={(input, option: any) =>
            option.search.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          options={cycles.map((cycle: ICycle) => ({
            value: cycle.id,
            label: <CycleCell cycle={cycle} />,
            search: cycle.name,
          }))}
          mode={mode}
          maxTagCount={maxTagCount}
          onSelect={handelOnSelect}
          onChange={onChange}
        ></Select>
      </Form.Item>
    </CycleSelectFieldWrapper>
  );
};

export default CycleSelectField;
