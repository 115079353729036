/* eslint-disable no-console */

import { ICompany, ICycle, IDepartment, IUser } from "../../../common/types";
import {
  CREATE_COMPANY,
  UPDATE_COMPANY,
  DELETE_COMPANY,
  FETCH_COMPANIES,
  LISTEN_TO_SELECTED_COMPANY,
  CLEAR_COMPANIES,
  CLEAR_SELECTED_COMPANY,
  LISTEN_TO_SELECTED_COMPANY_CYCLES,
  LISTEN_TO_SELECTED_COMPANY_DEPARTMENTS,
  LISTEN_TO_SELECTED_COMPANY_USERS,
} from "./companyConstants";

export function listenToCompanies(companies: ICompany[]) {
  return {
    type: FETCH_COMPANIES,
    payload: companies,
  };
}

export function listenToSelectedCompany(company: ICompany) {
  return {
    type: LISTEN_TO_SELECTED_COMPANY,
    payload: company,
  };
}
export function listenToSelectedCompanyDefaultCycles(cycles: ICycle[]) {
  return {
    type: LISTEN_TO_SELECTED_COMPANY_CYCLES,
    payload: cycles,
  };
}
export function listenToSelectedCompanyDepartments(departments: IDepartment[]) {
  return {
    type: LISTEN_TO_SELECTED_COMPANY_DEPARTMENTS,
    payload: departments,
  };
}
export function listenToSelectedCompanyUsers(users: IUser[]) {
  return {
    type: LISTEN_TO_SELECTED_COMPANY_USERS,
    payload: users,
  };
}
export function clearSelectedCompany() {
  return {
    type: CLEAR_SELECTED_COMPANY,
  };
}

export function createCompany(company: ICompany) {
  return {
    type: CREATE_COMPANY,
    payload: company,
  };
}

export function updateCompany(company: ICompany) {
  return {
    type: UPDATE_COMPANY,
    payload: company,
  };
}

export function deleteCompany(companyId: string) {
  return {
    type: DELETE_COMPANY,
    payload: companyId,
  };
}

export function clearCompanies() {
  return {
    type: CLEAR_COMPANIES,
  };
}
