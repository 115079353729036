/* eslint-disable no-console */
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { find } from "lodash";
import { useDispatch } from "react-redux";
import { Alert, Button, Form, Input } from "../../atoms";
import { SelectField } from "../../molecules/commonFields/SelectField";
import { industryOptions } from "../../../enums/industies";
import { addCompanyToFirestore } from "../../../firestore/firestoreService";
import { ISelectOption } from "../../../common/types";
import { verifyAuth } from "../../../redux/data/auth/authActions";
import { SignUpWrapper } from "./Registration.style";

interface IRegCompanyInfoProps {
  actionKey?: string;
}
export const RegCompanyInfo: React.FC<IRegCompanyInfoProps> = () => {
  const params = useParams();
  const userId = params.key || "";
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const handleOnFinish = async () => {
    setLoading(true);
    try {
      const values: { name: string; industryCodes: string[] } =
        await form.validateFields();
      const selectedIndustries: ISelectOption[] =
        values.industryCodes.map((code) => {
          const ind = find(industryOptions, ["value", code]);
          if (ind) {
            return ind;
          }
          return { value: code, label: "" };
        }) || [];
      const companyId = await addCompanyToFirestore(userId, {
        name: values.name,
        industries: selectedIndustries || [],
        industryCodes: values.industryCodes || [],
      });
      if (companyId) {
        setLoading(false);
        dispatch(verifyAuth());
        navigate(`/`);
      }
    } catch (errorInfo: any) {
      setLoading(false);
      setError(errorInfo.message);
    }
  };
  return (
    <div className="registration-page">
      <div className="registration-page-body">
        <SignUpWrapper>
          <div className="sign-up-title">Your company info</div>
          <div className="sign-up-subtitle">
            This will be the name of your company - Choose something your team
            will recognize.{" "}
          </div>
          {error !== "" && (
            <Alert message={error} type="error" style={{ marginBottom: 20 }} />
          )}
          <Form
            form={form}
            layout="vertical"
            onFinish={handleOnFinish}
            autoComplete="off"
          >
            <Form.Item
              name="name"
              label="Company Name"
              rules={[
                {
                  required: true,
                  message: "Please input Your Company Name",
                },
              ]}
            >
              <Input placeholder="Your Name" />
            </Form.Item>
            <SelectField
              label="Industries"
              name="industryCodes"
              rules={[{ required: true, message: "Missing Industries" }]}
              options={industryOptions}
              mode="multiple"
            />

            <Button className="sign-up-btn" loading={loading} htmlType="submit">
              Done
            </Button>
          </Form>
        </SignUpWrapper>
      </div>
      <div className="registration-page-aside"> </div>
    </div>
  );
};
