/* eslint-disable no-console */
import { find } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams, useLocation } from "react-router-dom";
import { IBoard } from "../../App/common/types";
import { ItemNotFound } from "../../App/components/molecules";
import {
  fetchCompanyCyclesFromFirestore,
  fetchCompanyInitiativesFromFirestore,
  fetchCompanyOkrsFromFirestore,
  fetchCompanyUsersFromFirestore,
  listenToCompanyBoardFromFirestore,
} from "../../App/firestore/firestoreService";
import { userFirestoreDoc } from "../../App/hooks/useFirestoreDoc";
import { useFirestoreCollection } from "../../App/hooks/useFirestoreCollection";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";
import {
  listenToSelectedBoardCycles,
  listenToSelectedBoardInitiatives,
  listenToSelectedBoardOkrs,
  listenToSelectedBoardUsers,
  listenToSelectedCompanyBoard,
} from "../../App/redux/data/companyBoard/companyBoardActions";

const currentMenu = (boardId: string) => [
  {
    key: "details",
    label: "Details",
    link: `/board/${boardId}/details`,
  },
  {
    key: "cycles",
    label: "Cycles",
    link: `/board/${boardId}/cycles`,
  },

  {
    key: "objectives",
    label: "Objectives",
    link: `/board/${boardId}/objectives`,
  },
  {
    key: "keyResults",
    label: "Key Results",
    link: `/board/${boardId}/key-results`,
  },
  {
    key: "initiatives",
    label: "Initiatives",
    link: `/board/${boardId}/initiatives`,
  },

  {
    key: "users",
    label: "Users",
    link: `/board/${boardId}/users`,
  },
  {
    key: "report",
    label: "Report",
    link: `/board/${boardId}/report`,
  },
  {
    key: "setting",
    label: "Setting",
    link: `/board/${boardId}/setting`,
  },
];
const currentBreadcrumb = (board: IBoard, name: string) => [
  {
    key: "board",
    label: board?.title,
    link: `/board/${board?.id}/details`,
  },
  {
    key: "cycle",
    label: name,
    link: "#",
  },
];
const BrandViewPage: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();

  const boardId = params.boardId || "";
  const headerMenu = currentMenu(boardId);
  const [breadcrumb, setBreadcrumb] = React.useState<any[]>([]);
  const { selectedBoard } = useSelector((state: any) => state.boards);
  const { error } = useSelector((state: any) => state.async);
  userFirestoreDoc({
    query: () => listenToCompanyBoardFromFirestore(boardId),
    data: listenToSelectedCompanyBoard,
    deps: [dispatch, boardId],
  });

  useFirestoreCollection({
    query: () =>
      fetchCompanyUsersFromFirestore([
        {
          type: "filter",
          name: "boardIds",
          opr: "array-contains", // TODO
          value: boardId,
        },
      ]),
    data: listenToSelectedBoardUsers,
    deps: [dispatch, boardId],
  });
  useFirestoreCollection({
    query: () =>
      fetchCompanyCyclesFromFirestore([
        {
          type: "filter",
          name: "boardId",
          opr: "==",
          value: boardId,
        },
      ]),
    data: listenToSelectedBoardCycles,
    deps: [dispatch, boardId],
  });
  useFirestoreCollection({
    query: () =>
      fetchCompanyOkrsFromFirestore([
        {
          type: "filter",
          name: "boardId",
          opr: "==",
          value: boardId,
        },
      ]),
    data: listenToSelectedBoardOkrs,
    deps: [dispatch, boardId],
  });
  useFirestoreCollection({
    query: () =>
      fetchCompanyInitiativesFromFirestore([
        {
          type: "filter",
          name: "boardId",
          opr: "==",
          value: boardId,
        },
      ]),
    data: listenToSelectedBoardInitiatives,
    deps: [dispatch, boardId],
  });
  console.log(location.pathname);
  React.useEffect(() => {
    const name = find(headerMenu, ["link", location.pathname]);
    setBreadcrumb(currentBreadcrumb(selectedBoard, name?.label || "") || []);
  }, [selectedBoard, location.pathname]);
  // if (loading) return <Loader color="success" title="Loading" />;
  if (error) {
    return (
      <ItemNotFound
        redirectTo="/boards"
        redirectBtnText="Back to Boards"
        itemType="Board"
        itemId={boardId}
      />
    );
  }
  return (
    <AdminPage
      title={selectedBoard?.title || "---"}
      headerMenu={headerMenu}
      breadcrumb={breadcrumb}
    >
      <Outlet />
    </AdminPage>
  );
};

export default BrandViewPage;
