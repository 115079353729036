/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { find } from "lodash";
import { InputField, UserSelectedField } from "../../molecules";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { IDepartmentInput } from "../../../common/types";
import {
  Alert,
  Button,
  Divider,
  Form,
  Input,
  InputFormWrapper,
} from "../../atoms";
import { addCompanyDepartmentToFirestore } from "../../../firestore/firestoreService";
import { closeModal } from "../../../redux/data/modals/modalReducer";

export const AddDepartmentModal: React.FC = (props) => {
  const dispatch = useDispatch();
  const { users } = useSelector((state: any) => state.users);
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleSubmit = async () => {
    try {
      const values: IDepartmentInput = await form.validateFields();
      const manager = find(users, ["id", values.managerId]);
      setLoading(true);
      await addCompanyDepartmentToFirestore({
        name: values.name,
        description: values.description || "",
        manager,
        managerId: values.managerId,
        status: true,
      });
      setLoading(false);
      dispatch(closeModal());
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  return (
    <ModalWrapper {...props} footer={null}>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <InputFormWrapper>
        <div className="input-form-body">
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
          >
            <InputField
              label="Department name"
              name="name"
              rules={[{ required: true, message: "Missing Department name" }]}
              placeholder="Department name"
              style={{ width: "100%" }}
            />
            <Form.Item name="description" label="Description">
              <Input.TextArea placeholder="Description" />
            </Form.Item>
            <UserSelectedField
              label="Department Manager"
              name="managerId"
              rules={[{ required: true, message: "Missing Manager" }]}
            />

            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              Add Department
            </Button>
          </Form>
        </div>
      </InputFormWrapper>
    </ModalWrapper>
  );
};
