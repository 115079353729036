import styled from "styled-components";
export const CheckboxGroupWrapper = styled.div`
  .checkbox-group {
    &-all {
      border-bottom: 1px solid #f8f8f8;
      padding-bottom: 5px;
      margin-bottom: 5px;
      font-size: 16px;
      font-weight: 500;
    }
    &-list {
      display: flex;
      flex-direction: column;
      .ant-checkbox-group-item {
        line-height: 32px;
        padding-bottom: 10px;
      }
    }
  }
`;
