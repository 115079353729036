/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-console */
import React from "react";
import noUiSlider from "nouislider";
import "nouislider/dist/nouislider.css";
import { Tag } from "../../atoms/Tag/Tag";
import { ObjectiveProgressIndicatorWrapper } from "./ObjectiveProgressIndicator.style";
const marks: number[] | number = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
interface IOkrProgressIndicatorProps {
  value?: [number, number];
  onUpdate: any;
}
export const ObjectiveProgressIndicator: React.FC<
  IOkrProgressIndicatorProps
> = ({ value, onUpdate }) => {
  // const [selectedValue, setSelectedValue] = React.useState<number[]>([]);
  const gridRef = React.useRef<any>();
  React.useEffect(() => {
    if (gridRef.current) {
      noUiSlider.create(gridRef.current, {
        start: [30, 70],
        connect: [true, true, true],
        range: {
          min: 0,
          max: 100,
        },
        pips: {
          // @ts-ignore
          mode: "values",
          // @ts-ignore
          values: marks,
          density: 5,
        },
      });
      const connect = gridRef.current.querySelectorAll(".noUi-connect");
      const classes = ["c-1-color", "c-2-color", "c-3-color"];

      for (let i = 0; i < connect.length; i++) {
        connect[i].classList.add(classes[i]);
      }
      gridRef.current?.noUiSlider.on("end", (values: any) => {
        const [atRisk, onTrack] = values;
        // setSelectedValue([Math.round(atRisk), Math.round(onTrack)]);
        onUpdate([Math.round(atRisk), Math.round(onTrack)]);
      });
    }
  }, [gridRef]);
  React.useEffect(() => {
    console.log(value);
    if (value) {
      gridRef.current?.noUiSlider?.updateOptions({ start: value });
    }
  }, [value, gridRef]);
  return (
    <ObjectiveProgressIndicatorWrapper>
      <div className="labels">
        <Tag className="indicator-tag c-1-color">At Risk</Tag>
        <Tag className="indicator-tag c-2-color">Behind</Tag>
        <Tag className="indicator-tag c-3-color">On Track</Tag>
      </div>
      <div
        ref={(ref) => (gridRef.current = ref)}
        className=""
        style={{ margin: 20 }}
      ></div>
    </ObjectiveProgressIndicatorWrapper>
  );
};
