/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { OkrList } from "../../molecules";

interface ICompanyOkrListProps {
  myProfileId?: string;
}
export const CompanyOkrList: React.FC<ICompanyOkrListProps> = () => {
  const { okrs } = useSelector((state: any) => state.okrs);
  const { loading } = useSelector((state: any) => state.async);
  return (
    <div style={{ margin: "10px 40px" }}>
      <OkrList okrs={okrs} loading={loading} />
    </div>
  );
};
