/* eslint-disable no-console */
// import {
//   asyncActionStart,
//   asyncActionFinish,
//   asyncActionError,
// } from "../async/asyncReducer";
// import {
//   // fetchUsersFromFirestore,
//   dataFromSnapshot,
// } from "../../../firestore/firestoreService";
import { IInitiative, IOkr, IUser } from "../../../common/types";
import {
  CREATE_USER,
  FETCH_USERS,
  LISTEN_TO_SELECTED_USER,
  CLEAR_USERS,
  CLEAR_SELECTED_USER,
  LISTEN_TO_SELECTED_USER_INITIATIVES,
  LISTEN_TO_SELECTED_USER_OKRS,
} from "./userConstants";

export function listenToUsers(users: IUser[]) {
  return {
    type: FETCH_USERS,
    payload: users,
  };
}

export function listenToSelectedUser(user: IUser) {
  return {
    type: LISTEN_TO_SELECTED_USER,
    payload: user,
  };
}
export function listenToSelectedUserOkrs(okrs: IOkr[]) {
  return {
    type: LISTEN_TO_SELECTED_USER_OKRS,
    payload: okrs,
  };
}

export function listenToSelectedUserInitiatives(initiatives: IInitiative[]) {
  return {
    type: LISTEN_TO_SELECTED_USER_INITIATIVES,
    payload: initiatives,
  };
}

export function clearSelectedUser() {
  return {
    type: CLEAR_SELECTED_USER,
  };
}

export function createUser(user: IUser) {
  return {
    type: CREATE_USER,
    payload: user,
  };
}

export function clearUsers() {
  return {
    type: CLEAR_USERS,
  };
}
