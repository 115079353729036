export const COLLECTION_COMPANIES = "COLLECTION_COMPANIES";
export const COLLECTION_BOARDS = "COLLECTION_BOARDS";
export const COLLECTION_INITIATIVES = "COLLECTION_INITIATIVES";
export const COLLECTION_CHECK_INS = "COLLECTION_CHECK_INS";
export const COLLECTION_USERS = "COLLECTION_USERS";
export const COLLECTION_DEPARTMENTS = "COLLECTION_DEPARTMENTS";
export const COLLECTION_SIGNUP_DETAILS = "COLLECTION_SIGNUP_DETAILS";
export const COLLECTION_CYCLES = "COLLECTION_CYCLES";
export const COLLECTION_TEAMS = "COLLECTION_TEAMS";
export const COLLECTION_OKRS = "COLLECTION_OKRS";
