import React from "react";
import classNames from "classnames";
import { MenuOutlined } from "@ant-design/icons";
import { IGridCell } from "../../../common/types";
import { GridCell } from "./GridCell";

interface IGridRowProps {
  isHeader?: boolean;
  columns: any[];
  record?: { [key: string]: any };
}
export const GridRow: React.FC<IGridRowProps> = ({
  isHeader,
  columns,
  record,
}) => {
  const [cells, setCells] = React.useState<IGridCell[]>([]);
  React.useEffect(() => {
    setCells(columns);
  }, [columns]);
  return (
    <div className={classNames("grid-row", { "grid-row-header": isHeader })}>
      <div className="grid-row-sortableHandle">
        <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
      </div>
      {cells.map((cell: IGridCell) => (
        <GridCell
          key={cell.dataIndex}
          style={{ width: cell.width, flex: cell.width }}
        >
          {isHeader && cell.title}
          {(!isHeader &&
            cell.render &&
            record &&
            cell.render(record[cell.dataIndex], record)) ||
            ""}
          {!isHeader && !cell.render && record && record[cell.dataIndex]}
        </GridCell>
      ))}
    </div>
  );
};
