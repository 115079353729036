import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import {
  EditableFieldForm,
  EditableSelectedField,
  EditableTextField,
  EditableUserSelectedField,
  FieldView,
} from "../commonFields";
import { IUserInput, IUser } from "../../../common/types";
import { updateCompanyUserInFirestore } from "../../../firestore/firestoreService";
import { Avatar } from "../../atoms";
import { UserCardWrapper } from "./User.style";

interface IUserCardProps {
  user: IUser;
  keywords: string[];
  parent?: IUserInput;
  objectivesTotal?: number;
  initiativesTotal?: number;
  keyResultsTotal?: number;
}
export const UserCard: React.FC<IUserCardProps> = ({
  user,
  objectivesTotal,
  keyResultsTotal,
  initiativesTotal,
}) => {
  const handleEdit = async (name: string, value: string | number) => {
    await updateCompanyUserInFirestore(user.id, {
      [name]: value,
    });
  };
  const handleEditUser = async (
    name: string,
    value: { userId: string; user: IUser }
  ) => {
    await updateCompanyUserInFirestore(user.id, {
      managerId: value.userId,
      manager: value.user,
    });
  };
  return (
    <UserCardWrapper>
      <EditableFieldForm>
        <div className="user-card-header">
          <div>
            {user?.image ? (
              <Avatar
                className={classNames("user-card-header-avatar")}
                src={user.image.url}
                size={120}
              />
            ) : (
              <Avatar
                className={classNames("user-card-header-avatar")}
                size={120}
              >
                {user?.avatar || "AS"}
              </Avatar>
            )}
          </div>
          <div className="user-card-header-info">
            <div className="user-card-title">{user?.displayName || ""}</div>
            <FieldView label="Phone Number" width={100}>
              <EditableTextField
                title={"Phone Number"}
                editable
                dataIndex={"phoneNumber"}
                value={user?.phoneNumber || ""}
                handleSave={handleEdit}
              />
            </FieldView>
            <FieldView label="Email" width={100}>
              {user?.email || "--"}
            </FieldView>
            <FieldView label="Manager" width={100}>
              <EditableUserSelectedField
                title={"managerId"}
                editable
                dataIndex={"managerId"}
                record={user?.managerId || ""}
                handleSave={handleEditUser}
                placeholder="Select Manager"
                style={{ width: 200 }}
              />
            </FieldView>
            <FieldView label="Position" width={100}>
              <EditableTextField
                title={"Position"}
                editable
                dataIndex={"position"}
                value={user?.position || ""}
                handleSave={handleEdit}
              />
            </FieldView>
            <FieldView label="Okr Champion" width={100}>
              <EditableSelectedField
                title={"okrChampion"}
                editable
                dataIndex={"okrChampion"}
                record={user?.okrChampion || "--"}
                handleSave={handleEdit}
                options={[
                  { value: "YES", label: "Yes" },
                  { value: "--", label: "No" },
                ]}
                style={{ width: 200 }}
              />
            </FieldView>
          </div>
        </div>
      </EditableFieldForm>
      <div className="user-card-footer">
        <Link to={`/user/${user?.id}/objectives`} className="user-card-stat">
          <span className="user-card-stat-value">{objectivesTotal || 0}</span>
          <span className="user-card-stat-label">Objectives</span>
        </Link>
        <Link to={`/user/${user?.id}/key-results`} className="user-card-stat">
          <span className="user-card-stat-value">{keyResultsTotal || 0}</span>
          <span className="user-card-stat-label">Key Results</span>
        </Link>
        <Link to={`/user/${user?.id}/initiatives`} className="user-card-stat">
          <span className="user-card-stat-value">{initiativesTotal || 0}</span>
          <span className="objective-card-stat-label">Initiatives</span>
        </Link>
      </div>
    </UserCardWrapper>
  );
};
