/* eslint-disable no-console */
import { useEffect } from "react";
import { IOkr } from "../common/types";
interface IUseOKRsChartDataGeneratorProps {
  parentNode: any;
  okrs: IOkr[];
  deps: any[];
  data: (data: any[]) => void;
}
export const useOKRsChartDataGenerator = ({
  parentNode,
  okrs,
  deps,
  data,
}: IUseOKRsChartDataGeneratorProps) => {
  useEffect(() => {
    if (!parentNode) return;
    // const cycles = chain(objectives)
    //   .groupBy("cycleId")
    //   .map((cycleObjectives: IOkrInputWithId[]) => ({
    //     ...cycleObjectives[0].cycle,
    //     parentId: parentNode.id,
    //     treeType: "CYCLE",
    //   }))
    //   .value();
    const cycleObjectives = okrs.map((okr) => ({
      ...okr,
      treeType: okr.okrType,
      parentId: okr.okrParentId || parentNode.id,
    }));
    // console.log([parentNode, ...cycles, ...cycleObjectives, ...treeKeyResults]);
    data([parentNode, ...cycleObjectives]);
    return () => {
      console.log([parentNode]);
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  }, deps);
};
