/* eslint-disable no-console */
import React from "react";
import { notification } from "antd";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Alert, Button, Form, Input, Logo } from "../../atoms";
import { closeModal } from "../../../redux/data/modals/modalReducer";
import { ICreds } from "../../../common/types";
import { signInWithEmail } from "../../../firestore/firebaseService";
import { SignInWrapper } from "./Auth.style";

export const ForgetPassword: React.FC = (props) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [, forceUpdate] = React.useState({});

  // To disable submit button at the beginning.
  React.useEffect(() => {
    forceUpdate({});
  }, []);

  const handleSubmit = async () => {
    try {
      const values: ICreds = await form.validateFields();
      setLoading(true);
      await signInWithEmail({
        email: values.email,
        password: values.password,
      });

      setLoading(false);
      dispatch(closeModal());
      history("/");
      notification.success({
        message: "Notification Title",
        description: "This is the content",
      });
    } catch (errorInfo: any) {
      console.log({ errorInfo });
      setLoading(false);
      setError("Problem with user name or password");
    }
  };
  return (
    <SignInWrapper>
      <Logo className="sign-in-logo" />
      <div className="sign-in-title">Forget Password</div>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <Form form={form} name="horizontal_login" layout="vertical">
        <Form.Item
          label="Email Address"
          name="email"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Button
          className="sign-in-btn"
          onClick={handleSubmit}
          loading={loading}
        >
          Send Link
        </Button>
        <div className="sign-in-up">
          {" "}
          Dont have an account? <Link to="/sign-in">Sign in</Link>
        </div>
      </Form>
    </SignInWrapper>
  );
};
