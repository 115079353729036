/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { listenToCompanyBoardFromFirestore } from "../../../firestore/firestoreService";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { listenToSelectedCompanyBoard } from "../../../redux/data/companyBoard/companyBoardActions";
import { Button, InputFormWrapper, Typography } from "../../atoms";
import {
  InputValueView,
  InputValueViewGroup,
  ItemNotFound,
  Loader,
  UserCell,
} from "../../molecules";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
const { Paragraph } = Typography;
interface IBoardSettingProps {
  boardId?: string;
}
export const BoardSetting: React.FC<IBoardSettingProps> = () => {
  const params = useParams();

  const boardId = params.boardId || "";
  const dispatch = useDispatch();
  const { selectedBoard } = useSelector((state: any) => state.boards);
  const { loading: dataLoading, error: dataError } = useSelector(
    (state: any) => state.async
  );
  userFirestoreDoc({
    query: () => listenToCompanyBoardFromFirestore(boardId),
    data: listenToSelectedCompanyBoard,
    deps: [dispatch, boardId],
  });
  // const handleSubmit = async () => {
  //   try {
  //     const values: IBoardInput = await form.validateFields();
  //     setLoading(true);
  //     await updateCompanyBoardInFirestore( boardId, {
  //       mission: values.mission || "",
  //       missionKeywords: values.missionKeywords || [],
  //       strengthKeywords: values.strengthKeywords || [],
  //       weaknessKeywords: values.weaknessKeywords || [],
  //       opportunityKeywords: values.opportunityKeywords || [],
  //       threadsKeywords: values.threadsKeywords || [],
  //       priorities: values.priorities || [],
  //       probabilities: values.probabilities || "",
  //     });
  //     setLoading(false);
  //   } catch (errorInfo) {
  //     setLoading(false);
  //     if (typeof errorInfo === "string") {
  //       notification.error({
  //         message: "Update Failed",
  //         description: errorInfo.toUpperCase(),
  //       });
  //     } else if (errorInfo instanceof Error) {
  //       notification.error({
  //         message: "Update Failed",
  //         description: errorInfo.message,
  //       });
  //     }
  //   }
  // };
  if (dataLoading) return <Loader color="success" title="Loading" />;
  if (dataError) {
    return (
      <ItemNotFound
        redirectTo="/boards"
        redirectBtnText="Back to Boards"
        itemType="Board"
        itemId={boardId}
      />
    );
  }
  return (
    <AdminPageBody>
      <InputFormWrapper>
        <div className="input-form-body">
          <InputValueViewGroup title="Board Setting">
            <InputValueView
              title="Mission"
              subTitle="Board Mission"
              value={selectedBoard.mission}
            />

            <InputValueView
              title="Mission Keyword"
              subTitle="What keywords describe your mission"
              value={selectedBoard.missionsKeyword}
            />
            <InputValueView
              title="Strengths keywords"
              subTitle="What keywords describe your strengths?"
              value={selectedBoard.strengthsKeyword}
            />
            <InputValueView
              title="Weaknesses keywords"
              subTitle="What keywords describe your weaknesses?"
              value={selectedBoard.weaknessesKeyword}
            />
            <InputValueView
              title="opportunities keywords"
              subTitle="What keywords describe your opportunities?"
              value={selectedBoard.opportunityKeyword}
            />
            <InputValueView
              title="Threads keywords"
              subTitle="What keywords describe your threads?"
              value={selectedBoard.threadKeyword || "-"}
            />
          </InputValueViewGroup>
          <InputValueViewGroup title="Board info">
            <InputValueView
              title="Board ID"
              subTitle=""
              value={<Paragraph copyable>{selectedBoard.id}</Paragraph>}
            />
            <InputValueView
              title="Create by"
              subTitle="The user who created this Board"
              value={<UserCell user={selectedBoard.owner} />}
            />
            <InputValueView
              title="Create Date"
              subTitle="When this board created"
              value={moment(selectedBoard.createDate).format("DD MMM, YYYY")}
            />
            <InputValueView
              title="Archive "
              subTitle="Archive this board"
              value={<Button>Archive</Button>}
            />

            <InputValueView
              title="Delete"
              subTitle="Delete this board"
              value={
                <Button type="primary" danger>
                  Delete
                </Button>
              }
            />
          </InputValueViewGroup>
        </div>
      </InputFormWrapper>
    </AdminPageBody>
  );
};
