export const CREATE_COMPANY_CHECK_IN = "CREATE_COMPANY_CHECK_IN";
export const UPDATE_COMPANY_CHECK_IN = "UPDATE_COMPANY_CHECK_IN";
export const DELETE_COMPANY_CHECK_IN = "DELETE_COMPANY_CHECK_IN";
export const FETCH_COMPANY_CHECK_INS = "FETCH_COMPANY_CHECK_INS";
export const LISTEN_TO_SELECTED_COMPANY_CHECK_IN =
  "LISTEN_TO_SELECTED_COMPANY_CHECK_IN";
export const CLEAR_SELECTED_COMPANY_CHECK_IN =
  "CLEAR_SELECTED_COMPANY_CHECK_IN";
export const CLEAR_COMPANY_CHECK_INS = "CLEAR_COMPANY_CHECK_INS";
export const SET_COMPANY_CHECK_INS_FILTER = "SET_COMPANY_CHECK_INS_FILTER";
