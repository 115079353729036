/* eslint-disable no-console */
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Space } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Form, Input } from "../../atoms";
import { SignUpWrapper } from "./Registration.style";

interface IRegTeamInvitationProps {
  actionKey?: string;
}
export const RegTeamInvitation: React.FC<IRegTeamInvitationProps> = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const handleOnFinish = async () => {
    setLoading(true);
    try {
      // const values: { password: string } = await form.validateFields();
      // await registerInFirebase({
      //   email: "rsb@sdsd.com",
      //   password: values.password,
      // });
      navigate(`/`);
      setLoading(false);
    } catch (errorInfo: any) {
      setLoading(false);
      setError("Problem with email please try again");
    }
  };
  return (
    <div className="registration-page">
      <div className="registration-page-body">
        <SignUpWrapper>
          <div className="sign-up-title">Invite your team to </div>
          <div className="sign-up-subtitle">
            This will be the name of your company - Choose something your team
            will recognize.{" "}
          </div>
          {error !== "" && (
            <Alert message={error} type="error" style={{ marginBottom: 20 }} />
          )}
          <Form
            form={form}
            layout="vertical"
            onFinish={handleOnFinish}
            autoComplete="off"
          >
            <Form.List name="users">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "email"]}
                        rules={[
                          {
                            type: "email",
                            message: "The input is not valid E-mail!",
                          },
                          {
                            required: true,
                            message: "Please input your E-mail!",
                          },
                        ]}
                      >
                        <Input placeholder="Email" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add email
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>

            <Button className="sign-up-btn" loading={loading} htmlType="submit">
              Next
            </Button>
          </Form>
        </SignUpWrapper>
      </div>
    </div>
  );
};
