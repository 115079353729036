import styled from "styled-components";
export const EmptyResultWrapper = styled.div`
  background-color: #f1f7ff;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  flex: 1;
  width: 100%;
  .empty-result {
    &-title {
      font-size: 18px;
      font-weight: 500;
    }
    &-icon {
      font-size: 48px;
      color: #d55f8d52;
      line-height: 60px;
    }
    &-sub-title {
      font-size: 14px;
      line-height: 32px;
      color: #7c7d7e;
    }
    &-btn {
    }
  }
`;
