/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { InputFormWrapper } from "../../atoms";
import { ObjectiveListCard } from "../../molecules";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { openModal } from "../../../redux/data/modals/modalReducer";

export const MyOkrList: React.FC = () => {
  const dispatch = useDispatch();
  const { selectedCompany } = useSelector((state: any) => state.companies);
  const { myProfile } = useSelector((state: any) => state.myProfile);
  const handelAddObjective = () => {
    dispatch(
      openModal({
        modalType: "AddObjectiveModal",
        modalProps: {
          title: "Create new objective",
        },
      })
    );
  };
  return (
    <AdminPageBody>
      <InputFormWrapper>
        <div className="input-form-body">
          <div
            className="input-form-description text-center "
            style={{ marginBottom: 0, fontWeight: 500 }}
          >
            {moment().format("dddd, MMMM DD")}
          </div>
          <div className="input-form-title text-center">My OKRs</div>
          <ObjectiveListCard
            filters={[]}
            cycleId={
              myProfile?.defaultCycleId || selectedCompany?.defaultCycleId || ""
            }
            action={handelAddObjective}
          />
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
