/* eslint-disable no-console */
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { IDepartment, IUser } from "../../../common/types";
import { deleteCompanyDepartmentInFirestore } from "../../../firestore/firestoreService";
import { Table } from "../../atoms";
import { ActionCell, UserCell } from "../CommonCells";
import { DepartmentWrapper } from "./Department.style";

interface IDepartmentListProps {
  departments: IDepartment[];
  loading: boolean;
}
export const DepartmentList: React.FC<IDepartmentListProps> = ({
  departments,
  loading,
}) => {
  const handelDeleteDepartment = async (id: string) => {
    await deleteCompanyDepartmentInFirestore(id);
  };
  const columnsList = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: true,
      render: (v: string, record: any) => (
        <Link to={`/department/${record.id}`}>{v}</Link>
      ),
      width: 400,
    },
    {
      title: "Manager",
      dataIndex: "manager",
      key: "manager",
      render: (v: IUser) => <UserCell user={v} avatarOnly />,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (v: boolean) => (v ? "Active" : "Disabled"),
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (v: moment.Moment) => moment(v).format("DD/MM/YYYY"),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: IDepartment) => (
        <ActionCell onDelete={() => handelDeleteDepartment(record.id)} />
      ),
    },
  ];
  return (
    <DepartmentWrapper>
      <Table
        columns={columnsList}
        dataSource={departments}
        loading={loading}
        rowKey="id"
        // scroll={{ x: 800, y: 500 }}
      />
    </DepartmentWrapper>
  );
};
