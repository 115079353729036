/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import {
  AppstoreAddOutlined,
  PlusOutlined,
  SortAscendingOutlined,
} from "@ant-design/icons";
import { Button } from "../../atoms";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchCompanyCyclesFromFirestore } from "../../../firestore/firestoreService";
import { listenToCompanyCycles } from "../../../redux/data/companyCycle/companyCycleActions";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { PageToolbar } from "../../molecules";

interface ICycleOkrListToolbarProps {
  cycleId: string;
}
export const CycleOkrListToolbar: React.FC<ICycleOkrListToolbarProps> = ({
  cycleId,
}) => {
  const dispatch = useDispatch();

  useFirestoreCollection({
    query: () => fetchCompanyCyclesFromFirestore([]),
    data: listenToCompanyCycles,
    deps: [dispatch],
  });
  const handelSorting = () => {
    console.log("filterCycles");
  };
  const handelCustomize = () => {
    console.log("filterCycles");
  };
  const handelAddCycle = () => {
    dispatch(
      openModal({
        modalType: "AddObjectiveModal",
        modalProps: { title: "Add Objective Form", cycleId },
      })
    );
  };

  return (
    <PageToolbar>
      <Button
        type="primary"
        onClick={handelAddCycle}
        block
        icon={<PlusOutlined />}
        className="page-toolbar-btn"
      >
        Add Objective
      </Button>

      <div className="page-toolbar-right-children-elements">
        <Button
          type="link"
          onClick={handelSorting}
          icon={<SortAscendingOutlined />}
          className="page-toolbar-btn-link"
        >
          Sorting
        </Button>
        <Button
          type="link"
          onClick={handelCustomize}
          icon={<AppstoreAddOutlined />}
          className="page-toolbar-btn-link"
        >
          Customize
        </Button>
      </div>
    </PageToolbar>
  );
};
