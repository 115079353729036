import styled from "styled-components";
export const ActivityListCardWrapper = styled.div`
  margin-top: 50px;
  .activity-list-card {
    &-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #edeae9;
      align-items: center;
      line-height: 24px;
    }
    &-title {
      font-size: 16px;
      font-weight: 500;
    }
    &-Actions {
    }
    &-body {
      padding: 20px;
    }
  }
`;

export const ActivityItemWrapper = styled.div`
  margin-top: 50px;
  .activity-item {
    &-title {
      font-size: 24px;
      font-weight: 500;
    }
    &-Actions {
    }
  }
`;
