/* eslint-disable no-console */
import {
  IBoard,
  ICycle,
  IInitiative,
  IOkr,
  IUser,
} from "../../../common/types";
import {
  CREATE_COMPANY_BOARD,
  UPDATE_COMPANY_BOARD,
  DELETE_COMPANY_BOARD,
  FETCH_COMPANY_BOARDS,
  LISTEN_TO_SELECTED_COMPANY_BOARD,
  LISTEN_TO_SELECTED_BOARD_OKRS,
  LISTEN_TO_SELECTED_BOARD_INITIATIVES,
  LISTEN_TO_SELECTED_BOARD_CYCLES,
  LISTEN_TO_SELECTED_BOARD_USERS,
  CLEAR_COMPANY_BOARDS,
  CLEAR_SELECTED_COMPANY_BOARD,
} from "./companyBoardConstants";

export function listenToCompanyBoards(boards: IBoard[]) {
  return {
    type: FETCH_COMPANY_BOARDS,
    payload: boards,
  };
}

export function listenToSelectedCompanyBoard(board: IBoard) {
  return {
    type: LISTEN_TO_SELECTED_COMPANY_BOARD,
    payload: board,
  };
}

export function listenToSelectedBoardOkrs(okrs: IOkr[]) {
  return {
    type: LISTEN_TO_SELECTED_BOARD_OKRS,
    payload: okrs,
  };
}

export function listenToSelectedBoardInitiatives(initiatives: IInitiative[]) {
  return {
    type: LISTEN_TO_SELECTED_BOARD_INITIATIVES,
    payload: initiatives,
  };
}
export function listenToSelectedBoardCycles(cycles: ICycle[]) {
  return {
    type: LISTEN_TO_SELECTED_BOARD_CYCLES,
    payload: cycles,
  };
}
export function listenToSelectedBoardUsers(users: IUser[]) {
  return {
    type: LISTEN_TO_SELECTED_BOARD_USERS,
    payload: users,
  };
}
export function clearSelectedCompanyBoard() {
  return {
    type: CLEAR_SELECTED_COMPANY_BOARD,
  };
}

export function createCompanyBoard(board: IBoard) {
  return {
    type: CREATE_COMPANY_BOARD,
    payload: board,
  };
}

export function updateCompanyBoard(board: IBoard) {
  return {
    type: UPDATE_COMPANY_BOARD,
    payload: board,
  };
}

export function deleteCompanyBoard(boardId: string) {
  return {
    type: DELETE_COMPANY_BOARD,
    payload: boardId,
  };
}

export function clearCompanyBoards() {
  return {
    type: CLEAR_COMPANY_BOARDS,
  };
}
