/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCopyToClipboard } from "usehooks-ts";
import {
  CheckOutlined,
  LinkOutlined,
  StarFilled,
  StarOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { isArray } from "lodash";
import {
  CheckInListCard,
  CommentForm,
  CommentListCard,
  InitiativeListCard,
  KeyResultActionMenu,
  KeyResultCard,
  KeyResultChartCard,
  KeyResultMilestoneCard,
  TooltipField,
} from "../../molecules";
import { DrawerWrapper } from "../../../common/Drawer/DrawerWrapper";
// import { InputFormWrapper } from "../../atoms";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import {
  addCompanyOkrCommentToFirestore,
  fetchCompanyCheckInsFromFirestore,
  likeOkrCompanyUserFirestore,
  listenToCompanyCycleFromFirestore,
  listenToCompanyOkrFromFirestore,
} from "../../../firestore/firestoreService";
import { ICheckIn, ICycle, IOkr } from "../../../common/types";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { Button, Tag, Tooltip } from "../../atoms";
import { getKeyResultScoreNumber } from "../../../services/keyResultService";
import { openDrawer } from "../../../redux/data/drawer/drawerReducer";
import { notificationServices } from "../../../services/notificationServices";
interface IObjectiveDrawerProps {
  id: string;
}
export const KeyResultDetailsDrawer: React.FC<IObjectiveDrawerProps> = (
  props
) => {
  const keyResultId = props.id;
  const dispatch = useDispatch();
  const [cycle, setCycle] = React.useState<ICycle>();
  const [, copy] = useCopyToClipboard();
  const [objective, setObjective] = React.useState<IOkr>();
  const { myProfile } = useSelector((state: any) => state.myProfile);
  const [isLiked, setIsLiked] = React.useState(false);
  const [keyResult, setKeyResult] = React.useState<IOkr>();
  const [checkIns, setCheckIns] = React.useState<ICheckIn[]>([]);
  const [score, setScore] = React.useState<number>(0);
  userFirestoreDoc({
    query: () => listenToCompanyOkrFromFirestore(keyResultId),
    data: setKeyResult,
    deps: [dispatch, keyResultId],
    local: true,
  });

  userFirestoreDoc({
    query: () => listenToCompanyOkrFromFirestore(keyResult?.okrParentId || ""),
    data: setObjective,
    deps: [keyResult],
    shouldExecute: !!keyResult,
    local: true,
  });
  userFirestoreDoc({
    query: () => listenToCompanyCycleFromFirestore(keyResult?.cycleId || ""),
    data: setCycle,
    deps: [keyResult],
    shouldExecute: !!keyResult,
    local: true,
  });
  useFirestoreCollection({
    query: () =>
      fetchCompanyCheckInsFromFirestore([
        {
          type: "filter",
          name: "keyResultId",
          opr: "==",
          value: keyResultId,
        },
      ]),
    data: setCheckIns,
    deps: [dispatch, keyResultId],
    local: true,
  });
  const handelAddCheckIn = () => {
    if (!keyResult) return;
    dispatch(
      openModal({
        modalType: "AddCheckInForm",
        modalProps: {
          title: "Create Check In",
          boardId: keyResult.boardId,
          objectiveId: keyResult.okrParentId,
          keyResultId: props.id,
          keyResult,
          cycleId: keyResult.cycleId,
        },
      })
    );
  };
  const makeKeyResultObjective = () => {
    if (!keyResult) return;
    dispatch(
      openModal({
        modalType: "CascadeKeyResultModal",
        modalProps: {
          title: "Cascade key result",
          keyResultId: props.id,
          keyResult,
        },
      })
    );
  };
  const copyKeyResultUrl = async () => {
    await copy(`app.weey.io/key-result/${keyResultId}/details`);
    notificationServices("COPY_OKR_URL");
  };
  const handelLikeKeyResult = (action: string) => async () => {
    if (action === "add") {
      await likeOkrCompanyUserFirestore(keyResultId, false);
      notificationServices("ADD_OKR_TO_FAVORED");
    } else {
      await likeOkrCompanyUserFirestore(keyResultId, true);
      notificationServices("REMOVE_OKR_FROM_FAVORED");
    }
  };
  React.useEffect(() => {
    const currentScore = getKeyResultScoreNumber({
      targetNumber: +(keyResult?.targetNumber || 0),
      initialNumber: +(keyResult?.initialNumber || 0),
      checkIns,
    });
    setScore(currentScore);
  }, [keyResult, checkIns]);
  React.useEffect(() => {
    if (
      isArray(myProfile.okrsLiked) &&
      myProfile.okrsLiked.includes(keyResultId)
    ) {
      setIsLiked(true);
    } else {
      setIsLiked(false);
    }
  }, [myProfile.okrsLiked, keyResultId]);
  React.useEffect(() => {
    if (keyResult?.okrType === "OBJECTIVE_CASCADED") {
      dispatch(
        openDrawer({
          drawerType: "ObjectiveDetailsDrawer",
          drawerProps: {
            title: keyResult.title,
            id: keyResult.id,
          },
        })
      );
    }
  }, [keyResult]);
  if (!keyResult || !cycle || !objective) {
    return <div></div>;
  }
  return (
    <DrawerWrapper
      {...props}
      width={700}
      extra={
        <>
          <Tooltip title="Like this key result" placement="bottom">
            {isLiked ? (
              <StarFilled
                className="drawer-extra-btn-link isLiked"
                onClick={handelLikeKeyResult("remove")}
              />
            ) : (
              <StarOutlined
                className="drawer-extra-btn-link"
                onClick={handelLikeKeyResult("add")}
              />
            )}
          </Tooltip>
          <Tooltip title="Copy key result link" placement="bottom">
            <LinkOutlined
              className="drawer-extra-btn-link"
              onClick={copyKeyResultUrl}
            />
          </Tooltip>
          <Tooltip
            title={
              <TooltipField
                title={"Cascade key result"}
                description={"Convert this key result to objective"}
              />
            }
            placement="bottomRight"
          >
            <VerticalAlignBottomOutlined
              className="drawer-extra-btn-link"
              onClick={makeKeyResultObjective}
            />
          </Tooltip>
          <Tooltip title="More actions" placement="bottom">
            <KeyResultActionMenu
              keyResult={keyResult}
              keyResultId={keyResultId}
            />
          </Tooltip>
        </>
      }
      title={
        <div className="drawer-title">
          <Tag className="drawer-title-tag">Key Result</Tag>
          <Button
            onClick={handelAddCheckIn}
            icon={<CheckOutlined />}
            className="drawer-title-btn"
          >
            Check In
          </Button>
        </div>
      }
      footer={
        <CommentForm
          addComment={addCompanyOkrCommentToFirestore}
          id={keyResultId}
        />
      }
    >
      <KeyResultCard
        keyResult={keyResult}
        cycle={cycle}
        score={score}
        objective={objective}
      />

      {keyResult?.keyResultType === "NUMBER" && (
        <KeyResultChartCard
          checkInRequestDates={keyResult?.checkInRequestDates || []}
          checkIns={checkIns || []}
          greyHeader
          calculationMethod={keyResult?.calculationMethod || ""}
          initialNumber={keyResult?.initialNumber || 0}
          targetNumber={keyResult?.targetNumber || 0}
          startDate={moment(cycle.startDate)}
        />
      )}
      {keyResult?.keyResultType === "MILESTONE" && (
        <KeyResultMilestoneCard
          milestones={keyResult?.milestones || []}
          keyResult={keyResult}
          keyResultId={keyResultId}
          greyHeader
        />
      )}

      <CheckInListCard
        checkIns={checkIns}
        action={handelAddCheckIn}
        greyHeader
      />
      <InitiativeListCard
        keyResult={keyResult}
        keyResultId={keyResultId}
        me={myProfile}
        greyHeader
        filters={[
          {
            type: "filter",
            name: "keyResultId",
            opr: "==",
            value: keyResultId,
          },
        ]}
      />
      <CommentListCard
        comments={keyResult?.comments || []}
        addComment={addCompanyOkrCommentToFirestore}
        id={keyResultId}
      />
    </DrawerWrapper>
  );
};
