/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IOkr } from "../../../common/types";
import { fetchCompanyOkrsFromFirestore } from "../../../firestore/firestoreService";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { listenToCompanyOkrs } from "../../../redux/data/companyOKR/companyOKRActions";
import { Form, Select } from "../../atoms";

interface IOkrSelectFieldProps {
  boardId?: string;
  bordered?: boolean;
  name: string;
  label?: string;
  rules?: any[];
  placeholder?: string;
  style?: any;
  mode?: "multiple" | "tags";
  maxTagCount?: number;
  initialValue?: any;
  getObjective?: () => IOkr;
  onChange?: (value: any) => void;
}
export const ObjectiveSelectField: React.FC<IOkrSelectFieldProps> = ({
  name,
  label,
  boardId,
  mode,
  rules,
  getObjective,
  initialValue,
  bordered,
  onChange,
  style,
  placeholder,
  maxTagCount,
}) => {
  const dispatch = useDispatch();
  const { okrs } = useSelector((state: any) => state.okrs);
  useFirestoreCollection({
    query: () =>
      fetchCompanyOkrsFromFirestore([
        {
          type: "filter",
          name: "boardId",
          opr: "==",
          value: boardId,
        },
      ]),
    data: listenToCompanyOkrs,
    deps: [dispatch],
  });
  const handelOnSelect = (value: string) => {
    console.log(value);
  };
  return (
    <>
      <Form.Item
        label={label}
        name={name}
        rules={rules}
        initialValue={initialValue}
      >
        <Select
          showSearch
          bordered={bordered}
          style={style}
          placeholder={placeholder || label}
          optionFilterProp="label"
          filterOption={(input, option: any) =>
            option.search.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          options={okrs.map((objective: IOkr) => ({
            value: objective.id,
            label: objective.title,
            search: objective.title,
          }))}
          mode={mode}
          maxTagCount={maxTagCount}
          onSelect={handelOnSelect}
          onChange={onChange}
        ></Select>
      </Form.Item>
    </>
  );
};

export default ObjectiveSelectField;
