/* eslint-disable no-console */
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ISignUpDetails } from "../../../common/types";
import {
  listenToSignUpDetailsFromFirestore,
  verificationEmailFromFirestore,
} from "../../../firestore/firestoreService";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { Alert, Button, Form, Input } from "../../atoms";
import { SignUpWrapper } from "./Registration.style";

interface IRegEmailVerificationProps {
  actionKey?: string;
}
export const RegEmailVerification: React.FC<
  IRegEmailVerificationProps
> = () => {
  const params = useParams();
  const actionKey = params.key || "";
  const [singUpDetails, setSingUpDetails] = React.useState<ISignUpDetails>();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  userFirestoreDoc({
    query: () => listenToSignUpDetailsFromFirestore(actionKey),
    data: setSingUpDetails,
    deps: [actionKey],
    local: true,
  });
  const handleOnFinish = async () => {
    setLoading(true);
    try {
      const values: { code: string } = await form.validateFields();
      await verificationEmailFromFirestore(actionKey, {
        code: values.code,
      });
      setLoading(false);
      navigate(`/registration/user-details/${actionKey}`);
    } catch (errorInfo: any) {
      setLoading(false);
      setError(errorInfo.message);
    }
  };
  return (
    <div className="registration-page">
      <div className="registration-page-body">
        <SignUpWrapper>
          <div className="sign-up-title">Email Verification</div>
          {error !== "" && (
            <Alert message={error} type="error" style={{ marginBottom: 20 }} />
          )}
          <div className="sign-up-subtitle">
            Please check your inbox for the verification code. We have sent you
            a 6 character code to{" "}
            <span style={{ fontWeight: 500, color: "#d65f8e" }}>
              {singUpDetails?.email}
            </span>{" "}
            The code expires shortly so please enter it soon.
          </div>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleOnFinish}
            autoComplete="off"
          >
            <Form.Item
              name="code"
              label="Code"
              rules={[
                {
                  required: true,
                  message: "Please input your Code",
                },
              ]}
            >
              <Input placeholder="Code" />
            </Form.Item>

            <Button className="sign-up-btn" loading={loading} htmlType="submit">
              Verify
            </Button>

            <div className="sign-up-up">
              {" "}
              Cant find your code? Check your spam folder or request a new code.{" "}
              <Link to="/registration/sign-up">Resend</Link>
            </div>
          </Form>
        </SignUpWrapper>
      </div>
      <div className="registration-page-aside"> </div>
    </div>
  );
};
