import { IDepartmentInput, IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import {
  COLLECTION_COMPANIES,
  COLLECTION_DEPARTMENTS,
} from "./firebaseConstants";

export function fetchCompanyDepartmentsFromFirestore(
  args?: IQueryConfigArgs[]
) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  const ref = db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_DEPARTMENTS);
  return collectionWithArgs(ref, args || []);
}
export function listenToCompanyDepartmentFromFirestore(id: string) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_DEPARTMENTS)
    .doc(id);
}

export function addCompanyDepartmentToFirestore(doc: IDepartmentInput) {
  // const user = firebase.auth().currentUser;
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_DEPARTMENTS)
    .add({
      ...doc,
      createDate: new Date(),
      // createBy: user,
    });
}

export function updateCompanyDepartmentInFirestore(
  docId: string,
  doc: IDepartmentInput
) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_DEPARTMENTS)
    .doc(docId)
    .update(doc);
}
export function deleteCompanyDepartmentInFirestore(docId: string) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_DEPARTMENTS)
    .doc(docId)
    .delete();
}

export function activeToggleCompanyDepartmentInFirestore(
  docId: string,
  status: boolean
) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_DEPARTMENTS)
    .doc(docId)
    .update({
      status,
    });
}
