import { appStore } from "../redux/store";
import { userRelationObject } from "./firestoreDataFormat/userDataFormat";
export function setUserAddLog() {
  const { myProfile } = appStore.getState().myProfile;
  return {
    createAt: new Date(),
    createdBy: {
      ...userRelationObject(myProfile),
    },
    createdById: myProfile.id,
  };
}
export function setUserUpdateLog() {
  const { myProfile } = appStore.getState().myProfile;
  return {
    updateAt: new Date(),
    updateBy: {
      ...userRelationObject(myProfile),
    },
    updateById: myProfile.id,
  };
}
