/* eslint-disable import/no-named-as-default */
/* eslint-disable no-console */
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import React from "react";
import classNames from "classnames";
import faker from "@faker-js/faker";
import cuid from "cuid";
import { Button, Space } from "../../atoms";
import { IFakeObjective } from "../../../common/types";
import { ObjectiveSuggestedItemWrapper } from "./Objective.style";

interface IOkrSuggestedItemProps {
  objective: IFakeObjective;
  selected: boolean;
  onSelect: () => void;
  onReload?: () => void;
  onRemove: () => void;
}

export const ObjectiveSuggestedItem: React.FC<IOkrSuggestedItemProps> = ({
  objective,
  selected,
  onSelect,
  onReload,
  onRemove,
}) => {
  const [currentObjective, setCurrentObjective] =
    React.useState<IFakeObjective>(objective);
  const handleOnReload = (id: string) => () => {
    const newObjective = {
      id: currentObjective.id,
      title: faker.lorem.sentences(1),
      description: faker.lorem.paragraph(1),
      keyResults: Array(4)
        .fill("test")
        .map(() => ({ name: faker.lorem.sentences(1), id: cuid() })),
    };
    setCurrentObjective(newObjective);
  };

  return (
    <ObjectiveSuggestedItemWrapper>
      <div className="objective-Suggested-item-header">
        <div className="objective-Suggested-item-objective">
          <div className="objective-Suggested-item-title">
            {currentObjective.title}
          </div>
        </div>
        <div className="objective-Suggested-item-actions">
          <Space>
            <Button
              onClick={onSelect}
              size="small"
              type="primary"
              className={classNames("objective-Suggested-item-select", {
                selected,
              })}
            >
              {" "}
              {selected && <CheckOutlined />} Select
            </Button>
            {!selected ? (
              <Button
                size="small"
                type="primary"
                onClick={handleOnReload(currentObjective.id)}
              >
                <ReloadOutlined />
              </Button>
            ) : (
              <Button size="small" type="default" onClick={onRemove}>
                <CloseOutlined />
              </Button>
            )}
          </Space>
        </div>
      </div>
      <div className="objective-Suggested-item-description">
        {currentObjective.description}
      </div>
      <div className="objective-Suggested-item-keyResults-title">
        key Results
      </div>
      <div className="objective-Suggested-item-keyResults">
        {currentObjective.keyResults.map((keyResult, index) => (
          <div key={index} className="objective-Suggested-item-keyResult">
            <CheckCircleOutlined /> {keyResult.name}
          </div>
        ))}
      </div>
    </ObjectiveSuggestedItemWrapper>
  );
};
