/* eslint-disable no-console */
import { GlobalOutlined } from "@ant-design/icons";
import React from "react";
import { OrgChartCardWrapper } from "./OrgChart.style";

interface IOrgChartCardCompanyProps {
  data: any;
}
export const OrgChartCardCompany: React.FC<IOrgChartCardCompanyProps> = ({
  data,
}) => {
  console.log("data");
  return (
    <OrgChartCardWrapper>
      <div className="org-chart-card">
        <div className="org-chart-card-icon">
          <GlobalOutlined className="alignment-view-icon" />
        </div>
        <div className="org-chart-card-info">
          <div className="org-chart-card-title">Company OKRs</div>
        </div>
      </div>
    </OrgChartCardWrapper>
  );
};
