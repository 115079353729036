/* eslint-disable no-console */
import { find } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { fetchCompanyUsersFromFirestore } from "../../App/firestore/firestoreService";
import { useFirestoreCollection } from "../../App/hooks/useFirestoreCollection";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";
import { listenToCompanyUsers } from "../../App/redux/data/companyUser/companyUserActions";

const currentMenu = [
  {
    key: "details",
    label: "List",
    link: `/users/list`,
  },
  {
    key: "organizationChart",
    label: "Organization chart",
    link: `/users/chart`,
  },
];
const currentBreadcrumb = (name: string) => [
  {
    key: "users",
    label: "Users",
    link: "/users/list",
  },
  {
    key: "page",
    label: name,
    link: "#",
  },
];
const UsersPage: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [breadcrumb, setBreadcrumb] = React.useState<any[]>([]);
  const { selectedCycle } = useSelector((state: any) => state.users);

  useFirestoreCollection({
    query: () => fetchCompanyUsersFromFirestore(),
    data: listenToCompanyUsers,
    deps: [dispatch],
  });
  React.useEffect(() => {
    const name = find(currentMenu, ["link", location.pathname]);
    setBreadcrumb(currentBreadcrumb(name?.label || "") || []);
  }, [selectedCycle, location.pathname]);
  return (
    <AdminPage title="Members" headerMenu={currentMenu} breadcrumb={breadcrumb}>
      <Outlet />
    </AdminPage>
  );
};

export default UsersPage;
