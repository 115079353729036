import { omit } from "lodash";
import moment from "moment";
import {
  ICycle,
  ICycleRelationObject,
  ICycleInput,
} from "../../common/types/cycleTypes";

export const cycleDataFormat = (doc: ICycle | ICycleInput) => {
  if (!doc) return undefined;
  if (doc.startDate) {
    doc.startDate = moment(doc.startDate).toDate();
  }
  if (doc.endDate) {
    doc.endDate = moment(doc.endDate).toDate();
  }
  return {
    ...doc,
  };
};

export function cycleRelationObject(cycle: ICycle): ICycleRelationObject {
  return {
    ...omit(cycle, [
      "createAt",
      "updateAt",
      "createdBy",
      "updateBy",
      "comments",
      "period",
      "boardId",
      "description",
    ]),
  };
}
