import { ICheckIn } from "../../../common/types";
import {
  CREATE_COMPANY_CHECK_IN,
  UPDATE_COMPANY_CHECK_IN,
  DELETE_COMPANY_CHECK_IN,
  FETCH_COMPANY_CHECK_INS,
  LISTEN_TO_SELECTED_COMPANY_CHECK_IN,
  CLEAR_COMPANY_CHECK_INS,
  CLEAR_SELECTED_COMPANY_CHECK_IN,
} from "./companyCheckInConstants";

const initialState = {
  checkIns: [],
  checkInsDropDown: [],
  imagesGroups: [],
  moreCheckIns: true,
  selectedCheckIn: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
};

export default function checkInReducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_COMPANY_CHECK_IN:
      return {
        ...state,
        checkIns: [...state.checkIns, payload],
      };
    case UPDATE_COMPANY_CHECK_IN:
      return {
        ...state,
        checkIns: [
          ...state.checkIns.filter((evt: ICheckIn) => evt.id !== payload.id),
          payload,
        ],
      };
    case DELETE_COMPANY_CHECK_IN:
      return {
        ...state,
        checkIns: [
          ...state.checkIns.filter((evt: ICheckIn) => evt.id !== payload),
        ],
      };
    case FETCH_COMPANY_CHECK_INS:
      return {
        ...state,
        checkIns: payload,
        moreCheckIns: payload.moreCheckIns,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_COMPANY_CHECK_IN:
      return {
        ...state,
        selectedCheckIn: payload,
      };
    case CLEAR_SELECTED_COMPANY_CHECK_IN:
      return {
        ...state,
        selectedCheckIn: null,
      };
    case CLEAR_COMPANY_CHECK_INS:
      return {
        ...state,
        checkIns: [],
        moreCheckIns: true,
        lastVisible: null,
      };
    default:
      return state;
  }
}
