import React from "react";
import { Tooltip } from "../../atoms";
import { FieldViewWrapper } from "./Fields.style";
import { TooltipField } from "./TooltipField";

interface IFieldViewProps {
  label: string;
  width?: number;
  block?: boolean;
  labelStyle?: any;
  tooltip?: string;
}
export const FieldView: React.FC<IFieldViewProps> = ({
  label,
  width,
  children,
  block,
  labelStyle = {},
  tooltip,
}) => (
  <FieldViewWrapper block={block || false}>
    <div className="field-view-label" style={{ width: width || "100%" }}>
      <Tooltip
        title={<TooltipField title={label} description={tooltip || ""} />}
      >
        <label htmlFor="" style={labelStyle}>
          {label}
        </label>
      </Tooltip>
    </div>
    <div className="field-view-value">{children}</div>
  </FieldViewWrapper>
);
