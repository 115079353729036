/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ITeam } from "../../../common/types";
import { fetchCompanyTeamsFromFirestore } from "../../../firestore/firestoreService";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { listenToCompanyTeams } from "../../../redux/data/companyTeam/companyTeamActions";
import { Form, Select } from "../../atoms";

interface ITeamSelectFieldProps {
  boardId?: string;
  bordered?: boolean;
  name: string;
  label?: string;
  rules?: any[];
  placeholder?: string;
  style?: any;
  mode?: "multiple" | "tags";
  maxTagCount?: number;
  initialValue?: any;
  getTeam?: () => ITeam;
  onChange?: (value: any) => void;
}
export const TeamSelectField: React.FC<ITeamSelectFieldProps> = ({
  name,
  label,
  boardId,
  mode,
  rules,
  getTeam,
  initialValue,
  bordered,
  onChange,
  style,
  placeholder,
  maxTagCount,
}) => {
  const dispatch = useDispatch();
  const { teams } = useSelector((state: any) => state.teams);
  useFirestoreCollection({
    query: () =>
      fetchCompanyTeamsFromFirestore([
        {
          type: "filter",
          name: "boardId",
          opr: "==",
          value: boardId,
        },
      ]),
    data: listenToCompanyTeams,
    deps: [dispatch],
  });
  const handelOnSelect = (value: string) => {
    console.log(value);
  };
  return (
    <>
      <Form.Item
        label={label}
        name={name}
        rules={rules}
        initialValue={initialValue}
      >
        <Select
          showSearch
          bordered={bordered}
          style={style}
          placeholder={placeholder || label}
          optionFilterProp="label"
          filterOption={(input, option: any) =>
            option.search.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          options={teams.map((team: ITeam) => ({
            value: team.id,
            label: team.name,
            search: team.name,
          }))}
          mode={mode}
          maxTagCount={maxTagCount}
          onSelect={handelOnSelect}
          onChange={onChange}
        ></Select>
      </Form.Item>
    </>
  );
};

export default TeamSelectField;
