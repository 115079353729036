/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { InputFormWrapper } from "../../atoms";
import { TeamCard, TeamOkrsCard } from "../../molecules";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { openModal } from "../../../redux/data/modals/modalReducer";

export const TeamDetails: React.FC = () => {
  const params = useParams();

  const teamId = params.teamId || "";
  const dispatch = useDispatch();
  const {
    selectedTeam,
    selectedTeamInitiatives,
    selectedTeamUsers,
    selectedTeamKeyResults,
    selectedTeamObjectives,
  } = useSelector((state: any) => state.teams);
  const { selectedCompany } = useSelector((state: any) => state.companies);
  const { myProfile } = useSelector((state: any) => state.myProfile);

  const handelAddObjective = () => {
    dispatch(
      openModal({
        modalType: "AddObjectiveModal",
        modalProps: {
          title: "Create new objective",
          teamId,
          type: "TEAM_OBJECTIVE",
        },
      })
    );
  };

  return (
    <AdminPageBody>
      <InputFormWrapper>
        <div className="input-form-body">
          <TeamCard
            team={selectedTeam}
            objectivesTotal={selectedTeamObjectives?.length}
            initiativesTotal={selectedTeamInitiatives?.length}
            keyResultsTotal={selectedTeamKeyResults?.length}
            usersTotal={selectedTeamUsers?.length}
          />
          <TeamOkrsCard
            cycleId={
              myProfile.defaultCycleId || selectedCompany.defaultCycleId || ""
            }
            action={handelAddObjective}
            teamId={teamId}
          />
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
