import styled from "styled-components";
export const AuthWrapper = styled.div`
  background-color: #f8f8f8;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .ant-input-affix-wrapper {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
  }
  .ant-input {
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    padding: 0 12px;
    box-sizing: border-box;
  }
`;

export const SignInWrapper = styled.div`
  background-color: #fff;
  padding: 40px 60px 60px;
  box-shadow: rgb(64 87 109 / 7%) 0px 1px 4px;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 100px;
  max-width: 440px;
  padding: 48px 32px;
  width: calc(100% - 16px);
  .sign-in {
    &-title {
      font-size: 24px;
      margin-bottom: 30px;
      text-align: center;
    }
    &-logo {
      text-align: center;
      margin-bottom: 100px;
      img {
        height: 36px;
      }
    }
    &-forget-password {
      text-align: right;
      margin-bottom: 30px;
      margin-top: -15px;
    }
    &-up {
      margin-top: 40px;
      text-align: center;
    }
    &-btn {
      background-color: #d65f8e;
      font-size: 14px;
      height: 36px;
      line-height: 36px;
      padding: 0 12px;
      width: 100%;
      color: #fff;
      border-radius: 6px;
    }
  }
`;
