import { ConnectedRouter } from "connected-react-router";
import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { history } from "../redux/store";
import { Loader } from "../components/molecules";
import ModalManager from "../common/modals/ModalManager";
import AppRouter from "./AppRouter";
import { AppWrapper } from "./App.style";

const App: React.FC = () => {
  const { initialized } = useSelector((state: any) => state.async);
  if (!initialized) return <Loader color="red" />;
  return (
    <ConnectedRouter history={history}>
      <BrowserRouter>
        <ModalManager />
        <AppWrapper>
          <div className="app-body">
            <AppRouter childProps="fdfd"></AppRouter>
          </div>
        </AppWrapper>
      </BrowserRouter>
    </ConnectedRouter>
  );
};

export default App;
