import styled from "styled-components";
export const CheckInWrapper = styled.div`
  background-color: #fff;
  border-top: 1px solid #e9eef2;
`;

export const CheckInListCardWrapper = styled.div`
  margin-top: 50px;
  .checkIn-list-card {
    &-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #edeae9;
      align-items: center;
      line-height: 24px;
    }
    &-title {
      font-size: 16px;
      font-weight: 500;
    }
    &-Actions {
    }
    &-body {
      padding: 20px;
    }
    &-tree {
      .ant-tree {
        &-treenode {
          width: 100%;
          padding: 0;
        }
        &-node-content-wrapper {
          width: 100%;
        }
        &-switcher {
          display: flex;
          align-items: center;
        }
      }
    }
  }
`;

export const CheckInItemWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #edeae9;
  line-height: 32px;
  gap: 20px;
  padding: 0 10px;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
  .checkIn-item {
    &-keyResult {
      width: 150px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-progress {
      width: 150px;
    }
    &-title {
      font-size: 14px;
      font-weight: 500;
      width: 300px;
    }
    &-dueDate {
      font-size: 14px;
      width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-owner {
      width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-status {
      flex: 1;
    }
    &-Actions {
    }
    &-body {
      padding: 20px;
    }
  }
`;
