/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { InputFormWrapper, Modal } from "../../atoms";
import {
  BoardCard,
  ObjectiveListCard,
  ActivityListCard,
} from "../../molecules";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { CheckInListCard } from "../../molecules/CheckIn/CheckInListCard";

interface IBoardDetailsProps {
  boardId?: string;
}
export const BoardDetails: React.FC<IBoardDetailsProps> = () => {
  const params = useParams();

  const boardId = params.boardId || "";
  const dispatch = useDispatch();
  const {
    selectedBoard,
    selectedBoardInitiatives,
    selectedBoardKeyResults,
    selectedBoardCycles,
    selectedBoardObjectives,
  } = useSelector((state: any) => state.boards);

  const handelAddObjective = () => {
    dispatch(
      openModal({
        modalType: "AddObjectiveModal",
        modalProps: {
          title: "Create new objective",

          boardId,
          board: selectedBoard,
        },
      })
    );
  };
  React.useEffect(() => {
    if (selectedBoardCycles.length === 0) {
      Modal.info({
        title: "This is a notification message",
        content: (
          <div>
            <p>some messages...some messages...</p>
            <p>some messages...some messages...</p>
          </div>
        ),
        onOk() {
          console.log("OK!");
        },
      });
    }
  }, [selectedBoardCycles]);

  return (
    <AdminPageBody>
      <InputFormWrapper>
        <div className="input-form-body">
          <BoardCard
            title={selectedBoard?.title || ""}
            owner={selectedBoard?.owner || null}
            description={selectedBoard?.description}
            keywords={["Sell", "Marketing"]}
            objectivesTotal={selectedBoardObjectives?.length || 0}
            keyResultsTotal={selectedBoardKeyResults?.length || 0}
            initiativesTotal={selectedBoardInitiatives?.length || 0}
            cyclesTotal={selectedBoardCycles?.length || 0}
          />
          <ObjectiveListCard action={handelAddObjective} />
          <CheckInListCard checkIns={[]} action={handelAddObjective} />
          <ActivityListCard activities={[]} />
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
