export const CREATE_COMPANY_OKR = "CREATE_COMPANY_OKR";
export const UPDATE_COMPANY_OKR = "UPDATE_COMPANY_OKR";
export const DELETE_COMPANY_OKR = "DELETE_COMPANY_OKR";
export const FETCH_COMPANY_OKRS = "FETCH_COMPANY_OKRS";
export const LISTEN_TO_SELECTED_COMPANY_OKR = "LISTEN_TO_SELECTED_COMPANY_OKR";
export const CLEAR_SELECTED_COMPANY_OKR = "CLEAR_SELECTED_COMPANY_OKR";
export const CLEAR_COMPANY_OKRS = "CLEAR_COMPANY_OKRS";
export const SET_COMPANY_OKRS_FILTER = "SET_COMPANY_OKRS_FILTER";
export const LISTEN_TO_SELECTED_OKR_KEY_RESULTS =
  "LISTEN_TO_SELECTED_OKR_KEY_RESULTS";
export const LISTEN_TO_SELECTED_OKR_INITIATIVES =
  "LISTEN_TO_SELECTED_OKR_INITIATIVES";
export const LISTEN_TO_SELECTED_OKR_CYCLE = "LISTEN_TO_SELECTED_OKR_CYCLE";
