import { ITeam } from "../../../common/types";
import {
  CREATE_COMPANY_TEAM,
  UPDATE_COMPANY_TEAM,
  DELETE_COMPANY_TEAM,
  FETCH_COMPANY_TEAMS,
  LISTEN_TO_SELECTED_COMPANY_TEAM,
  CLEAR_COMPANY_TEAMS,
  CLEAR_SELECTED_COMPANY_TEAM,
  LISTEN_TO_SELECTED_TEAM_USERS,
  LISTEN_TO_SELECTED_TEAM_OKRS,
  LISTEN_TO_SELECTED_TEAM_INITIATIVES,
} from "./companyTeamConstants";

const initialState = {
  teams: [],
  teamsDropDown: [],
  imagesGroups: [],
  moreTeams: true,
  selectedTeam: null,
  selectedTeamUsers: [],
  selectedTeamOkrs: [],
  selectedTeamInitiatives: [],
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
};

export default function teamReducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_COMPANY_TEAM:
      return {
        ...state,
        teams: [...state.teams, payload],
      };
    case UPDATE_COMPANY_TEAM:
      return {
        ...state,
        teams: [
          ...state.teams.filter((evt: ITeam) => evt.id !== payload.id),
          payload,
        ],
      };
    case DELETE_COMPANY_TEAM:
      return {
        ...state,
        teams: [...state.teams.filter((evt: ITeam) => evt.id !== payload)],
      };
    case FETCH_COMPANY_TEAMS:
      return {
        ...state,
        teams: payload,
        moreTeams: payload.moreTeams,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_COMPANY_TEAM:
      return {
        ...state,
        selectedTeam: payload,
      };
    case LISTEN_TO_SELECTED_TEAM_OKRS:
      return {
        ...state,
        selectedTeamOkrs: payload,
      };
    case LISTEN_TO_SELECTED_TEAM_INITIATIVES:
      return {
        ...state,
        selectedTeamInitiatives: payload,
      };
    case LISTEN_TO_SELECTED_TEAM_USERS:
      return {
        ...state,
        selectedTeamUsers: payload,
      };
    case CLEAR_SELECTED_COMPANY_TEAM:
      return {
        ...state,
        selectedTeam: null,
      };
    case CLEAR_COMPANY_TEAMS:
      return {
        ...state,
        teams: [],
        moreTeams: true,
        lastVisible: null,
      };
    default:
      return state;
  }
}
