import styled from "styled-components";
export const UserSelectedFieldWrapper = styled.div`
  .user-selected-field {
  }
`;

export const CycleSelectFieldWrapper = styled.div`
  .cycle-select-field {
  }
`;

export const TooltipFieldWrapper = styled.div`
  padding: 3px 8px;
  .tooltip-field {
    &-title {
      border-bottom: 1px solid #edeae9;
      font-weight: 500;
      margin-bottom: 8px;
      line-height: 32px;
    }
    &-description {
      font-size: 14px;
    }
  }
`;
interface IProps {
  block: boolean;
}

export const FieldViewWrapper = styled.div<IProps>`
  margin-bottom: 4px;
  display: flex;
  flex-shrink: 0;
  flex-direction: ${({ block }: IProps) => (block ? "column" : "row")};
  .field-view {
    &-label {
      padding-right: 8px;
      align-items: center;
      display: flex;
      height: 36px;
      justify-content: flex-start;
      label {
        font-size: 12px;
        line-height: 18px;
        color: #6d6e6f;
        /* max-width: 140px; */
        overflow: hidden;
        padding-top: 2px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    &-value {
      flex: 1;
      display: flex;
      align-items: center;
    }
  }
`;
