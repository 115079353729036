import React from "react";
import { SelectField } from "..";
import { IBoardSetting } from "../../../common/types";
import { updateCompanyBoardInFirestore } from "../../../firestore/firestoreService";
import { Alert, Button, Divider, Form, Input } from "../../atoms";

interface IAddBoardSettingFormProps {
  boardId: string;
  callback: () => void;
  btnName?: string;
}
export const AddBoardSettingForm: React.FC<IAddBoardSettingFormProps> = ({
  boardId,
  callback,
  btnName = "Create Board",
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const handleSubmit = async () => {
    try {
      const values: IBoardSetting = await form.validateFields();
      await updateCompanyBoardInFirestore(boardId, {
        mission: values.mission || "",
        missionKeywords: values.missionKeywords || [],
        strengthKeywords: values.strengthKeywords || [],
        weaknessKeywords: values.weaknessKeywords || [],
        opportunityKeywords: values.opportunityKeywords || [],
        threadsKeywords: values.threadsKeywords || [],
        priorities: values.priorities || [],
        probabilities: values.probabilities || "",
      });
      setLoading(false);
      callback();
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  return (
    <>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <Form
        form={form}
        name="horizontal_login"
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item name="mission" label="Mission">
          <Input.TextArea placeholder="Mission" />
        </Form.Item>
        <SelectField
          label="What keywords describe your mission"
          name="missionsKeyword"
          options={[]}
          mode="tags"
        />
        <SelectField
          label="What keywords describe your strengths?"
          name="strengthsKeyword"
          options={[]}
          mode="tags"
        />
        <SelectField
          label="What keywords describe your weaknesses?"
          name="strengthsKeyword"
          options={[]}
          mode="tags"
        />
        <SelectField
          label="What keywords describe your opportunities?"
          name="opportunityKeyword"
          options={[]}
          mode="tags"
        />
        <SelectField
          label="What keywords describe your threads?"
          name="threadKeyword"
          options={[]}
          mode="tags"
        />
        <Divider></Divider>
        <Button
          type="primary"
          onClick={handleSubmit}
          loading={loading}
          style={{ width: "100%" }}
        >
          {btnName}
        </Button>
      </Form>
    </>
  );
};
