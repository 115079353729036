/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { CycleList } from "../../molecules/Cycle";
import { BoardWrapper } from "./Board.style";
import { BoardCycleListToolbar } from "./BoardCycleListToolbar";

interface IBoardCycleListProps {
  boardId?: string;
}
export const BoardCycleList: React.FC<IBoardCycleListProps> = () => {
  const params = useParams();
  const boardId = params.boardId || "";
  const { selectedBoardCycles } = useSelector((state: any) => state.boards);
  const { loading } = useSelector((state: any) => state.async);
  return (
    <AdminPageBody pageToolbar={<BoardCycleListToolbar boardId={boardId} />}>
      <BoardWrapper>
        <CycleList
          cycles={selectedBoardCycles}
          loading={loading}
          defaultCycleId={""}
        />
      </BoardWrapper>
    </AdminPageBody>
  );
};
