/* eslint-disable no-console */
import React, { useRef } from "react";
import { Form } from "antd";
import { find } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "../../../atoms";
import { IUser } from "../../../../common/types";
import { useFirestoreCollection } from "../../../../hooks/useFirestoreCollection";
import { fetchCompanyUsersFromFirestore } from "../../../../firestore/firestoreService";
import { UserCell } from "../../CommonCells";
import { listenToCompanyUsers } from "../../../../redux/data/companyUser/companyUserActions";
import { EditableContext } from "./EditableForm";
import { EditableFieldWrapper } from "./EditableFields.style";

interface IEditableUserSelectedFieldProps {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: string;
  record: string;
  placeholder?: string;
  style?: any;
  rules?: any;
  shouldExecute?: boolean;
  handleSave: (name: string, record: { userId: string; user: IUser }) => void;
}

export const EditableUserSelectedField: React.FC<
  IEditableUserSelectedFieldProps
> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  placeholder,
  style,
  rules,
  shouldExecute = true,
  handleSave,
  ...restProps
}) => {
  const valueRef = useRef<any>(null);
  const inputRef = useRef<any>(null);
  const dispatch = useDispatch();
  const { users } = useSelector((state: any) => state.users);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const form = React.useContext(EditableContext)!;

  const save = async () => {
    try {
      const values = await form.validateFields();
      const owner = find(users, ["id", values[dataIndex]]);
      // toggleEdit();
      if (owner) {
        handleSave(dataIndex, { userId: values[dataIndex], user: owner });
      }
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  useFirestoreCollection({
    query: () => fetchCompanyUsersFromFirestore([]),
    data: listenToCompanyUsers,
    deps: [dispatch],
    shouldExecute,
  });

  React.useEffect(() => {
    if (record && record !== valueRef.current) {
      form.setFieldsValue({ [dataIndex]: record });
      valueRef.current = record;
    }
  }, [dataIndex, record]);
  return (
    <EditableFieldWrapper {...restProps}>
      {" "}
      <EditableContext.Provider value={form}>
        <Form.Item
          className="editable-field-item"
          style={{ margin: 0 }}
          name={dataIndex}
          rules={rules}
        >
          <Select
            showSearch
            className="editable-field-selected-input"
            bordered={false}
            ref={inputRef}
            style={style}
            placeholder={placeholder}
            optionFilterProp="label"
            filterOption={(input, option: any) =>
              option.search.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={users.map((user: IUser) => ({
              value: user.id,
              label: <UserCell user={user} nameOnly xSmall />,
              search: `${user.firstName} ${user.lastName}`,
            }))}
            onSelect={save}
          ></Select>
        </Form.Item>
      </EditableContext.Provider>
    </EditableFieldWrapper>
  );
};
