/* eslint-disable no-console */
import React, { useRef } from "react";
import { Input, Form } from "antd";
import { EditableContext } from "./EditableForm";
import { EditableFieldWrapper } from "./EditableFields.style";

interface IEditableTextFieldProps {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: string;
  value: string | number;
  handleSave: (name: string, value: string | number) => void;
}

export const EditableTextField: React.FC<IEditableTextFieldProps> = ({
  title,
  editable,
  dataIndex,
  value,
  handleSave,
  ...restProps
}) => {
  const valueRef = useRef<any>(null);
  const inputRef = useRef<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const form = React.useContext(EditableContext)!;

  const save = async () => {
    try {
      const values = await form.validateFields();

      // toggleEdit();
      handleSave(dataIndex, values[dataIndex]);
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  React.useEffect(() => {
    if (value !== valueRef.current) {
      form.setFieldsValue({ [dataIndex]: value });
      valueRef.current = value;
    }
  }, [dataIndex, value]);

  return (
    <EditableFieldWrapper {...restProps}>
      {" "}
      <EditableContext.Provider value={form}>
        <Form.Item
          className="editable-field-item"
          style={{ margin: 0 }}
          name={dataIndex}
        >
          <Input
            className="editable-field-simple-text"
            ref={inputRef}
            data-replicated-value={value}
            onBlur={save}
          />
        </Form.Item>
      </EditableContext.Provider>
    </EditableFieldWrapper>
  );
};
