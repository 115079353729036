/* eslint-disable no-console */
import React from "react";
import classNames from "classnames";
import { IUser, IUserRelationObject } from "../../../common/types";
import { Avatar } from "../../atoms";
import { UserCellWrapper } from "./Cells.style";

interface IUserCellProps {
  user: IUser | IUserRelationObject | null;
  avatarOnly?: boolean;
  nameOnly?: boolean;
  className?: string;
  small?: boolean;
  xSmall?: boolean;
}

export const UserCell: React.FC<IUserCellProps> = ({
  user,
  avatarOnly,
  nameOnly,
  className,
  small,
  xSmall,
}) => {
  if (!user) return <span>--</span>;
  return (
    <UserCellWrapper className={className}>
      {user.image ? (
        <Avatar
          className={classNames("user-cell-avatar", { small, xSmall })}
          src={user.image.url}
        />
      ) : (
        <Avatar className={classNames("user-cell-avatar", { small, xSmall })}>
          {user?.avatar || "AS"}
        </Avatar>
      )}
      {!avatarOnly && (
        <div className="user-cell-info">
          <div className={classNames("user-cell-full-name", { small, xSmall })}>
            {user.displayName
              ? user.displayName
              : `${user.firstName} ${user.lastName}`}
          </div>
          {!nameOnly && <div className="user-cell-email">{user.email} </div>}
        </div>
      )}
    </UserCellWrapper>
  );
};
