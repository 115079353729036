import React from "react";
import { Form } from "antd";
import { FormInstance } from "antd/lib/form";

export const EditableContext = React.createContext<FormInstance<any> | null>(
  null
);

export const EditableFieldForm: React.FC = ({ children, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <div {...props}>{children}</div>
      </EditableContext.Provider>
    </Form>
  );
};
