/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { find } from "lodash";
import moment from "moment";
import { SelectField, UserSelectedField } from "../../molecules";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { IInitiativeInput } from "../../../common/types";
import {
  Alert,
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputFormWrapper,
  Space,
} from "../../atoms";
import { addCompanyInitiativeToFirestore } from "../../../firestore/firestoreService";
import { closeModal } from "../../../redux/data/modals/modalReducer";
import {
  initiativeStatusTypesOptions,
  initiativePriorityTypesOptions,
} from "../../../enums";
import { InputField } from "../../molecules/commonFields/InputField";
interface IAddInitiativeFormProps {
  boardId?: string;
  objectiveId: string;
  keyResultId: string;
  cycleId: string;
}
export const AddInitiativeForm: React.FC<IAddInitiativeFormProps> = (props) => {
  const dispatch = useDispatch();
  const { users } = useSelector((state: any) => state.users);
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleSubmit = async () => {
    try {
      const values: IInitiativeInput = await form.validateFields();
      setLoading(true);
      const owner = find(users, ["id", values.ownerId]);
      await addCompanyInitiativeToFirestore({
        name: values.name,
        owner,
        ownerId: values.ownerId || "",
        description: values.description || "",
        dueDate: values.dueDate ? moment(values.dueDate) : null,
        keyResultId: props.keyResultId,
        objectiveId: props.objectiveId,
        status: values.status,
        boardId: props.boardId || null,
        priority: values.priority || "",
        cycleId: props.cycleId,
      });
      setLoading(false);
      dispatch(closeModal());
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  return (
    <ModalWrapper
      {...props}
      onSave={handleSubmit}
      loading={loading}
      className="full-page"
      footer={null}
    >
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <InputFormWrapper>
        <div className="input-form-body">
          <div className="input-form-title">Create New Initiative</div>
          <div className="input-form-description">Initiative</div>
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
          >
            <InputField
              label="Initiative name"
              name="name"
              rules={[{ required: true, message: "Missing name" }]}
              placeholder="Initiative name"
            />
            <Form.Item name="description" label="Description">
              <Input.TextArea placeholder="Description" />
            </Form.Item>
            <UserSelectedField
              label="Who owns this Initiative?"
              name="ownerId"
              boardId={props.boardId}
            />
            <Space className="input-form-space">
              <SelectField
                label="Status"
                name="status"
                options={initiativeStatusTypesOptions}
                initialValue={"TO_DO"}
              />
              <SelectField
                label="Priority"
                name="priority"
                options={initiativePriorityTypesOptions}
              />
            </Space>
            <Form.Item name="dueDate" label="Due Date">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              Create Initiative
            </Button>
          </Form>
        </div>
      </InputFormWrapper>
    </ModalWrapper>
  );
};
