/* eslint-disable no-console */
import React, { useRef } from "react";
import { Form } from "antd";
import { find } from "lodash";
import { Select } from "../../../atoms";
import { ICycle } from "../../../../common/types";
import { useFirestoreCollection } from "../../../../hooks/useFirestoreCollection";
import { fetchCompanyCyclesFromFirestore } from "../../../../firestore/firestoreService";
import { CycleCell } from "../../CommonCells";
import { EditableContext } from "./EditableForm";
import { EditableFieldWrapper } from "./EditableFields.style";

interface IEditableCycleSelectedFieldProps {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: string;
  record: string;
  placeholder?: string;
  style?: any;
  handleSave?: (
    name: string,
    record: { cycleId: string; cycle: ICycle }
  ) => void;
}

export const EditableCycleSelectedField: React.FC<
  IEditableCycleSelectedFieldProps
> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  placeholder,
  style,
  handleSave,
  ...restProps
}) => {
  const valueRef = useRef<any>(null);
  const inputRef = useRef<any>(null);
  const [cycles, setCycles] = React.useState([]);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const form = React.useContext(EditableContext)!;

  const save = async () => {
    try {
      const values = await form.validateFields();
      const item = find(cycles, ["id", values[dataIndex]]);
      // toggleEdit();
      if (item) {
        handleSave &&
          handleSave(dataIndex, { cycleId: values[dataIndex], cycle: item });
      }
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  useFirestoreCollection({
    query: () =>
      fetchCompanyCyclesFromFirestore([
        {
          type: "sorting",
          name: "startDate",
          opr: "asc",
          order: "asc",
        },
      ]),
    data: setCycles,
    deps: [setCycles],
    local: true,
  });

  React.useEffect(() => {
    if (record !== valueRef.current) {
      form.setFieldsValue({ [dataIndex]: record });
      valueRef.current = record;
    }
  }, [dataIndex, record]);

  return (
    <EditableFieldWrapper {...restProps}>
      {" "}
      <EditableContext.Provider value={form}>
        <Form.Item
          className="editable-field-item"
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Select
            showSearch
            className="editable-field-selected-input"
            bordered={false}
            ref={inputRef}
            style={style}
            placeholder={placeholder}
            optionFilterProp="label"
            filterOption={(input, option: any) =>
              option.search.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={cycles.map((cycle: ICycle) => ({
              value: cycle.id,
              label: <CycleCell cycle={cycle} nameOnly />,
              search: cycle.name,
            }))}
            onSelect={save}
          ></Select>
        </Form.Item>
      </EditableContext.Provider>
    </EditableFieldWrapper>
  );
};
