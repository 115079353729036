import styled from "styled-components";
export const CommentWrapper = styled.div`
  background-color: #f9f8f8;
  border-top: 1px solid #e9eef2;
  display: flex;
  padding: 8px 24px;
`;

export const CommentFormWrapper = styled.div`
  background-color: #f9f8f8;
  border-top: 1px solid #e9eef2;
  display: flex;
  padding: 8px 24px;
  .comment-form {
    &-user {
      flex: 0 0 30px;
    }
    &-area {
      flex: 1;
      .ant-form-item {
        margin-bottom: 5px;
      }
    }
    &-textarea {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      background-color: #fff;
      border-width: 1px;
      border-style: solid;
      border-color: #cfcbcb;
      border-radius: 6px;
      padding: 6px 12px;
      box-sizing: border-box;
      min-height: 30px;
      overflow-wrap: break-word;
      resize: none;
      white-space: pre-wrap;
      width: calc(100% + 14px);
      transition: all 0.3s ease;
      &:hover {
        border-color: #afabac;
        transition: border-color 100ms;
      }
      &:focus {
        border-color: #6d6e6f;
        outline: none;
        box-shadow: none;
        min-height: 120px;
      }
    }
    &-action {
      /* margin-top: 5px; */
      /* border-top: 1px solid #cfcbcb; */
      padding: 5px;
      width: 100%;
      text-align: right;
      /* background-color: #cccc; */
      z-index: 222;
      /* bottom: -20px;
      position: absolute; */
      display: block;
      &.show {
        bottom: -20px;
        display: block;
        transition: all 0.3s ease;
      }
      &-btn {
        font-size: 12px;
        height: 28px;
        line-height: 26px;
        padding: 0 8px;
        border-radius: 6px;
        user-select: none;
      }
    }
  }
`;
