/* eslint-disable @typescript-eslint/await-thenable */
/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { IBoard, IOkr } from "../../../common/types";
import {
  Alert,
  Button,
  Divider,
  Form,
  Input,
  InputFormWrapper,
  Space,
} from "../../atoms";
import { inviteCompanyUserInFirestore } from "../../../firestore/firestoreService";
import { closeModal } from "../../../redux/data/modals/modalReducer";
interface IInviteUsersModalProps {
  boardId?: string;
  objective: IOkr;
  objectiveId: string;
  board?: IBoard;
  keyResult: IOkr;
  keyResultId: string;
}
export const InviteUsersModal: React.FC<IInviteUsersModalProps> = (props) => {
  const dispatch = useDispatch();
  // const { boards, selectedBoardCycle } = useSelector(
  //   (state: any) => state.boards
  // );
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleSubmit = async () => {
    try {
      const values: { emails: string[] } = await form.validateFields();
      setLoading(true);
      console.log(values);
      const keyResult = await inviteCompanyUserInFirestore(
        values.emails,
        props.boardId
      );
      setLoading(false);
      dispatch(closeModal());
      console.log(keyResult);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  return (
    <ModalWrapper
      {...props}
      onSave={handleSubmit}
      loading={loading}
      className="full-page"
      footer={null}
    >
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <InputFormWrapper>
        <div className="input-form-body">
          <div className="input-form-title">Invite Users</div>
          <div className="input-form-description">User</div>
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
          >
            <Form.List name="emails" initialValue={[{}]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                      className="input-form-space"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "email"]}
                        rules={[
                          {
                            type: "email",
                            message: "The input is not valid E-mail!",
                          },
                          {
                            required: true,
                            message: "Please input your E-mail!",
                          },
                        ]}
                      >
                        <Input placeholder="Email" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add email
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              Invite users
            </Button>
          </Form>
        </div>
      </InputFormWrapper>
    </ModalWrapper>
  );
};
