/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import {
  AppstoreAddOutlined,
  DownOutlined,
  PlusOutlined,
  SortAscendingOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Menu } from "../../atoms";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { PageToolbar } from "../../molecules";

interface ICompanyOkrsInitiativeToolbarProps {
  objectiveId?: string;
  keyResultId?: string;
}
export const CompanyOkrsInitiativeToolbar: React.FC<
  ICompanyOkrsInitiativeToolbarProps
> = ({ objectiveId, keyResultId }) => {
  const dispatch = useDispatch();
  const handelSorting = () => {
    console.log("filterCycles");
  };
  const handelCustomize = () => {
    console.log("filterCycles");
  };
  const handelAddObjective = () => {
    dispatch(
      openModal({
        modalType: "AddInitiativeForm",
        modalProps: {
          title: "Add Initiative",
          objectiveId,
          keyResultId,
        },
      })
    );
  };
  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => console.log("dd")}>
        Suggestions Initiative
      </Menu.Item>
    </Menu>
  );
  return (
    <PageToolbar>
      <Dropdown.Button
        type="primary"
        onClick={handelAddObjective}
        overlay={menu}
        icon={<DownOutlined />}
      >
        <PlusOutlined /> Add Initiative
      </Dropdown.Button>

      <div className="page-toolbar-right-children-elements">
        <Button
          type="link"
          onClick={handelSorting}
          icon={<SortAscendingOutlined />}
          className="page-toolbar-btn-link"
        >
          Sorting
        </Button>
        <Button
          type="link"
          onClick={handelCustomize}
          icon={<AppstoreAddOutlined />}
          className="page-toolbar-btn-link"
        >
          Customize
        </Button>
      </div>
    </PageToolbar>
  );
};
