/* eslint-disable no-console */
import React from "react";
import { useParams } from "react-router-dom";
import { ChangePassword } from "../../App/components/organisms";

const ChangePasswordPage: React.FC = () => {
  const params = useParams();

  return (
    <div>
      <ChangePassword changePasswordKey={params.key || ""} />
    </div>
  );
};

export default ChangePasswordPage;
