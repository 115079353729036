/* eslint-disable no-console */
import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Alert, Button, Form, Input } from "../../atoms";
import { SelectField } from "../../molecules";
import { SignUpWrapper } from "./Registration.style";

interface IRegCompanyDetailsProps {
  actionKey?: string;
}
export const RegCompanyDetails: React.FC<IRegCompanyDetailsProps> = () => {
  const params = useParams();
  const companyId = params.key || "";
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  console.log(companyId);
  const handleOnFinish = async () => {
    setLoading(true);
    try {
      // const values: { password: string } = await form.validateFields();
      // await registerInFirebase({
      //   email: "rsb@sdsd.com",
      //   password: values.password,
      // });
      navigate(`/registration/user-invitations/232`);
      setLoading(false);
    } catch (errorInfo: any) {
      setLoading(false);
      setError("Problem with email please try again");
    }
  };
  return (
    <div className="registration-page">
      <div className="registration-page-body">
        <SignUpWrapper>
          <div className="sign-up-title">Your first board details</div>
          <div className="sign-up-subtitle">
            This will be the name of your company - Choose something your team
            will recognize.{" "}
          </div>
          {error !== "" && (
            <Alert message={error} type="error" style={{ marginBottom: 20 }} />
          )}
          <Form
            form={form}
            layout="vertical"
            onFinish={handleOnFinish}
            autoComplete="off"
          >
            <Form.Item name="title" label="Title">
              <Input placeholder="Title" />
            </Form.Item>
            <Form.Item name="mission" label="Mission">
              <Input.TextArea placeholder="Mission" />
            </Form.Item>
            <SelectField
              label="What keywords describe your mission"
              name="missionsKeyword"
              options={[]}
              mode="tags"
            />
            <SelectField
              label="What keywords describe your strengths?"
              name="strengthsKeyword"
              options={[]}
              mode="multiple"
            />
            <SelectField
              label="What keywords describe your weaknesses?"
              name="strengthsKeyword"
              options={[]}
              mode="multiple"
            />
            <SelectField
              label="What keywords describe your opportunities?"
              name="opportunityKeyword"
              options={[]}
              mode="multiple"
            />
            <SelectField
              label="What keywords describe your threads?"
              name="threadKeyword"
              options={[]}
              mode="multiple"
            />
            <Button className="sign-up-btn" loading={loading} htmlType="submit">
              Next
            </Button>
          </Form>
        </SignUpWrapper>
      </div>
    </div>
  );
};
