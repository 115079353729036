/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { InputFormWrapper } from "../../atoms";
import { ActivityListCard, InitiativeCard } from "../../molecules";

interface IInitiativeDetailsProps {
  keyResultId?: string;
}
export const InitiativeDetails: React.FC<IInitiativeDetailsProps> = () => {
  const { selectedInitiative } = useSelector((state: any) => state.initiatives);
  return (
    <AdminPageBody>
      <InputFormWrapper>
        <div className="input-form-body">
          <InitiativeCard
            initiative={selectedInitiative}
            keywords={["Sell", "Marketing"]}
          />

          <ActivityListCard activities={[]} />
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
