/* eslint-disable no-console */

import { IInitiative, IOkr, ITeam, IUser } from "../../../common/types";
import {
  CREATE_COMPANY_TEAM,
  UPDATE_COMPANY_TEAM,
  DELETE_COMPANY_TEAM,
  FETCH_COMPANY_TEAMS,
  LISTEN_TO_SELECTED_COMPANY_TEAM,
  CLEAR_COMPANY_TEAMS,
  CLEAR_SELECTED_COMPANY_TEAM,
  LISTEN_TO_SELECTED_TEAM_USERS,
  LISTEN_TO_SELECTED_TEAM_OKRS,
  LISTEN_TO_SELECTED_TEAM_INITIATIVES,
} from "./companyTeamConstants";

export function listenToCompanyTeams(teams: ITeam[]) {
  return {
    type: FETCH_COMPANY_TEAMS,
    payload: teams,
  };
}

export function listenToSelectedCompanyTeam(team: ITeam) {
  return {
    type: LISTEN_TO_SELECTED_COMPANY_TEAM,
    payload: team,
  };
}
export function listenToSelectedTeamOkrs(okrs: IOkr[]) {
  return {
    type: LISTEN_TO_SELECTED_TEAM_OKRS,
    payload: okrs,
  };
}

export function listenToSelectedTeamInitiatives(initiatives: IInitiative[]) {
  return {
    type: LISTEN_TO_SELECTED_TEAM_INITIATIVES,
    payload: initiatives,
  };
}
export function listenToSelectedTeamUsers(users: IUser[]) {
  return {
    type: LISTEN_TO_SELECTED_TEAM_USERS,
    payload: users,
  };
}
export function clearSelectedCompanyTeam() {
  return {
    type: CLEAR_SELECTED_COMPANY_TEAM,
  };
}

export function createCompanyTeam(team: ITeam) {
  return {
    type: CREATE_COMPANY_TEAM,
    payload: team,
  };
}

export function updateCompanyTeam(team: ITeam) {
  return {
    type: UPDATE_COMPANY_TEAM,
    payload: team,
  };
}

export function deleteCompanyTeam(teamId: string) {
  return {
    type: DELETE_COMPANY_TEAM,
    payload: teamId,
  };
}

export function clearCompanyTeams() {
  return {
    type: CLEAR_COMPANY_TEAMS,
  };
}
