/* eslint-disable no-console */
import moment from "moment";
import React from "react";
import { Switch } from "antd";
import { useDispatch } from "react-redux";
import { ActionCell } from "../CommonCells";
import { ICycle } from "../../../common/types";
import { Table, Tooltip } from "../../atoms";
import {
  deleteCompanyCycleInFirestore,
  toggleCompanyCycleDefaultInFirestore,
} from "../../../firestore/firestoreService";
import { CycleCell } from "../CommonCells/CycleCell";
import { openDrawer } from "../../../redux/data/drawer/drawerReducer";
import { CycleWrapper } from "./Cycle.style";

interface ICycleListProps {
  cycles: ICycle[];
  loading: boolean;
  defaultCycleId: string;
}
export const CycleList: React.FC<ICycleListProps> = ({
  loading,
  cycles,
  defaultCycleId,
}) => {
  const handelDeleteCycle = async (id: string) => {
    await deleteCompanyCycleInFirestore(id);
  };
  const handelToggleDefaultCycle = (id: string) => async () => {
    await toggleCompanyCycleDefaultInFirestore(id);
  };
  const dispatch = useDispatch();
  const handelOpenCycle = (id: string) => () => {
    dispatch(
      openDrawer({
        drawerType: "CycleDetailsDrawer",
        drawerProps: {
          id,
        },
      })
    );
  };
  const columnsList = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: true,
      render: (v: string, record: ICycle) => (
        <div onClick={handelOpenCycle(record.id)}>
          <CycleCell cycle={record} />
        </div>
      ),
      width: 400,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (v: string) => v || "",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (v: string) => (
        <Tooltip title={moment(v).format("DD/MM/YYYY")}>
          <span>{moment(v).fromNow()}</span>
        </Tooltip>
      ),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (v: string) => (
        <Tooltip title={moment(v).format("DD/MM/YYYY")}>
          <span>{moment(v).fromNow()}</span>
        </Tooltip>
      ),
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      render: (v: string) => (
        <div
          style={{ backgroundColor: v, height: 32, width: 32, borderRadius: 5 }}
        ></div>
      ),
    },
    {
      title: "Default Cycle",
      dataIndex: "defaultCycle",
      key: "defaultCycle",
      render: (v: boolean, record: ICycle) => (
        <Switch
          onChange={handelToggleDefaultCycle(record.id)}
          checked={record.id === defaultCycleId}
        />
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (v: moment.Moment) => moment(v).format("DD/MM/YYYY"),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: ICycle) => (
        <ActionCell onDelete={() => handelDeleteCycle(record.id)} />
      ),
    },
  ];
  return (
    <CycleWrapper>
      <Table
        columns={columnsList}
        dataSource={cycles}
        loading={loading}
        rowKey="id"
        // scroll={{ x: 800, y: 500 }}
      />
    </CycleWrapper>
  );
};
