/* eslint-disable no-console */
import React from "react";
import {
  LineChart as LineChartComponent,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

interface ILineChartLine {
  dataKey: string;
  stroke: string;
  name: string;
  type?:
    | "basis"
    | "basisClosed"
    | "basisOpen"
    | "linear"
    | "linearClosed"
    | "natural"
    | "monotoneX"
    | "monotoneY"
    | "monotone"
    | "step"
    | "stepBefore"
    | "stepAfter";
}
interface ILineChartProps {
  data: any[];
  lines: ILineChartLine[];
}
export const LineChart: React.FC<ILineChartProps> = ({ data, lines }) => {
  console.log(data);
  return (
    <div style={{ height: 400 }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChartComponent
          width={400}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          {lines.map((line) => (
            <Line
              key={line.dataKey}
              type={line.type || "monotone"}
              dataKey={line.dataKey}
              stroke={line.stroke}
              name={line.name}
            />
          ))}
        </LineChartComponent>
      </ResponsiveContainer>
    </div>
  );
};
