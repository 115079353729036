/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { InitiativeList } from "../../molecules";
import { TeamInitiativeToolbar } from "./TeamInitiativeToolbar";

export const TeamInitiativeList: React.FC = () => {
  const { initiatives } = useSelector((state: any) => state.initiatives);
  const { loading } = useSelector((state: any) => state.async);
  return (
    <AdminPageBody pageToolbar={<TeamInitiativeToolbar />}>
      <InitiativeList initiatives={initiatives} loading={loading} />
    </AdminPageBody>
  );
};
