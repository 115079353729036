import styled from "styled-components";
export const InitiativeWrapper = styled.div`
  background-color: #fff;
  width: 100%;
`;

export const InitiativeCardWrapper = styled.div`
  padding: 0 24px;
  .initiative-card {
    &-header {
      margin-bottom: 5px;
    }
    &-title {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
    }
    &-owner {
    }
    &-description {
      font-size: 14px;
      line-height: 32px;
      color: #7c7d7e;
    }
    &-keywords {
      display: flex;
      margin: 10px 0px;
      flex-wrap: wrap;
      &-name {
        display: block;
        background-color: #229cff13;
        color: #118fe9;
        border: none;
      }
    }
    &-footer {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #edeae9;
      padding: 10px;
      gap: 20px;
    }
    &-stat {
      background-color: #f1f7ff;
      color: #399ceb;
      border: #f1f7ff;
      border-radius: 7px;
      width: 100%;
      font-size: 13px;
      &-value {
        font-weight: 900;
        font-size: 14px;
        margin-right: 5px;
      }
      &-label {
      }
    }
  }
`;

export const InitiativeListCardWrapper = styled.div`
  margin-top: 50px;
  .initiative-list-card {
    &-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #edeae9;
      align-items: center;
      line-height: 24px;
    }
    &-title {
      font-size: 16px;
      font-weight: 500;
    }
    &-Actions {
    }
    &-body {
      padding: 20px;
    }
    &-tree {
      .ant-tree {
        &-treenode {
          width: 100%;
          padding: 0;
        }
        &-node-content-wrapper {
          width: 100%;
        }
        &-switcher {
          display: flex;
          align-items: center;
        }
      }
    }
  }
`;

export const InitiativeItemWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #edeae9;
  line-height: 32px;
  gap: 20px;
  padding: 0 10px;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
  &:hover {
    transition: 300ms all ease;
    background-color: #f5f5f5;
    .initiative-item-completeIcon {
      transform: scale(1.2);
    }
  }
  .initiative-item {
    &-completeIcon {
      width: 40px;
      font-size: 18px;
      color: #ccc;
      transition: 300ms all ease;
      &:hover {
        transform: scale(1.2);
      }
      &.complete {
        color: #25c2a1;
      }
    }
    &-keyResult {
      width: 150px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-progress {
      width: 150px;
    }
    &-title {
      font-size: 14px;
      font-weight: 500;
      width: 200px;
    }
    &-dueDate {
      font-size: 14px;
      width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-owner {
      width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-Actions {
    }
    &-body {
      padding: 20px;
    }
  }
`;
