/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { OkrList } from "../../molecules";
import { TeamWrapper } from "./Team.style";
import { TeamOkrToolbar } from "./TeamOkrToolbar";

interface ITeamOkrListProps {
  myProfileId?: string;
}
export const TeamOkrList: React.FC<ITeamOkrListProps> = () => {
  const [okrView, setOkrView] = React.useState("list");
  const { selectedTeamOkrs, selectedTeam } = useSelector(
    (state: any) => state.teams
  );
  const { loading } = useSelector((state: any) => state.async);
  const handleOkrView = (e: any) => {
    setOkrView(e.target.value);
  };
  return (
    <AdminPageBody
      pageToolbar={
        <TeamOkrToolbar okrView={okrView} onChangeOkrView={handleOkrView} />
      }
    >
      <TeamWrapper>
        <OkrList
          okrs={selectedTeamOkrs}
          loading={loading}
          teamId={selectedTeam.id}
        />
      </TeamWrapper>
    </AdminPageBody>
  );
};
