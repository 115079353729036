/* eslint-disable no-console */
import { AimOutlined } from "@ant-design/icons";
import { Progress } from "antd";
import React from "react";
import { IOkr } from "../../../common/types";
import { UserCell } from "../CommonCells";
import { OrgChartCardWrapper } from "./OrgChart.style";

interface IOrgChartCardObjectiveProps {
  data: IOkr;
  score: number;
  openOkr: (id: string) => () => void;
}
export const OrgChartCardObjective: React.FC<IOrgChartCardObjectiveProps> = ({
  data,
  score,
  openOkr,
}) => {
  console.log("openOkr");
  return (
    <OrgChartCardWrapper>
      <div className="org-chart-card-objective">
        <div className="org-chart-card" onClick={openOkr(data.id)}>
          <div className="org-chart-card-icon">
            <AimOutlined className="alignment-view-icon" />
          </div>
          <div>
            <div className="org-chart-card-objective-title">
              <a href={`/objective/${data.id}/details`}>{data.title}</a>
            </div>{" "}
            <UserCell
              className="org-chart-card-objective-owner"
              user={data.owner || null}
              nameOnly
              xSmall
            />
            {/* <div className="org-chart-card-objective-cycle">
              {data.cycle?.name}
            </div> */}
          </div>
        </div>
        <Progress percent={score || 0} className="org-chart-card-progress" />
      </div>
    </OrgChartCardWrapper>
  );
};
