import { IInitiative } from "../../../common/types";
import {
  CREATE_COMPANY_INITIATIVE,
  UPDATE_COMPANY_INITIATIVE,
  DELETE_COMPANY_INITIATIVE,
  FETCH_COMPANY_INITIATIVES,
  LISTEN_TO_SELECTED_COMPANY_INITIATIVE,
  CLEAR_COMPANY_INITIATIVES,
  CLEAR_SELECTED_COMPANY_INITIATIVE,
} from "./companyInitiativeConstants";

const initialState = {
  initiatives: [],
  initiativesDropDown: [],
  imagesGroups: [],
  moreInitiatives: true,
  selectedInitiative: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
};

export default function initiativeReducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_COMPANY_INITIATIVE:
      return {
        ...state,
        initiatives: [...state.initiatives, payload],
      };
    case UPDATE_COMPANY_INITIATIVE:
      return {
        ...state,
        initiatives: [
          ...state.initiatives.filter(
            (evt: IInitiative) => evt.id !== payload.id
          ),
          payload,
        ],
      };
    case DELETE_COMPANY_INITIATIVE:
      return {
        ...state,
        initiatives: [
          ...state.initiatives.filter((evt: IInitiative) => evt.id !== payload),
        ],
      };
    case FETCH_COMPANY_INITIATIVES:
      return {
        ...state,
        initiatives: payload,
        moreInitiatives: payload.moreInitiatives,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_COMPANY_INITIATIVE:
      return {
        ...state,
        selectedInitiative: payload,
      };
    case CLEAR_SELECTED_COMPANY_INITIATIVE:
      return {
        ...state,
        selectedInitiative: null,
      };
    case CLEAR_COMPANY_INITIATIVES:
      return {
        ...state,
        initiatives: [],
        moreInitiatives: true,
        lastVisible: null,
      };
    default:
      return state;
  }
}
