/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import {
  fetchCompanyInitiativesFromFirestore,
  fetchCompanyOkrsFromFirestore,
  listenToCompanyUserFromFirestore,
} from "../../App/firestore/firestoreService";
import { userFirestoreDoc } from "../../App/hooks/useFirestoreDoc";
import { useFirestoreCollection } from "../../App/hooks/useFirestoreCollection";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";
import { listenToSelectedCompanyUser } from "../../App/redux/data/companyUser/companyUserActions";
import {
  listenToSelectedUserInitiatives,
  listenToSelectedUserOkrs,
} from "../../App/redux/data/user/userActions";

const currentMenu = (userId: string) => [
  {
    key: "details",
    label: "Details",
    link: `/user/${userId}/details`,
  },
  {
    key: "okrs",
    label: "OKRs",
    link: `/user/${userId}/okrs`,
  },
  {
    key: "initiatives",
    label: "Initiatives",
    link: `/user/${userId}/initiatives`,
  },
];
const UserViewPage: React.FC = () => {
  const params = useParams();

  const userId = params.userId || "";
  const headerMenu = currentMenu(userId);
  const { selectedUser } = useSelector((state: any) => state.users);
  const dispatch = useDispatch();
  userFirestoreDoc({
    query: () => listenToCompanyUserFromFirestore(userId),
    data: listenToSelectedCompanyUser,
    deps: [dispatch, userId],
  });
  useFirestoreCollection({
    query: () =>
      fetchCompanyOkrsFromFirestore([
        {
          type: "filter",
          name: "ownerId",
          opr: "==",
          value: userId,
        },
      ]),
    data: listenToSelectedUserOkrs,
    deps: [dispatch, userId],
  });
  useFirestoreCollection({
    query: () =>
      fetchCompanyInitiativesFromFirestore([
        {
          type: "filter",
          name: "ownerId",
          opr: "==",
          value: userId,
        },
      ]),
    data: listenToSelectedUserInitiatives,
    deps: [dispatch],
  });
  return (
    <AdminPage title={`${selectedUser?.displayName}`} headerMenu={headerMenu}>
      <Outlet />
    </AdminPage>
  );
};

export default UserViewPage;
