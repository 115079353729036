/* eslint-disable no-console */
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { ActionCell, UserCell } from "../CommonCells";
import { IInitiative, IOkr, IUser } from "../../../common/types";
import { deleteCompanyOkrInFirestore } from "../../../firestore/firestoreService";
import { Table } from "../../atoms";
import { KeyResultWrapper } from "./KeyResult.style";

interface IOkrListProps {
  keyResults: IOkr[];
  loading: boolean;
}
export const KeyResultList: React.FC<IOkrListProps> = ({
  keyResults,
  loading,
}) => {
  const handelDeleteOkr = (id: string) => async () => {
    await deleteCompanyOkrInFirestore(id);
  };
  const columnsList = [
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
      fixed: true,
      render: (v: string, record: any) => (
        <Link to={`/key-result/${record.id}/details`}>{v}</Link>
      ),
      width: 400,
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      render: (v: IUser) => <UserCell user={v} avatarOnly />,
    },
    {
      title: "Initiatives",
      dataIndex: "initiatives",
      key: "initiatives",
      render: (v: IInitiative[]) => v?.length || 0,
    },
    {
      title: "Cycle",
      dataIndex: "cycle",
      key: "cycle",
      render: (v: string) => v,
    },
    {
      title: "Completion",
      dataIndex: "completion",
      key: "completion",
      render: (v: string) => v,
    },
    {
      title: "Progress",
      dataIndex: "progress",
      key: "progress",
      render: (v: string) => v,
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (v: moment.Moment) => moment(v).format("DD/MM/YYYY"),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: IOkr) => (
        <ActionCell onDelete={handelDeleteOkr(record.id)} />
      ),
    },
  ];
  return (
    <KeyResultWrapper>
      <Table
        columns={columnsList}
        dataSource={keyResults}
        loading={loading}
        rowKey="id"
        // scroll={{ x: 800, y: 500 }}
      />
    </KeyResultWrapper>
  );
};
