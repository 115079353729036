/* eslint-disable no-console */
import cuid from "cuid";
import React from "react";
import { useSelector } from "react-redux";
import { useOKRsChartDataGenerator } from "../../../hooks/useOKRsChartDataGenerator";
import { Loader, OrgChartComponent } from "../../molecules";

interface ICompanyOkrsChartProps {
  myProfileId?: string;
}
export const CompanyOkrsChart: React.FC<ICompanyOkrsChartProps> = () => {
  const { okrs } = useSelector((state: any) => state.okrs);
  const { loading } = useSelector((state: any) => state.async);
  const [data, setData] = React.useState<any[]>([]);
  useOKRsChartDataGenerator({
    parentNode: { id: cuid(), name: "company", treeType: "COMPANY" },
    okrs,
    deps: [okrs],
    data: setData,
  });
  function onNodeClick(nodeId: string) {
    alert(`clicked ${nodeId}`);
  }
  if (loading) return <Loader color="success" title="Loading" />;
  return <OrgChartComponent onNodeClick={onNodeClick} data={data} />;
};
