/* eslint-disable import/no-named-as-default */
/* eslint-disable no-console */
import faker from "@faker-js/faker";
import React from "react";
import { useSelector } from "react-redux";
import { find, findIndex } from "lodash";
import cuid from "cuid";
import { IBoard, IOkrObjectiveInput } from "../../../common/types";
import { Alert, Button, Form } from "../../atoms";
import { addCompanyOkrToFirestore } from "../../../firestore/firestoreService";
import { ObjectiveSuggestedItem } from "..";
import { ObjectiveSuggestedWrapper } from "./Objective.style";

export interface IFakeObjective {
  id: string;
  title: string;
  description: string;
  keyResults: Array<{
    id: string;
    name: string;
  }>;
}
interface IAddSuggestedObjectiveFormProps {
  boardId?: string;
  board?: IBoard;
  callback: () => void;
  cycleId?: string;
  keyResultId?: string;
  teamId?: string;
}
export const AddSuggestedObjectiveForm: React.FC<
  IAddSuggestedObjectiveFormProps
> = ({ boardId, board, callback, keyResultId }) => {
  const [form] = Form.useForm();
  const [selectedObjectives, setSelectedObjectives] = React.useState<
    IFakeObjective[]
  >([]);
  const [suggestedObjectives, setSuggestedObjectives] = React.useState<
    IFakeObjective[]
  >([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const { users } = useSelector((state: any) => state.users);
  const handleGreatSuggestedObjectives = (total: number) => {
    const objectives = Array(total)
      .fill("test")
      .map((v) => ({
        id: cuid(),
        title: faker.lorem.sentences(1),
        description: faker.lorem.paragraph(1),
        keyResults: Array(4)
          .fill("test")
          .map(() => ({ name: faker.lorem.sentences(1), id: cuid() })),
      }));
    setSuggestedObjectives([...suggestedObjectives, ...objectives]);
  };

  const handleLoadMoreObjectives = () => {
    handleGreatSuggestedObjectives(2);
  };

  const handleSubmit = async () => {
    try {
      const values: IOkrObjectiveInput = await form.validateFields();
      setLoading(true);
      const owner = find(users, ["id", values.ownerId]);
      await addCompanyOkrToFirestore({
        status: true,
        title: values.title,
        owner,
        ownerId: values.ownerId,
        description: values.description || "",
        priority: values.priority || "",
        progressRange: values.progressRange || [30, 70],
        cycleId: values.cycleId,
        visibility: values.visibility,
        okrFor: values.okrFor || "INDIVIDUAL_OBJECTIVE",
        boardId,
        teamId: values.teamId || null,
        okrType: "OBJECTIVE",
        okrParentId: null,
        okrParentIds: [],
      });
      setLoading(false);
      callback();
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  const handleOnSelect = (id: string) => () => {
    const currentObjective = find(suggestedObjectives, ["id", id]);
    if (currentObjective) {
      setSelectedObjectives([...selectedObjectives, currentObjective]);
    }
  };
  const handleCheck = (id: string) => {
    const index = findIndex(selectedObjectives, ["id", id]);
    if (index !== -1) {
      return true;
    }
    return false;
  };
  const handleRemove = (id: string) => () => {
    setSelectedObjectives([...selectedObjectives.filter((v) => v.id !== id)]);
  };
  React.useEffect(() => {
    setSuggestedObjectives([]);
    handleGreatSuggestedObjectives(4);
  }, []);
  return (
    <>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}

      <ObjectiveSuggestedWrapper>
        <div className="objectiveSuggested-selector text-center">
          <div className="objectiveSuggested-selector-text">
            Objective Selected <span>({selectedObjectives.length})</span>
          </div>{" "}
          <Button type="link" loading={loading} onClick={handleSubmit}>
            Creat objectives
          </Button>
        </div>
        <div className="objectiveSuggested-list">
          {suggestedObjectives.map((v) => (
            <ObjectiveSuggestedItem
              key={v.id}
              objective={v}
              selected={handleCheck(v.id)}
              onSelect={handleOnSelect(v.id)}
              onRemove={handleRemove(v.id)}
            />
          ))}
        </div>
        <Button
          className="objectiveSuggested-loadMore"
          onClick={handleLoadMoreObjectives}
          type="primary"
        >
          Load more
        </Button>
      </ObjectiveSuggestedWrapper>
    </>
  );
};
