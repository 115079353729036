import styled from "styled-components";
export const ObjectiveWrapper = styled.div`
  background-color: #fff;
  width: 100%;
`;

export const ObjectiveListCardWrapper = styled.div`
  margin-top: 50px;
  .objective-list-card {
    &-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #edeae9;
      align-items: center;
      line-height: 24px;
    }
    &-title {
      font-size: 16px;
      font-weight: 500;
    }
    &-Actions {
    }
    &-body {
      padding: 20px;
    }
    &-tree {
      .ant-tree {
        &-treenode {
          width: 100%;
          padding: 0;
        }
        &-node-content-wrapper {
          width: 100%;
        }
        &-switcher {
          display: flex;
          align-items: center;
        }
      }
    }
  }
`;

export const ObjectiveItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #edeae9;
  line-height: 52px;
  gap: 20px;
  padding: 0 10px;
  .objective-item {
    &-progress {
      width: 150px;
    }
    &-title {
      font-size: 14px;
      font-weight: 500;
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      a {
        color: #1e1f21;
      }
    }
    &-cycle {
      font-size: 14px;
      width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-owner {
      width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-Actions {
    }
    &-body {
      padding: 20px;
    }
  }
`;

export const ObjectiveCardWrapper = styled.div`
  padding: 0px 24px;
  border-bottom: 1px solid #edeae9;
  .objective-card {
    &-header {
      margin-bottom: 0px;
      display: flex;
      gap: 20px;
      &-info {
        flex: 1;
        padding-top: 10px;
      }
    }
    &-title {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      margin-bottom: 16px;
    }
    &-owner {
    }
    &-description {
      font-size: 14px;
      line-height: 32px;
      color: #7c7d7e;
    }
    &-details {
      /* display: flex;
      gap: 20px;
      justify-content: space-between;
      width: calc(100% - 20px); */
    }
    &-keywords {
      display: flex;
      margin: 10px 0px;
      flex-wrap: wrap;
      &-name {
        display: block;
        background-color: #229cff13;
        color: #118fe9;
        border: none;
      }
    }
    &-footer {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #edeae9;
      padding: 10px;
      gap: 20px;
    }
    &-stat {
      background-color: #f1f7ff;
      color: #399ceb;
      border: #f1f7ff;
      border-radius: 7px;
      width: 100%;
      font-size: 13px;
      height: 32px;
      padding: 5px 15px;
      font-weight: 400;
      text-align: center;
      &-value {
        font-weight: 900;
        font-size: 14px;
        margin-right: 5px;
      }
      &-label {
      }
    }
    &-objective-icon {
      font-size: 18px;
      color: #d55f8e;
      margin-right: 10px;
    }
  }
`;

export const ObjectiveSuggestedItemWrapper = styled.div`
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 160px;
  padding: 24px 24px 16px;
  position: relative;
  width: 450px;
  border: 1px solid #edeae9;
  border-radius: 8px;
  box-shadow: none;
  transition-duration: 200ms;
  transition-property: border-color, box-shadow;
  .objective-Suggested-item {
    &-header {
      display: flex;
      align-items: flex-start;
    }
    &-objective {
      flex: 1;
    }
    &-actions {
      .ant-btn-sm {
        font-size: 12px;
        border-radius: 4px;
      }
      .anticon + span {
        margin-left: 0px;
        margin-right: 8px;
      }
      span + .anticon {
        margin-left: 0px;
        margin-right: 8px;
      }
    }
    &-select {
      &.selected {
        background-color: #25c2a1;
        border-color: #25c2a1;
      }
    }
    &-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      color: #1e1f21;
      width: 300px;
      margin-bottom: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-description {
      margin-bottom: 16px;
      flex: 1;
    }
    &-keyResults {
      border-color: #edeae9;
      border-style: solid none none;
      border-width: 1px;
      bottom: 24px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding: 16px;
      width: 100%;
      &-title {
        padding-top: 16px;
        font-weight: 500;
        padding-bottom: 8px;
        color: #25c2a1;
      }
    }
    &-keyResult {
      border-bottom: 1px solid #edeae9;
      font-weight: 500;
      padding-bottom: 10px;
      margin-bottom: 10px;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .anticon {
        color: #00bf9c;
        font-size: 16px;
        margin-right: 8px;
      }
    }
  }
`;

export const ObjectiveSuggestedWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .objectiveSuggested {
    &-selector {
      align-items: center;
      background: #f9f8f8;
      border-radius: 60px;
      display: inline-flex;
      height: 48px;
      padding-right: 8px;
      transition: all 200ms ease-in-out;
      margin-bottom: 16px;
      width: 480px;
      justify-content: center;
      text-align: center;
      gap: 20px;
      &-text {
        color: #757677;
        span {
          font-weight: 500;
          color: #262626;
          font-size: 16px;
        }
      }
    }
    &-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 32px;
    }
    &-loadMore {
      margin-top: 32px;
      width: 480px;
      height: 32px;
      border-radius: 8px;
      font-weight: 500;
    }
  }
`;
