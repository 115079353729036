/* eslint-disable no-console */
import { ICompanyInput, IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import { COLLECTION_COMPANIES, COLLECTION_USERS } from "./firebaseConstants";

export function fetchCompaniesFromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_COMPANIES);
  return collectionWithArgs(ref, args || []);
}
export function listenToCompanyFromFirestore() {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db.collection(COLLECTION_COMPANIES).doc(companyId);
}

export async function addCompanyToFirestore(
  userId: string,
  doc: ICompanyInput
) {
  // const user = firebase.auth().currentUser;
  try {
    const user = await db.collection(COLLECTION_USERS).doc(userId).get();
    const selectedUser = user.data();
    if (!selectedUser) {
      throw new Error("User not found");
    }
    const batch = db.batch();
    const companyId = db.collection(COLLECTION_COMPANIES).doc().id;
    const newCompanyRef = db.collection(COLLECTION_COMPANIES).doc(companyId);
    const userRef = db.collection(COLLECTION_USERS).doc(userId);
    localStorage.setItem("defaultCompanyKey", companyId);
    const newCompanyUserRef = db
      .collection(COLLECTION_COMPANIES)
      .doc(companyId)
      .collection(COLLECTION_USERS)
      .doc(userId);
    batch.set(newCompanyRef, {
      ...doc,
      createDate: new Date(),
      createBy: selectedUser,
      createById: userId,
    });
    batch.set(newCompanyUserRef, selectedUser);
    batch.update(userRef, {
      companyIds: [...(selectedUser.companyIds || []), companyId],
      defaultCompanyId: companyId,
    });
    await batch.commit();
    return companyId;
  } catch (err) {
    throw err;
  }
}

export function updateCompanyInFirestore(doc: ICompanyInput | any) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db.collection(COLLECTION_COMPANIES).doc(companyId).update(doc);
}

export function activeToggleCompanyInFirestore(status: boolean) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db.collection(COLLECTION_COMPANIES).doc(companyId).update({
    status,
  });
}
