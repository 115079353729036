import {
  IBoardInput,
  IBoardSetting,
  IQueryConfigArgs,
} from "../../common/types";
import firebase from "../../config/firebase";
import { db, collectionWithArgs } from "./firestoreService";
import {
  COLLECTION_COMPANIES,
  COLLECTION_BOARDS,
  COLLECTION_USERS,
} from "./firebaseConstants";

export function fetchCompanyBoardsFromFirestore(args?: IQueryConfigArgs[]) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  const ref = db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_BOARDS);
  return collectionWithArgs(ref, args || []);
}
export function listenToCompanyBoardFromFirestore(id: string) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_BOARDS)
    .doc(id);
}

export async function addCompanyBoardToFirestore(
  doc: IBoardInput & IBoardSetting
) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  // const user = firebase.auth().currentUser;
  try {
    const batch = db.batch();
    const docRef = await db
      .collection(COLLECTION_COMPANIES)
      .doc(companyId)
      .collection(COLLECTION_BOARDS)
      .add({ createDate: new Date() });
    const userRef = db
      .collection(COLLECTION_COMPANIES)
      .doc(companyId)
      .collection(COLLECTION_USERS)
      .doc(doc.ownerId);

    batch.set(docRef, doc);
    batch.update(userRef, {
      boardIds: firebase.firestore.FieldValue.arrayUnion(docRef.id),
    });
    await batch.commit();
    return docRef;
  } catch (err) {
    throw err;
  }
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_BOARDS)
    .add({
      ...doc,
      createDate: new Date(),
      // createBy: user,
    });
}

export function updateCompanyBoardInFirestore(
  docId: string,
  doc: IBoardInput | IBoardSetting
) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_BOARDS)
    .doc(docId)
    .update(doc);
}

export function deleteCompanyBoardInFirestore(docId: string) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_BOARDS)
    .doc(docId)
    .delete();
}

export function activeToggleCompanyBoardInFirestore(
  docId: string,
  status: boolean
) {
  const companyId = localStorage.getItem("defaultCompanyKey") || "";
  return db
    .collection(COLLECTION_COMPANIES)
    .doc(companyId)
    .collection(COLLECTION_BOARDS)
    .doc(docId)
    .update({
      status,
    });
}
