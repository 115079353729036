/* eslint-disable no-console */
import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { registerInFirebase } from "../../../firestore/firebaseService";
// import { registerInFirebase } from "../../../firestore/firebaseService";
import { Alert, Button, Input } from "../../atoms";
import { Form } from "../../atoms/Form/Form";
import { SignUpWrapper } from "./Registration.style";

interface IRegUserDetailsProps {
  actionKey?: string;
}
export const RegUserDetails: React.FC<IRegUserDetailsProps> = () => {
  const params = useParams();
  const actionKey = params.key || "";
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const handleOnFinish = async () => {
    setLoading(true);
    try {
      const values: { password: string; name: string } =
        await form.validateFields();
      const userId = await registerInFirebase({
        name: values.name,
        password: values.password,
        singUpId: actionKey,
      });
      navigate(`/registration/company-info/${userId}`);
      setLoading(false);
    } catch (errorInfo: any) {
      setLoading(false);
      setError("Problem with email please try again");
    }
  };
  return (
    <div className="registration-page">
      <div className="registration-page-body">
        <SignUpWrapper>
          <div className="sign-up-title">User Details</div>
          <div className="sign-up-subtitle">Please User Details</div>
          {error !== "" && (
            <Alert message={error} type="error" style={{ marginBottom: 20 }} />
          )}
          <Form
            form={form}
            layout="vertical"
            onFinish={handleOnFinish}
            autoComplete="off"
          >
            <Form.Item
              name="name"
              label="Your Name"
              rules={[
                {
                  required: true,
                  message: "Please input Your Name",
                },
              ]}
            >
              <Input placeholder="Your Name" />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Button className="sign-up-btn" loading={loading} htmlType="submit">
              Next
            </Button>
          </Form>
        </SignUpWrapper>
      </div>
      <div className="registration-page-aside"> </div>
    </div>
  );
};
