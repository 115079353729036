/* eslint-disable no-console */

import { IUser } from "../../../common/types";
import {
  CREATE_COMPANY_USER,
  UPDATE_COMPANY_USER,
  DELETE_COMPANY_USER,
  FETCH_COMPANY_USERS,
  LISTEN_TO_SELECTED_COMPANY_USER,
  CLEAR_COMPANY_USERS,
  CLEAR_SELECTED_COMPANY_USER,
} from "./companyUserConstants";

export function listenToCompanyUsers(users: IUser[]) {
  return {
    type: FETCH_COMPANY_USERS,
    payload: users,
  };
}

export function listenToSelectedCompanyUser(user: IUser) {
  return {
    type: LISTEN_TO_SELECTED_COMPANY_USER,
    payload: user,
  };
}

export function clearSelectedCompanyUser() {
  return {
    type: CLEAR_SELECTED_COMPANY_USER,
  };
}

export function createCompanyUser(user: IUser) {
  return {
    type: CREATE_COMPANY_USER,
    payload: user,
  };
}

export function updateCompanyUser(user: IUser) {
  return {
    type: UPDATE_COMPANY_USER,
    payload: user,
  };
}

export function deleteCompanyUser(userId: string) {
  return {
    type: DELETE_COMPANY_USER,
    payload: userId,
  };
}

export function clearCompanyUsers() {
  return {
    type: CLEAR_COMPANY_USERS,
  };
}
