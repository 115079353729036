/* eslint-disable no-console */
import { IUserInput, IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import { COLLECTION_USERS } from "./firebaseConstants";

export function fetchUsersFromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_USERS);
  return collectionWithArgs(ref, args || []);
}
export function listenToUserFromFirestore(id: string) {
  return db.collection(COLLECTION_USERS).doc(id);
}

export function addUserToFirestore(doc: IUserInput) {
  // const user = firebase.auth().currentUser;
  return db.collection(COLLECTION_USERS).add({
    ...doc,
    createDate: new Date(),
    // createBy: user,
  });
}

export function updateUserInFirestore(docId: string, doc: IUserInput) {
  return db.collection(COLLECTION_USERS).doc(docId).update(doc);
}

export function activeToggleUserInFirestore(docId: string, status: boolean) {
  return db.collection(COLLECTION_USERS).doc(docId).update({
    status,
  });
}
