/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { UserList } from "../../molecules";
import { BoardUserListToolbar } from "./BoardUserListToolbar";
import { BoardWrapper } from "./Board.style";

interface IBoardUserListProps {
  boardId?: string;
}
export const BoardUserList: React.FC<IBoardUserListProps> = () => {
  const params = useParams();

  const boardId = params.boardId || "";
  const { selectedBoardUsers } = useSelector((state: any) => state.boards);
  const { loading } = useSelector((state: any) => state.async);

  return (
    <AdminPageBody pageToolbar={<BoardUserListToolbar boardId={boardId} />}>
      <BoardWrapper>
        <UserList users={selectedBoardUsers} loading={loading} />
      </BoardWrapper>
    </AdminPageBody>
  );
};
