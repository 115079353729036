import React from "react";
import { PageToolbarWrapper } from "./PageToolbar.style";
interface IPageToolbarProps {
  children: React.ReactNode[];
}
export const PageToolbar: React.FC<IPageToolbarProps> = ({ children }) => {
  const [leftChildren, rightChildren] = children;
  return (
    <PageToolbarWrapper>
      <div className="page-toolbar-left-children">{leftChildren}</div>
      <div className="page-toolbar-right-children">{rightChildren}</div>
    </PageToolbarWrapper>
  );
};
