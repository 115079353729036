import styled from "styled-components";
export const KeyResultWrapper = styled.div`
  background-color: #fff;
`;
export const KeyResultCardWrapper = styled.div`
  padding: 0px 24px;
  border-bottom: 1px solid #edeae9;
  .key-result-card {
    &-header {
      margin-bottom: 0px;
      display: flex;
      gap: 20px;
      &-info {
        flex: 1;
        padding-top: 10px;
      }
    }
    &-title {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      margin-bottom: 16px;
    }
    &-description {
      font-size: 14px;
      line-height: 32px;
      color: #7c7d7e;
    }
    &-keywords {
      display: flex;
      margin: 10px 0px;
      flex-wrap: wrap;
      &-name {
        display: block;
        background-color: #229cff13;
        color: #118fe9;
        border: none;
      }
    }
    &-footer {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #edeae9;
      padding: 10px;
      gap: 20px;
    }
    &-stat {
      background-color: #f1f7ff;
      color: #399ceb;
      border: #f1f7ff;
      border-radius: 7px;
      width: 100%;
      font-size: 13px;
      &-value {
        font-weight: 900;
        font-size: 14px;
        margin-right: 5px;
      }
      &-label {
      }
    }
    &-details {
      /* display: flex; */
      /* gap: 20px;
      justify-content: space-between; */
    }
  }
`;

export const KeyResultListCardWrapper = styled.div`
  margin-top: 50px;
  padding: 0px 24px;
  .keyResult-list-card {
    &-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #edeae9;
      align-items: center;
      line-height: 24px;
    }
    &-title {
      font-size: 16px;
      font-weight: 500;
    }
    &-Actions {
    }
    &-body {
      padding: 20px;
    }
    &-tree {
      .ant-tree {
        &-treenode {
          width: 100%;
          padding: 0;
        }
        &-node-content-wrapper {
          width: 100%;
        }
        &-switcher {
          display: flex;
          align-items: center;
        }
      }
    }
  }
`;

export const KeyResultItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #edeae9;
  line-height: 32px;
  gap: 20px;
  padding: 0 10px;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
  &:hover {
    transition: 300ms all ease;
    background-color: #f5f5f5;
  }
  .keyResult-item {
    &-progress {
      width: 50px;
    }
    &-completion {
      width: 150px;
    }
    &-title {
      font-size: 14px;
      /* font-weight: 500; */
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      a {
        color: #1e1f21;
      }
    }
    &-cycle {
      font-size: 14px;
      width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-owner {
      width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-Actions {
    }
    &-body {
      padding: 20px;
    }
    &-btn {
      font-size: 12px;
      height: 28px;
      line-height: 26px;
      padding: 0 8px;
      border-radius: 6px;
      user-select: none;
      font-weight: 500;
      color: #6d6e6f;
      &:hover,
      &:active {
        background: rgb(55 23 23 / 3%);
        color: #1e1f21;
      }
    }
  }
`;
