import { IOkr, IOkrInput } from "../../common/types";
import { userRelationObject } from "./userDataFormat";
export const objectiveDataFormat = (doc: IOkr | IOkrInput) => {
  if (!doc) return undefined;
  if (doc.owner) {
    const owner: any = doc.owner;
    doc.owner = userRelationObject(owner);
  }

  return {
    ...doc,
  };
};
