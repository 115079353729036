import { values, pick } from "lodash";
import { ISelectOption } from "../common/types";

interface ISelectOptionMap {
  [key: string]: ISelectOption;
}
export const unitTypes: ISelectOptionMap = {
  PERCENT: {
    value: "PERCENT",
    label: "%",
  },
  EURO: {
    value: "EURO",
    label: " Euro",
  },
  USD: {
    value: "USD",
    label: "$",
  },
  PERSON: {
    value: "PERSON",
    label: "Person",
  },
};

export const unitTypesOptions = values(unitTypes);

export const keyResultTypes: ISelectOptionMap = {
  SHOULD_INCREASE_TO: {
    value: "SHOULD_INCREASE_TO",
    label: "Should increase to",
  },
  SHOULD_DECREASE_TO: {
    value: "SHOULD_DECREASE_TO",
    label: "Should decrease to",
  },
  SHOULD_STAY_ABOVE: {
    value: "SHOULD_STAY_ABOVE",
    label: "Should stay above",
  },
  SHOULD_STAY_BELOW: {
    value: "SHOULD_STAY_BELOW",
    label: "Should stay below",
  },
};

export const keyResultTypesOptions = values(keyResultTypes);

export const checkInRequestTypes: ISelectOptionMap = {
  DAILY: {
    value: "DAILY",
    label: "Daily",
    unitOfTime: "days",
  },
  WEEKLY: {
    value: "WEEKLY",
    label: "Weekly",
    unitOfTime: "weeks",
  },
  MONTHLY: {
    value: "MONTHLY",
    label: "Monthly",
    unitOfTime: "months",
  },
  // LAST_DAY_WEEK: {
  //   value: "LAST_DAY_WEEK",
  //   label: "Last day of the week",
  // },
  // LAST_DAY_MONTH: {
  //   value: "LAST_DAY_MONTH",
  //   label: "Last day of the month",
  // },
  EVERY_QUARTER: {
    value: "EVERY_QUARTER",
    label: "Every Quarter",
    unitOfTime: "quarters",
  },
};

export const checkInRequestTypesOptions = values(checkInRequestTypes).map(
  (type) => pick(type, ["value", "label"])
);

export const okrsForTypes: ISelectOptionMap = {
  COMPANY_OBJECTIVE: {
    value: "COMPANY_OBJECTIVE",
    label: "Company objective",
  },
  TEAM_OBJECTIVE: {
    value: "TEAM_OBJECTIVE",
    label: "Team objective",
  },
  INDIVIDUAL_OBJECTIVE: {
    value: "INDIVIDUAL_OBJECTIVE",
    label: "Individual objective",
  },
};

export const okrsForTypesOptions = values(okrsForTypes);

export const objectiveVisibility: ISelectOptionMap = {
  PUBLIC_OBJECTIVE: {
    value: "PUBLIC_OBJECTIVE",
    label: "Public objective",
  },
  PRIVATE_OBJECTIVE: {
    value: "PRIVATE_OBJECTIVE",
    label: "Private objective",
  },
};

export const objectiveVisibilityOptions = values(objectiveVisibility);

export const objectivePriorityTypes: ISelectOptionMap = {
  NORMAL: {
    value: "NORMAL",
    label: "Normal",
  },
  HIGH: {
    value: "HIGH",
    label: "High",
  },
  EXTREMELY_IMPOTENT: {
    value: "EXTREMELY_IMPORTANT",
    label: "Extremely Important",
  },
};

export const objectivePriorityTypesOptions = values(objectivePriorityTypes);

export const BoardPriorityTypes: ISelectOptionMap = {
  GROWTH: {
    value: "GROWTH",
    label: "Growth",
  },
  PROFIT: {
    value: "PROFIT",
    label: "Profit",
  },
  QUALITY: {
    value: "QUALITY",
    label: "Quality",
  },
  REVENUE: {
    value: "REVENUE",
    label: "Revenue",
  },
  ENGAGEMENT: {
    value: "ENGAGEMENT",
    label: "Engagement",
  },
  PERFORMANCE: {
    value: "PERFORMANCE",
    label: "Performance",
  },
};

export const BoardPriorityTypesOptions = values(BoardPriorityTypes);

export const dayNamesTypes: ISelectOptionMap = {
  Monday: {
    value: "MONDAY",
    label: "Monday",
  },
  TUESDAY: {
    value: "TUESDAY",
    label: "Tuesday",
  },
  WEDNESDAY: {
    value: "WEDNESDAY",
    label: "Wednesday",
  },
  THURSDAY: {
    value: "THURSDAY",
    label: "Thursday",
  },
  FRIDAY: {
    value: "FRIDAY",
    label: "Friday",
  },
  Saturday: {
    value: "SATURDAY",
    label: "Saturday",
  },
  SUNDAY: {
    value: "SUNDAY",
    label: "Sunday",
  },
};

export const dayNamesTypesOptions = values(dayNamesTypes);

export const cycleStatusTypes: ISelectOptionMap = {
  OPEN: {
    value: "OPEN",
    label: "Open (User can Create delete and edit OKRs)",
  },
  IN_PROGRESS: {
    value: "IN_PROGRESS",
    label: "In Progress (User can update there OKRs cant add new ones)",
  },
  Archived: {
    value: "ARCHIVED",
    label: "Archived (the session is close and no update are allowed)",
  },
};

export const cycleStatusTypesOptions = values(cycleStatusTypes);

export const initiativeStatusTypes: ISelectOptionMap = {
  TO_DO: {
    value: "TO_DO",
    label: "To Do",
  },
  IN_PROGRESS: {
    value: "IN_PROGRESS",
    label: "In Progress",
  },
  COMPLETED: {
    value: "COMPLETED",
    label: "Completed",
  },
};

export const initiativeStatusTypesOptions = values(initiativeStatusTypes);

export const initiativePriorityTypes: ISelectOptionMap = {
  LOW: {
    value: "LOW",
    label: "Low",
  },
  NORMAL: {
    value: "NORMAL",
    label: "Normal",
  },
  HIGH: {
    value: "HIGH",
    label: "High",
  },
};

export const initiativePriorityTypesOptions = values(initiativePriorityTypes);
