/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import {
  AppstoreAddOutlined,
  PlusOutlined,
  SortAscendingOutlined,
} from "@ant-design/icons";
import { Button } from "../../atoms";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { PageToolbar } from "../../molecules";

interface ITeamInitiativeToolbarProps {
  objectiveId?: string;
  keyResultId?: string;
}
export const TeamInitiativeToolbar: React.FC<ITeamInitiativeToolbarProps> = ({
  objectiveId,
  keyResultId,
}) => {
  const dispatch = useDispatch();
  const handelSorting = () => {
    console.log("filterCycles");
  };
  const handelCustomize = () => {
    console.log("filterCycles");
  };
  const handelAddObjective = () => {
    dispatch(
      openModal({
        modalType: "AddInitiativeForm",
        modalProps: {
          title: "Add Initiative",
          objectiveId,
          keyResultId,
        },
      })
    );
  };
  return (
    <PageToolbar>
      <Button
        type="primary"
        onClick={handelAddObjective}
        icon={<PlusOutlined />}
        className="page-toolbar-btn"
      >
        Add Initiative
      </Button>

      <div className="page-toolbar-right-children-elements">
        <Button
          type="link"
          onClick={handelSorting}
          icon={<SortAscendingOutlined />}
          className="page-toolbar-btn-link"
        >
          Sorting
        </Button>
        <Button
          type="link"
          onClick={handelCustomize}
          icon={<AppstoreAddOutlined />}
          className="page-toolbar-btn-link"
        >
          Customize
        </Button>
      </div>
    </PageToolbar>
  );
};
