export const CREATE_COMPANY = "CREATE_COMPANY";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const FETCH_COMPANIES = "FETCH_COMPANIES";
export const LISTEN_TO_SELECTED_COMPANY = "LISTEN_TO_SELECTED_COMPANY";
export const CLEAR_SELECTED_COMPANY = "CLEAR_SELECTED_COMPANY";
export const CLEAR_COMPANIES = "CLEAR_COMPANIES";
export const SET_COMPANIES_FILTER = "SET_COMPANIES_FILTER";
export const LISTEN_TO_SELECTED_COMPANY_CYCLES =
  "LISTEN_TO_SELECTED_COMPANY_CYCLES";
export const LISTEN_TO_SELECTED_COMPANY_DEPARTMENTS =
  "LISTEN_TO_SELECTED_COMPANY_DEPARTMENTS";
export const LISTEN_TO_SELECTED_COMPANY_USERS =
  "LISTEN_TO_SELECTED_COMPANY_USERS";
