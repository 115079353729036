/* eslint-disable no-console */

import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { DndBoardItemList } from "./DndBoardItemList";
import { DndBoardColumnWrapper } from "./DndBoardWrapper.style";

interface IDndBoardColumnProps {
  droppableId: string;
  isDragDisabled: boolean;
  height: number;
  title?: string | React.ReactElement;
  renderItem: (obj: any) => React.ReactElement;
  draggableKey: string;
  data: any[];
}

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "#E7ECF1" : "transparent",
  width: isDraggingOver ? "inherit" : "100%",
  opacity: isDraggingOver ? "0.5" : "1",
});

export const DndBoardColumn: React.FC<IDndBoardColumnProps> = ({
  droppableId,
  height,
  title,
  isDragDisabled,
  renderItem,
  draggableKey,
  data,
}) => (
  // let pageNou = 0

  <DndBoardColumnWrapper>
    <Droppable droppableId={droppableId} isDropDisabled={isDragDisabled}>
      {(provided, snapshot) => (
        <div
          className="dndColumn__column"
          ref={provided.innerRef}
          style={getListStyle(snapshot.isDraggingOver)}
          // isDraggingOver={snapshot.isDraggingOver}
        >
          <div className="dndColumn" style={{ height }}>
            {title && <div className="dndColumn__box">{title}</div>}

            <DndBoardItemList
              items={data}
              itemsCount={10}
              height={height}
              renderItem={renderItem}
              isDragDisabled={isDragDisabled}
              draggableKey={draggableKey}
            />
            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  </DndBoardColumnWrapper>
);
