import styled from "styled-components";
// import {palette} from 'styled-theme'

export const LoaderWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 500px;
  justify-content: center;
  .loader-wrapper {
    button {
      margin-top: 20px;
    }

    &__body {
      vertical-align: middle;
      text-align: center;
      color: ${(props) => (props.color ? props.color : "#cdd3da")};
      font-size: 18px;
    }

    &__title {
      font-size: 32px;
      padding: 15px;
    }

    &__subTitle {
      font-size: 14px;
    }
  }
`;
