import { omit } from "lodash";
import {
  IUser,
  IUserInput,
  IUserRelationObject,
} from "../../common/types/userTypes";

export function isUser(object: any): object is IUser {
  return "IUser" in object;
}
export function isUserInput(object: any): object is IUserInput {
  return "IUserInput" in object;
}
export const userDataFormat = (doc: IUser | IUserInput) => {
  if (!doc) return undefined;

  if (doc.manager) {
    const manager: any = doc.manager;
    doc.manager = userRelationObject(manager);
  }
  return {
    ...doc,
  };
};

export function userRelationObject(doc: IUser): IUserRelationObject {
  return {
    ...omit(doc, [
      "lastLogin",
      "createAt",
      "updateAt",
      "createdBy",
      "updateBy",
      "manager",
      "managerId",
      "boardIds",
      "companyIds",
      "defaultCompanyId",
    ]),
  };
}
