import React from "react";
import { TooltipFieldWrapper } from "./Fields.style";

interface ITooltipFieldProp {
  title: string;
  description: string;
}
export const TooltipField: React.FC<ITooltipFieldProp> = ({
  title,
  description,
}) => (
  <TooltipFieldWrapper>
    <div className="tooltip-field-title">{title}</div>
    <div className="tooltip-field-description">{description}</div>
  </TooltipFieldWrapper>
);
