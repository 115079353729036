/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  LinkOutlined,
  PlusOutlined,
  StarFilled,
  StarOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";
import { isArray } from "lodash";
import { useCopyToClipboard } from "usehooks-ts";
import {
  CommentForm,
  CommentListCard,
  KeyResultListCard,
  ObjectiveActionMenu,
  ObjectiveCard,
  ObjectiveListCard,
  TooltipField,
} from "../../molecules";
import { DrawerWrapper } from "../../../common/Drawer/DrawerWrapper";
// import { InputFormWrapper } from "../../atoms";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import {
  addCompanyOkrCommentToFirestore,
  likeOkrCompanyUserFirestore,
  listenToCompanyOkrFromFirestore,
} from "../../../firestore/firestoreService";
import { IOkr } from "../../../common/types";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { Button, Tag, Tooltip } from "../../atoms";
import { notificationServices } from "../../../services/notificationServices";
interface IObjectiveDrawerProps {
  id: string;
}
const orkTypes = {
  OBJECTIVE: "Objective",
  KEY_RESULT: "Key Result",
  OBJECTIVE_CASCADED: "Cascaded Objective ",
  OBJECTIVE_ALIGNMENT: "Objective Alignment",
};
export const ObjectiveDetailsDrawer: React.FC<IObjectiveDrawerProps> = (
  props
) => {
  const dispatch = useDispatch();
  const [, copy] = useCopyToClipboard();
  const { myProfile } = useSelector((state: any) => state.myProfile);
  const [objective, setObjective] = React.useState<IOkr>();
  const [parentObjective, setParentObjective] = React.useState<IOkr>();
  const [isLiked, setIsLiked] = React.useState(false);
  userFirestoreDoc({
    query: () => listenToCompanyOkrFromFirestore(props.id),
    data: setObjective,
    deps: [dispatch, props.id],
    local: true,
  });
  userFirestoreDoc({
    query: () => listenToCompanyOkrFromFirestore(objective?.okrParentId || ""),
    data: setParentObjective,
    deps: [dispatch, objective],
    shouldExecute: !!objective?.okrParentId,
    local: true,
  });
  const handelAddKeyResult = () => {
    if (!objective || !props.id) return;
    dispatch(
      openModal({
        modalType: "AddKeyResultModal",
        modalProps: {
          title: "Create key result",
          boardId: objective?.boardId || null,
          okrParentId: props.id,
          okrParentIds: objective.okrParentIds || [],
          cycleId: objective.cycleId,
          okrFor: objective.okrFor,
        },
      })
    );
  };
  const alignmentObjective = () => {
    if (!objective || !props.id) return;
    dispatch(
      openModal({
        modalType: "AlignmentObjectiveModal",
        modalProps: {
          title: "Cascade key result",
          objectiveId: props.id,
          objective,
          okrFor: objective.okrFor,
        },
      })
    );
  };
  const handelLikeObjective = (action: string) => async () => {
    if (action === "add") {
      await likeOkrCompanyUserFirestore(props.id, false);
      notificationServices("ADD_OKR_TO_FAVORED");
    } else {
      await likeOkrCompanyUserFirestore(props.id, true);
      notificationServices("REMOVE_OKR_FROM_FAVORED");
    }
  };
  const copyObjectiveUrl = async () => {
    await copy(`app.weey.io/objective/${props.id}/details`);
    notificationServices("COPY_OKR_URL");
  };
  React.useEffect(() => {
    if (
      isArray(myProfile.okrsLiked) &&
      myProfile.okrsLiked.includes(props.id)
    ) {
      setIsLiked(true);
    } else {
      setIsLiked(false);
    }
  }, [myProfile.okrsLiked, props.id]);
  if (!objective) {
    return <div></div>;
  }
  return (
    <DrawerWrapper
      {...props}
      width={700}
      footer={
        <CommentForm
          addComment={addCompanyOkrCommentToFirestore}
          id={props.id}
        />
      }
      extra={
        <>
          <Tooltip title="Like this objective" placement="bottom">
            {isLiked ? (
              <StarFilled
                className="drawer-extra-btn-link isLiked"
                onClick={handelLikeObjective("remove")}
              />
            ) : (
              <StarOutlined
                className="drawer-extra-btn-link"
                onClick={handelLikeObjective("add")}
              />
            )}
          </Tooltip>
          <Tooltip title="Copy Objective link">
            <LinkOutlined
              className="drawer-extra-btn-link"
              onClick={copyObjectiveUrl}
            />
          </Tooltip>
          {objective.okrType === "OBJECTIVE" && (
            <Tooltip
              title={
                <TooltipField
                  title={"Alignment Objective"}
                  description={"Alignment Objective"}
                />
              }
            >
              <VerticalAlignTopOutlined
                className="drawer-extra-btn-link"
                onClick={alignmentObjective}
              />
            </Tooltip>
          )}
          <Tooltip title="More actions" placement="bottom">
            <ObjectiveActionMenu objective={objective} objectiveId={props.id} />
          </Tooltip>
        </>
      }
      title={
        <div className="drawer-title">
          <Tag className="drawer-title-tag">
            {orkTypes[objective.okrType] || ""}
          </Tag>
          <Button
            onClick={handelAddKeyResult}
            icon={<PlusOutlined />}
            className="drawer-title-btn"
          >
            Add Key Result
          </Button>
        </div>
      }
    >
      <ObjectiveCard
        title={objective.title || ""}
        owner={objective.owner}
        description={objective.description}
        objective={objective}
        score={objective.score || 0}
        parentObjective={parentObjective}
      />
      <KeyResultListCard
        filters={[
          {
            type: "filter",
            name: "okrParentId",
            opr: "==",
            value: props.id,
          },
        ]}
        action={handelAddKeyResult}
        greyHeader
      />
      <ObjectiveListCard
        title="Related Objectives"
        filters={[
          {
            type: "filter",
            name: "okrParentId",
            opr: "==",
            value: props.id,
          },
          {
            type: "filter",
            name: "okrType",
            opr: "in",
            value: ["OBJECTIVE_CASCADED", "OBJECTIVE_ALIGNMENT"],
          },
        ]}
        greyHeader
        noCascade
        cycleId={objective.cycleId}
        action={handelAddKeyResult}
      />
      <CommentListCard
        comments={objective?.comments || []}
        addComment={addCompanyOkrCommentToFirestore}
        id={props.id}
      />
    </DrawerWrapper>
  );
};
