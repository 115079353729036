/* eslint-disable no-console */
import { DashboardOutlined, ImportOutlined } from "@ant-design/icons";
import { Button, Progress } from "antd";
import React from "react";
import { IOkr } from "../../../common/types";
import { Tag } from "../../atoms";
import { UserCell } from "../CommonCells";
import { OrgChartCardWrapper } from "./OrgChart.style";

interface IOrgChartCardKeyResultProps {
  data: IOkr;
  score: number;
}
export const OrgChartCardKeyResult: React.FC<IOrgChartCardKeyResultProps> = ({
  data,
  score,
}) => {
  console.log("data");
  const handelAddCheckIn = () => {
    console.log("test");
  };
  return (
    <OrgChartCardWrapper>
      <div className="org-chart-card-keyResult">
        <div className="org-chart-card">
          <div className="org-chart-card-icon">
            <DashboardOutlined className="alignment-view-icon" />
          </div>
          <div className="org-chart-card-keyResult-info">
            <div className="org-chart-card-keyResult-title">
              <a href={`/key-result/${data.id}/details`}>{data.title}</a>
            </div>
            <UserCell
              className="org-chart-card-keyResult-owner"
              user={data.owner || null}
              nameOnly
              xSmall
            />
            <div className="org-chart-card-keyResult-checkIn">
              <div className="keyResult-item-progress">
                <Tag color="green"> On track </Tag>
              </div>
              <div className="keyResult-item-checkIn">
                <Button
                  type="link"
                  className="org-chart-card-keyResult-checkIn-btn"
                  onClick={handelAddCheckIn}
                >
                  <ImportOutlined /> Check In
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Progress
          percent={Math.round(score || 0)}
          className="org-chart-card-progress"
        />
      </div>
    </OrgChartCardWrapper>
  );
};
