/* eslint-disable no-console */
import React from "react";
import { FlowPickerWrapper } from "./CommonComponents.style";

interface IFlowPickerProps {
  pickerOptions: Array<{
    title: string;
    subTitle?: string;
    icon?: React.ReactElement;
    action: () => void;
  }>;
}
export const FlowPicker: React.FC<IFlowPickerProps> = ({ pickerOptions }) => {
  console.log("ddd");
  return (
    <FlowPickerWrapper>
      {pickerOptions.map((p, i) => (
        <div className="flow-picker-box" key={i} onClick={p.action}>
          <div className="flow-picker-box-wrapper">
            {p.icon && <div className="flow-picker-box-icon">{p.icon}</div>}
            <div className="flow-picker-box-body">
              <div className="flow-picker-box-title">{p.title}</div>
              {p.subTitle && (
                <div className="flow-picker-box-subTitle">{p.subTitle}</div>
              )}
            </div>
          </div>
        </div>
      ))}
    </FlowPickerWrapper>
  );
};
