import styled from "styled-components";
export const OkrListViewWrapper = styled.div`
  width: 100%;
  .okr-list-view {
    &-tree {
      .ant-tree {
        &-treenode {
          width: 100%;
          padding: 0;
        }
        &-node-content-wrapper {
          width: 100%;
        }
        &-switcher {
          display: flex;
          align-items: center;
        }
      }
    }
    &-panel {
      border: none;
    }
  }
`;

export const OkrItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #edeae9;
  line-height: 42px;
  padding: 0 10px;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
  &:hover {
    transition: 300ms all ease;
    background-color: #f5f5f5;
    .okr-item-actions-icon {
      visibility: visible;
    }
  }
  &.okr-item-onTrack {
    /* background-color: rgb(113 183 0 / 20%); */
    .okr-item {
      &-completion {
        .ant-progress-success-bg,
        .ant-progress-bg {
          background-color: #71b700;
        }
      }
      &-tag {
        background-color: #e3f1d4;
        border-color: #71b700;
        color: #497107;
      }
    }
  }
  &.okr-item-behind {
    /* background-color: rgb(255 160 49 / 20%); */
    .okr-item {
      &-completion {
        .ant-progress-success-bg,
        .ant-progress-bg {
          background-color: #ffa031;
        }
      }
      &-tag {
        background-color: #ffecd8;
        border-color: #ffa031;
        color: #ac6d22;
      }
    }
  }
  &.okr-item-atRisk {
    .okr-item {
      &-completion {
        .ant-progress-success-bg,
        .ant-progress-bg {
          background-color: #ff3300;
        }
      }
      &-tag {
        background-color: rgb(255 51 0 / 20%);
        border-color: #ff3300;
        color: #871d03;
      }
    }
  }
  .okr-item {
    &-type {
      width: 40px;
    }
    &-icon {
      font-size: 18px;
      color: #d55f8e;
      &-group {
        display: flex;
      }
    }
    &-completion {
      min-width: 150px;
      flex: 1;
      padding: 0 10px;
      .ant-progress-inner {
        background-color: #eceae9;
      }
    }
    &-progress {
      width: 80px;
      text-align: center;
    }
    &-title {
      font-size: 14px;
      width: 240px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      /* display: flex;
      align-items: center; */
      a {
        color: #1e1f21;
      }
      &.bold {
        font-weight: 500;
      }
    }
    &-owner {
      width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-tag {
      text-align: center;
      font-size: 12px;
      padding: 1px 8px;
      text-align: center;
      background-color: #ffecd8;
      border-color: #ffecd7;
      color: #ac6d22;
      border-radius: 4px;
      margin: 0;
    }
    &-actions {
      width: 50px;
      text-align: center;
      &-icon {
        font-size: 14px;
        color: #999999;
        visibility: hidden;
      }
    }
    &-body {
      padding: 20px;
    }
  }
`;
