/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/await-thenable */
import { CloseOutlined, VerticalLeftOutlined } from "@ant-design/icons";
import React from "react";
import { useDispatch } from "react-redux";
import { Drawer, Tooltip } from "../../components/atoms";
import { closeDrawer } from "../../redux/data/drawer/drawerReducer";
interface IDrawerProps {
  children: React.ReactNode;
  title?: string | React.ReactElement;
  loading?: boolean;
  className?: string;
  footer?: React.ReactElement | null;
  extra?: React.ReactElement | null;
  width?: number;
}

export const DrawerWrapper: React.FC<IDrawerProps> = ({
  children,
  title,
  loading,
  className,
  footer,
  width,
  extra,
}) => {
  const dispatch = useDispatch();
  const handleOnClose = () => {
    dispatch(closeDrawer());
  };
  // const handleOk = async () => {
  //   try {
  //     await onSave();
  //     // dispatch(closeDrawer());
  //   } catch (err) {
  //     throw err;
  //   }

  // setDrawerText('The drawer will be closed after two seconds');
  // setConfirmLoading(true);
  // setTimeout(() => {
  //   setVisible(false);
  //   setConfirmLoading(false);
  // }, 2000);

  return (
    <Drawer
      title={title}
      visible={true}
      className={className}
      onClose={handleOnClose}
      footer={footer}
      getContainer={false}
      style={{ position: "absolute" }}
      closeIcon={<VerticalLeftOutlined />}
      width={width}
      headerStyle={{ display: "hidden" }}
      mask={false}
      extra={
        <div className="drawer-extra">
          {extra && extra}

          <Tooltip title={"Close"} placement="bottom">
            <CloseOutlined
              onClick={handleOnClose}
              className="drawer-extra-btn-link"
            />
          </Tooltip>
        </div>
      }
      closable={false}
    >
      {children}
    </Drawer>
  );
};
