/* eslint-disable no-console */
import { find } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { IOkr } from "../../App/common/types";
import { Tag } from "../../App/components/atoms";
import {
  fetchCompanyInitiativesFromFirestore,
  listenToCompanyOkrFromFirestore,
} from "../../App/firestore/firestoreService";
import { userFirestoreDoc } from "../../App/hooks/useFirestoreDoc";
import { useFirestoreCollection } from "../../App/hooks/useFirestoreCollection";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";
import {
  listenToSelectedCompanyOkr,
  listenToSelectedOkrInitiatives,
} from "../../App/redux/data/companyOKR/companyOKRActions";

const currentMenu = (objectiveId: string) => [
  {
    key: "details",
    label: "Details",
    link: `/objective/${objectiveId}/details`,
  },
  {
    key: "keyResults",
    label: "Key Results",
    link: `/objective/${objectiveId}/key-results`,
  },
  {
    key: "initiatives",
    label: "Initiatives",
    link: `/objective/${objectiveId}/initiatives`,
  },
];
const currentBreadcrumb = (objective: IOkr, name: string) =>
  [
    objective?.boardId && {
      key: "board",
      label: objective?.title,
      link: `/board/${objective?.boardId}/details`,
    },
    objective?.boardId && {
      key: "objectives",
      label: "Objectives",
      link: `/board/${objective?.boardId}/objectives`,
    },
    !objective?.boardId && {
      key: "okr",
      label: "OKRs",
      link: `/okr/details`,
    },
    {
      key: "objective",
      label: objective?.title,
      link: `/objective/${objective?.id}/details`,
    },
    {
      key: "page",
      label: name,
      link: "#",
    },
  ].filter((v) => !!v);
const OKRsViewPage: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [breadcrumb, setBreadcrumb] = React.useState<any[]>([]);
  const okrId = params.okrId || "";
  const headerMenu = currentMenu(okrId);
  const { selectedOkr } = useSelector((state: any) => state.okrs);
  const cycleId = selectedOkr?.cycleId;
  console.log({ cycleId }, !!cycleId);
  userFirestoreDoc({
    query: () => listenToCompanyOkrFromFirestore(okrId),
    data: listenToSelectedCompanyOkr,
    deps: [dispatch, okrId],
  });

  // useFirestoreCollection({
  //   query: () =>
  //     fetchCompanyKeyResultsFromFirestore([
  //       {
  //         type: "filter",
  //         name: "objectiveId",
  //         opr: "==",
  //         value: objectiveId,
  //       },
  //     ]),
  //   data: listenToSelectedObjectiveKeyResults,
  //   deps: [dispatch],
  // });
  useFirestoreCollection({
    query: () =>
      fetchCompanyInitiativesFromFirestore([
        {
          type: "filter",
          name: "objectiveId",
          opr: "==",
          value: okrId,
        },
      ]),
    data: listenToSelectedOkrInitiatives,
    deps: [dispatch],
  });
  React.useEffect(() => {
    const name = find(headerMenu, ["link", location.pathname]);
    setBreadcrumb(currentBreadcrumb(selectedOkr, name?.label || "") || []);
  }, [selectedOkr, location.pathname]);
  return (
    <AdminPage
      title={
        <div className="admin-page-title">
          <Tag>{selectedOkr?.okrType}</Tag> {selectedOkr?.title || ""}
        </div>
      }
      headerMenu={headerMenu}
      breadcrumb={breadcrumb}
    >
      <Outlet />
    </AdminPage>
  );
};

export default OKRsViewPage;
