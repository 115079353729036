import { UserOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";
import { ICycle, IUser } from "../../../common/types";
import { toggleCompanyUserDefaultCycleInFirestore } from "../../../firestore/firestoreService";
import { Avatar, Button, Divider, Dropdown, Menu } from "../../atoms";
import {
  EditableCycleSelectedField,
  EditableFieldForm,
  FieldView,
} from "../commonFields";
import { LeftMenuWrapper } from "./LeftMenu.style";

interface ILeftMenuProps {
  authenticated: boolean;
  login: () => void;
  logout: () => void;
  currentUser: IUser;
  defaultCycleId: string;
  defaultCompanyCycleId: string;
}

export const LeftMenu: React.FC<ILeftMenuProps> = ({
  authenticated,
  login,
  logout,
  currentUser,
  defaultCycleId,
  defaultCompanyCycleId,
}) => {
  const [cycleId, setCycleId] = React.useState("");
  const menu = (
    <Menu style={{ width: 250, padding: "5px 0" }}>
      <Menu.Item key="0" style={{ padding: "5px 25px" }}>
        <Link to="/profile/details"> My Profile</Link>
      </Menu.Item>
      <Menu.Item key="1" style={{ padding: "5px 25px" }}>
        <Link to="/company/details"> Company Setting</Link>
      </Menu.Item>
      <Divider style={{ margin: "5px" }}></Divider>
      <Menu.Item key="6" style={{ padding: "5px 25px" }}>
        <Link to="#" onClick={logout}>
          {" "}
          Log out
        </Link>
      </Menu.Item>
    </Menu>
  );
  const handleEditCycle = async (
    name: string,
    value: { cycleId: string; cycle: ICycle }
  ) => {
    setCycleId(value.cycleId);
    await toggleCompanyUserDefaultCycleInFirestore(value.cycleId);
  };
  React.useEffect(() => {
    if (defaultCycleId) {
      if (defaultCycleId !== cycleId) {
        setCycleId(defaultCycleId);
      }
    } else {
      setCycleId(defaultCompanyCycleId);
    }
  }, [defaultCycleId, defaultCompanyCycleId]);
  const menuLogIn = (
    <Menu>
      <Menu.Item key="0">
        <Link to="#" onClick={login}>
          {" "}
          Log in{" "}
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <LeftMenuWrapper>
      <div className="dashboard-cycle-selector text-center">
        <EditableFieldForm>
          <FieldView
            label="Cycle"
            width={50}
            labelStyle={{ fontWeight: 500, color: "#1e1f21" }}
          >
            <EditableCycleSelectedField
              title={"cycleId"}
              editable
              dataIndex={"cycleId"}
              record={cycleId}
              style={{ width: 200 }}
              handleSave={handleEditCycle}
            />
          </FieldView>
        </EditableFieldForm>
      </div>
      <Button className="left-menu-btn"> Beta Version</Button>
      {authenticated ? (
        <Dropdown overlay={menu}>
          <Avatar
            size={40}
            style={{
              background: "#d55f8e",
              fontSize: 16,
            }}
          >
            {currentUser.avatar}
          </Avatar>
        </Dropdown>
      ) : (
        <Dropdown overlay={menuLogIn}>
          <Avatar size={40} icon={<UserOutlined />} />
        </Dropdown>
      )}
    </LeftMenuWrapper>
  );
};
