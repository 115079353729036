/* eslint-disable no-console */
import { PlusOutlined } from "@ant-design/icons";
import React from "react";
import { useDispatch } from "react-redux";
import { SectionCard } from "../CommonComponents";
import { EmptyResult } from "../EmptyResult/EmptyResult";
import { IOkr, IQueryConfigArgs } from "../../../common/types";
import { Button, Divider, Space } from "../../atoms";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchCompanyOkrsFromFirestore } from "../../../firestore/firestoreService";
import { OkrList } from "../OkrListView";
interface IKeyResultListCardProps {
  action?: () => void;
  objectiveId?: string;
  greyHeader?: boolean;
  filters: IQueryConfigArgs[];
}
export const KeyResultListCard: React.FC<IKeyResultListCardProps> = ({
  action,
  objectiveId,
  greyHeader,
  filters = [],
}) => {
  const [objectives, setObjectives] = React.useState<IOkr[]>([]);
  const dispatch = useDispatch();
  const handelObjectiveListView = (value: string) => {
    console.log(value);
  };

  useFirestoreCollection({
    query: () =>
      fetchCompanyOkrsFromFirestore([
        ...filters,
        {
          type: "filter",
          name: "okrType",
          opr: "==",
          value: "KEY_RESULT",
        },
      ]),
    data: setObjectives,
    deps: [dispatch, filters],
    local: true,
  });
  return (
    <SectionCard
      title={"Key result"}
      titleOnSelect={handelObjectiveListView}
      greyHeader={greyHeader}
      extra={
        <Space split={<Divider type="vertical" />}>
          {action && (
            <Button
              type="link"
              className="section-card-header-btn-link"
              onClick={action}
            >
              <PlusOutlined /> Add key result
            </Button>
          )}
        </Space>
      }
    >
      <div className="section-card-body">
        {objectives.length === 0 && (
          <EmptyResult
            subTitle="Create a new key Result"
            title="No key Result"
            btnName="Create key Result"
            btnAction={action}
          />
        )}
        {objectives.length > 0 && (
          <OkrList
            okrs={objectives || []}
            loading={false}
            objectiveId={objectiveId}
            noCascade
          />
        )}
      </div>
    </SectionCard>
  );
};
