import React from "react";
import { ICheckIn, IInitiative } from "../../../common/types";
import { DashboardCardWrapper } from "./Dashboard.style";

interface IDashboardCardProps {
  selectedInitiative: IInitiative[];
  selectedCheckIns: ICheckIn[];
}

export const DashboardCard: React.FC<IDashboardCardProps> = ({
  selectedInitiative,
  selectedCheckIns,
}) => (
  <DashboardCardWrapper>
    <div className="dashboard-card-selector text-center">
      <div className="dashboard-card-selector-text">
        Initiative Completed <span>({selectedInitiative.length})</span>
      </div>
      <div className="dashboard-card-selector-text">
        Check In Completed <span>({selectedCheckIns.length})</span>
      </div>{" "}
    </div>
  </DashboardCardWrapper>
);
