/* eslint-disable no-console */
import React from "react";
import { useParams } from "react-router-dom";
import { BoardWrapper } from "./Board.style";

interface IBoardReportProps {
  boardId?: string;
}
export const BoardReport: React.FC<IBoardReportProps> = () => {
  const params = useParams();

  const boardId = params.boardId || "";
  console.log(boardId);
  return <BoardWrapper>BoardReport</BoardWrapper>;
};
