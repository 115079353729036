/* eslint-disable no-console */

import { IInitiative } from "../../../common/types";
import {
  CREATE_COMPANY_INITIATIVE,
  UPDATE_COMPANY_INITIATIVE,
  DELETE_COMPANY_INITIATIVE,
  FETCH_COMPANY_INITIATIVES,
  LISTEN_TO_SELECTED_COMPANY_INITIATIVE,
  CLEAR_COMPANY_INITIATIVES,
  CLEAR_SELECTED_COMPANY_INITIATIVE,
} from "./companyInitiativeConstants";

export function listenToCompanyInitiatives(initiatives: IInitiative[]) {
  return {
    type: FETCH_COMPANY_INITIATIVES,
    payload: initiatives,
  };
}

export function listenToSelectedCompanyInitiative(initiative: IInitiative) {
  return {
    type: LISTEN_TO_SELECTED_COMPANY_INITIATIVE,
    payload: initiative,
  };
}

export function clearSelectedCompanyInitiative() {
  return {
    type: CLEAR_SELECTED_COMPANY_INITIATIVE,
  };
}

export function createCompanyInitiative(initiative: IInitiative) {
  return {
    type: CREATE_COMPANY_INITIATIVE,
    payload: initiative,
  };
}

export function updateCompanyInitiative(initiative: IInitiative) {
  return {
    type: UPDATE_COMPANY_INITIATIVE,
    payload: initiative,
  };
}

export function deleteCompanyInitiative(initiativeId: string) {
  return {
    type: DELETE_COMPANY_INITIATIVE,
    payload: initiativeId,
  };
}

export function clearCompanyInitiatives() {
  return {
    type: CLEAR_COMPANY_INITIATIVES,
  };
}
