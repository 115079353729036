/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-console */
import React from "react";
import { useElementSize } from "usehooks-ts";
import { DragDropContext } from "react-beautiful-dnd";
import { findIndex, omit } from "lodash";
import { useDispatch } from "react-redux";
import { CycleCell, VirtualTable } from "..";
import { ICycle } from "../../../common/types";
import { DndBoardColumn } from "../DndBoard";
import { DndBoardWrapper } from "../DndBoard/DndBoardWrapper.style";
import { OrgChartCardObjective } from "../OrgChart/OrgChartCardObjective";
import { updateCompanyOkrInFirestore } from "../../../firestore/firestoreService";
import { openDrawer } from "../../../redux/data/drawer/drawerReducer";
interface ICycleBoardProps {
  cycles: ICycle[];
  loading: boolean;
}
export const CycleBoard: React.FC<ICycleBoardProps> = ({ cycles, loading }) => {
  const [squareRef, { height }] = useElementSize();
  const dispatch = useDispatch();
  const handleOnDragEnd = async (result: any) => {
    const { draggableId, destination } = result;
    const index = findIndex(cycles, ["id", destination.droppableId]);
    console.log({ index, draggableId, destination });
    if (index !== -1) {
      await updateCompanyOkrInFirestore(draggableId, {
        cycleId: destination.droppableId,
        cycle: omit(cycles[index], ["objectives", "comments"]),
      });
    }
  };

  const handelOpenOkr = (id: string) => () => {
    console.log("ffff");
    dispatch(
      openDrawer({
        drawerType: "ObjectiveDetailsDrawer",
        drawerProps: {
          title: "dd",
          id,
        },
      })
    );
  };
  return (
    <DndBoardWrapper ref={squareRef} style={{ height: "100%", width: "100%" }}>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <VirtualTable
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          columns={cycles.map((cycle) => ({
            title: <CycleCell cycle={cycle} />,
            dataIndex: cycle.id,
            width: 250,
            render: () => (
              <DndBoardColumn
                key={cycle?.id}
                droppableId={cycle?.id}
                isDragDisabled={false}
                height={height - 80}
                renderItem={(objective) => (
                  <OrgChartCardObjective
                    data={objective}
                    score={0}
                    openOkr={handelOpenOkr}
                  />
                )}
                draggableKey={"id"}
                data={[]}
              />
            ),
          }))}
          dataSource={[{ id: "1", name: "33" }]}
        />
      </DragDropContext>
    </DndBoardWrapper>
  );
};
