import styled from "styled-components";
import { Menu } from "../../atoms";
export const UserCellWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0px;
  .user-cell {
    &-info {
      font-size: 12px;
      line-height: 15px;
    }
    &-avatar {
      font-size: 14px;
      height: 32px;
      line-height: 32px;
      min-width: 32px;
      width: 32px;
      background-color: #f9aaef;
      margin-right: 4px;
      transition: 200ms box-shadow, 200ms color, 200ms background, 200ms fill,
        200ms border-color;
      &.small {
        height: 28px;
        line-height: 28px;
        min-width: 28px;
        width: 28px;
      }
      &.xSmall {
        height: 24px;
        line-height: 24px;
        min-width: 24px;
        width: 24px;
      }
    }
    &-full-name {
      font-size: 14px;
      /* font-weight: 500; */
      color: #1e1f21;
      &.small,
      &.xSmall {
        font-size: 12px;
      }
    }
    &-email {
      color: #6d6e6f;
    }
  }
`;

export const CycleCellWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 0px;
  .cycle-cell {
    &-body {
      font-size: 12px;
      line-height: 15px;
      border: 0px solid transparent;
      padding: 4px 5px;
      border-radius: 2px;
    }
    &-color {
      height: 24px;
      line-height: 24px;
      width: 24px;
      border-radius: 50%;
      background-color: #f9aaef;
    }
    &-name {
      font-size: 12px;
      /* font-weight: 500; */
      color: #1e1f21;
    }
    &-period {
      color: #6d6e6f;
    }
  }
`;

export const AvatarGroupCellWrapper = styled.div`
  display: flex;
  align-items: center;
  .avatar-group-cell {
    &-avatar {
      font-size: 14px;
      height: 32px;
      line-height: 32px;
      min-width: 32px;
      width: 32px;
      background-color: #f9aaef;
      margin-right: 4px;
      transition: 200ms box-shadow, 200ms color, 200ms background, 200ms fill,
        200ms border-color;
      &.small {
        height: 28px;
        line-height: 28px;
        min-width: 28px;
        width: 28px;
      }
    }
  }
`;

export const CheckInCellWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #ccc;
  font-weight: 500;
  border: 1px solid #ffffff;
  .checkIn-cell {
    &-info {
      display: flex;
      border-bottom: 1px solid #ffffff;
      font-size: 12px;
      width: 100%;
      justify-content: space-between;
      padding: 2px 10px;
      &-initial {
        border-right: 1px solid #ffffff;
        padding: 5px 10px;
        flex: 1;
      }
      &-target {
        padding: 5px 10px;
        flex: 1;
      }
    }
    &-actual {
      font-size: 14px;
      padding: 5px 10px;
    }
  }
`;

export const MenuActionCellWrapper = styled(Menu)`
  font-size: 14px;
  max-width: 500px;
  min-width: 250px;
  padding: 4px 0;
  .menu-action-cell {
    &-item {
      display: flex;
      border-bottom: 1px solid #ffffff;
      font-size: 14px;
      width: 100%;
      justify-content: space-between;
      padding: 7px 20px;
      /* padding-left: 36px; */
      height: 36px;
      align-items: center;
      display: flex;
      position: relative;
      .ant-dropdown-menu-item-icon {
        min-width: 12px;
        margin-right: 10px;
        font-size: 15px;
        color: #6d6e6f;
      }
      &-divider {
        margin: 0;
        margin-bottom: 5px;
      }
    }
    &-delete {
      color: #f06a6f;
      .ant-dropdown-menu-item-icon {
        color: #f06a6f;
      }
      &:hover {
        color: #ad4453;
        .ant-dropdown-menu-item-icon {
          color: #ad4453;
        }
      }
    }
  }
`;
