import React from "react";
import { get } from "lodash";
import { Draggable } from "react-beautiful-dnd";
import { EmptyResult } from "../EmptyResult/EmptyResult";

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  background: isDragging ? "#F9FBFD" : "white",
  ...draggableStyle,
});

interface IDndBoardItemListProps {
  items: any[];
  itemsCount?: number;
  draggableKey: string;
  height: number;
  renderItem: (obj: any) => React.ReactElement;
  isDragDisabled: boolean;
  loading?: boolean;
}
export const DndBoardItemList: React.FC<IDndBoardItemListProps> = ({
  items,
  itemsCount,
  draggableKey,
  height,
  loading,
  isDragDisabled,
  renderItem,
}) => {
  if (items && items.length === 0) {
    return <EmptyResult subTitle="" title="No Items" />;
  }
  const ItemCom = renderItem;
  return (
    <div
      style={{
        height,
        overflowY: "scroll",
        overflowX: "hidden",
        margin: 0,
        width: "100%",
      }}
    >
      <div className="item_content-list">
        {items &&
          items.map((item, index) => (
            <Draggable
              key={get(item, draggableKey, "")}
              draggableId={get(item, "id", 0).toString()}
              index={index}
              isDragDisabled={isDragDisabled}
            >
              {(provided, snapshot) => (
                <div
                  className="dndColumn__column"
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                  )}
                >
                  <ItemCom {...item} />
                </div>
              )}
            </Draggable>
          ))}
      </div>
    </div>
  );
};
