import moment from "moment";
import { ICheckIn, ICheckInInput } from "../../common/types/checkInTypes";
import { milestoneDataFormat } from "./milestoneDataFormat";

export const checkInDataFormat = (doc: ICheckIn | ICheckInInput) => {
  if (!doc) return undefined;
  if (doc.checkInDate) {
    doc.checkInDate = moment(doc.checkInDate).toDate();
  }

  if (doc.milestone) {
    doc.milestone = milestoneDataFormat(doc.milestone, "write");
  }
  return {
    ...doc,
  };
};
