/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-console */
import { DownOutlined } from "@ant-design/icons";
import { Tree } from "antd";
import React from "react";
import { reduce } from "lodash";
import { IOkr } from "../../../common/types";
import {
  getKeyResultProgress,
  keyResultCheckInNearestDate,
} from "../../../services/keyResultService";
import { OkrListViewWrapper } from "./OkrListView.style";
import { OkrItem } from "./OkrItem";

interface IOkrListProps {
  okrs: IOkr[];
  loading: boolean;
  teamId?: string;
  objectiveId?: string;
  noCascade?: boolean;
}

const getScore = (items: IOkr[]) =>
  reduce(items, (sum, n) => sum + +(n?.score || 0), 0);

const getNestedOkrs: any = (items: IOkr[], id = null) => {
  const currentItems = items.filter((item: IOkr) => item.okrParentId === id);
  return currentItems.map((item) => {
    let childrenItems = [];
    if (item.okrType !== "KEY_RESULT") {
      childrenItems = getNestedOkrs(items, item.id);
      item.score = getScore(childrenItems) / childrenItems.length;
    }
    if (item.okrType === "KEY_RESULT") {
      if (item.checkInRequestDates && item.checkInRequestDates.length > 0) {
        const nearestDate = keyResultCheckInNearestDate(
          item.checkInRequestDates || []
        );
        const currentProgress = getKeyResultProgress(
          nearestDate.target,
          item.targetNumber,
          item.initialNumber,
          item?.score || 0,
          [30, 70]
        );
        item.progress = currentProgress;
      }
    }
    return {
      ...item,
      title: <OkrItem key={item.id} okr={item} />,
      key: item.id,
      children: childrenItems,
    };
  });
};
const getNestedOkrsType: any = (items: IOkr[], id: string, type: "teamId") =>
  items
    .filter((item: IOkr) => item[type] === id)
    .map((item) => ({
      ...item,
      title: <OkrItem key={item.id} okr={item} />,
      key: item.id,
      children: getNestedOkrs(items, item.id),
    }));

const getOkrsRow: any = (items: IOkr[]) =>
  items.map((item) => ({
    ...item,
    title: <OkrItem key={item.id} okr={item} />,
    key: item.id,
    children: [],
  }));

export const OkrList: React.FC<IOkrListProps> = ({
  okrs,
  teamId,
  objectiveId,
  noCascade,
}) => {
  const [data, setData] = React.useState<any[]>([]);
  React.useEffect(() => {
    let okrData = [];
    if (noCascade) {
      okrData = getOkrsRow(okrs);
    } else {
      if (teamId) {
        okrData = getNestedOkrsType(okrs, teamId, "teamId");
      } else if (objectiveId) {
        okrData = getNestedOkrs(okrs, objectiveId);
      } else {
        okrData = getNestedOkrs(okrs);
      }
    }
    setData(okrData);
    return () => {
      setData([]);
    };
  }, [okrs]);
  return (
    <OkrListViewWrapper>
      <Tree
        className="okr-list-view-tree"
        showLine={{ showLeafIcon: false }}
        showIcon={false}
        selectable={false}
        switcherIcon={<DownOutlined />}
        defaultExpandedKeys={[]}
        treeData={data}
      />
    </OkrListViewWrapper>
  );
};
