/* eslint-disable no-console */
import { IOkr } from "../../../common/types";
import {
  CREATE_COMPANY_OKR,
  UPDATE_COMPANY_OKR,
  DELETE_COMPANY_OKR,
  FETCH_COMPANY_OKRS,
  LISTEN_TO_SELECTED_COMPANY_OKR,
  CLEAR_COMPANY_OKRS,
  CLEAR_SELECTED_COMPANY_OKR,
  LISTEN_TO_SELECTED_OKR_KEY_RESULTS,
  LISTEN_TO_SELECTED_OKR_INITIATIVES,
  LISTEN_TO_SELECTED_OKR_CYCLE,
} from "./companyOKRConstants";

const initialState = {
  okrs: [],
  okrsDropDown: [],
  imagesGroups: [],
  moreOKRs: true,
  selectedOkr: null,
  selectedOkrCycle: null,
  selectedOKRKeyResults: [],
  selectedOkrInitiatives: [],
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
};

export default function okrReducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_COMPANY_OKR:
      return {
        ...state,
        okrs: [...state.okrs, payload],
      };
    case UPDATE_COMPANY_OKR:
      return {
        ...state,
        okrs: [
          ...state.okrs.filter((evt: IOkr) => evt.id !== payload.id),
          payload,
        ],
      };
    case DELETE_COMPANY_OKR:
      return {
        ...state,
        okrs: [...state.okrs.filter((evt: IOkr) => evt.id !== payload)],
      };
    case FETCH_COMPANY_OKRS:
      return {
        ...state,
        okrs: payload,
        moreOKRs: payload.moreOKRs,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_COMPANY_OKR:
      return {
        ...state,
        selectedOkr: payload,
      };
    case LISTEN_TO_SELECTED_OKR_KEY_RESULTS:
      return {
        ...state,
        selectedOKRKeyResults: payload,
      };
    case LISTEN_TO_SELECTED_OKR_INITIATIVES:
      return {
        ...state,
        selectedOkrInitiatives: payload,
      };
    case LISTEN_TO_SELECTED_OKR_CYCLE:
      console.log({ payload });
      return {
        ...state,
        selectedOkrCycle: payload,
      };
    case CLEAR_SELECTED_COMPANY_OKR:
      return {
        ...state,
        selectedOKR: null,
      };
    case CLEAR_COMPANY_OKRS:
      return {
        ...state,
        okrs: [],
        moreOKRs: true,
        lastVisible: null,
      };
    default:
      return state;
  }
}
