/* eslint-disable no-console */
import React from "react";
import {
  CheckCircleOutlined,
  CopyOutlined,
  ExportOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { filter, findIndex } from "lodash";
import { useDispatch } from "react-redux";
import {
  EditableTextField,
  EditableFieldForm,
  EditableUserSelectedField,
  EditableSelectedField,
} from "../commonFields";
import { IOkr, IQueryConfigArgs, IUser } from "../../../common/types";
import { Button, Divider, Space } from "../../atoms";
import { SectionCard } from "../CommonComponents";
import { ActionCell } from "../CommonCells";
import {
  IInitiative,
  IInitiativeInputWithId,
} from "../../../common/types/initiativeTypes";
import {
  addCompanyInitiativeToFirestore,
  deleteCompanyInitiativeInFirestore,
  fetchCompanyInitiativesFromFirestore,
  updateCompanyInitiativeInFirestore,
} from "../../../firestore/firestoreService";
import {
  initiativePriorityTypesOptions,
  initiativeStatusTypesOptions,
} from "../../../enums";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { Grid } from "../Grid/Grid";

interface IInitiativeListCardProps {
  keyResultId?: string;
  keyResult?: IOkr;
  me?: IUser;
  action?: () => void;
  viewAll?: string;
  greyHeader?: boolean;
  filters: IQueryConfigArgs[];
}

export const InitiativeListCard: React.FC<IInitiativeListCardProps> = ({
  keyResultId,
  keyResult,
  me,
  action,
  viewAll,
  greyHeader,
  filters = [],
}) => {
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = React.useState<
    IInitiative[] | IInitiativeInputWithId[]
  >([]);
  useFirestoreCollection({
    query: () => fetchCompanyInitiativesFromFirestore([...filters]),
    data: setDataSource,
    deps: [dispatch, filters],
    local: true,
  });
  const handelAddInitiative = () => {
    if (!keyResult || !keyResultId || !me) {
      return;
    }
    const milestone: IInitiativeInputWithId = {
      id: "no-id",
      name: "",
      isSaved: false,
      description: "",
      status: "TO_DO",
      priority: "NORMAL",
      keyResultId,
      ownerId: null,
      owner: null,
      objectiveId: keyResult.okrParentId || "",
      index: dataSource.length + 1,
      dueDate: null,
      cycleId: keyResult.cycleId,
    };
    const index = findIndex(dataSource, ["isSaved", false]);
    if (index === -1) {
      setDataSource([...dataSource, milestone]);
    }
  };
  const handelUpdateInitiative =
    (id: string) => async (name: string, value: any) => {
      const index: number = findIndex(dataSource, ["id", id]);
      if (index !== -1) {
        if (name === `${id}-name`) {
          dataSource[index].name = String(value);
        }
        if (name === `${id}-ownerId`) {
          dataSource[index].ownerId = value.userId;
          dataSource[index].owner = value.user;
        }

        if (name === `${id}-dueDate`) {
          dataSource[index].dueDate = moment(value);
        }
        if (name === `${id}-priority`) {
          dataSource[index].priority = value;
        }
        if (name === `${id}-status`) {
          dataSource[index].status = value;
        }
        dataSource[index].isSaved = true;
        if (id === "no-id") {
          await addCompanyInitiativeToFirestore({
            ...dataSource[index],
          });
        } else {
          await updateCompanyInitiativeInFirestore(id, {
            ...dataSource[index],
          });
        }
      }
    };
  const handelUpdateSortingInitiative = async (id: string, index: number) => {
    await updateCompanyInitiativeInFirestore(id, {
      index,
    });
  };

  const handelDeleteInitiative = (id: string) => async () => {
    const filterDate = filter(dataSource, (item) => item.id !== id);
    setDataSource(filterDate);
    await deleteCompanyInitiativeInFirestore(id);
  };

  // const handelEditInitiative = (id: string) => () => {
  //   dispatch(
  //     openDrawer({
  //       drawerType: "EditInitiativeForm",
  //       drawerProps: {
  //         title: "Add Initiative",
  //         id,
  //       },
  //     })
  //   );
  // };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      className: "drag-visible",
      width: 300,
      render: (v: string, record: any) => (
        <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
          <CheckCircleOutlined
            style={{
              color: record.status === "COMPLETED" ? "#25c2a1" : "#999",
              fontSize: 16,
            }}
          />
          <EditableTextField
            title={"Title"}
            editable
            dataIndex={`${record.id}-name`}
            value={v}
            handleSave={handelUpdateInitiative(record.id)}
          />
        </div>
      ),
    },
    {
      title: "Owner",
      dataIndex: "ownerId",
      className: "drag-visible",
      width: 100,
      render: (v: string, record: any) => (
        <EditableUserSelectedField
          title={"ownerId"}
          key={`${record.id}-ownerId`}
          editable
          dataIndex={`${record.id}-ownerId`}
          record={v}
          shouldExecute={false}
          handleSave={handelUpdateInitiative(record.id)}
          style={{ width: 100 }}
        />
      ),
    },
    {
      title: "Priority",
      dataIndex: "priority",
      className: "drag-visible",
      width: 100,
      render: (v: string, record: any) => (
        <EditableSelectedField
          title={"Priority"}
          editable
          dataIndex={`${record.id}-priority`}
          record={v}
          handleSave={handelUpdateInitiative(record.id)}
          options={initiativePriorityTypesOptions}
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      className: "drag-visible",
      width: 100,
      render: (v: string, record: any) => (
        <EditableSelectedField
          title={"Status"}
          editable
          dataIndex={`${record.id}-status`}
          record={v}
          handleSave={handelUpdateInitiative(record.id)}
          options={initiativeStatusTypesOptions}
        />
      ),
    },
    {
      title: "",
      dataIndex: "action",
      className: "drag-visible",
      render: (v: string, record: any) => (
        <ActionCell
          onDelete={handelDeleteInitiative(record.id)}
          menu={[
            { title: "Duplicate", icon: <CopyOutlined /> },
            { title: "Check-in", icon: <ExportOutlined /> },
          ]}
        />
      ),
    },
  ];
  return (
    <SectionCard
      title="Initiatives"
      greyHeader={greyHeader}
      extra={
        <Space split={<Divider type="vertical" />}>
          {handelAddInitiative && (
            <Button
              type="link"
              className="section-card-header-btn-link"
              onClick={handelAddInitiative}
            >
              <PlusOutlined /> Add initiative
            </Button>
          )}
        </Space>
      }
    >
      {" "}
      <div className="section-card-body">
        <EditableFieldForm>
          <Grid
            columns={columns}
            dataSource={dataSource}
            updateSortOrder={handelUpdateSortingInitiative}
          />
          {keyResult && (
            <div>
              <Button
                type="link"
                style={{ marginTop: 10, marginLeft: 34 }}
                className="section-card-header-btn-link"
                onClick={handelAddInitiative}
              >
                <PlusOutlined /> Add initiatives
              </Button>{" "}
            </div>
          )}
        </EditableFieldForm>
      </div>
    </SectionCard>
  );
};
