/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";
import { userFirestoreDoc } from "../../App/hooks/useFirestoreDoc";
import { useFirestoreCollection } from "../../App/hooks/useFirestoreCollection";
import {
  fetchCompanyInitiativesFromFirestore,
  fetchCompanyOkrsFromFirestore,
  listenToCompanyUserFromFirestore,
} from "../../App/firestore/firestoreService";
import {
  listenToMyProfile,
  listenToMyProfileInitiatives,
  listenToMyProfileOkrs,
} from "../../App/redux/data/myProfile/myProfileActions";

const currentMenu = [
  {
    key: "okrs",
    label: "OKRs",
    link: `/my-okr/okrs`,
  },
  {
    key: "initiatives",
    label: "Initiatives",
    link: `/my-okr/initiatives`,
  },
];

const MyOKRsPage: React.FC = () => {
  const dispatch = useDispatch();
  const { myProfile } = useSelector((state: any) => state.myProfile);
  userFirestoreDoc({
    query: () => listenToCompanyUserFromFirestore(myProfile.id),
    data: listenToMyProfile,
    deps: [dispatch],
  });
  useFirestoreCollection({
    query: () =>
      fetchCompanyOkrsFromFirestore([
        {
          type: "filter",
          name: "ownerId",
          opr: "==",
          value: myProfile.id,
        },
      ]),
    data: listenToMyProfileOkrs,
    deps: [dispatch],
  });
  useFirestoreCollection({
    query: () =>
      fetchCompanyInitiativesFromFirestore([
        {
          type: "filter",
          name: "ownerId",
          opr: "==",
          value: myProfile.id,
        },
      ]),
    data: listenToMyProfileInitiatives,
    deps: [dispatch],
  });
  return (
    <AdminPage title={"MY OKRs"} headerMenu={currentMenu}>
      <Outlet />
    </AdminPage>
  );
};

export default MyOKRsPage;
