/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { closeModal, openModal } from "../../../redux/data/modals/modalReducer";
import { Button, InputFormWrapper } from "../../atoms";

export const RegWelcomeForm: React.FC = (props) => {
  const { myProfile } = useSelector((state: any) => state.myProfile);
  const dispatch = useDispatch();
  const handleSubmit = () => {
    dispatch(closeModal());
    dispatch(
      openModal({
        modalType: "AddBoardModal",
        modalProps: { title: "AddBoardModal" },
      })
    );
  };
  return (
    <ModalWrapper
      {...props}
      onSave={handleSubmit}
      className="full-page"
      footer={null}
    >
      <InputFormWrapper>
        <div className="input-form-body">
          <div className="input-form-title ">
            Well done, {myProfile.displayName}
          </div>

          <div className="input-form-description">
            In order for us to help you get hte best out of Weey, we will guide
            you create your initial setup. In Weey you may create multiple
            boards for different departments or projects. We already created
            your company s board. It is important, before you set any OKR, you
            need to set at least one Cycle. Dont worry. It will only take one
            minute as we will guide you how to do so, by answering a few
            questions. Lets start
          </div>
          <Button type="primary" onClick={handleSubmit}>
            Create Board
          </Button>
        </div>
      </InputFormWrapper>
    </ModalWrapper>
  );
};
