/* eslint-disable no-console */
import React from "react";
import { AuthWrapper, ForgetPassword } from "../../App/components/organisms";

const ForgetPasswordPage: React.FC = () => (
  <AuthWrapper>
    <ForgetPassword />
  </AuthWrapper>
);

export default ForgetPasswordPage;
