import styled from "styled-components";

// theme is now fully typed
export const MobileMenuWrapper = styled.div<{ showMenu: boolean }>`
  background-color: #1d3bf8;
  position: absolute;
  top: 50px;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  transform: translateX(${({ showMenu }) => (showMenu ? "0" : "-100%")});
  transition: all 0.35s ease;
  .mobile-menu {
    &-items {
      display: flex;
      flex-direction: column;
      padding: 60px;
      .ant-divider {
        background-color: #fff;
      }
    }
    &-item {
      font-size: 18px;
      color: #fff;
      font-weight: 100;
      padding: 5px 20px;
    }
    &-footer {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      border-top: 1px solid #fff;
      padding: 20px;
    }
    &-btn {
      background-color: #1d3bf8;
      height: 64px;
      width: 100%;
      color: #fff;
      font-size: 18px;
      font-family: "Avenir";
      font-weight: 900;
    }
  }
`;
