import { IUser } from "../../../common/types";
import {
  CREATE_COMPANY_USER,
  UPDATE_COMPANY_USER,
  DELETE_COMPANY_USER,
  FETCH_COMPANY_USERS,
  LISTEN_TO_SELECTED_COMPANY_USER,
  CLEAR_COMPANY_USERS,
  CLEAR_SELECTED_COMPANY_USER,
} from "./companyUserConstants";

const initialState = {
  users: [],
  usersDropDown: [],
  imagesGroups: [],
  moreUsers: true,
  selectedUser: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
};

export default function userReducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_COMPANY_USER:
      return {
        ...state,
        users: [...state.users, payload],
      };
    case UPDATE_COMPANY_USER:
      return {
        ...state,
        users: [
          ...state.users.filter((evt: IUser) => evt.id !== payload.id),
          payload,
        ],
      };
    case DELETE_COMPANY_USER:
      return {
        ...state,
        users: [...state.users.filter((evt: IUser) => evt.id !== payload)],
      };
    case FETCH_COMPANY_USERS:
      return {
        ...state,
        users: payload,
        moreUsers: payload.moreUsers,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_COMPANY_USER:
      return {
        ...state,
        selectedUser: payload,
      };
    case CLEAR_SELECTED_COMPANY_USER:
      return {
        ...state,
        selectedUser: null,
      };
    case CLEAR_COMPANY_USERS:
      return {
        ...state,
        users: [],
        moreUsers: true,
        lastVisible: null,
      };
    default:
      return state;
  }
}
