import { ICycle } from "../../../common/types";
import {
  CREATE_COMPANY_CYCLE,
  UPDATE_COMPANY_CYCLE,
  DELETE_COMPANY_CYCLE,
  FETCH_COMPANY_CYCLES,
  LISTEN_TO_SELECTED_COMPANY_CYCLE,
  CLEAR_COMPANY_CYCLES,
  CLEAR_SELECTED_COMPANY_CYCLE,
  LISTEN_TO_SELECTED_CYCLE_OKRS,
} from "./companyCycleConstants";

const initialState = {
  cycles: [],
  cyclesDropDown: [],
  imagesGroups: [],
  moreCycles: true,
  selectedCycle: null,
  selectedCycleOkrs: [],
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
};

export default function cycleReducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_COMPANY_CYCLE:
      return {
        ...state,
        cycles: [...state.cycles, payload],
      };
    case UPDATE_COMPANY_CYCLE:
      return {
        ...state,
        cycles: [
          ...state.cycles.filter((evt: ICycle) => evt.id !== payload.id),
          payload,
        ],
      };
    case DELETE_COMPANY_CYCLE:
      return {
        ...state,
        cycles: [...state.cycles.filter((evt: ICycle) => evt.id !== payload)],
      };
    case FETCH_COMPANY_CYCLES:
      return {
        ...state,
        cycles: payload,
        moreCycles: payload.moreCycles,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_COMPANY_CYCLE:
      return {
        ...state,
        selectedCycle: payload,
      };
    case LISTEN_TO_SELECTED_CYCLE_OKRS:
      return {
        ...state,
        selectedCycleOkrs: payload,
      };
    case CLEAR_SELECTED_COMPANY_CYCLE:
      return {
        ...state,
        selectedCycle: null,
      };
    case CLEAR_COMPANY_CYCLES:
      return {
        ...state,
        cycles: [],
        moreCycles: true,
        lastVisible: null,
      };
    default:
      return state;
  }
}
