/* eslint-disable no-console */
import {
  ArrowsAltOutlined,
  CopyOutlined,
  ExportOutlined,
  NodeExpandOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IOkr } from "../../../common/types";
import { closeDrawer } from "../../../redux/data/drawer/drawerReducer";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { Modal } from "../../atoms";
import { ActionCell } from "../CommonCells";

interface IKeyResultActionMenuProps {
  keyResult: IOkr;
  keyResultId: string;
}
export const KeyResultActionMenu: React.FC<IKeyResultActionMenuProps> = ({
  keyResult,
  keyResultId,
}) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const handelAddCheckIn = () => {
    if (!keyResult) return;
    dispatch(
      openModal({
        modalType: "AddCheckInForm",
        modalProps: {
          title: "Create Check In",
          boardId: keyResult.boardId,
          objectiveId: keyResult.okrParentId,
          keyResultId,
          keyResult,
          cycleId: keyResult.cycleId,
        },
      })
    );
  };
  const makeKeyResultObjective = () => {
    if (!keyResult) return;
    dispatch(
      openModal({
        modalType: "CascadeKeyResultModal",
        modalProps: {
          title: "Cascade key result",
          keyResultId,
          keyResult,
        },
      })
    );
  };
  const handelDeleteKeyResult = (id: string) => () => {
    Modal.warning({
      title: `Delete Key Result "${keyResult.title}"`,
      content:
        "Are Sure you want to delete this key result, the delete will be parament",
      cancelText: "Cancel",
      closable: true,
      okText: "Delete",
      onOk: () => console.log("delete"),
    });
  };
  const handelFullPageKeyResult = () => {
    dispatch(closeDrawer());
    history(`/key-result/${keyResultId}/details`);
  };
  const handelDuplicatedKeyResult = () => {
    Modal.warning({
      title: `Duplicated Key Result "${keyResult.title}"`,
      content: "Coming Soon",
      cancelText: "Cancel",
      closable: true,
      okText: "Duplicate",
      onOk: () => console.log("Duplicate"),
    });
  };
  const handelMoveKeyResult = () => {
    Modal.warning({
      title: `Move key result Key Result "${keyResult.title}"`,
      content: "Coming Soon",
      cancelText: "Cancel",
      closable: true,
      okText: "Duplicate",
      onOk: () => console.log("Duplicate"),
    });
  };
  const handelCloseKeyResult = () => {
    Modal.warning({
      title: `Close Key Result "${keyResult.title}"`,
      content: "Coming Soon",
      cancelText: "Cancel",
      closable: true,
      okText: "Close",
      onOk: () => console.log("Close"),
    });
  };
  const handelMakeTemplateKeyResult = () => {
    Modal.warning({
      title: `Create key result template "${keyResult.title}"`,
      content: "Coming Soon",
      cancelText: "Create key result template ",
      closable: true,
      okText: "Close",
      onOk: () => console.log("Create key result template "),
    });
  };
  return (
    <ActionCell
      onDelete={handelDeleteKeyResult(keyResultId)}
      className="drawer-extra-btn-link"
      menu={[
        {
          title: "Full Page key result",
          icon: <ArrowsAltOutlined />,
          action: handelFullPageKeyResult,
        },
        {
          title: "Duplicate key result",
          icon: <CopyOutlined />,
          action: handelDuplicatedKeyResult,
        },
        {
          title: "Check-in",
          icon: <ExportOutlined />,
          action: handelAddCheckIn,
        },
        {
          title: "Cascade key result",
          action: makeKeyResultObjective,
          icon: <VerticalAlignBottomOutlined />,
        },
        {
          title: "Move key result",
          icon: <NodeExpandOutlined />,
          action: handelMoveKeyResult,
        },
        { title: "", divider: true },
        { title: "Close key result", action: handelCloseKeyResult },
        {
          title: "Make key result as template",
          action: handelMakeTemplateKeyResult,
        },
      ]}
    />
  );
};
