import styled from "styled-components";
export const RegistrationWrapper = styled.div`
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  .registration {
    &-header {
      position: fixed;
      background-color: transparent;
      box-shadow: none;
      width: 100%;
      &-logo {
        height: 100%;
        max-width: 1420px;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        padding: 10px 64px;
        overflow: hidden;
        img {
          height: 36px;
        }
      }
    }
    &-footer {
      background-color: #2a2b2c;
      color: #fff;
      padding-bottom: 32px;
      padding-top: 32px;
    }
    &-page {
      flex: 1;
      display: flex;
      &-body {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-aside {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f6f8f9;
        flex: 0 0 33.333%;
        img {
          max-width: 340px;
        }
      }
    }
  }
`;

export const SignUpWrapper = styled.div`
  background-color: #fff;
  width: 600px;
  padding: 40px 60px 60px;
  box-shadow: rgb(64 87 109 / 7%) 0px 1px 4px;
  .sign-up {
    &-title {
      font-size: 32px;
      margin-bottom: 20px;
      text-align: center;
    }
    &-forget-password {
      text-align: right;
      margin-bottom: 30px;
      margin-top: -15px;
    }
    &-up {
      margin-top: 40px;
      text-align: center;
    }
    &-btn {
      background-color: #d65f8e;
      width: 100%;
      color: #fff;
      font-size: 16px;
      height: 50px;
      padding: 0 24px;
      line-height: 50px;
      letter-spacing: 0.5px;
      border-radius: 4px;
    }
  }
`;
