/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";
import { useFirestoreCollection } from "../../App/hooks/useFirestoreCollection";
import {
  fetchCompanyInitiativesFromFirestore,
  fetchCompanyOkrsFromFirestore,
  listenToCompanyFromFirestore,
} from "../../App/firestore/firestoreService";
import { listenToCompanyInitiatives } from "../../App/redux/data/companyInitiative/companyInitiativeActions";
import { userFirestoreDoc } from "../../App/hooks/useFirestoreDoc";
import { listenToSelectedCompany } from "../../App/redux/data/company/companyActions";
import { listenToCompanyOkrs } from "../../App/redux/data/companyOKR/companyOKRActions";

const currentMenu = [
  {
    key: "details",
    label: "Details",
    link: `/company-okrs/details`,
  },
  {
    key: "OKRs",
    label: "OKRs",
    link: `/company-okrs/okrs/chart`,
  },
  // {
  //   key: "initiatives",
  //   label: "Initiatives",
  //   link: `/okr/initiatives`,
  // },
  // {
  //   key: "report",
  //   label: "Report",
  //   link: `/user/${userId}/report`,
  // },
  // {
  //   key: "setting",
  //   label: "Setting",
  //   link: `/user/${userId}/setting`,
  // },
];

const CompanyOKRsPage: React.FC = () => {
  const dispatch = useDispatch();
  const { selectedCompany } = useSelector((state: any) => state.companies);
  const { myProfile } = useSelector((state: any) => state.myProfile);
  userFirestoreDoc({
    query: () => listenToCompanyFromFirestore(),
    data: listenToSelectedCompany,
    deps: [dispatch],
  });
  useFirestoreCollection({
    query: () =>
      fetchCompanyOkrsFromFirestore([
        {
          type: "filter",
          name: "cycleId",
          opr: "==",
          value: myProfile?.defaultCycleId || selectedCompany?.defaultCycleId,
        },
      ]),
    data: listenToCompanyOkrs,
    deps: [dispatch],
  });
  useFirestoreCollection({
    query: () =>
      fetchCompanyInitiativesFromFirestore([
        {
          type: "filter",
          name: "cycleId",
          opr: "==",
          value: myProfile?.defaultCycleId || selectedCompany?.defaultCycleId,
        },
      ]),
    data: listenToCompanyInitiatives,
    deps: [dispatch],
  });
  return (
    <AdminPage title={`${selectedCompany?.name} OKRs`} headerMenu={currentMenu}>
      <Outlet />
    </AdminPage>
  );
};

export default CompanyOKRsPage;
