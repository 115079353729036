/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import {
  AppstoreAddOutlined,
  PlusOutlined,
  SortAscendingOutlined,
} from "@ant-design/icons";
import { Button, Radio } from "../../atoms";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { PageToolbar } from "../../molecules";

interface ICompanyOkrsToolbarProps {
  objectiveId?: string;
  okrView: string;
  onChangeObjectiveView: (v: any) => void;
}
export const CompanyOkrsToolbar: React.FC<ICompanyOkrsToolbarProps> = ({
  objectiveId,
  okrView,
  onChangeObjectiveView,
}) => {
  const dispatch = useDispatch();
  const handelSorting = () => {
    console.log("filterCycles");
  };
  const handelCustomize = () => {
    console.log("filterCycles");
  };

  const handelAddObjective = () => {
    dispatch(
      openModal({
        modalType: "AddObjectiveModal",
        modalProps: {
          title: "Add Objective",
        },
      })
    );
  };
  return (
    <PageToolbar>
      <Button
        type="primary"
        onClick={handelAddObjective}
        icon={<PlusOutlined />}
        className="page-toolbar-btn"
      >
        Add Objective
      </Button>

      <div className="page-toolbar-right-children-elements">
        <Radio.Group
          className="page-toolbar-btn-group"
          value={okrView}
          onChange={onChangeObjectiveView}
        >
          <Radio.Button className="page-toolbar-btn-group-cell" value="chart">
            Chart
          </Radio.Button>
          <Radio.Button className="page-toolbar-btn-group-cell" value="list">
            List
          </Radio.Button>
        </Radio.Group>
        <Button
          type="link"
          onClick={handelSorting}
          icon={<SortAscendingOutlined />}
          className="page-toolbar-btn-link"
        >
          Sorting
        </Button>
        <Button
          type="link"
          onClick={handelCustomize}
          icon={<AppstoreAddOutlined />}
          className="page-toolbar-btn-link"
        >
          Customize
        </Button>
      </div>
    </PageToolbar>
  );
};
