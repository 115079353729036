import styled from "styled-components";
export const AddBoardFormWrapper = styled.div`
  height: 100%;
  overflow: auto;
  .add-board {
    &-body {
      width: 840px;
      margin: 32px auto;
    }
    &-title {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      margin-bottom: 16px;
    }
    &-description {
      font-size: 15px;
      line-height: 32px;
      margin-bottom: 16px;
    }
  }
`;

export const BoardCardWrapper = styled.div`
  .board-card {
    &-header {
      margin-bottom: 5px;
    }
    &-title {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      margin-bottom: 16px;
    }
    &-owner {
    }
    &-description {
      font-size: 14px;
      line-height: 32px;
      color: #7c7d7e;
    }
    &-keywords {
      display: flex;
      margin: 10px 0px;
      flex-wrap: wrap;
      &-name {
        display: block;
        background-color: #229cff13;
        color: #118fe9;
        border: none;
      }
    }
    &-footer {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #edeae9;
      padding: 10px;
      gap: 20px;
    }
    &-stat {
      background-color: #f1f7ff;
      color: #399ceb;
      border: #f1f7ff;
      border-radius: 7px;
      width: 100%;
      font-size: 13px;
      &-value {
        font-weight: 900;
        font-size: 14px;
        margin-right: 5px;
      }
      &-label {
      }
    }
  }
`;
