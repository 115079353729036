/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { InitiativeList } from "../../molecules";
import { BoardWrapper } from "./Board.style";
import { BoardInitiativeListToolbar } from "./BoardInitiativeListToolbar";

interface IBoardInitiativeListProps {
  boardId?: string;
}
export const BoardInitiativeList: React.FC<IBoardInitiativeListProps> = () => {
  const params = useParams();

  const boardId = params.boardId || "";
  const { selectedBoardInitiatives } = useSelector(
    (state: any) => state.boards
  );
  const { loading } = useSelector((state: any) => state.async);

  return (
    <AdminPageBody
      pageToolbar={<BoardInitiativeListToolbar boardId={boardId} />}
    >
      <BoardWrapper>
        <InitiativeList
          initiatives={selectedBoardInitiatives}
          loading={loading}
        />
      </BoardWrapper>
    </AdminPageBody>
  );
};
