import React from "react";
import { useSelector } from "react-redux";
import { find } from "lodash";
import { InputField, UserSelectedField, SelectField } from "../commonFields";
import { Alert, Button, Divider, Form, Input, Space } from "../../atoms";
import {
  checkInRequestTypesOptions,
  keyResultTypesOptions,
  unitTypesOptions,
} from "../../../enums";
import { IOkrForTypes, IOkrKeyResultAmountInput } from "../../../common/types";
import { addCompanyOkrToFirestore } from "../../../firestore/firestoreService";
import { generateKeyResultCheckInDates } from "../../../services/keyResultService";
interface IKeyResultAddAmountTrackingFormProps {
  boardId: string;
  okrParentId: string;
  okrParentIds: string[];
  cycleId: string;
  okrFor: IOkrForTypes;
  cycleStartDate: moment.Moment | Date;
  cycleEndDate: moment.Moment | Date;
  callback: (keyResultId: string) => void;
}
export const KeyResultAddAmountTrackingForm: React.FC<
  IKeyResultAddAmountTrackingFormProps
> = ({
  boardId,
  callback,
  okrParentId,
  cycleId,
  cycleStartDate,
  cycleEndDate,
  okrFor,
  okrParentIds,
}) => {
  const { users } = useSelector((state: any) => state.users);
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleSubmit = async () => {
    try {
      const values: IOkrKeyResultAmountInput = await form.validateFields();
      setLoading(true);
      const owner = find(users, ["id", values.ownerId]);
      const checkInRequest = generateKeyResultCheckInDates({
        startDate: cycleStartDate,
        endDate: cycleEndDate,
        targetNumber: values.targetNumber,
        initialNumber: values.initialNumber,
        calculationMethod: values.calculationMethod,
        checkInRequest: values.checkInRequest,
      });
      const keyResult = await addCompanyOkrToFirestore({
        keyResultType: "NUMBER",
        status: true,
        title: values.title,
        owner,
        ownerId: values.ownerId,
        description: values.description || "",
        okrParentId,
        unitType: values.unitType,
        calculationMethod: values.calculationMethod,
        targetNumber: +values.targetNumber,
        initialNumber: +values.initialNumber,
        dueDate: values.dueDate || "",
        checkInRequest: values.checkInRequest || "",
        boardId: boardId || null,
        okrType: "KEY_RESULT",
        cycleId,
        checkInRequestDates: checkInRequest,
        okrFor,
        okrParentIds: [...(okrParentIds || []), okrParentId],
      });
      setLoading(false);
      callback(keyResult.id);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  return (
    <>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <Form
        form={form}
        name="horizontal_login"
        layout="vertical"
        autoComplete="off"
      >
        <InputField
          label="Key result title"
          name="title"
          rules={[{ required: true, message: "Missing title" }]}
          placeholder="Key result title"
        />
        <Form.Item name="description" label="Description">
          <Input.TextArea placeholder="Description" />
        </Form.Item>
        <UserSelectedField
          label="Who owns this Key Result?"
          name="ownerId"
          boardId={boardId}
          rules={[{ required: true, message: "Missing owner" }]}
        />
        <Space className="input-form-space">
          <SelectField
            label="Unit type"
            name="unitType"
            options={unitTypesOptions}
            rules={[{ required: true, message: "Missing Unit type" }]}
          />
          <SelectField
            label="Calculation Method"
            name="calculationMethod"
            options={keyResultTypesOptions}
            rules={[{ required: true, message: "Missing Calculation Method" }]}
          />
        </Space>
        <Space className="input-form-space">
          <InputField
            label="Initial number"
            name="initialNumber"
            rules={[{ required: true, message: "Missing Initial number" }]}
            placeholder="Initial number"
          />
          <InputField
            label="Target number"
            name="targetNumber"
            rules={[{ required: true, message: "Missing Target number" }]}
            placeholder="Target number"
          />
        </Space>
        <Space className="input-form-space">
          <SelectField
            label="Cascading"
            name="cascading"
            options={[
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
            rules={[{ required: true, message: "Missing Cascading" }]}
            initialValue={"no"}
          />
          <SelectField
            label="CheckIn Request"
            name="checkInRequest"
            options={checkInRequestTypesOptions}
            rules={[{ required: true, message: "Missing CheckIn Request" }]}
          />
        </Space>
        <Divider></Divider>
        <Button
          type="primary"
          className="input-form-btn"
          onClick={handleSubmit}
          loading={loading}
        >
          Create Key Result
        </Button>
      </Form>
    </>
  );
};
