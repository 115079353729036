/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { IOkr } from "../../../common/types";
import { InputFormWrapper, Form, Alert, Button } from "../../atoms";
import { closeModal } from "../../../redux/data/modals/modalReducer";
import { ObjectiveSelectField } from "../../molecules";
import { updateCompanyOkrInFirestore } from "../../../firestore/firestoreService";

interface IAlignmentObjectiveModalProps {
  objectiveId: string;
  objective: IOkr;
}
export const AlignmentObjectiveModal: React.FC<
  IAlignmentObjectiveModalProps
> = ({ objectiveId, objective, ...rest }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    try {
      const values: IOkr = await form.validateFields();
      setLoading(true);
      await updateCompanyOkrInFirestore(objectiveId, {
        okrType: "OBJECTIVE_ALIGNMENT",
        okrParentId: values.okrParentId || "",
        okrParentIds: [...(objective.okrParentIds || []), values.okrParentId],
      });
      setLoading(false);
      dispatch(closeModal());
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  return (
    <ModalWrapper {...rest} footer={null}>
      <InputFormWrapper>
        <div className="input-form-body">
          {error !== "" && (
            <Alert message={error} type="error" style={{ marginBottom: 20 }} />
          )}
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
          >
            <ObjectiveSelectField label="Alignment" name="orkParentId" />

            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              Align Objective
            </Button>
          </Form>
        </div>
      </InputFormWrapper>
    </ModalWrapper>
  );
};
